import { Action, action, createContextStore } from "easy-peasy";

import { ShareClassServiceUpdateData } from "common/shareClass/ShareClassService";

interface CreateShareClassStoreModel {
  formData: ShareClassServiceUpdateData;
  setFormData: Action<this, this["formData"]>;
  updateFormData: Action<this, Partial<this["formData"]>>;
}

const CreateShareClassStore = createContextStore<CreateShareClassStoreModel>({
  formData: {} as ShareClassServiceUpdateData,
  setFormData: action((state, payload) => {
    state.formData = payload;
  }),
  updateFormData: action((state, payload) => {
    state.formData = { ...state.formData, ...payload };
  }),
});

export default CreateShareClassStore;
