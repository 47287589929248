import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";

import { CapitalIncreaseFormGetDTO, TransactionTypesIds } from "../../../types";
import { ImportTransactionFormValues } from "./steps/capital-increase-transactions-table/use-import-transaction-form";

export type OnboardingTransactionPostDTO = {
  transactionIdsToDelete: number[];
  completedOnboarding: boolean;
  transactions: OnboardingTransaction[];
  companyId: number;
};

export type OnboardingTransaction = {
  transactionDate: string;
  transactionType: TransactionTypesIds;
  numberOfShares?: number;
  sharePrice?: number;
  shareClassName: string;
  receivingShareholderId: number;
  sourceShareholderId: number | null;
  transactionId?: number | null;
};

export type OnboardingTransactionGetDTO = {
  shareClasses: {
    id: number;
    name: string;
  }[];
  stakeholders: {
    id: number;
    name: string;
    hasFromShareholder?: boolean;
  }[];
};

interface CapitalIncreaseContextModel {
  isLoading: boolean;
  currentBundle: CapitalIncreaseFormGetDTO;
  transactionsInProgress: ImportTransactionFormValues[];
  step: number;
  maxStep: number;
  defaultShareClassId?: number;
  defaultEventTypeId?: number;
  setIsLoading: Action<this, boolean>;
  setCurrentBundle: Action<this, CapitalIncreaseFormGetDTO>;
  setTransactionsInProgress: Action<this, ImportTransactionFormValues[]>;
  setStep: Action<this, number>;
  setMaxStep: Action<this, number>;
  setDefaultShareClassId: Action<this, number>;
  setDefaultEventTypeId: Action<this, number>;

  // need refactoring
  onboardingTransactions: OnboardingTransactionGetDTO | null;
  setTransaction: Action<this, OnboardingTransactionGetDTO | null>;
  getTransactionsThunk: Thunk<this, number, {}, {}, Promise<AxiosResponse<OnboardingTransactionGetDTO>>>;
  setTransactionsThunk: Thunk<this, OnboardingTransactionPostDTO, {}, {}, Promise<AxiosResponse>>;
}

const CapitalIncreaseContext = createContextStore<CapitalIncreaseContextModel>(
  {
    isLoading: false,
    currentBundle: {
      transactionId: undefined,
      transactionBundleId: undefined,
      transactedAt: createDateString(new Date(), true),
      description: "",
      eventName: "",
      sharePrice: 0,
      numberOfShares: 0,
      statusId: 1,
      documentFiles: [],
      transactions: [],
    },
    transactionsInProgress: [],
    step: 1,
    maxStep: 2,
    defaultShareClassId: undefined,
    defaultEventTypeId: undefined,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),
    setCurrentBundle: action((state, payload) => {
      state.currentBundle = payload;
    }),
    setTransactionsInProgress: action((state, payload) => {
      state.transactionsInProgress = payload;
    }),
    setStep: action((state, payload) => {
      state.step = payload;
    }),
    setMaxStep: action((state, payload) => {
      state.maxStep = payload;
    }),
    setDefaultShareClassId: action((state, payload) => {
      state.defaultShareClassId = payload;
    }),
    setDefaultEventTypeId: action((state, payload) => {
      state.defaultEventTypeId = payload;
    }),

    // need refactoring
    // some code moved from onboarding have no idea why part of transactions dependent on it
    onboardingTransactions: null,
    setTransaction: action((state, payload) => {
      state.onboardingTransactions = payload;
    }),
    getTransactionsThunk: thunk(async (actions, companyId) => {
      try {
        const req = await axios.get<OnboardingTransactionGetDTO>(`/api/onboarding/company/transactions/${companyId}`);
        if (req.data) {
          actions.setTransaction(req.data);
        }
        return req;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    setTransactionsThunk: thunk(async (actions, payload) => {
      try {
        return await axios.post("/api/onboarding/company/transactions", payload);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
  },
  {
    name: "capitalIncrease",
  }
);
export default CapitalIncreaseContext;
