import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { Shareholder, ShareholderEditFormValues } from "../../types";

const t = createTranslation(TranslationNS.validation);

const NORWAY_COUNTRY_ID = 166;

export const fields = {
  stakeholderId: "stakeholderId",
  shareholderName: "shareholderName",
  isin: "isin",
  numberOfShares: "numberOfShares",
  adresse: "adresse",
  postnummer: "postnummer",
  poststed: "poststed",
  land: "land",
  shareClass: "shareClass",
} as const;

const initValue: ShareholderEditFormValues = {
  [fields.stakeholderId]: 0,
  [fields.shareholderName]: "",
  [fields.isin]: "",
  [fields.adresse]: "",
  [fields.postnummer]: undefined,
  [fields.poststed]: undefined,
  [fields.land]: 0,
  [fields.numberOfShares]: 0,
};

const whenCountryBuilder = {
  is: (countryId: string) => Number(countryId) === NORWAY_COUNTRY_ID,
  then: (schema: Yup.StringSchema<string | undefined, Object, string | undefined>) =>
    schema.required(() => t("required")),
};

const objectSchema = Yup.object().shape({
  [fields.isin]: Yup.string().when(fields.land, whenCountryBuilder),
  [fields.adresse]: Yup.string()
    .test(
      fields.adresse,
      () => t("maxSymbols", { number: 35 }),
      (value) => {
        return (value?.length || 0) <= 35;
      }
    )
    .when(fields.land, whenCountryBuilder),
  [fields.land]: Yup.string().required(() => t("required")),
  [fields.postnummer]: Yup.string().when(fields.land, whenCountryBuilder),
  [fields.poststed]: Yup.string().when(fields.land, whenCountryBuilder),
});

const useEditShareholdersForm = (shareholders: Shareholder[]) => {
  const initialValues: ShareholderEditFormValues[] = useMemo(() => {
    if (shareholders.length === 0) {
      return [initValue];
    }

    return shareholders.map((shareholder) => ({
      [fields.stakeholderId]: shareholder.stakeholderId,
      [fields.shareholderName]: shareholder.shareholderName,
      [fields.isin]: shareholder.isin || "",
      [fields.numberOfShares]: shareholder.numberOfShares,

      [fields.adresse]: shareholder?.address || "",
      [fields.land]: shareholder?.countryId || NORWAY_COUNTRY_ID,
      [fields.postnummer]: shareholder?.postalCode || undefined,
      [fields.poststed]: shareholder?.postalCity || undefined,
    }));
  }, [shareholders]);

  const validationSchema = useMemo(() => {
    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useEditShareholdersForm;
