import React, { FC, useMemo } from "react";

import { sortCaptableData } from "common/utils/functions";
import { TaxableValue } from "store/types";

import classes from "../../valuation.module.scss";
import { ValuationTaxableValueTableSortValue } from "./ValuationTaxableValueTable";
import ValuationTaxableValueTableRow from "./ValuationTaxableValueTableRow";

type Props = {
  data: TaxableValue[];
  sortedValue: ValuationTaxableValueTableSortValue;
};

const ValuationTaxableValueTableBody: FC<Props> = ({ data, sortedValue }) => {
  const sortedData = useMemo(() => {
    return sortCaptableData({
      data,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [data, sortedValue.field, sortedValue.type]);

  return (
    <tbody className={classes["table-body"]}>
      {sortedData.map((el) => (
        <ValuationTaxableValueTableRow key={el.id} {...el} />
      ))}
    </tbody>
  );
};

export default ValuationTaxableValueTableBody;
