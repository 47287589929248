import { FC } from "react";

import { LinkHelpText, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import useSelectedCountry from "common/hooks/useSelectedCountry";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../valuation.module.scss";
import CompanyValuationChart from "./CompanyValuationChart";
import SharesBlocksInfo from "./SharesBlocksInfo/SharesBlocksInfo";
import TaxableBlocksInfo from "./TaxableBlocksInfo/TaxableBlocksInfo";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationOverview: FC = () => {
  const fNumber = useFormatNumbers();
  const { isSelectedCountryNorway } = useSelectedCountry();

  const valuation = useStoreState((state) => state.company.valuation);

  return (
    <div>
      <div className={classes["overtview-chart"]}>
        <div className="d-flex justify-content-between w-100 mb-5">
          <div>
            <Ui.xl bold color="foregroundHigh" className="mb-1">
              {t("companyValuation")}
            </Ui.xl>
            <Ui.s>{t("totalValue")}</Ui.s>
          </div>
          <div>
            <Ui.xl bold color="foregroundHigh" className="mb-1 text-end">
              {fNumber(valuation?.latestValuation || 0)}
            </Ui.xl>
            <Ui.s>
              {fNumber(
                (valuation?.fdSharePriceIncUnusedSharesInPools || 0) * (valuation?.fdWithUnusedPoolsTotalShares || 0)
              )}{" "}
              fully diluted{" "}
              <LinkHelpText
                type="modal"
                placement="left"
                title={t("fdHelper.companyValuation.title")}
                content={t("fdHelper.companyValuation.description")}
                // className={classes.tooltip}
              >
                <QuestionCircleIcon color={scssVariables.foregroundLow} width={16} height={16} />
              </LinkHelpText>
            </Ui.s>
          </div>
        </div>

        <CompanyValuationChart />
      </div>

      <SharesBlocksInfo />

      {isSelectedCountryNorway && <TaxableBlocksInfo />}
    </div>
  );
};

export default ValuationOverview;
