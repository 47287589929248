import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { ContextMenuProps, Ui } from "common/components/atoms";
import { formatNumber } from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import NameCell from "../../../../../../../fundraising/components/DashboardTable/NameCell/NameCell";
import { CapTableChange } from "../../../../types";

const t = createTranslation(
  TranslationNS.pages,
  "company.transactions.importTransactions.captablePreview.tablesHeaders"
);

const useCapTablePreview = (currencySymbol?: string, _menuClickCallback?: ContextMenuProps["menuClickCallback"]) => {
  const columns = useMemo<ColumnDef<CapTableChange>[]>(
    () => [
      {
        accessorKey: "initials",
        cell: (info) => {
          return (
            <NameCell
              initials={info.getValue() as string}
              name={info.row.original.shareholderName}
              representByName={info.row.original.representedBy}
              isCompany={info.row.original.isCompanyOwned}
              showBlueDot={!info.row.original.removedFromCaptable && info.row.original.hasChanges}
              showRedDot={info.row.original.removedFromCaptable}
              isAttached={false}
            />
          );
        },
        header: () => <Ui.s tag="span">{t("shareholder")}</Ui.s>,
        footer: ({ table }) => {
          return <Ui.s tag="span" bold>{`${table.getFilteredRowModel().rows.length} stakeholders`}</Ui.s>;
        },
        enableResizing: false,
        meta: { footerColSpan: 3 },
      },
      {
        accessorKey: "shareClasses",
        cell: (info) => {
          const shareClasses = info.getValue() as string[];
          return <Ui.s>{shareClasses.map((shareClass) => shareClass + " ") || "-"}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("shareClass")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "relationship",
        cell: (info) => <Ui.s>{info.getValue() as string}</Ui.s>,
        header: () => <Ui.s tag="span">{t("relationship")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "shares",
        cell: (info) => {
          return <Ui.s>{formatNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("shares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footClass: "right", footerColSpan: 1 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table.getFilteredRowModel().rows.reduce((sum, row) => +sum + +(row.original.shares || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {formatNumber(total)}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "ownershipPercentage",
        cell: (info) => (
          <Ui.s>{formatNumber(info.getValue() as number, { decimals: "percent", allowZeros: true })} %</Ui.s>
        ),
        header: () => <Ui.s tag="span">{t("ownership")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "totalValue",
        cell: (info) => (
          <Ui.s>
            {formatNumber(info.getValue() as number)} {currencySymbol}
          </Ui.s>
        ),
        header: () => <Ui.s tag="span">{t("totalValue")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footClass: "right", footerColSpan: 2 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.totalValue || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {formatNumber(total)} {currencySymbol}
            </Ui.s>
          );
        },
      },
    ],
    [currencySymbol]
  );

  return { columns };
};
export default useCapTablePreview;
