import React from "react";
import { useNavigate } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import { ManageEquityIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Card.module.scss";

const t = createTranslation(TranslationNS.pages, "company.newUser");

const EmptyCompanyCard = () => {
  const navigate = useNavigate();

  return (
    <div className={classes["card"]}>
      <div className={classes["block-icons"]}>
        <ManageEquityIcon />
      </div>
      <h3 className="m-0 head-xxxs mt-5 mb-2">{t("manageTitle")}</h3>
      <p className={`${classes["subtitle"]} m-0 ui-s mb-6`}>{t("manageSubtitle")}</p>

      <Button
        size="s"
        onClick={() => {
          navigate(getPath(["onboard", "company", "getStarted"]));
        }}
      >
        {t("manageBtn")}
      </Button>
    </div>
  );
};

export default EmptyCompanyCard;
