import { FC, useCallback, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { Button, H, P, Ui } from "common/components/atoms";
import { animationStakeholder } from "common/components/molecules/StakeholderSection/fields/RenderStakeholderFields/RenderStakeholderFields";
import { ChevronDownIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import ConversionDateField from "../../fields/ConversionDateField";
import InterestAmountField from "../../fields/InterestAmountField";
import InterestPaidInCashField from "../../fields/InterestPaidInCashField";
import classes from "./Interest.module.scss";

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.interest");

type Props = {
  className?: string;
};

const Interest: FC<Props> = ({ className }) => {
  const [direction, setDirection] = useState(false);

  const handleView = useCallback(() => {
    setDirection((prev) => !prev);
  }, []);

  return (
    <div className={classNames(classes.wrap, className)}>
      <H.xxs className="mb-4">{t("title")}</H.xxs>
      <div>
        <Button
          variant="secondary"
          size="s"
          iconLeft={<ChevronDownIcon direction={direction ? "top" : "bottom"} />}
          onClick={handleView}
          className="mb-2"
        >
          {!direction ? t("viewCalculation") : t("hideCalculation")}
        </Button>
        <motion.div initial="close" variants={animationStakeholder} animate={direction ? "open" : "close"}>
          <div className={classes.formula}>
            <P.m className="mb-3">{t("compoundInterest")}</P.m>
            <Ui.m className="mb-3" bold>
              {t("formula")}
            </Ui.m>
            <P.s color="foregroundMedium">{t("formulaP")}</P.s>
            <P.s color="foregroundMedium">{t("formulaR")}</P.s>
            <P.s color="foregroundMedium">{t("formulaD")}</P.s>
          </div>
        </motion.div>
      </div>
      <ConversionDateField className="mt-4" />
      <InterestAmountField className="mt-4" />
      <InterestPaidInCashField className="mt-4" />
    </div>
  );
};

export default Interest;
