import { FC, ReactNode, RefObject, useCallback, useEffect, useState } from "react";
import { AccordionProps, ButtonProps, Offcanvas, OffcanvasProps, Spinner } from "react-bootstrap";
import classNames from "classnames";
import * as R from "ramda";

import { ChevronDownIcon, CloseIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import Button from "../Button/Button";
import CollapsibleContainer from "../Collapsible/Collapsible";
import Tag, { TagVariant } from "../Tag/Tag";
import { H } from "../Typography";
import classes from "./SlidePanel.module.scss";

const t = createTranslation(TranslationNS.common, "components.slidePanel");

type Props = {
  show: boolean;
  onHide?: () => void;
  className?: string;
  bodySectionClassName?: string;
  customRef?: RefObject<HTMLDivElement>;
};

type SlidePanelComponent = FC<Props & OffcanvasProps> & {
  Header: typeof SlidePanelHeader;
  Section: typeof SlidePanelSection;
  Actions: typeof SlidePanelActions;
  Body: typeof SlidePanelBody;
};

const SlidePanel: SlidePanelComponent = ({
  show,
  children,
  className,
  placement = "end",
  onHide,
  customRef,
  bodySectionClassName,
  ...props
}) => {
  return (
    <Offcanvas
      scroll
      show={show}
      placement={placement}
      data-testid="offcanvas-container-test-id"
      id={classes["container"]}
      className={classNames(classes["slide-panel-container"], className)}
      {...props}
    >
      <Offcanvas.Body className="p-0" ref={customRef}>
        <div className={classNames("slide-panel-content-setion", classes["content"], bodySectionClassName)}>
          {children}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

// Sub-components

type HeaderProps = {
  id?: string;
  title?: string;
  isEdit?: boolean;
  isDraft?: boolean;
  closeBtn?: boolean;
  tagVariant?: TagVariant;
  tagText?: string;
  children?: ReactNode;
  onHide?: Props["onHide"];
};

const SlidePanelHeader: FC<HeaderProps> = ({
  id,
  title,
  children,
  closeBtn = true,
  isEdit,
  isDraft,
  onHide,
  tagVariant,
  tagText,
}) => {
  if (!R.isNil(children)) {
    return <div className={classes["header"]}>{children}</div>;
  }

  return (
    <div className={classes["header"]} id={id}>
      <div className="d-flex align-items-center">
        <H.xs>{title}</H.xs>
        {isEdit && (
          <Tag className={classNames(classes["tag"], "ms-2")} variant="information">
            {t("edit")}
          </Tag>
        )}
        {isDraft && (
          <Tag className={classNames(classes["tag"], "ms-2")} variant="draft">
            {t("draft")}
          </Tag>
        )}
        {tagVariant && (
          <Tag className={classNames(classes["tag"], "ms-2")} variant={tagVariant}>
            {tagText}
          </Tag>
        )}
      </div>
      {closeBtn && (
        <Button variant="dark" size="s" isOnlyIcon onClick={onHide}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

type SectionProps = {
  isSingle?: boolean;
  withoutHeader?: boolean;
  title: string | ReactNode;
  isDividerVisible?: boolean;
  containerClassName?: string;
};

const SlidePanelSection: FC<SectionProps & Omit<AccordionProps, "title">> = ({
  title,
  isSingle,
  isDividerVisible = true,
  children,
  withoutHeader,
  className,
  containerClassName,
  ...props
}) => {
  const [isDividerShown, setIsDividerShown] = useState<boolean>(isDividerVisible);

  const RenderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div
          className={classNames(classes["section-head"], "pt-5", "pb-3", className)}
          onClick={isSingle ? undefined : onClick}
        >
          {typeof title === "string" ? <H.xxs>{title}</H.xxs> : title}

          {isSingle ? null : (
            <ChevronDownIcon
              fontSize={24}
              className={classes["chevron"]}
              direction={activeEventKey ? "top" : "bottom"}
            />
          )}
        </div>
      );
    },
    [isSingle, title, className]
  );

  useEffect(() => {
    if (isSingle) {
      setIsDividerShown(false);
    }
  }, [isSingle]);

  return (
    <CollapsibleContainer
      defaultOpen
      withoutDivider
      className={classNames(classes["section"], containerClassName)}
      Header={withoutHeader ? () => null : RenderHead}
      {...props}
    >
      <div>
        {children}
        {isDividerShown && <div className={classNames(classes["divider"], "mt-5")} />}
      </div>
    </CollapsibleContainer>
  );
};

type ActionsProps = {
  isLoading?: boolean;
  primaryTitle?: string;
  secondaryTitle?: string;
  primaryAction?: ButtonProps["onClick"];
  secondaryAction?: ButtonProps["onClick"];
  isDisabledPrimary?: boolean;
  className?: string;
  children?: ReactNode;
};

const SlidePanelActions: FC<ActionsProps> = ({
  children,
  isLoading,
  primaryTitle,
  secondaryTitle,
  primaryAction,
  secondaryAction,
  isDisabledPrimary,
  className,
}) => {
  if (!R.isNil(children)) {
    return <div className={classNames(classes["actions"], className)}>{children}</div>;
  }

  const isPrimaryButtonExist = R.and(!R.isNil(primaryTitle), !R.isNil(primaryAction));
  const isSecondaryButtonExist = R.and(!R.isNil(secondaryTitle), !R.isNil(secondaryAction));

  return (
    <div className={classNames(classes["actions"], className)}>
      {R.and(
        isPrimaryButtonExist,
        <Button
          isFocusDisabled
          isLoading={isLoading}
          isDisabled={isDisabledPrimary || isLoading}
          onClick={primaryAction}
        >
          {primaryTitle}
        </Button>
      )}

      {R.and(
        isSecondaryButtonExist,
        <Button
          isFocusDisabled
          variant="secondary"
          isLoading={isLoading}
          isDisabled={isLoading}
          className={`${isPrimaryButtonExist ? "ms-3" : ""}`}
          onClick={secondaryAction}
        >
          {secondaryTitle}
        </Button>
      )}
    </div>
  );
};

const SlidePanelBody: FC<{ isLoading?: boolean; children: ReactNode }> = ({ children, isLoading }) => {
  return (
    <div className={classes.body}>
      {isLoading ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

SlidePanel.Header = SlidePanelHeader;
SlidePanel.Section = SlidePanelSection;
SlidePanel.Body = SlidePanelBody;
SlidePanel.Actions = SlidePanelActions;

export default SlidePanel;
