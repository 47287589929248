import { useCallback } from "react";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, ExpansionPanel, Ui } from "common/components/atoms";
import { LinkIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import CapTablePreview from "./CapTablePreview/CapTablePreview";

const t = createTranslation(TranslationNS.pages, "fundraising.preview.capTable");

const FundraiseCapTable = () => {
  const handleOpenCapTablePage = useCallback(() => {
    window.open(getEMPath(["ownership", "capTable"]));
  }, []);

  return (
    <div>
      <ExpansionPanel.Toggle eventKey="1">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              {t("title")}
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              {t("subTitle")}
            </Ui.xs>
          </div>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey="1">
        <div className="d-flex ms-4 me-4 gap-4">
          <Ui.m>{t("description")}</Ui.m>
          <div>
            <Button
              className="text-nowrap"
              variant="secondary"
              iconRight={<LinkIcon />}
              onClick={handleOpenCapTablePage}
            >
              {t("viewCapTable")}
            </Button>
          </div>
        </div>
        <div className="px-4 pb-4">
          <div className="mb-3" />
          <CapTablePreview />
        </div>
      </ExpansionPanel.Collapse>
    </div>
  );
};

export default FundraiseCapTable;
