import { FC, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import cn from "classnames";
import { isEmpty } from "ramda";

import { Button } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";

import { ChevronLeftIcon, ChevronRightIcon } from "../icons/svg";
import Header from "./header";
import GuideCard from "./learn-more-modals/guide-card/guide-card";
import LearnMoreWrapper from "./learn-more-modals/learn-more-wrapper";

export const eventKey = "GuidePlate";

type PT = {
  className?: string;
};

const GUIDES_PER_PAGE = 4;

const GuidesPlate: FC<PT> = ({ className }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const guides = useStoreState((state) => state.guideModel.populatedGuides);
  const isExpanded = useStoreState((state) => state.guideModel.isGuidesPlateExpanded);

  const guidesPaginated = useMemo(() => {
    if (guides && !isEmpty(guides)) {
      const guidesAsArray = Object.values(guides);

      return Array.from({ length: Math.ceil(guidesAsArray.length / GUIDES_PER_PAGE) }, (_, i) =>
        guidesAsArray.slice(i * GUIDES_PER_PAGE, i * GUIDES_PER_PAGE + GUIDES_PER_PAGE)
      );
    }
  }, [guides]);

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  return (
    <>
      <div className={cn(className, "p-3 rounded-2")} style={{ backgroundColor: scssVariables.surface1 }}>
        <Accordion flush defaultActiveKey={isExpanded ? eventKey : null}>
          <Header />
          <Accordion.Collapse eventKey={eventKey}>
            <>
              <div className="d-flex mt-2 mb-2 gap-2">
                {guidesPaginated?.[currentPage].map((guide) => (
                  <GuideCard key={guide.id} guide={guide} />
                ))}
              </div>

              <div className="d-flex justify-content-between">
                <Button isOnlyIcon size="s" variant="secondary" isDisabled={currentPage <= 0} onClick={prevPage}>
                  <ChevronLeftIcon />
                </Button>
                <Button
                  isOnlyIcon
                  size="s"
                  variant="secondary"
                  isDisabled={currentPage + 1 >= (guidesPaginated?.length || 0)}
                  onClick={nextPage}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>

      <LearnMoreWrapper />
    </>
  );
};

export default GuidesPlate;
