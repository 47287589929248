import { Action, action, createContextStore } from "easy-peasy";

import { TaxableValue, ValuationSingle } from "store/types";

import { ValuationHistoryTabsTypes } from "./components/ValuationTabs/ValuationTabs";

interface ValuationContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  selectedRowId: number | undefined;
  setSelectedRowId: Action<this, this["selectedRowId"]>;

  isValuationEdit: boolean;
  setIsValuationEdit: Action<this, this["isValuationEdit"]>;

  isTaxableValueEdit: boolean;
  setIsTaxableValueEdit: Action<this, this["isTaxableValueEdit"]>;

  removeValuation: ValuationSingle | null;
  setRemoveValuation: Action<this, this["removeValuation"]>;

  removeTaxableValue: TaxableValue | null;
  setRemoveTaxableValue: Action<this, this["removeTaxableValue"]>;

  valuationHistorySelectedTad: ValuationHistoryTabsTypes;
  setValuationHistorySelectedTad: Action<this, this["valuationHistorySelectedTad"]>;
}

const ValuationContext = createContextStore<ValuationContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    selectedRowId: undefined,
    setSelectedRowId: action((state, payload) => {
      state.selectedRowId = payload;
    }),

    isValuationEdit: false,
    setIsValuationEdit: action((state, payload) => {
      state.isValuationEdit = payload;
    }),
    isTaxableValueEdit: false,
    setIsTaxableValueEdit: action((state, payload) => {
      state.isTaxableValueEdit = payload;
    }),

    removeValuation: null,
    setRemoveValuation: action((state, payload) => {
      state.removeValuation = payload;
    }),

    removeTaxableValue: null,
    setRemoveTaxableValue: action((state, payload) => {
      state.removeTaxableValue = payload;
    }),

    valuationHistorySelectedTad: "valuation",
    setValuationHistorySelectedTad: action((state, payload) => {
      state.valuationHistorySelectedTad = payload;
    }),
  },
  {
    name: "Equity management. Valuation",
  }
);

export default ValuationContext;
