import BTable from "react-bootstrap/Table";
import { flexRender, Table } from "@tanstack/react-table";
import cn from "classnames";

import { FilledArrowDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "./OverviewTable.module.scss";

type OverviewTableProps<T extends object> = {
  table: Table<T>;
  withHover?: boolean;
  handleClickRow?: (row: T) => void;
};

const OverviewTable = <T extends object>({ table, withHover, handleClickRow }: OverviewTableProps<T>) => {
  return (
    <div className={classes["table-container"]}>
      <BTable className={cn(classes.table, { [classes.withHover]: withHover }, classes["scrollable-table"])}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const canResize = header.column.getCanResize();
                const maxWidth = canResize ? header.column.columnDef.maxSize : undefined;
                const minWidth = canResize ? header.column.columnDef.minSize : undefined;
                const width = canResize ? header.column.getSize() : undefined;

                return (
                  <th key={header.id} colSpan={header.colSpan} style={{ maxWidth, minWidth, width }}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: cn("d-flex align-items-center", header.column.columnDef.meta?.headClass, {
                            "cursor-pointer select-none": header.column.getCanSort(),
                          }),
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.getIsSorted() && (
                          <span className={classes.sorting}>
                            <FilledArrowDownIcon
                              style={{
                                color: scssVariables.foregroundHigh,
                                transition: "all 0.2s",
                                transform:
                                  (header.column.getIsSorted() as string) === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const canResize = cell.column.getCanResize();
                  const maxWidth = canResize ? cell.column.columnDef.maxSize : undefined;
                  const minWidth = canResize ? cell.column.columnDef.minSize : undefined;
                  const width = canResize ? cell.column.getSize() : undefined;

                  return (
                    <td
                      onClick={() => {
                        cell.column.columnDef.meta?.allowClick && handleClickRow?.(row.original);
                      }}
                      key={cell.id}
                      style={{
                        maxWidth,
                        minWidth,
                        width,
                      }}
                    >
                      <div
                        className={cn("d-flex align-items-center", cell.column.columnDef.meta?.bodyClass, {
                          "w-100": cell.id.includes("companyId"),
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </div>
  );
};

export default OverviewTable;
