import { FC, Fragment, memo, ReactNode, RefObject, useMemo } from "react";
import cn from "classnames";

import { InfoAlert, P, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import {
  ArrowUpIcon,
  CommonFileAddIcon,
  DeleteIcon,
  FilledArrowDownIcon,
  InformationCircleIcon,
  SplitIcon,
  SynchronizeArrowsSquareIcon,
  TotalValueIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory, TransactionCategoryIds, TransactionStatus } from "../../types";
import TransactionItem from "./transaction-item/transaction-item";
import classes from "./transaction-item/transaction-item.module.scss";

export type TransactionListProps = {
  transactions: Transaction[];
  handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
  handleOpenConfirmTransaction?: (transaction: Transaction) => void;
  handleOpenDeleteTransaction?: (transactionId: number) => void;
  handleOpenDeleteCapitalIncrease?: (capitalIncreaseId: number) => void;
  handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  scrollRef?: RefObject<HTMLUListElement>;
  bundleView?: boolean;
  portfolioView?: boolean;
  importView?: boolean;
  isMini?: boolean;
  isFilteredBySearch?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

export const TransactionIcons: Record<TransactionCategoryIds, ReactNode> = {
  [TransactionCategory.Issue]: <CommonFileAddIcon width={24} height={24} />,
  [TransactionCategory.Sell]: <SynchronizeArrowsSquareIcon width={24} height={24} />,
  [TransactionCategory.Split]: <SplitIcon width={24} height={24} />,
  [TransactionCategory.CapitalIncrease]: <ArrowUpIcon width={24} height={24} />,
  [TransactionCategory.ChangeNominalValue]: <FilledArrowDownIcon width={24} height={24} />,
  [TransactionCategory.Deletion]: <DeleteIcon width={24} height={24} />,
  [TransactionCategory.ShareTransfer]: <SynchronizeArrowsSquareIcon width={24} height={24} />,
} as const;

const TransactionsList: FC<TransactionListProps> = memo(
  ({
    transactions,
    handleOpenEditTransaction,
    handleOpenConfirmTransaction,
    handleOpenDeleteTransaction,
    handleOpenDeleteCapitalIncrease,
    handleOpenRollbackTransaction,
    scrollRef,
    bundleView = false,
    portfolioView = false,
    importView = false,
    isMini,
    isFilteredBySearch,
  }) => {
    const fn = useFormatNumbers();

    const hasPendingTransactions = useMemo(
      () =>
        bundleView ? false : transactions.find((transaction) => transaction.statusId === TransactionStatus.Pending),
      [bundleView, transactions]
    );

    if (!transactions.length && !bundleView) {
      if (isFilteredBySearch) {
        return (
          <div className={cn(classes.empty, "px-5 py-4 d-flex")}>
            <InformationCircleIcon fontSize="24" color={scssVariables.foregroundLow} />
            <div className="ms-2">
              <Ui.l bold className="mb-2">
                {t("empty.searchedTitle")}
              </Ui.l>

              <P.m color="foregroundMedium">{t("empty.searchedDescription")}</P.m>
            </div>
          </div>
        );
      }

      return (
        <div className={cn(classes.empty, "px-5 py-4")}>
          <Ui.l bold className="mb-2">
            {t("empty.title")}
          </Ui.l>

          <P.m color="foregroundMedium">
            {t.el(portfolioView ? "empty.portfolioDescription" : "empty.description", {
              components: [
                <Fragment key={1}>
                  <br />
                  <br />
                </Fragment>,
              ],
            })}
          </P.m>
        </div>
      );
    }

    return (
      <>
        {hasPendingTransactions && !portfolioView && (
          <InfoAlert
            className="p-3 mb-4"
            closable
            customContent={
              <div className="d-flex">
                <InformationCircleIcon height={24} width={56} color={scssVariables.information700} />
                <div className="ms-2">
                  <Ui.m bold className="mb-1">
                    {t("pendingTransactionsInfo.title")}
                  </Ui.m>
                  <Ui.s>{t("pendingTransactionsInfo.content")}</Ui.s>
                </div>
              </div>
            }
          />
        )}

        <Step customRef={scrollRef}>
          {transactions.map((transaction) => {
            const isConfirmedSplit =
              transaction.categoryId === TransactionCategory.Split &&
              transaction.statusId === TransactionStatus.Confirmed;

            const variant =
              transaction.categoryId === TransactionCategory.CapitalIncrease ||
              transaction.categoryId === TransactionCategory.ShareTransfer
                ? "pill"
                : "circle";

            return (
              <Fragment key={transaction.transactionId}>
                <Step.Item
                  id={String(transaction.transactionId)}
                  variant={variant}
                  status={
                    transaction.statusId === TransactionStatus.Pending ? "pending-transaction" : "committed-transaction"
                  }
                >
                  <div>
                    <Step.Point
                      icon={
                        transaction.categoryId === TransactionCategory.ChangeNominalValue ? (
                          <TotalValueIcon
                            fontSize={24}
                            color={
                              transaction.statusId === TransactionStatus.Pending
                                ? scssVariables.foregroundMedium
                                : scssVariables.positive900
                            }
                          />
                        ) : (
                          TransactionIcons[transaction.categoryId]
                        )
                      }
                      variant={variant}
                    />
                  </div>
                  <TransactionItem
                    className="ms-3"
                    transaction={transaction}
                    handleOpenEditTransaction={handleOpenEditTransaction}
                    handleOpenConfirmTransaction={handleOpenConfirmTransaction}
                    handleOpenDeleteTransaction={handleOpenDeleteTransaction}
                    handleOpenDeleteCapitalIncrease={handleOpenDeleteCapitalIncrease}
                    handleOpenRollbackTransaction={handleOpenRollbackTransaction}
                    portfolioView={portfolioView}
                    importView={importView}
                    isMini={isMini}
                  />
                </Step.Item>

                {isConfirmedSplit && (
                  <Ui.xs className={cn(classes.splitDivider, "py-1 px-2 mb-2")}>{`${transaction.categoryName} 1:${fn(
                    transaction.multiplier,
                    "unitPrice",
                    {
                      hideCurrencySymbol: true,
                    }
                  )}`}</Ui.xs>
                )}
              </Fragment>
            );
          })}
        </Step>
      </>
    );
  }
);

export default TransactionsList;
