import { Modal } from "react-bootstrap";

import { Button, H, P } from "common/components/atoms";
import Confetti from "common/components/atoms/Confetti/Confetti";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import pieChartWithArrow from "./assets/PieChartWithArrow.png";

const t = createTranslation(TranslationNS.pages, "company.capTable.onboardingFinishedModal");

const OnboardingFinishedModal = () => {
  const companyOnboardingResult = useStoreState((state) => state.companyOnboardingModel.companyOnboardingResult);
  const setCompanyOnboardingResult = useStoreActions(
    (actions) => actions.companyOnboardingModel.setCompanyOnboardingResult
  );

  const showModal = !!companyOnboardingResult;
  const isImported = companyOnboardingResult?.numberOfShareholders;

  const handleCloseModal = () => {
    setCompanyOnboardingResult(null);
  };

  return (
    <Modal onClose={handleCloseModal} show={showModal} centered onHide={handleCloseModal}>
      <div className="d-flex flex-column align-items-center text-center p-5">
        <img src={pieChartWithArrow} alt="pieChart" width={254} height={150} />
        <Confetti />
        <H.xs className="mt-5 mb-3">{t("title")}</H.xs>
        {isImported ? (
          <>
            <P.m className="mb-3" color="foregroundMedium">
              {t.el("importDescription.description1", {
                components: [<b key="0"></b>],
                values: {
                  numberOfShareholders: companyOnboardingResult?.numberOfShareholders,
                  year: companyOnboardingResult?.year,
                },
              })}
            </P.m>
            <P.m className="mb-5" color="foregroundMedium">
              {t("importDescription.description2")}
            </P.m>
          </>
        ) : (
          <>
            <P.m className="mb-3" color="foregroundMedium">
              {t("emptyCompanyDescription.description1")}
            </P.m>
            <P.m className="mb-5" color="foregroundMedium">
              {t("emptyCompanyDescription.description2")}
            </P.m>
          </>
        )}
        <Button variant="secondary" onClick={handleCloseModal}>
          {t("btn")}
        </Button>
      </div>
    </Modal>
  );
};

export default OnboardingFinishedModal;
