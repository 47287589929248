import { FC } from "react";
import cn from "classnames";
import * as R from "ramda";
import { defaultTo, isNil } from "ramda";

import { AnimatedText, Helper, P, ProgressChart, Tag, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId, SharePriceTypeEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import SOAgreementContext from "../../../contexts/SOAgreementContext";
import SimulationActions from "../../common/simulation-actions/SimulationActions";
import classes from "./Overview.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.so.overview");

const Overview: FC<JSX.IntrinsicElements["div"]> = ({ className, ...props }) => {
  const { agreementDetails, overviewSimulationValue } = SOAgreementContext.useStoreState((state) => state);
  const { currencySymbol } = useCurrencyById(agreementDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const isAgreementTerminated = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.terminated;
  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;

  const multiplicator = !isNil(overviewSimulationValue) ? parseInt(overviewSimulationValue) : 1;

  return (
    <div className={`${classes["overview"]} ${className}`} {...props}>
      <Helper>
        <div className={classes["charts"]}>
          <div className="mt-3 mb-5">
            <div className="d-flex justify-content-between mb-half">
              <div>
                <Helper.Question questionId="title" questionMarkClassName="mb-1">
                  <Ui.xl bold className="mb-1">
                    {t("status")}
                  </Ui.xl>
                </Helper.Question>

                <Ui.xs style={{ color: scssVariables.foregroundMedium }}>
                  {t("totalStatus", {
                    shares: fNumber(agreementDetails?.totalShares, "amount"),
                  })}
                </Ui.xs>
              </div>

              <div>
                <Helper.Question questionId="description" questionMarkClassName="me-0 mb-1">
                  <Ui.xl
                    bold
                    className="ms-auto mb-1"
                    style={{
                      color: !isNil(overviewSimulationValue) ? scssVariables.positive700 : scssVariables.foregroundHigh,
                    }}
                  >
                    <AnimatedText
                      from={0}
                      to={multiplicator * defaultTo(0, agreementDetails?.totalSharesValue)}
                      formatterConfig={{
                        type: "value",
                        config: { customCurrencySymbol: currencySymbol },
                      }}
                    />
                  </Ui.xl>
                </Helper.Question>

                <SimulationActions type={InstrumentTypesNamesEnum.OPTION} />
              </div>
            </div>

            <Helper.Answer
              answerId="title"
              text={t("helperAnswerText")}
              linkText={t("helperAnswerLinkText")}
              link="https://www.unlisted.ai/blogg/hva-er-aksjeopsjoner"
            />

            <Helper.Answer answerId="description">
              <P.s>
                {t(
                  agreementDetails?.sharePriceType === SharePriceTypeEnum.ACTUAL
                    ? "statusDescriptionActual"
                    : "statusDescriptionFullyDiluted",
                  {
                    percent: fNumber(agreementDetails?.planPercentageOfCompanyShares, "percent"),
                  }
                )}
              </P.s>
            </Helper.Answer>
          </div>

          <ProgressChart
            withoutLabel
            withLeftBorder
            total={agreementDetails?.totalShares}
            totalSharesBarColor={isAgreementTerminated ? scssVariables.critical050 : scssVariables.surface1}
            totalSharesBorderColor={
              isAgreementTerminated
                ? scssVariables.critical700
                : isAgreementExpired
                ? scssVariables.strokeHigh
                : scssVariables.positive900
            }
            data={[
              {
                value: R.defaultTo(0, agreementDetails?.vestedAndExercisedShares),
                id: "shares",
                withThumb: true,
                showTooltip: true,
                color: scssVariables.positive050,
                thumbColor: scssVariables.positive500,
                labelColor: "red",
              },
              {
                id: "available for purchase",
                withThumb: false,
                showTooltip: true,
                color: scssVariables.positive500,
                value: R.defaultTo(0, agreementDetails?.optionsAvailableForExercising),
                labelColor: "orange",
              },
            ]}
          />
        </div>

        <div className={cn("mt-4", classes["statistic"])}>
          <div className="mt-3" style={{ flexBasis: "40%" }}>
            <div className="mb-2 d-flex align-items-center justify-content-center">
              <div className={classes["circle"]} />
              <Ui.xs className="ms-1">{t("vestedAndExercised")}</Ui.xs>
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m bold className="mb-1">
                  {fNumber(agreementDetails?.vestedAndExercisedShares, "amount")}
                </Ui.m>
                <Ui.xs>{t("shares")}</Ui.xs>
              </div>
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: !isNil(overviewSimulationValue) ? scssVariables.positive700 : scssVariables.foregroundHigh,
                  }}
                >
                  <AnimatedText
                    from={0}
                    to={multiplicator * R.defaultTo(0, agreementDetails?.vestedAndExercisedSharesValue)}
                    formatterConfig={{
                      type: "value",
                      config: { customCurrencySymbol: currencySymbol },
                    }}
                  />
                </Ui.m>

                <div className="d-flex align-items-center justify-content-center">
                  <Ui.xs>{t("value")}</Ui.xs>

                  {!isNil(overviewSimulationValue) ? (
                    <Tag variant="complete" size="s" className="ms-1">
                      {overviewSimulationValue}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div
            className={cn(classes["purchase-available"], {
              [classes["expired"]]: isAgreementExpired,
            })}
          >
            <Ui.xs className="text-center mb-2">
              {t("avaliableForPurchase", {
                empty:
                  agreementDetails?.optionsAvailableForExercising && agreementDetails?.optionsAvailableForExercising > 0
                    ? "🎉"
                    : "",
              })}
            </Ui.xs>
            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m bold className="mb-1">
                  {fNumber(agreementDetails?.availableForPurchaseShares, "amount")}{" "}
                </Ui.m>
                <Ui.xs>{t("shares")}</Ui.xs>
              </div>
              <div className="text-center">
                <Ui.m bold className="mb-1">
                  {fNumber(agreementDetails?.optionsAvailableForExercisingPrice, "sharePrice")}{" "}
                </Ui.m>
                <Ui.xs>{t("purchasePrice")}</Ui.xs>
              </div>
            </div>
          </div>

          <div className="mt-3 ms-auto" style={{ flexBasis: "35%" }}>
            <div className="mb-2 d-flex align-items-center justify-content-center">
              <div
                className={cn(classes["circle"], {
                  [classes["dotted"]]: true,
                  [classes["danger"]]: isAgreementTerminated,
                  [classes["low"]]: isAgreementExpired,
                })}
              />
              <Ui.xs className="ms-1">
                {isAgreementTerminated || isAgreementExpired ? t("optionsLost") : t("optionsWaitingToVest")}
              </Ui.xs>
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: isAgreementTerminated
                      ? scssVariables.critical700
                      : isAgreementExpired
                      ? scssVariables.foregroundLow
                      : undefined,
                    textDecoration: isAgreementTerminated || isAgreementExpired ? "line-through" : undefined,
                  }}
                >
                  {fNumber(agreementDetails?.restrictedShares, "amount")}
                </Ui.m>
                <Ui.xs>{t("shares")}</Ui.xs>
              </div>
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: isAgreementTerminated
                      ? scssVariables.critical700
                      : isAgreementExpired
                      ? scssVariables.foregroundLow
                      : !isNil(overviewSimulationValue)
                      ? scssVariables.positive700
                      : scssVariables.foregroundHigh,
                    textDecoration: isAgreementTerminated || isAgreementExpired ? "line-through" : undefined,
                  }}
                >
                  <AnimatedText
                    from={0}
                    to={multiplicator * R.defaultTo(0, agreementDetails?.restrictedSharesValue)}
                    formatterConfig={{
                      type: "value",
                      config: { customCurrencySymbol: currencySymbol },
                    }}
                  />
                </Ui.m>

                <div className="d-flex align-items-center justify-content-center">
                  <Ui.xs>{t("value")}</Ui.xs>

                  {!isNil(overviewSimulationValue) ? (
                    <Tag variant="complete" size="s" className="ms-1">
                      {overviewSimulationValue}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Helper>
    </div>
  );
};

export default Overview;
