import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardCloseConfirm from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../transactions/components/import-transactions-wizard/styles.module.scss";
import TransactionsContext from "../../transactions/transactions.context";
import CloseConfirm from "./close-confirm/close-confirm";
import CaptablePreview from "./steps/captable-preview/captable-preview";
import DownloadTemplate from "./steps/download-template/download-template";
import ImportStatus from "./steps/import-status/import-status";

const t = createTranslation(TranslationNS.pages, "company.capTable.importCaptable");

const Wizard = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { wizardStep } = useWizard();
  return (
    <>
      <WizardLayout.Header title={t("wizardTitle")} />
      <WizardContent maxStep={3} className={cn({ [classes.maxWidth]: wizardStep === 3 })}>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.importCaptable.downloadTemplate} element={<DownloadTemplate />} />
          <Route path={ROUTER_V2.equityManagement.importCaptable.importStatus} element={<ImportStatus />} />
          <Route path={ROUTER_V2.equityManagement.importCaptable.captablePreview} element={<CaptablePreview />} />
          <Route
            path="*"
            element={<Navigate to={getEMPath(["importCaptable", "downloadTemplate"], { companyId })} />}
          />
        </Routes>
      </WizardContent>
      <WizardCloseConfirm>
        <CloseConfirm />
      </WizardCloseConfirm>
    </>
  );
};

const ImportCaptableWizard: FC = () => {
  return (
    <TransactionsContext.Provider>
      <WizardLayout>
        <Wizard />
      </WizardLayout>
    </TransactionsContext.Provider>
  );
};

export default ImportCaptableWizard;
