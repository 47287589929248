import { FC, useMemo } from "react";
import * as R from "ramda";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import CapTableStore from "../../store";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable");

const Foot: FC = () => {
  const data = CapTableStore.useStoreState((state) => state.actualData);
  const fNumber = useFormatNumbers();

  const isEmpty = useMemo(() => !R.isNil(data) && R.isEmpty(data.capTable), [data]);

  return (
    <tfoot className={classes.tableFoot}>
      <tr>
        <th className="ui-bold-xs" colSpan={4}>
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <Ui.s bold style={{ marginLeft: 38 }}>
              {t("totalStakeholdersActual", {
                totalStakeholders: R.defaultTo(0, data?.capTableByShareholder?.length),
              })}
            </Ui.s>
          )}
        </th>
        <th className="ui-bold-s text-end" colSpan={2}>
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            fNumber(data.capTableSummary?.numberOfShares, "amount")
          )}
        </th>
        <th className="ui-bold-s text-end" colSpan={2}>
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <>{fNumber(data.capTableSummary?.valuation, "value")}</>
          )}
        </th>
        <th />
      </tr>
    </tfoot>
  );
};

export default Foot;
