import { FC } from "react";
import { useFormikContext } from "formik";

import DocumentStatus from "../../atoms/DocumentStatus/DocumentStatus";
import FileUploader, { FilesDataMultiple } from "../../atoms/FileUploader/FileUploader";

type UploadDocumentsFieldProps = {
  className?: string;
};
const DocumentStatusField: FC<UploadDocumentsFieldProps> = ({ className }) => {
  const { values, errors, handleChange, handleBlur, touched } = useFormikContext<{ documentStatusId?: number }>();

  return (
    <div className={className}>
      <DocumentStatus
        label="Document status"
        value={values.documentStatusId || 0}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.documentStatusId}
        touched={touched.documentStatusId}
      />
    </div>
  );
};

export default DocumentStatusField;
