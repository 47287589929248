import { FC, Fragment, memo, useMemo } from "react";
import cn from "classnames";
import { flatten, keys, values } from "ramda";

import { InfoAlert, P, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { InformationCircleIcon, TotalValueIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory, TransactionStatus } from "../../types";
import TransactionItem from "./transaction-item/transaction-item";
import classes from "./transaction-item/transaction-item.module.scss";
import { TransactionIcons, TransactionListProps } from "./transactions-list";

type PropsTypes = Omit<TransactionListProps, "transactions"> & Record<"transactions", { [key: string]: Transaction[] }>;

const t = createTranslation(TranslationNS.pages, "company.transactions");

const TransactionListWithYearSorting: FC<PropsTypes> = memo(
  ({
    transactions,
    handleOpenEditTransaction,
    handleOpenConfirmTransaction,
    handleOpenDeleteTransaction,
    handleOpenDeleteCapitalIncrease,
    handleOpenRollbackTransaction,
    scrollRef,
    bundleView = false,
    portfolioView = false,
    isMini,
  }) => {
    const fn = useFormatNumbers();

    const flattenTransactionsList = useMemo(() => flatten(values(transactions)), [transactions]);

    const sortedYearsData = keys(transactions).sort((a, b) => Number(b) - Number(a));

    const hasPendingTransactions = useMemo(
      () =>
        bundleView
          ? false
          : flattenTransactionsList.find((transaction) => transaction.statusId === TransactionStatus.Pending),
      [bundleView, flattenTransactionsList]
    );

    if (!flattenTransactionsList.length && !bundleView) {
      return (
        <div className={cn(classes.empty, "px-5 py-4")}>
          <Ui.l bold className="mb-2">
            {t("empty.title")}
          </Ui.l>

          <P.m style={{ color: scssVariables.foregroundMedium }}>
            {t.el(portfolioView ? "empty.portfolioDescription" : "empty.description", {
              components: [
                <Fragment key={1}>
                  <br />
                  <br />
                </Fragment>,
              ],
            })}
          </P.m>
        </div>
      );
    }

    return (
      <>
        {hasPendingTransactions && !portfolioView && (
          <InfoAlert
            className="p-3 mb-4"
            closable
            customContent={
              <div className="d-flex">
                <InformationCircleIcon height={24} width={56} color={scssVariables.information700} />
                <div className="ms-2">
                  <Ui.m bold className="mb-1">
                    {t("pendingTransactionsInfo.title")}
                  </Ui.m>
                  <Ui.s>{t("pendingTransactionsInfo.content")}</Ui.s>
                </div>
              </div>
            }
          />
        )}

        <Step customRef={scrollRef}>
          {sortedYearsData.map((year) => {
            return (
              <div key={year}>
                <Ui.m bold className="mt-4 mb-1" color="foregroundMedium">
                  {year}
                </Ui.m>

                {transactions[year].map((transaction) => {
                  const isConfirmedSplit =
                    transaction.categoryId === TransactionCategory.Split &&
                    transaction.statusId === TransactionStatus.Confirmed;

                  const variant =
                    transaction.categoryId === TransactionCategory.CapitalIncrease ||
                    transaction.categoryId === TransactionCategory.ShareTransfer
                      ? "pill"
                      : "circle";

                  return (
                    <Fragment key={transaction.transactionId}>
                      <Step.Item
                        id={String(transaction.transactionId)}
                        variant={variant}
                        status={
                          transaction.statusId === TransactionStatus.Pending
                            ? "pending-transaction"
                            : "committed-transaction"
                        }
                      >
                        <div>
                          <Step.Point
                            icon={
                              transaction.categoryId === TransactionCategory.ChangeNominalValue ? (
                                <TotalValueIcon
                                  fontSize={24}
                                  color={
                                    transaction.statusId === TransactionStatus.Pending
                                      ? scssVariables.foregroundMedium
                                      : scssVariables.positive900
                                  }
                                />
                              ) : (
                                TransactionIcons[transaction.categoryId]
                              )
                            }
                            variant={variant}
                          />
                        </div>
                        <TransactionItem
                          className="ms-3"
                          transaction={transaction}
                          handleOpenEditTransaction={handleOpenEditTransaction}
                          handleOpenConfirmTransaction={handleOpenConfirmTransaction}
                          handleOpenDeleteTransaction={handleOpenDeleteTransaction}
                          handleOpenDeleteCapitalIncrease={handleOpenDeleteCapitalIncrease}
                          handleOpenRollbackTransaction={handleOpenRollbackTransaction}
                          portfolioView={portfolioView}
                          isMini={isMini}
                        />
                      </Step.Item>
                      {isConfirmedSplit && (
                        <Ui.xs className={cn(classes.splitDivider, "py-1 px-2 mb-2")}>{`${
                          transaction.categoryName
                        } 1:${fn(transaction.multiplier, "unitPrice", {
                          hideCurrencySymbol: true,
                        })}`}</Ui.xs>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
        </Step>
      </>
    );
  }
);

export default TransactionListWithYearSorting;
