import { FC } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";

type Props = {
  className?: string;
  valuation: string;
};

const styles = {
  borderRadius: 8,
  border: `1px solid ${scssVariables.strokeMedium}`,
};
const ValuationBlock: FC<Props> = ({ className, valuation }) => {
  return (
    <div style={styles} className={classNames(className, "d-flex justify-content-between p-3")}>
      <Ui.m>Company valuation at fundraise</Ui.m>
      <Ui.m bold>{valuation}</Ui.m>
    </div>
  );
};

export default ValuationBlock;
