import React, { FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import SetNewSubscriptionFormik from "./SetNewSubscriptionFormik";

type SetShareIssueSlidePanelProps = {
  show: boolean;
  onHide: () => void;
  fundraiseRoundId: number;
  sharesSubscriptionId?: number;
  onSaved?: () => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.newSubscription");

const SetNewSubscriptionSidePanel: FC<SetShareIssueSlidePanelProps> = ({
  show,
  onHide,
  fundraiseRoundId,
  sharesSubscriptionId,
  onSaved,
}) => {
  const [isSaved, setIsSaved] = useState(false);

  const handleOnSaved = useCallback(() => {
    setIsSaved(true);
  }, []);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      setIsSaved(false);
      onSaved?.();
    }
  }, [isSaved, onSaved]);

  const title = sharesSubscriptionId ? t("title_edit") : t("title");

  const handleOnHide = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={title} onHide={handleOnHide} />
      <SetNewSubscriptionFormik
        onHide={handleOnHide}
        onSaved={handleOnSaved}
        fundraiseRoundId={fundraiseRoundId}
        sharesSubscriptionId={sharesSubscriptionId}
      />
    </SlidePanel>
  );
};

export default SetNewSubscriptionSidePanel;
