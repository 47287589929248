import ConfettiExplosion from "react-confetti-explosion";

import { scssVariables } from "common/utils/constants";

const confettiColors = [
  scssVariables.additional11500,
  scssVariables.critical050,
  scssVariables.information300,
  scssVariables.additional5550,
  scssVariables.additional9500,
  scssVariables.additional7200,
  scssVariables.positive300,
  scssVariables.warning300,
];

const Confetti = () => <ConfettiExplosion colors={confettiColors} zIndex={9999999} />;

export default Confetti;
