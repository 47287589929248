import { FC } from "react";

import { H } from "common/components/atoms/Typography";
import DetailsCard from "common/landingDashboard/components/DetailsCard";
import EmptyCompanyCard from "common/landingDashboard/components/EmptyCompanyCard";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../Dashboard.module.scss";

const t = createTranslation(TranslationNS.pages, "company.newUser");

const NewUserDashboard: FC = () => {
  const { user } = useStoreState((state) => state.account);

  return (
    <PageContent className={classes["container"]} data-testid="company-profile-home-test-id">
      <PageContent.Header className={classes["title"]}>
        <H.s>
          {t("title", {
            name: user?.firstName,
          })}
        </H.s>
      </PageContent.Header>

      <div className="d-flex flex-wrap gap-4 mt-8">
        <EmptyCompanyCard />

        <DetailsCard />
      </div>
    </PageContent>
  );
};

export default NewUserDashboard;
