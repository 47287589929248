import { FC } from "react";

import { Tag, TagVariant } from "common/components/atoms";

export enum FundraiseStatuses {
  Draft = 0,
  Ongoing = 1,
  Finished = 2,
  Cancelled = 3,
}

type StatusCellProps = {
  status: FundraiseStatuses;
  statusName: string;
};
const FundraiseStatusCell: FC<StatusCellProps> = ({ status, statusName }) => {
  let variant: TagVariant = "closed";

  if ([FundraiseStatuses.Ongoing].includes(status)) {
    variant = "information";
  } else if ([FundraiseStatuses.Finished].includes(status)) {
    variant = "complete";
  } else if ([FundraiseStatuses.Draft].includes(status)) {
    variant = "draft";
  }

  return <Tag variant={variant}>{statusName}</Tag>;
};

export default FundraiseStatusCell;
