import React, { useState } from "react";

import Table from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { useStoreState } from "store/store";
import { TaxableValue } from "store/types";

import classes from "../ValuationHistory/ValuationHistory.module.scss";
import ValuationTaxableValueTableBody from "./ValuationTaxableValueTableBody";
import ValuationTaxableValueTableHead from "./ValuationTaxableValueTableHead";

export type ValuationTaxableValueTableSortValue = {
  field: keyof TaxableValue;
  type: keyof typeof sortingParams;
};

const ValuationTaxableValueTable = () => {
  const valuation = useStoreState((state) => state.company.valuation);

  const [activeSortedValue, setActiveSortedValue] = useState<ValuationTaxableValueTableSortValue>({
    field: "year",
    type: sortingParams.desc,
  });

  return (
    <div className={classes["table-container"]}>
      <Table
        className={classes.table}
        headComponent={
          <ValuationTaxableValueTableHead sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />
        }
        bodyComponent={
          <ValuationTaxableValueTableBody data={valuation?.taxableValuations || []} sortedValue={activeSortedValue} />
        }
      />
    </div>
  );
};

export default ValuationTaxableValueTable;
