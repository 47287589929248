import { FC } from "react";
import classNames from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

import ValuationContext from "../../ValuationContext";
import ValuationHistoryTable from "../ValuationTable/ValuationHistoryTable";
import ValuationTabs from "../ValuationTabs/ValuationTabs";
import ValuationTaxableValueTable from "../ValuationTaxableValueTable/ValuationTaxableValueTable";
import classes from "./ValuationHistory.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationHistory: FC = () => {
  const valuationHistorySelectedTad = ValuationContext.useStoreState((state) => state.valuationHistorySelectedTad);

  return (
    <div className={classNames(classes.container, "p-3")}>
      <Ui.xl bold className="mb-3">
        {t("valuationHistory")}
      </Ui.xl>

      <ValuationTabs />

      {equals(valuationHistorySelectedTad, "valuation") ? <ValuationHistoryTable /> : null}

      {equals(valuationHistorySelectedTad, "taxableValue") ? <ValuationTaxableValueTable /> : null}
    </div>
  );
};

export default ValuationHistory;
