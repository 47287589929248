import { FC, memo, SyntheticEvent, useCallback, useMemo } from "react";
import axios from "axios";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { MenuItem } from "common/components/atoms/ContextMenu/ContextMenu";
import { InstrumentTypesIdsEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import {
  CheckBadgeIcon,
  DeleteIcon,
  EditIcon,
  EmailActionSend,
  FileSubtractIcon,
  MenuTabBarVerticalIcon,
  RemoveIcon,
} from "common/icons/svg";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../managePlansContext";
import classes from "./ProgramContainer.module.scss";

const [t, tNotify] = [
  createTranslation(TranslationNS.pages, "company.ownershipPlans.table"),
  createTranslation(TranslationNS.pages, "company.ownershipPlans.sendInvite"),
];

type Props = {
  row: OwnershipProgramTableData;
  programID?: number;
  isOneOff?: boolean;
};

const ActionRowMenu: FC<Props> = memo(({ row, programID }) => {
  const { hasFullAccess } = useFeatures(FEATURES.managePlans);

  const {
    setEditPlanInfo,
    setDeletePlanInfo,
    setSendInviteInfo,
    setGrantPlanInfo,
    getPlanTerminationDetailsThunk,
    setMarkSharesAsSoldInfo,
  } = ManagePlansContext.useStoreActions((actions) => actions);

  const handleClickDelete = useCallback(() => {
    setDeletePlanInfo(row);
  }, [row, setDeletePlanInfo]);

  const handleGrantPlan = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();

      setGrantPlanInfo(row);
    },
    [row, setGrantPlanInfo]
  );

  const handleClickEdit = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();

      setEditPlanInfo({ ...row, programId: programID });
    },
    [programID, row, setEditPlanInfo]
  );

  const handleClickTerminate = useCallback(() => {
    getPlanTerminationDetailsThunk(row.id);
  }, [row.id, getPlanTerminationDetailsThunk]);

  const handleMarkSharesAsSold = useCallback(() => {
    setMarkSharesAsSoldInfo(row);
  }, [row, setMarkSharesAsSoldInfo]);

  const sendInviteToManagerAction = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("planId", String(row.id));

      const request = await axios.post(`/api/ownership/plan/resend-manager-email/${row.id}`, formData);
      if (request.status === 200) {
        notify(tNotify("emailSent"), true, "success");
      }
    } catch (e) {
      console.warn({ e });
    }
  }, [row.id]);

  const handleSendInvite = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();

      if (row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForManager) {
        sendInviteToManagerAction().then(() => {
          document.body.click();
        });
        return;
      }

      setSendInviteInfo(row);
    },
    [row, sendInviteToManagerAction, setSendInviteInfo]
  );

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const canBeMarkAsSold =
      row.instrumentTypeId === InstrumentTypesIdsEnum.OPTION &&
      [
        PlanStatusesBasedOnAPIStatusId.expired,
        PlanStatusesBasedOnAPIStatusId.terminated,
        PlanStatusesBasedOnAPIStatusId.active,
        PlanStatusesBasedOnAPIStatusId.cancelled,
      ].includes(row.statusId) &&
      (row.exercisedShares || 0) > 0;

    const InviteLabel = () => {
      const isReceiver =
        row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForReceiver ||
        row.statusId === PlanStatusesBasedOnAPIStatusId.granted;

      const resendText = isReceiver ? t("resendInviteReceiver") : t("resendInviteManager");

      return (
        <>
          <Ui.m>{row.invitationDate ? resendText : t("sendInvite")}</Ui.m>
          <Ui.xs color={"foregroundLow"}>{!isReceiver ? row.managerEmail : row.stakeholderEmail}</Ui.xs>
        </>
      );
    };

    const grant = {
      key: "grant",
      label: t("grant"),
      icon: <CheckBadgeIcon />,
      onClick: handleGrantPlan,
      isDisabled: !hasFullAccess,
    };
    const edit = {
      key: "edit",
      label: t("edit"),
      icon: <EditIcon />,
      onClick: handleClickEdit,
      isDisabled: !hasFullAccess,
    };
    const remove: MenuItem = {
      key: "remove",
      label: t("remove"),
      icon: <DeleteIcon />,
      onClick: handleClickDelete,
      type: "delete",
      isDisabled: !row.canDeleteAgreement || !hasFullAccess,
    };

    const terminate: MenuItem = {
      key: "terminate",
      label: t("terminate"),
      icon: <RemoveIcon color={scssVariables.critical500} />,
      onClick: handleClickTerminate,
      type: "terminate",
      isDisabled: !hasFullAccess,
    };

    const markSharesAsSold = canBeMarkAsSold
      ? {
          key: "markSharesAsSold",
          label: t("markSharesAsSold"),
          icon: <FileSubtractIcon />,
          onClick: handleMarkSharesAsSold,
          isDisabled: !hasFullAccess,
          forceHideDropdown: true,
        }
      : null;

    const invite = {
      key: "invite",
      label: <InviteLabel />,
      icon: <EmailActionSend />,
      onClick: handleSendInvite,
      isDisabled: !hasFullAccess,
    };

    const editActiveSO =
      row.instrumentTypeId === InstrumentTypesIdsEnum.OPTION && (row.vestedShares || 0) <= 0 ? edit : null;

    const menuList: (MenuItem | null)[] = [];

    switch (row.statusId) {
      case PlanStatusesBasedOnAPIStatusId.draft:
        menuList.push(grant, edit, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.active:
        menuList.push(markSharesAsSold, editActiveSO, terminate, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.granted:
        menuList.push(invite, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.terminated:
        menuList.push(markSharesAsSold, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.expired:
        menuList.push(markSharesAsSold, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.cancelled:
        menuList.push(markSharesAsSold, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.waitingForReceiver:
        menuList.push(invite, remove);
        break;
      case PlanStatusesBasedOnAPIStatusId.waitingForManager:
        menuList.push(invite, remove);
        break;
      default:
        menuList.push(remove);
    }
    //filter null values
    const menuItems = menuList.filter((item) => item !== null);

    return menuItems as MenuItem[];
  }, [
    handleClickDelete,
    handleClickEdit,
    handleClickTerminate,
    handleGrantPlan,
    handleMarkSharesAsSold,
    handleSendInvite,
    hasFullAccess,
    row.canDeleteAgreement,
    row.exercisedShares,
    row.instrumentTypeId,
    row.invitationDate,
    row.managerEmail,
    row.stakeholderEmail,
    row.statusId,
    row.vestedShares,
  ]);

  return (
    <ContextMenu items={menuItems}>
      <Button size="s" isOnlyIcon variant="dark" data-testid="plan-dropdown-btn" className={classes.actionButton}>
        <MenuTabBarVerticalIcon />
      </Button>
    </ContextMenu>
  );
});

export default ActionRowMenu;
