import { FC, ReactNode, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import { formatNumber } from "common/hooks/useFormatNumbers";
import { FundraisingInstrumentsGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import NameCell from "../../DashboardTable/NameCell/NameCell";
import SelectCheckbox from "./fields/SelectCheckbox/SelectCheckbox";

const CellDisabled: FC<{ children: ReactNode; isDisabled: boolean | undefined }> = ({ children, isDisabled }) => {
  return <div style={{ opacity: isDisabled ? 0.6 : 1 }}>{children}</div>;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.instruments.table");

const useInstrumentsTable = () => {
  const columns = useMemo<ColumnDef<FundraisingInstrumentsGetDTO>[]>(
    () => [
      {
        accessorKey: "stakeholderName",
        cell: (info) => (
          <CellDisabled isDisabled={info.row.original?.isDisabled}>
            <NameCell
              initials={info.row.original?.stakeholderInitials}
              name={info.row.original?.stakeholderName}
              representByName={info.row.original?.stakeholderRepresentedBy}
              isAttached={false}
              isCompany={info.row.original?.isCompanyOwned}
            />
          </CellDisabled>
        ),
        header: () => <Ui.s tag="span">{t("stakeholder")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "instrument",
        cell: (info) => (
          <CellDisabled isDisabled={info.row.original?.isDisabled}>
            <Ui.s>{info.getValue() as string}</Ui.s>
          </CellDisabled>
        ),

        header: () => <Ui.s tag="span">{t("instrument")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "amount",
        cell: (info) => (
          <CellDisabled isDisabled={info.row.original?.isDisabled}>
            <Ui.s>{formatNumber(info.getValue() as number)} kr</Ui.s>
          </CellDisabled>
        ),
        header: () => <Ui.s tag="span">{t("amount")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "entityId",
        cell: (info) => (
          <SelectCheckbox
            entityId={info.getValue() as number}
            fundraiseRoundId={info.row.original.checkboxRoundId}
            isDisabled={info.row.original.isDisabled}
            fundraiseRoundName={info.row.original.fundraiseEventName}
          />
        ),
        header: () => <Ui.s tag="span">{t("select")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
    ],
    []
  );

  return { columns };
};
export default useInstrumentsTable;
