import { FC, useMemo } from "react";
import { format } from "date-fns";
import { ActionCreator } from "easy-peasy";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, LinkHelpText, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CommonFileIcon, DeleteIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { ValuationSingle } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../../documents/components/CommonStyles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationTableRow: FC<ValuationSingle & { onValuationRemove: ActionCreator<ValuationSingle | null> }> = (
  item
) => {
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.valuation);

  const { valuation } = useStoreState((state) => state.company);

  const isValuationSingle = useMemo(() => R.defaultTo(0, valuation?.valuations.length) <= 1, [valuation]);

  const items: ContextMenuProps["items"] = useMemo(() => {
    return [
      {
        key: "delete",
        type: "delete",
        label: "Delete",
        icon: <DeleteIcon />,
        noBorder: true,
        isDisabled: !hasFullAccess,
        onClick: item.onValuationRemove.bind(null, item),
      },
    ];
  }, [hasFullAccess, item]);

  return (
    <tr>
      <td width="35%">
        <Ui.s>{item.title}</Ui.s>
      </td>
      <td width="10%">
        <Ui.s>{item.validFrom ? format(new Date(item.validFrom), "dd.MM.yy") : null}</Ui.s>
      </td>
      <td width="25%">
        <Ui.s>{item.editor}</Ui.s>
      </td>
      <td className="text-center" width="5%">
        {item?.description ? (
          <LinkHelpText
            title={t("valuationHistoryTable.description")}
            tooltipWithTitle
            content={item.description}
            placement="left"
          >
            <CommonFileIcon fontSize={24} style={{ color: scssVariables.foregroundLow }} />
          </LinkHelpText>
        ) : null}
      </td>
      <td width="20%" className="ps-0">
        <Ui.s bold className="text-end">
          {fNumber(item.valuation)}
        </Ui.s>
      </td>

      {isValuationSingle || !hasFullAccess ? null : (
        <td width="5%">
          <ContextMenu items={items} drop="down">
            <Button isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn" className={classes["menu-button"]}>
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </td>
      )}
    </tr>
  );
};

export default ValuationTableRow;
