import { useEffect, useRef, useState } from "react";
import BTable from "react-bootstrap/Table";
import { flexRender, Table } from "@tanstack/react-table";
import cn from "classnames";

import { FilledArrowDownIcon } from "common/icons/svg";

import classes from "./DashboardTable.module.scss";

type OverviewTableProps<T extends object> = {
  table: Table<T>;
  withHover?: boolean;
  handleClickRow?: (row: T) => void;
  type?: "fundraiseTable" | "capTable" | "bordered";
  withScroll?: boolean;
};

const hasScrollbar = (element: HTMLDivElement) => {
  return element.scrollWidth > element.clientWidth;
};

const DashboardTable = <T extends object & { isImportant?: boolean }>({
  table,
  withHover,
  handleClickRow,
  type,
  withScroll,
}: OverviewTableProps<T>) => {
  const [scrollPresent, setScrollPresent] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const scrollPresent = hasScrollbar(ref.current);
        setScrollPresent(scrollPresent);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current && refWrapper.current && withScroll) {
      const contentScrollWidth = ref.current.scrollWidth - refWrapper?.current?.offsetWidth;
      const shadowRight = refWrapper.current?.querySelector<HTMLDivElement>(`.${classes.rightShadow}`);
      const shadowLeft = refWrapper.current?.querySelector<HTMLDivElement>(`.${classes.leftShadow}`);

      const calculationFunction = () => {
        const currentScroll = (ref.current?.scrollLeft || 0) / contentScrollWidth;
        if (shadowLeft && shadowRight) {
          shadowLeft.style.opacity = currentScroll.toString();
          shadowRight.style.opacity = (1 - currentScroll).toString();
        }
      };
      calculationFunction();
      const divEl = ref.current;
      divEl.addEventListener("scroll", calculationFunction);

      return () => {
        divEl.removeEventListener("scroll", calculationFunction);
      };
    }
  }, [withScroll]);

  return (
    <div ref={refWrapper} className={cn(classes["table-wrapper"])}>
      {withScroll && <div className={classes.leftShadow} />}
      <div ref={ref} className={cn({ [classes["table-container"]]: withScroll })}>
        <BTable
          className={cn(classes.table, {
            [classes.withHover]: withHover,
            [classes["scrollable-table"]]: scrollPresent,
            [classes.fundraiseRound]: type === "fundraiseTable",
            [classes.capTable]: type === "capTable",
            [classes.bordered]: type === "bordered",
          })}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const canResize = header.column.getCanResize();
                  const maxWidth = canResize ? header.column.columnDef.maxSize : undefined;
                  const minWidth = canResize ? header.column.columnDef.minSize : undefined;
                  const width = canResize ? header.column.getSize() : undefined;
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ maxWidth, minWidth, width }}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: cn("d-flex align-items-center", header.column.columnDef.meta?.headClass, {
                              "cursor-pointer select-none": header.column.getCanSort(),
                            }),
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && (
                            <span className={classes.sorting}>
                              <FilledArrowDownIcon
                                style={{
                                  color: header.column.getIsSorted() ? "black" : "#806779",
                                  transition: "all 0.2s",
                                  transform:
                                    (header.column.getIsSorted() as string) === "asc"
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                }}
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const isImportant = row.original?.isImportant;
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const canResize = cell.column.getCanResize();
                    const maxWidth = canResize ? cell.column.columnDef.maxSize : undefined;
                    const minWidth = canResize ? cell.column.columnDef.minSize : undefined;
                    const width = canResize ? cell.column.getSize() : undefined;

                    return (
                      <td
                        onClick={() => {
                          cell.column.columnDef.meta?.allowClick && handleClickRow?.(row.original);
                        }}
                        key={cell.id}
                        style={{
                          maxWidth,
                          minWidth,
                          width,
                        }}
                        className={cn({ [classes.importantCell]: isImportant })}
                      >
                        <div
                          className={cn("d-flex align-items-center", cell.column.columnDef.meta?.bodyClass, {
                            "w-100": cell.id.includes("companyId"),
                          })}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {table.options.meta?.footerShow && (
            <tfoot>
              {table.getFooterGroups().map((row) => {
                return (
                  <tr key={row.id}>
                    {row.headers.map((cell) => {
                      // console.log(cell.column.columnDef.meta?.footerColSpan);
                      if (!cell.column.columnDef.meta?.footerColSpan) return null;
                      return (
                        <td
                          className={cell.column.columnDef.meta?.bodyClass}
                          key={cell.id}
                          colSpan={cell.column.columnDef.meta?.footerColSpan || cell.colSpan}
                        >
                          {flexRender(cell.column.columnDef.footer, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tfoot>
          )}
        </BTable>
      </div>
      {withScroll && <div className={classes.rightShadow} />}
    </div>
  );
};

export default DashboardTable;
