import { FC, useCallback, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import { TransactionStatus } from "../../../types";
import CreateIssueSharesForm from "./create-issue-shares-form";
import EditIssueSharesForm from "./edit-issue-shares-form";
import { EditIssueSharesFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const IssueSharesContainer: FC<PropsTypes> = ({ onSubmit, onClose, isDisabled }) => {
  const formRef = useRef<HTMLDivElement>(null);

  const isIssueSharesFormOpen = TransactionsContext.useStoreState((state) => state.isIssueSharesFormOpen);
  const issueSharesTransaction = TransactionsContext.useStoreState((state) => state.issueSharesTransaction);
  const {
    postIssueSharesTransactionThunk,
    editConfirmedIssueSharesTransactionThunk,
    editPendingIssueSharesTransactionThunk,
  } = TransactionsContext.useStoreActions((actions) => actions);

  const handleSubmitCreateIssueSharesForm = useCallback(
    async (transaction: FormData, restartForm?: boolean) => {
      const transactionId = await postIssueSharesTransactionThunk(transaction);

      if (transactionId) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(transactionId), restartForm);
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postIssueSharesTransactionThunk]
  );

  const handleSubmitEditIssueSharesForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully =
        issueSharesTransaction?.statusId === TransactionStatus.Confirmed
          ? await editConfirmedIssueSharesTransactionThunk(transaction)
          : await editPendingIssueSharesTransactionThunk(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");
        if (issueSharesTransaction?.transactionBundleId) {
          onSubmit(String(issueSharesTransaction.transactionBundleId));
        }
        onSubmit(String(transaction.get(EditIssueSharesFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [
      editConfirmedIssueSharesTransactionThunk,
      editPendingIssueSharesTransactionThunk,
      issueSharesTransaction?.statusId,
      issueSharesTransaction?.transactionBundleId,
      onSubmit,
    ]
  );

  return (
    <>
      <SlidePanel show={isIssueSharesFormOpen} customRef={formRef}>
        <CreateIssueSharesForm
          onSubmit={handleSubmitCreateIssueSharesForm}
          onClose={onClose}
          formRef={formRef.current}
          isDisabled={isDisabled}
        />
      </SlidePanel>

      <SlidePanel show={!!issueSharesTransaction} customRef={formRef}>
        <AnimatePresence>
          {!!issueSharesTransaction && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <EditIssueSharesForm
                onSubmit={handleSubmitEditIssueSharesForm}
                onClose={onClose}
                transaction={issueSharesTransaction}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </SlidePanel>
    </>
  );
};

export default IssueSharesContainer;
