import React, { FC, useCallback, useMemo } from "react";
import { format } from "date-fns";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, LinkHelpText, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CommonFileIcon, DeleteIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { TaxableValue } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../../documents/components/CommonStyles.module.scss";
import ValuationContext from "../../ValuationContext";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationTaxableValueTableRow: FC<TaxableValue> = ({
  valuePerShare,
  registeredDate,
  year,
  id,
  editor,
  description,
}) => {
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.valuation);

  const setRemoveTaxableValue = ValuationContext.useStoreActions((actions) => actions.setRemoveTaxableValue);

  const onTaxableValueRemove = useCallback(() => {
    setRemoveTaxableValue({
      year,
      editor,
      description,
      registeredDate,
      valuePerShare,
      id,
    });
  }, [description, editor, id, registeredDate, setRemoveTaxableValue, valuePerShare, year]);

  const items: ContextMenuProps["items"] = useMemo(() => {
    return [
      {
        key: "delete",
        type: "delete",
        label: "Delete",
        icon: <DeleteIcon />,
        noBorder: true,
        isDisabled: !hasFullAccess,
        onClick: onTaxableValueRemove,
      },
    ];
  }, [hasFullAccess, onTaxableValueRemove]);

  return (
    <tr>
      <td width="15%">
        <Ui.s>{year}</Ui.s>
      </td>
      <td width="20%">
        <Ui.s>{format(new Date(registeredDate), "dd.MM.yy")}</Ui.s>
      </td>
      <td width="30%">
        <Ui.s>{editor}</Ui.s>
      </td>

      <td className="text-center" width="5%">
        {description ? (
          <LinkHelpText
            title={t("valuationTaxableValueTable.description")}
            tooltipWithTitle
            content={description}
            placement="left"
          >
            <CommonFileIcon fontSize={24} style={{ color: scssVariables.foregroundLow }} />
          </LinkHelpText>
        ) : null}
      </td>
      <td width="20%" className="ps-0">
        <Ui.s bold className="text-end">
          {fNumber(valuePerShare, "sharePrice")}
        </Ui.s>
      </td>

      {!hasFullAccess ? null : (
        <td width="5%">
          <ContextMenu items={items} drop="down">
            <Button isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn" className={classes["menu-button"]}>
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </td>
      )}
    </tr>
  );
};

export default ValuationTaxableValueTableRow;
