import { useEffect, useRef } from "react";

import { useStoreActions, useStoreState } from "store/store";

import { GuideIds } from "../../guide-configs/types";

const UseGuideAddRecentTransactions2 = () => {
  const ref = useRef(false);
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isImportTransactionsGuideActive = currentGuideId === GuideIds.AddRecentTransactions;

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    if (isImportTransactionsGuideActive && currentStepId === 0) {
      moveNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default UseGuideAddRecentTransactions2;
