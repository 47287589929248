import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AccordionContext } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { defaultTo } from "ramda";

import TransactionsContext from "../../../../transactions.context";
import { SplitPreviewGetResponseDTO, Transaction, TransactionStatus } from "../../../../types";
import { splitPreviewDefault } from "../../../forms/form-sections/split-details/split-details";
import SplitOverview from "../../../forms/form-sections/split-details/split-overview";

type PropsTypes = {
  transaction: Transaction;
  eventKey: string;
};

const SplitBody: FC<PropsTypes> = ({ transaction, eventKey }) => {
  const { companyId = "0" } = useParams();
  const { activeEventKey } = useContext(AccordionContext);
  const { getSplitPreviewThunk, getSplitSnapshotThunk } = TransactionsContext.useStoreActions((actions) => actions);

  const [splitData, setSplitData] = useState<SplitPreviewGetResponseDTO>(splitPreviewDefault);

  const isConfirmed = defaultTo(false, transaction?.statusId === TransactionStatus.Confirmed);

  const getSplitData = useCallback(async () => {
    if (isConfirmed) {
      setSplitData((await getSplitSnapshotThunk(transaction?.transactionId)) || splitPreviewDefault);
    } else {
      setSplitData(
        (await getSplitPreviewThunk({
          companyId,
          multiplier: transaction?.multiplier || 1,
          date: transaction?.transactedAt || "",
        })) || splitPreviewDefault
      );
    }
  }, [
    companyId,
    getSplitPreviewThunk,
    getSplitSnapshotThunk,
    isConfirmed,
    transaction?.multiplier,
    transaction?.transactedAt,
    transaction?.transactionId,
  ]);

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getSplitData();
    }
  }, [activeEventKey, eventKey, getSplitData]);

  return (
    <SplitOverview
      transaction={transaction}
      splitPreview={splitData}
      isConfirmed={isConfirmed}
      description={transaction?.description}
      multiplier={transaction?.multiplier}
    />
  );
};

export default SplitBody;
