import { FC } from "react";
import { Outlet } from "react-router-dom";

import NoAccess from "common/access-control/no-access/NoAccess";
import NoSubscription from "common/access-control/no-subscription/NoSubscription";
import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";

// Access Middleware for Fundraise
const AccessMiddlewareEM: FC = () => {
  const { hasSubscriptionAccess, hasSysAdminAccess, hasFullAccess, hasViewAccess } = useFeatures(FEATURES.fundraise, [
    CompanyFeatures.Growth,
  ]);

  if (hasSysAdminAccess) return <Outlet />;

  if (!hasSubscriptionAccess) {
    return <NoSubscription />;
  }

  if (!hasFullAccess && !hasViewAccess) {
    return <NoAccess pageTitle="Fundraise" />;
  }

  return <Outlet />;
};

export default AccessMiddlewareEM;
