import { useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CapTableCard from "../../../components/CapTableCard/CapTableCard";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import useCapTablePreview from "./useCapTablePreview";

const t = createTranslation(TranslationNS.pages, "fundraising.preview.capTable");

const CapTablePreview = () => {
  const fNumber = useFormatNumbers();
  const [sorting, setSorting] = useState<SortingState>([{ id: "initials", desc: false }]);

  const preview = useStoreState((state) => state.fundraisingModel.fundraisePreview);

  const { currencySymbol } = useCurrency();

  const { columns } = useCapTablePreview(currencySymbol);

  const data = preview?.capTable?.shareholders || [];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: true,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <div className="d-flex gap-3 mb-4">
        <CapTableCard
          label={t("card.shareholdersAfter")}
          value={fNumber(preview?.capTable?.totalShareholders, "amount")}
        />
        <CapTableCard
          label={t("card.sharesAfter")}
          value={fNumber(preview?.capTable?.totalShares, "amount")}
          additionalValue={`+${fNumber(preview?.capTable?.totalSharesIncrease, "amount")}`}
        />
        <CapTableCard label={t("card.dilution")} value={fNumber(preview?.capTable?.dilutionPercentage, "percent")} />
      </div>
      <div>
        <DashboardTable table={table} type={"capTable"} />
      </div>
    </div>
  );
};

export default CapTablePreview;
