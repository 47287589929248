import { FC } from "react";
import { useFormikContext } from "formik";

import useFormatNumbers from "common/hooks/useFormatNumbers";

import { ConversionFormValues } from "../../fields/fieldsConversion";
import SubscriptionAmountBlock from "./SubscriptionAmountBlock";

type Props = {
  className?: string;
};
const SubscriptionAmount: FC<Props> = ({ className }) => {
  const fNumber = useFormatNumbers();

  const { values } = useFormikContext<ConversionFormValues>();

  const amount = (values.sharesAllocation || 0) * (values.sharePrice || 0);

  return <SubscriptionAmountBlock className={className} stakeholder={{}} amount={fNumber(amount, "sharePrice")} />;
};
export default SubscriptionAmount;
