import { FC } from "react";

import { H, P } from "../../../../../../atoms";
import ReadMore from "../../../../../../molecules/ReadMore/ReadMore";
import SingleAgreementDetailSection from "../../../common/details/SingleAgreementDetailSection";
import useRSAAgreementDetails, { RSAPlanDetails } from "../useRSAAgreementDetails";
import classes from "./PlanDetailsContent.module.scss";
type PlanDetailsContentProps = {
  planDetails: RSAPlanDetails | null;
};

const PlanDetailsContent: FC<PlanDetailsContentProps> = ({ planDetails }) => {
  const { agreementItems } = useRSAAgreementDetails(planDetails);
  return (
    <div className={classes["content"]}>
      {agreementItems.map((item, index, array) => (
        <SingleAgreementDetailSection
          key={index}
          title={item.title}
          subTitle={item.description}
          infoContent={item.content}
          isShareClass={item?.isShareClass}
          shareClassInfo={item?.shareClass}
          className={index === array.length - 1 ? "me-auto" : undefined}
        />
      ))}

      {planDetails?.essentialTerms && (
        <div className="mt-3 " style={{ flex: "0 0 100%" }}>
          <H.xxxs className="mb-1">Essential terms from agreement</H.xxxs>
          <ReadMore description={planDetails?.essentialTerms} modalTitle="Essential terms">
            <P.s style={{ whiteSpace: "break-spaces" }}>{planDetails?.essentialTerms}</P.s>
          </ReadMore>
        </div>
      )}
    </div>
  );
};

export default PlanDetailsContent;
