import { FC, ReactNode } from "react";
import cn from "classnames";

import { CheckIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "./step.module.scss";

type PropsTypes = {
  icon?: ReactNode;
  variant?: "circle" | "pill";
};

const StepPoint: FC<PropsTypes> = ({ icon, variant = "circle" }) => {
  return (
    <div className={cn({ [classes.point]: variant === "circle", [classes.pointPill]: variant === "pill" })}>
      {icon ? icon : <CheckIcon fontSize={24} color={scssVariables.foregroundHigh} />}
    </div>
  );
};
export default StepPoint;
