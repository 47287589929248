import { useCallback } from "react";

import { getEMPath } from "app/Router/RouterHelper";
import { Badge, Button, Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { LinkIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FundraiseCapitalIncreaseList from "./FundraiseTransactionList/FundraiseCapitalIncreaseList";

const t = createTranslation(TranslationNS.pages, "fundraising.preview.transactions");

const FundraiseTransaction = () => {
  const handleOpenTransactionPage = useCallback(() => {
    window.open(getEMPath(["ownership", "transactions"]), "_blank");
  }, []);

  const fundraisePreview = useStoreState((state) => state.fundraisingModel.fundraisePreview);

  const data = fundraisePreview?.transactionBundles || [];
  return (
    <div>
      <ExpansionPanel.Toggle eventKey={"2"}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              {t("title")} <Badge>{data.length}</Badge>
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              {t("sub_title")}
            </Ui.xs>
          </div>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey={"2"}>
        <div className="d-flex ms-4 me-4 gap-4">
          <Ui.m>{t("description")}</Ui.m>
          <div>
            <Button
              className="text-nowrap"
              variant="secondary"
              iconRight={<LinkIcon />}
              onClick={handleOpenTransactionPage}
            >
              {t("viewTransactions")}
            </Button>
          </div>
        </div>
        <FundraiseCapitalIncreaseList transactions={data} />
      </ExpansionPanel.Collapse>
    </div>
  );
};

export default FundraiseTransaction;
