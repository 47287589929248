import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { Button } from "common/components/atoms";
import { CloseIcon, LogoutIcon, UnlistedIcon } from "common/icons/svg";
import { handleLogout } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./onboardRouter.module.scss";

const t = createTranslation(TranslationNS.pages, "onboard");

const Onboard: FC<{ children?: ReactNode }> = ({ children }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const isOnboarded = useStoreState((state) => state.account.user?.isOnboarded);
  const isPricingTable = location.pathname.includes("pricing");

  const [exitPath, setExitPath] = useState("/");

  const onExitBtnPressHandler = () => {
    navigate(exitPath, { replace: true });
  };

  const handleLogoutClick = useCallback(() => {
    handleLogout(instance);
  }, [instance]);

  useEffect(() => {
    if (location?.state?.prevPath && location?.state?.prevPath !== location.pathname) {
      setExitPath(location?.state?.prevPath);
    }
  }, [exitPath, location.pathname, location?.state]);

  return (
    <div className={classes.wrap}>
      <div className={classes.header}>
        <div className="unlisted-icon">{<UnlistedIcon width={30} height={40} />}</div>
        <div className="d-flex align-items-center">
          {/* TODO: remove this check if language selector should be available for all onboarding flows */}
          {/* TODO: temporary removing language change support */}
          {/*{location.pathname.includes("company") ? <LanguageSelector className="me-7" /> : null}*/}

          {!isPricingTable && (
            <div className="skip-onboard-button">
              {isOnboarded ? (
                <Button variant="dark" onClick={onExitBtnPressHandler} isOnlyIcon>
                  <CloseIcon />
                </Button>
              ) : (
                <Button
                  className="logOutSelenium"
                  variant="tertiary"
                  onClick={handleLogoutClick}
                  iconRight={<LogoutIcon width={20} height={20} />}
                >
                  {t("company.logOut")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default Onboard;
