import { FC, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";

import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";

import { ConvertibleInfoProps } from "../../../../ConvertibleInfo/ConvertibleInfo";
import TotalInfoBlock from "../../components/TotalInfoBlock/TotalInfoBlock";
import { ConversionFormValues, fields } from "../../fields/fieldsConversion";

type ConvertToteType = {
  convertibleNoteId: number;
} & ConvertibleInfoProps["convertible"];
type Props = {
  convertible?: ConvertToteType | null;
  valuationAtConversion?: number;
};

type NoteConversionCalculationDto = {
  numberOfNewShares: number;
  sharePrice: number;
  interestToRepay: number;
};

type ParamsType = {
  noteId: number;
  valuationAtConversion?: number;
  valuationCap?: number;
  investmentAmount: number;
  agreementDate?: string;
  noteSettleDate?: string;
  discount?: number;
  interestRate?: number;
  sharesInCompanyBeforeConversion: number;
  interestNotConvertedToEquity?: boolean;
  overrideInterestAmount: number | null;
  overrideNumberOfShares: number | null;
  overrideSharePrice: number | null;
  useManualConversion: boolean;
};

const NoteTotalBlock: FC<Props> = ({ convertible, valuationAtConversion }) => {
  const fNumber = useFormatNumbers();
  const { values, setFieldValue } = useFormikContext<ConversionFormValues>();

  const [note, setNote] = useState<NoteConversionCalculationDto | null>(null);

  const debounceChange = useMemo(
    () =>
      debounce((payload: ConvertibleInfoProps["convertible"] | (null & ConversionFormValues)) => {
        axios
          .post<NoteConversionCalculationDto>("/api/equity-management/issue-equity/convertible-note/calculate", payload)
          .then((res) => {
            setNote(res.data);

            setFieldValue(fields.autoCalculatedInterest, res.data.interestToRepay);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setFieldValue("isLoading", false);
          });
      }, 1000),
    [setFieldValue]
  );

  useEffect(() => {
    const params: ParamsType = {
      noteId: convertible?.convertibleNoteId || 0,
      valuationAtConversion: valuationAtConversion,
      valuationCap: convertible?.valuationCap,
      agreementDate: convertible?.agreementDate,
      noteSettleDate: values.conversionDate,
      discount: convertible?.discount,
      interestRate: convertible?.interestRate,
      interestNotConvertedToEquity: values.interestPaidInCash,
      overrideInterestAmount: values?.interestAmount || null,
      investmentAmount: convertible?.investmentAmount || 0,
      sharesInCompanyBeforeConversion: convertible?.sharesInCompanyBeforeConversion || 0,
      overrideNumberOfShares: null,
      overrideSharePrice: null,
      useManualConversion: false,
    };
    if (values.conversionDate) {
      setFieldValue("isLoading", true);
      debounceChange(params);
    }

    return () => {
      debounceChange.cancel();
    };
  }, [
    convertible?.agreementDate,
    convertible?.convertibleNoteId,
    convertible?.discount,
    convertible?.interestRate,
    convertible?.investmentAmount,
    convertible?.sharesInCompanyBeforeConversion,
    convertible?.valuationCap,
    debounceChange,
    setFieldValue,
    valuationAtConversion,
    values.conversionDate,
    values?.interestAmount,
    values.interestPaidInCash,
  ]);

  const aboveDividerList = useMemo(
    () => [
      {
        name: "Note",
        value: fNumber(convertible?.investmentAmount),
      },
      {
        name: "Interest",
        value: fNumber(note?.interestToRepay),
      },
    ],
    [convertible?.investmentAmount, fNumber, note?.interestToRepay]
  );

  const belowDividerList = useMemo(() => {
    const subscriptionAmount =
      (note?.interestToRepay ? +note?.interestToRepay : 0) +
      (convertible?.investmentAmount ? +convertible?.investmentAmount : 0);

    const notRoundedNumberOffShares = subscriptionAmount / (note?.sharePrice || 1);

    const reminder = subscriptionAmount - (note?.numberOfNewShares || 0) * (note?.sharePrice || 0);
    return [
      {
        name: "Subscription amount (Conversion)",
        value: fNumber(subscriptionAmount),
      },
      {
        name: `Share price (${convertible?.discount}% discount)`,
        value: fNumber(note?.sharePrice),
      },
      {
        name: "Number of shares",
        value: `${formatNumber(notRoundedNumberOffShares, { decimals: "percent" })} ≈ ${fNumber(
          note?.numberOfNewShares,
          "amount"
        )}`,
        bold: true,
      },
      {
        name: "Remainder",
        value: fNumber(reminder),
      },
    ];
  }, [
    convertible?.discount,
    convertible?.investmentAmount,
    fNumber,
    note?.interestToRepay,
    note?.numberOfNewShares,
    note?.sharePrice,
  ]);

  if (!values.conversionDate) {
    return null;
  }

  return <TotalInfoBlock className="mt-4" aboveDividerList={aboveDividerList} belowDividerList={belowDividerList} />;
};

export default NoteTotalBlock;
