import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import NoAccess from "common/access-control/no-access/NoAccess";
import NoSubscription from "common/access-control/no-subscription/NoSubscription";
import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";

// Access Middleware for Equity Management
const AccessMiddlewareEM: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { pathname } = useLocation();

  const manageAccess = useMemo(() => {
    return {
      [getEMPath(["plans", "poolsAndPrograms"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["plans", "agreements"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["plans", "exercising"], { companyId })]: FEATURES.exercise,
      [getEMPath(["ownership", "capTable"], { companyId })]: FEATURES.capTable,
      [getEMPath(["ownership", "financialInstruments"], { companyId })]: FEATURES.issueEquity,
      [getEMPath(["ownership", "transactions"], { companyId })]: FEATURES.transactions,
      [getEMPath(["ownership", "valuation"], { companyId })]: FEATURES.valuation,
      [getEMPath(["ownership", "shareClasses"], { companyId })]: FEATURES.shareClasses,
      [getEMPath(["ownership", "stakeholdersManagement"], { companyId })]: FEATURES.stakeholdersManagement,
      [getEMPath(["ownership", "documents"], { companyId })]: FEATURES.documents,
      [getEMPath(["gettingStartedGuide"], { companyId })]: FEATURES.gettingStartedGuide,
      [getEMPath(["createPool", "basic"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["createProgram", "main"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["createPlan", "start"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["createOneOffPlan", "planReceiver"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["settings", "accessControl"], { companyId })]: FEATURES.userAccessManagement,
      [getEMPath(["settings", "companySettings"])]: FEATURES.companyInformation,
    };
  }, [companyId]);

  const subscriptionAccessKeys = useMemo(() => {
    const path = {
      [getEMPath(["plans", "poolsAndPrograms"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["plans", "agreements"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["plans", "exercising"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["ownership", "financialInstruments"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["ownership", "documents"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["createPool", "basic"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["createProgram", "main"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["createPlan", "start"], { companyId })]: [CompanyFeatures.Growth],
      [getEMPath(["createOneOffPlan", "planReceiver"], { companyId })]: [CompanyFeatures.Growth],
    };
    return path[pathname.replace(/\/$/, "")];
  }, [companyId, pathname]);

  const accessKey = manageAccess[pathname.replace(/\/$/, "")];

  const { hasSubscriptionAccess, hasSysAdminAccess, hasFullAccess, hasViewAccess } = useFeatures(
    accessKey,
    subscriptionAccessKeys
  );

  if (hasSysAdminAccess) return <Outlet />;

  if (!hasSubscriptionAccess && subscriptionAccessKeys) {
    return <NoSubscription />;
  }

  if (accessKey && !hasFullAccess && !hasViewAccess) {
    return <NoAccess />;
  }

  return <Outlet />;
};

export default AccessMiddlewareEM;
