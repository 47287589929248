import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import axios from "axios";
import cn from "classnames";
import { Form, useFormikContext } from "formik";

import { Button, ChecksGroup, Dropdown, H, InfoAlert, P, TextField, Ui } from "common/components/atoms";
import { getOS } from "common/helpers";
import useCheckSubscription from "common/hooks/useCheckSubscription";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { CheckIcon, InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import OwnershipImage from "../../../cap-table/InviteShareholderModal/img/OwnershipImage";
import { FormValues } from "./invite-stakeholder-modal";
import classes from "./styles.module.scss";
import { GetAccessRoleFeaturesDTO, GetAccessRoleSuggestionsDTO } from "./types";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.invitationModal");

const InviteForm = () => {
  const { stakeholderToInvite } = useStoreState((store) => store.stakeholderInvitation);
  const accessRoles = useStoreState((store) => store.common.dropdowns?.accessRoles);
  const { closeInviteModal, removeStakeholderToInvite } = useStoreActions((actions) => actions.stakeholderInvitation);
  const hasSubscription = useCheckSubscription();

  const [isRolesLoading, setIsRolesLoading] = useState(true);
  const [isFeaturesLoading, setIsFeaturesLoading] = useState(false);

  const [roleFeatures, setRoleFeatures] = useState<GetAccessRoleFeaturesDTO>();

  const { values, errors, touched, setFieldValue, handleChange, isSubmitting, handleSubmit } =
    useFormikContext<FormValues>();

  const handleClose = useCallback(() => {
    removeStakeholderToInvite();
    closeInviteModal();
  }, [closeInviteModal, removeStakeholderToInvite]);

  const handleToggleFeature = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setFieldValue("features", { ...values.features, [target.name]: target.checked });
    },
    [setFieldValue, values.features]
  );

  useEffect(() => {
    (async () => {
      try {
        setIsRolesLoading(true);
        const response = await axios.get<GetAccessRoleSuggestionsDTO>(
          `/api/access-role/suggestion/${stakeholderToInvite?.stakeholderId}`
        );

        if (response.status === 200) {
          setFieldValue("roleId", response.data.suggestedAccessRoleId);
        }
      } catch (e) {
        console.error({ e });
      } finally {
        setIsRolesLoading(false);
      }
    })();
  }, [setFieldValue, stakeholderToInvite]);

  useEffect(() => {
    (async () => {
      if (values.roleId && stakeholderToInvite?.stakeholderId) {
        setIsFeaturesLoading(true);
        try {
          const response = await axios.get<GetAccessRoleFeaturesDTO>(
            `/api/access-role/features/${values.roleId}/${stakeholderToInvite.stakeholderId}`
          );

          if (response.status === 200 && response.data) {
            setRoleFeatures(response.data);
            setFieldValue(
              "features",
              response.data.reduce((acc, feature) => ({ ...acc, [feature.accessRoleFeatureId]: true }), {})
            );
          }
        } catch (e) {
          console.error({e});
        } finally {
          setIsFeaturesLoading(false);
        }
      }
    })();
  }, [setFieldValue, stakeholderToInvite, values.roleId]);

  const accessRightsSelected = useMemo(() => {
    return Object.values(values.features).reduce((count, feature) => (feature ? ++count : count), 1);
  }, [values.features]);

  return (
    <Form>
      <Modal show onHide={closeInviteModal} centered size="lg" contentClassName="pb-6 px-7" scrollable>
        <Modal.Body className={cn({ [classes.customScroll]: getOS() === "Win" })}>
          <H.l className="mt-4 mb-3">{t("header", { name: stakeholderToInvite?.firstName })}</H.l>
          <P.m className="mb-2">
            {t("emailDescription", {
              firstName: stakeholderToInvite?.firstName,
              lastName: stakeholderToInvite?.lastName,
            })}
          </P.m>
          <div className={cn("pb-3 mb-3", classes.borderBottom)}>
            <TextField
              className="w-50"
              label={t("email")}
              value={values.email}
              name="email"
              onChange={handleChange}
              error={errors.email}
              isTouched={touched.email}
            />
          </div>
          <P.m className="mb-3">
            {t("description", {
              firstName: stakeholderToInvite?.firstName,
              lastName: stakeholderToInvite?.lastName,
            })}
          </P.m>
          <Dropdown
            className="w-50 mb-3"
            label={t("rolesLabel")}
            name="roleId"
            options={accessRoles || []}
            optionsIsObject
            onChange={handleChange}
            selectedValue={values.roleId}
            isLoading={isFeaturesLoading || isRolesLoading}
            error={errors.roleId}
            isTouched={touched.roleId}
          />

          {!hasSubscription && (
            <InfoAlert
              className="mb-5"
              type="Neutral"
              customContent={
                <div className="d-flex">
                  <InformationCircleIcon fontSize={24} className="me-2" />
                  <div>
                    {t.el("subscriptionInfo", {
                      components: [<a href="https://www.unlisted.ai/priser" key="0" />],
                    })}
                  </div>
                </div>
              }
            />
          )}

          <>
            <div className={cn("p-3 mb-4 rounded-3", classes.elementBackground)}>
              <Ui.m className="text-medium mb-4">{t("standardAccess")}</Ui.m>
              <div className={cn("d-flex  justify-content-between")}>
                <div className="d-flex flex-column justify-content-between me-2">
                  <div>
                    <H.xs className="mb-1">{t("ownershipFeatureName")}</H.xs>
                    <P.s className="mb-1">
                      {t("ownershipFeatureDescription", { firstName: stakeholderToInvite?.firstName })}
                    </P.s>
                  </div>
                  <ChecksGroup>
                    <ChecksGroup.Check checked={true} disabled label={t("ownershipFeatureName")} />
                  </ChecksGroup>
                </div>
                <div className={classes.imageContainer}>
                  <OwnershipImage />
                </div>
              </div>
            </div>

            {roleFeatures?.length ? (
              <div className={cn("p-3 rounded-3", classes.elementBackground)}>
                <Ui.m className="text-medium mb-4">{t("optionalAccess")}</Ui.m>
                {roleFeatures.map((feature, index) => (
                  <div
                    className={cn({ "pb-4 mb-4": index !== roleFeatures.length - 1 })}
                    style={
                      index !== roleFeatures.length - 1
                        ? { borderBottom: `1px solid ${scssVariables.strokeHigh}` }
                        : undefined
                    }
                    key={feature.accessRoleFeatureId}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column justify-content-between me-2">
                        <div>
                          <H.xs className="mb-1">{feature.name}</H.xs>
                          <P.s className="mb-1">{feature.description}</P.s>
                        </div>
                        <ChecksGroup>
                          <ChecksGroup.Check
                            checked={values.features[feature.accessRoleFeatureId]}
                            label={feature.name}
                            name={String(feature.accessRoleFeatureId)}
                            onChange={handleToggleFeature}
                          />
                        </ChecksGroup>
                      </div>
                      <div className={classes.imageContainer}>
                        <Image src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${feature.imageFilePath}`} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer className="justify-content-between pt-2 border-0">
          <div className="d-flex align-items-center">
            <CheckIcon fontSize={24} className="me-2" />
            <Ui.m>{accessRightsSelected + " " + t("accessRights")}</Ui.m>
          </div>
          <div>
            <Button onClick={handleClose} isDisabled={isSubmitting} variant="secondary" className="me-2">
              {t("cancelBtn")}
            </Button>
            <Button isLoading={isSubmitting} onClick={() => handleSubmit()}>
              {t("submitBtn")}
            </Button>
          </div>
        </Modal.Footer>
        <ToastFormikValidator />
      </Modal>
    </Form>
  );
};

export default InviteForm;
