import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";

import { Helper, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import { SplitPreviewGetResponseDTO } from "../../../../types";
import { splitFields } from "./form-fields";
import SplitOverview from "./split-overview";
import { SplitFieldsTypes } from "./types";

const t = createTranslation(TranslationNS.pages, "company.transactions.splitDetails");

type PropsTypes = {
  isDisabled?: boolean;
};

export const splitPreviewDefault: SplitPreviewGetResponseDTO = {
  totalSharesBefore: 0,
  totalSharesAfter: 0,
  sharePriceBefore: 0,
  sharePriceAfter: 0,
  nominalShareValueBefore: 0,
  nominalShareValueAfter: 0,
  shareholders: [],
  incentiveAgreements: [],
  warrants: [],
  shareholdersCount: 0,
  incentiveAgreementsCount: 0,
  warrantsCount: 0,
};

const SplitDetails: FC<PropsTypes> = ({ isDisabled }) => {
  const { companyId = "0" } = useParams();
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<SplitFieldsTypes>();

  const { getSplitPreviewThunk } = TransactionsContext.useStoreActions((actions) => actions);

  const [splitPreview, setSplitPreview] = useState<SplitPreviewGetResponseDTO>(splitPreviewDefault);

  const debouncedPostSplitPreview = useRef(
    debounce(async (multiplier: number, date: string) => {
      setSplitPreview(
        (await getSplitPreviewThunk({
          multiplier,
          date,
          companyId,
        })) || splitPreviewDefault
      );
    }, 1000)
  );

  useEffect(() => {
    if (values.multiplier > 0) {
      debouncedPostSplitPreview.current(values.multiplier, values.transactedAt);
    }
  }, [values.multiplier, values.transactedAt]);

  useEffect(() => {
    debouncedPostSplitPreview.current(values.multiplier, values.transactedAt);
  }, [values.multiplier, values.transactedAt]);

  return (
    <div>
      <div className="mb-4">
        <Helper>
          <Helper.Question className="w-50" questionId={splitFields.multiplier} type="input">
            <TextField
              label={t("multiplier")}
              type="number"
              name={splitFields.multiplier}
              value={values.multiplier}
              error={errors.multiplier}
              isTouched={touched.multiplier}
              onChange={handleChange}
              onBlur={handleBlur}
              isDisabled={isDisabled}
            />
          </Helper.Question>
          <Helper.Answer className="mt-3" answerId={splitFields.multiplier} text={t("multiplierHelp")} />
        </Helper>
      </div>

      <SplitOverview splitPreview={splitPreview} isConfirmed={false} multiplier={values.multiplier} />
    </div>
  );
};

export default SplitDetails;
