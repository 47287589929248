import { FC, ReactNode } from "react";
import classNames from "classnames";

import classes from "./Badge.module.scss";

type BadgeProps = {
  type?: "numeric" | "verified" | "user";
  size?: "s" | "m";
  variant?: "neutral" | "positive" | "primary";
  className?: string;
  children: ReactNode;
};
const Badge: FC<BadgeProps> = ({ type = "numeric", size = "m", variant = "neutral", children, className }) => {
  return (
    <div className={classNames(classes.wrap, className, classes[type], classes[size], classes[variant])}>
      {children}
    </div>
  );
};

export default Badge;
