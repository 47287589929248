import { FC } from "react";
import { AnimatePresence } from "framer-motion";

import OnboardUserTerms from "pages/onboard/user/terms/OnboardUserTerms";

const OnboardUserRouter: FC = () => {
  return (
    <div className="d-flex w-100 justify-content-center">
      <AnimatePresence>
        <OnboardUserTerms />
      </AnimatePresence>
    </div>
  );
};

export default OnboardUserRouter;
