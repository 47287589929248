import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { AccordionContext, Spinner } from "react-bootstrap";
import cn from "classnames";
import { defaultTo, isEmpty } from "ramda";

import { Button, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import useSelectedCountry from "common/hooks/useSelectedCountry";
import {
  ArrowUpIcon,
  CommonFileIcon,
  InformationCircleIcon,
  PlusIcon,
  ShareClassesIcon,
  WarningIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import { Transaction, TransactionCategory, TransactionStatus } from "../../../../types";
import TransactionsList from "../../transactions-list";
import { TransactionContextMenuHandlers } from "../body";
import classes from "../transaction-item.module.scss";

type PropsTypes = {
  bundle: Transaction;
  eventKey: string;
  contextMenuHandlers: TransactionContextMenuHandlers;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const Overview: FC<{ transactionId?: string }> = ({ transactionId }) => {
  const fNumber = useFormatNumbers();

  const isLoading = TransactionsContext.useStoreState((state) => state.isLocalLoading);
  const capitalIncreaseList = TransactionsContext.useStoreState((state) => state.capitalIncreaseDetails);
  const { isSelectedCountryNorway } = useSelectedCountry();

  const capitalIncreaseDetails = useMemo(
    () => capitalIncreaseList?.[String(transactionId)],
    [transactionId, capitalIncreaseList]
  );

  return (
    <div
      className="d-flex justify-content-evenly pb-4 mb-3 column-gap-2 row-gap-2 flex-wrap"
      style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}
    >
      {isSelectedCountryNorway ? (
        <div className="d-flex flex-column" style={{ flexBasis: "32%" }}>
          <div className={cn("p-3 d-flex", classes["body-item-section"])}>
            <ArrowUpIcon />

            <div className="ms-2">
              <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseDetails?.totalIncrease, "amount")}</Ui.xxl>
              <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
            </div>
          </div>

          <div className={cn("mt-2 p-3 d-flex", classes["body-item-section"])}>
            <ShareClassesIcon />

            <div className="ms-2">
              <Ui.xxl bold>{isLoading ? <Spinner /> : capitalIncreaseDetails?.shareClassName}</Ui.xxl>
              <Ui.xs className="text-medium">share class</Ui.xs>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cn("p-3 d-flex", classes["body-item-section"], {
            [classes["large-container"]]: !isSelectedCountryNorway,
          })}
        >
          <ArrowUpIcon />

          <div className="ms-2">
            <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseDetails?.totalIncrease, "amount")}</Ui.xxl>
            <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
          </div>
        </div>
      )}

      <div
        className={cn("p-3", classes["body-item-section"], {
          [classes["large-container"]]: !isSelectedCountryNorway,
        })}
      >
        <div>
          <Ui.xxl bold>
            {isLoading ? (
              <Spinner />
            ) : (
              fNumber((capitalIncreaseDetails?.shareCapital || 0) + (capitalIncreaseDetails?.totalSharePremium || 0))
            )}
          </Ui.xxl>
          <Ui.xs className="text-medium">{t("totalAmount")}</Ui.xs>
        </div>

        {isSelectedCountryNorway ? (
          <>
            <div className="d-flex justify-content-between mt-3">
              <Ui.s>Premium total</Ui.s>
              <Ui.s className="text-medium">{fNumber(capitalIncreaseDetails?.totalSharePremium)}</Ui.s>
            </div>

            <div className="my-2" style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

            <div className="d-flex justify-content-between">
              <Ui.s>Share capital</Ui.s>
              <Ui.s className="text-medium">{fNumber(capitalIncreaseDetails?.shareCapital)}</Ui.s>
            </div>
          </>
        ) : null}
      </div>

      {!isSelectedCountryNorway ? (
        <div
          className={cn("p-3 d-flex", classes["body-item-section"], {
            [classes["large-container"]]: true,
          })}
        >
          <ShareClassesIcon />

          <div className="ms-2">
            <Ui.xxl bold>{isLoading ? <Spinner /> : capitalIncreaseDetails?.shareClassName}</Ui.xxl>
            <Ui.xs className="text-medium">share class</Ui.xs>
          </div>
        </div>
      ) : null}

      <div
        className={cn("p-3", classes["body-item-section"], {
          [classes["large-container"]]: !isSelectedCountryNorway,
        })}
      >
        <div>
          <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseDetails?.sharePrice, "sharePrice")}</Ui.xxl>
          <Ui.xs className="text-medium">{t("sharePrice")}</Ui.xs>
        </div>

        {isSelectedCountryNorway ? (
          <>
            <div className="d-flex justify-content-between mt-3">
              <Ui.s>Premium</Ui.s>
              <Ui.s className="text-medium">{fNumber(capitalIncreaseDetails?.sharePremium, "sharePrice")}</Ui.s>
            </div>

            <div className="my-2" style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

            <div className="d-flex justify-content-between">
              <Ui.s>Nominal value</Ui.s>
              <Ui.s className="text-medium">{fNumber(capitalIncreaseDetails?.nominalValuePerShare, "sharePrice")}</Ui.s>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const CapitalIncreaseBody: FC<PropsTypes> = ({ bundle, eventKey, contextMenuHandlers }) => {
  const fNumber = useFormatNumbers();
  const { activeEventKey } = useContext(AccordionContext);

  const capitalIncreaseList = TransactionsContext.useStoreState((state) => state.capitalIncreaseDetails);
  const { getCapitalIncreaseThunk, setSelectedTransaction, setElementIdToScroll, setIsManageDocumentsFormOpen } =
    TransactionsContext.useStoreActions((actions) => actions);

  const capitalIncreaseDetails = useMemo(
    () => capitalIncreaseList?.[String(Math.abs(bundle.transactionId))],
    [bundle.transactionId, capitalIncreaseList]
  );

  const openManageDocumentsHandler = useCallback(() => {
    setSelectedTransaction(bundle);
    setIsManageDocumentsFormOpen(true);
    setElementIdToScroll(bundle?.transactionId ? String(bundle?.transactionId) : null);
  }, [bundle, setElementIdToScroll, setIsManageDocumentsFormOpen, setSelectedTransaction]);

  const getCapitalIncreaseRequest = useCallback(async () => {
    await getCapitalIncreaseThunk(bundle.transactionBundleId!);
  }, [bundle.transactionBundleId, getCapitalIncreaseThunk]);

  const totalAmountOfSharesInsideTransactions = useMemo(
    () =>
      defaultTo([] as Transaction[], capitalIncreaseDetails?.transactions).reduce(
        (acc: number, curr: Transaction) => acc + curr.numberOfShares,
        0
      ),
    [capitalIncreaseDetails?.transactions]
  );

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getCapitalIncreaseRequest();
    }
  }, [activeEventKey, eventKey, getCapitalIncreaseRequest, bundle.statusId]);

  return (
    <>
      <div className={cn("p-4 mb-2", classes.bodyContainer, classes.darkBackground)}>
        <Overview transactionId={String(Math.abs(bundle?.transactionId))} />

        {capitalIncreaseDetails?.description && (
          <div>
            <P.s
              className="text-medium mb-4"
              dangerouslySetInnerHTML={{
                __html: capitalIncreaseDetails?.description?.replace(/\r\n/g, "<br />"),
              }}
            />
          </div>
        )}

        <div className={cn("my-3 px-3 py-2", classes["documents"])}>
          {!isEmpty(defaultTo([], bundle?.files)) ? (
            <div className="d-flex flex-wrap">
              {defaultTo([], bundle?.files).map((el) => {
                const openDocHandler = () => {
                  openRemoteDocument(el?.downloadId);
                };

                return (
                  <div
                    key={el.downloadId}
                    className={cn("d-flex align-items-center", classes["single-document"])}
                    onClick={openDocHandler}
                  >
                    <CommonFileIcon fontSize={24} className="me-2" />
                    <Ui.s>{el.fileName}</Ui.s>
                  </div>
                );
              })}
            </div>
          ) : (
            <Button size="s" variant="tertiary" onClick={openManageDocumentsHandler} iconLeft={<PlusIcon />}>
              Add documents
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Ui.m bold className="text-medium">
            {t("transactions")}
          </Ui.m>

          {capitalIncreaseDetails?.totalIncrease === totalAmountOfSharesInsideTransactions ? null : (
            <Tag variant="draft">
              <WarningIcon className="me-1" />
              {defaultTo(0, capitalIncreaseDetails?.totalIncrease) > totalAmountOfSharesInsideTransactions
                ? t("sharesRemaining", {
                    shares: fNumber(
                      defaultTo(0, capitalIncreaseDetails?.totalIncrease) - totalAmountOfSharesInsideTransactions,
                      "amount"
                    ),
                  })
                : t("sharesTooMany", {
                    shares: fNumber(
                      totalAmountOfSharesInsideTransactions - defaultTo(0, capitalIncreaseDetails?.totalIncrease),
                      "amount"
                    ),
                  })}
            </Tag>
          )}
        </div>

        {(capitalIncreaseDetails?.transactions?.length || -1) > 0 ? (
          <TransactionsList
            isMini
            transactions={capitalIncreaseDetails?.transactions || []}
            bundleView
            {...contextMenuHandlers}
          />
        ) : (
          <div className="border-1 rounded-2 py-2 px-3" style={{ backgroundColor: scssVariables.surface1 }}>
            <Ui.l bold className="mb-3">
              {t("emptyTitle")}
            </Ui.l>
            <div className="d-flex align-items-center text-low">
              <InformationCircleIcon />
              <Ui.xs className="ms-2">{t("emptyDescription")}</Ui.xs>
            </div>
          </div>
        )}
      </div>
      {bundle.categoryId === TransactionCategory.CapitalIncrease && bundle.statusId === TransactionStatus.Confirmed && (
        <Ui.s color="foregroundLow" className={cn("py-2 px-4", classes.bodyContainer, classes.darkBackground)}>{`${t(
          "totalSharesAfterTransactions"
        )}: ${fNumber(bundle.numbersOfSharesInCompany, "amount")}`}</Ui.s>
      )}
    </>
  );
};

export default CapitalIncreaseBody;
