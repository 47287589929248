import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, LoaderContainer, P } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CapTablePreview from "../../preview-results/FundraiseCapTable/CapTablePreview/CapTablePreview";
import classes from "../ReviewEvents/ReviewEvents.module.scss";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.capTable");

const ReviewCapTable = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const preview = useStoreState((state) => state.fundraisingModel.fundraisePreview);

  const { getOngoingFundraiseThunk, getFundraisePreviewThunk, finishFundraiseThunk } = useStoreActions(
    (actions) => actions.fundraisingModel
  );

  const handleClose = useCallback(() => {
    navigate(getPath(["fundraising", "finishCampaign", "transactions"], { companyId }));
  }, [companyId, navigate]);

  const handleNext = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const resp = await finishFundraiseThunk(preview?.fundraiseId || 0);
      navigate(getPath(["fundraising", "confirmation"], { companyId }), {
        state: resp.data,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [companyId, finishFundraiseThunk, navigate, preview?.fundraiseId]);

  const handleLoadPage = useCallback(() => {
    setLoading(true);
    getOngoingFundraiseThunk(companyId ? +companyId : 0)
      .then((res) => {
        if (res.data.fundraiseId) {
          getFundraisePreviewThunk(res.data.fundraiseId).finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [companyId, getFundraisePreviewThunk, getOngoingFundraiseThunk]);

  useEffect(() => {
    handleLoadPage();
  }, [handleLoadPage]);

  return (
    <WizardContent.Content className={classes.wrap} step={3}>
      <LoaderContainer loading={loading} variant="contentScreen">
        <H.xs>{t("title")}</H.xs>
        <P.m className="mt-5 mb-5">{t("description")}</P.m>

        <CapTablePreview />

        <div className="d-flex justify-content-between mt-5">
          <Button variant={"secondary"} isOnlyIcon onClick={handleClose} isDisabled={isSubmitting}>
            <ArrowLeftIcon />
          </Button>
          <Button variant={"primary"} onClick={handleNext} isLoading={isSubmitting}>
            {t("confirm")}
          </Button>
        </div>
      </LoaderContainer>
    </WizardContent.Content>
  );
};
export default ReviewCapTable;
