import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { motion } from "framer-motion";

import { getPath } from "app/Router/RouterHelper";
import { Button, Check, Divider, TextArea, TextField } from "common/components/atoms";
import { animationStakeholder } from "common/components/molecules/StakeholderSection/fields/RenderStakeholderFields/RenderStakeholderFields";
import { createTranslation, TranslationNS } from "translation";

import UpdateValuationField from "./UpdateValuationField/UpdateValuationField";
import { fields, UpdateValuationFormValues } from "./useUpdateValuationForm";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.valuation");

const UpdateValuationForm = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const { values, handleChange, handleBlur, touched, errors, isSubmitting } =
    useFormikContext<UpdateValuationFormValues>();

  const handleSkip = useCallback(() => {
    navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId }));
  }, [companyId, navigate]);

  return (
    <Form>
      <UpdateValuationField className="mt-3" />
      <motion.div
        initial="close"
        variants={animationStakeholder}
        animate={values.updateType === "update" ? "open" : "close"}
      >
        <div>
          <Divider />
          <TextField
            name={fields.title}
            label={t("fields.title.label")}
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.title}
            isTouched={touched.title}
          />
          <TextArea
            isOptional
            name={fields.description}
            label={t("fields.description.label")}
            placeholder={t("fields.description.placeholder")}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.description}
            isTouched={touched.description}
            className="mt-4"
          />
          <Check
            className="mt-4"
            type="checkbox"
            label={t("fields.notifyStakeholderByEmail.label")}
            description={t("fields.notifyStakeholderByEmail.description")}
            name={fields.notifyStakeholderByEmail}
            checked={values.notifyStakeholderByEmail}
            onChange={handleChange}
          />
          {values.notifyStakeholderByEmail && (
            <TextArea
              isOptional
              name={fields.additionalComments}
              label={t("fields.additionalComment.label")}
              value={values.additionalComments}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.additionalComments}
              isTouched={touched.additionalComments}
              className="mt-4"
            />
          )}
        </div>
      </motion.div>
      <div className="d-flex justify-content-end gap-2 mt-5">
        <Button variant="secondary" onClick={handleSkip} isDisabled={isSubmitting}>
          {t("skip")}
        </Button>
        <Button isLoading={isSubmitting} type="submit">
          {t("confirm")}
        </Button>
      </div>
    </Form>
  );
};

export default UpdateValuationForm;
