import { FC } from "react";
import { useFormikContext } from "formik";

import { Helper, TextArea, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type CommentFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");

const CommentField: FC<CommentFieldProps> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<ConversionFormValues>();
  return (
    <div className={className}>
      <TextArea
        isOptional
        name={fields.comment}
        label={t("comment.label")}
        placeholder={t("comment.placeholder")}
        value={values.comment}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.comment}
        isTouched={touched.comment}
        rows={3}
      />
    </div>
  );
};
export default CommentField;
