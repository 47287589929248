import { FC, ReactNode, useCallback } from "react";

import { ModalInfo } from "common/components/atoms";

import classes from "./ShareholderDetailsModal.module.scss";
import StakeholderContentModal from "./StakeholderContentModal";

type StakeholderViewModalProps = {
  show: boolean;
  stakeholderId: number;
  handleClose: () => void;
  api?: string;
  children?: ReactNode;
};

const StakeholderViewModal: FC<StakeholderViewModalProps> = ({ show, stakeholderId, handleClose, api, children }) => {
  const handleHide = useCallback(() => {
    handleClose();
  }, [handleClose]);
  return (
    <ModalInfo show={show} className={classes.modal}>
      <StakeholderContentModal stakeholderId={stakeholderId} handleClose={handleHide} api={api}>
        {children}
      </StakeholderContentModal>
    </ModalInfo>
  );
};

export default StakeholderViewModal;
