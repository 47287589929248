import { useCallback, useMemo } from "react";
import { AxisTickProps } from "@nivo/axes";
import { PointTooltip } from "@nivo/line";
import * as R from "ramda";

import { LinearChart, Ui } from "common/components/atoms";
import { sortValuationData } from "common/helpers";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { axisXChartFormatter, transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../valuation.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const CustomTooltip: PointTooltip = ({ point }) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={classes["chart-tooltip"]}>
      <Ui.xs className="d-flex align-items-center">
        <div className={classes.circle} />
        {t("title")} {transformDateToCommonDateFormat(point.data.x as string)}:&nbsp;
        <strong className="d-flex align-items-center">{fNumber(point.data.y as number, "value")}</strong>
      </Ui.xs>
    </div>
  );
};

const CompanyValuationChart = () => {
  const valuation = useStoreState((state) => state.company.valuation);

  const valuationTransformData = useMemo(
    () => ({
      chartPoints: [
        {
          id: t("title"),
          color: scssVariables.foregroundOnSecondary,
          chartBgColor: scssVariables.additional7100,
          data: sortValuationData(valuation?.chartDataPoints || []),
        },
      ],
    }),
    [valuation]
  );

  const axisXFormatter = useCallback(
    (value: string) => {
      return axisXChartFormatter(value, valuationTransformData.chartPoints?.[0].data, "dd.MM.yyyy");
    },
    [valuationTransformData.chartPoints]
  );

  const axisBottom = useMemo(
    () => ({
      tickSize: 0,
      tickPadding: 10,
      format: axisXFormatter,
      renderTick: (e: AxisTickProps<any>) => {
        const isLastOneTick = e.tickIndex === valuationTransformData.chartPoints[0].data.length - 1;

        return (
          <text
            className="ui-xs"
            style={{ fill: scssVariables.foregroundMedium }}
            transform={`translate(${e.x - (isLastOneTick ? 55 : 0)}, 20)`}
          >
            {e?.format?.(e?.value)}
          </text>
        );
      },
    }),
    [axisXFormatter, valuationTransformData.chartPoints]
  );

  return (
    <div className={classes.valuationChart}>
      <LinearChart
        dashedLines
        height={190}
        gridYValues={4}
        axisRight={null}
        chartType="linear"
        tooltip={CustomTooltip}
        withCustomPoint
        pointSize={8}
        selectedPointLabel={R.pluck("x", valuationTransformData.chartPoints[0].data).map((el) => el.toString())}
        data={valuationTransformData.chartPoints}
        margin={{ bottom: 30, left: 5, right: 10, top: 4 }}
        axisBottom={axisBottom}
      />
    </div>
  );
};

export default CompanyValuationChart;
