export type OwnershipFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  organizationNumber: string;
  address: string;
  numberOfShares: number | undefined;
  countryId: number;
  shareClassName: string;
  dateOfOwnership: string;
  isCompanyOwned: number;
  stakeholderId?: number | null;
  shareholderId?: number | null;
  relationshipTypeId?: number;
  issuedShareId?: number | null;
  email?: string;
  postAddress?: string;
  phoneNumber?: string;
};

export const fields = {
  firstName: "firstName",
  lastName: "lastName",
  companyName: "companyName",
  organizationNumber: "organizationNumber",
  address: "address",
  email: "email",
  numberOfShares: "numberOfShares",
  countryId: "countryId",
  shareClassName: "shareClassName",
  dateOfOwnership: "dateOfOwnership",
  isCompanyOwned: "isCompanyOwned",
  stakeholderId: "stakeholderId",
  issuedShareId: "issuedShareId",
  relationshipTypeId: "relationshipTypeId",
  averageSharePrice: "averageSharePrice",
  canDeleteStakeholder: "canDeleteStakeholder",
  postAddress: "postAddress",
  phoneNumber: "phoneNumber",
  ownership: "ownership",
  allowToDeleteRecord: "allowToDeleteRecord",
} as const;
