import { FC, Fragment } from "react";

import Step from "common/components/atoms/Step/Step";
import { CommonFileAddIcon } from "common/icons/svg";
import { FundraiseTransactionDTO } from "store/modelTypes";

import FundraiseShareIssue from "./FundraiseShareIssue/FundraiseShareIssue";

type FundraiseShareIssueListProps = {
  transactions: FundraiseTransactionDTO[];
};
const FundraiseShareIssueList: FC<FundraiseShareIssueListProps> = ({ transactions }) => {
  return (
    <div>
      <Step>
        {transactions.map((transaction, index) => {
          return (
            <Fragment key={index}>
              <Step.Item id={String(index)} variant="circle" status="committed-transaction">
                <div className="me-3">
                  <Step.Point icon={<CommonFileAddIcon width={24} height={24} />} variant={"circle"} />
                </div>
                <FundraiseShareIssue {...transaction} />
              </Step.Item>
            </Fragment>
          );
        })}
      </Step>
    </div>
  );
};

export default FundraiseShareIssueList;
