import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { SharesDetailsFields } from "./form-fields";

const InheritanceTransferType = 22;
const GiftTransferType = 25;
const InheritanceWithoutTaxContinuityTransferType = 37;
const GiftWithoutTaxContinuityTransferType = 38;
const TaxableInheritanceGiftTransferType = 39;

export const sharesDetailsValidationSchema = (tFunction: TFunction, sharesAvailable: number) => ({
  [SharesDetailsFields.numberOfShares]: Yup.number()
    .test("is-decimal", tFunction("integer"), (value) => !!String(value).match(/^\d+$/))
    .min(
      1,
      tFunction("minNumber", {
        number: 1,
      })
    )
    .max(
      sharesAvailable,
      tFunction("maxNumber", {
        number: sharesAvailable,
      })
    )
    .required(tFunction("required")),
  [SharesDetailsFields.purchasePrice]: Yup.number().when("transactionTypeId", {
    is: (value: number) =>
      [
        InheritanceTransferType,
        GiftTransferType,
        InheritanceWithoutTaxContinuityTransferType,
        GiftWithoutTaxContinuityTransferType,
        TaxableInheritanceGiftTransferType,
      ].includes(value),
    then: Yup.number().min(0).required(tFunction("required")),
    otherwise: Yup.number()
      .min(
        0.000001,
        tFunction("minNumber", {
          number: "0,000001",
        })
      )
      .required(tFunction("required")),
  }),
});
