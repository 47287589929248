import { FC } from "react";
import { Form, useFormikContext } from "formik";

import { Button, DatePicker, Dropdown, Helper, SlidePanel, TextArea, TextField } from "common/components/atoms";
import { DocumentStatusField, UploadDocumentsField } from "common/components/molecules/formikFields";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { fields, FundingRoundFormValues } from "./useFundingEventSlidePanel";

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.event");

type SetFundingRoundFormProps = {
  onCancel: () => void;
};
const SetFundingEventForm: FC<SetFundingRoundFormProps> = ({ onCancel }) => {
  const fundingRoundTypes = useStoreState((state) => state.common?.dropdowns?.fundingRoundTypes || []);

  const { values, handleChange, errors, touched, isSubmitting, handleBlur, setFieldValue } =
    useFormikContext<FundingRoundFormValues>();

  return (
    <Form>
      <ToastFormikValidator />
      <SlidePanel.Section title={t("eventDetails")}>
        <Helper>
          <Helper.Question questionId={fields.name} type="input">
            <TextField
              className="mb-4"
              label={t("fields.eventName.label")}
              placeholder={t("fields.eventName.placeholder")}
              name={fields.name}
              value={values.name}
              isTouched={touched.name}
              error={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Helper.Question>
          <Helper.Answer className="mb-3" withRightMargin answerId={fields.name} text={t("fields.eventName.answer")} />
          <Helper.Question questionId={fields.fundraiseEventTypeId} type="input">
            <Dropdown
              optionsIsObject
              className="mb-4"
              label={t("fields.eventType.label")}
              placeholder={t("fields.eventType.placeholder")}
              name={fields.fundraiseEventTypeId}
              selectedValue={values.fundraiseEventTypeId}
              isTouched={touched.fundraiseEventTypeId}
              error={errors.fundraiseEventTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              options={fundingRoundTypes}
              isDisabled={!!values.fundingRoundId}
            />
          </Helper.Question>
          <Helper.Answer
            className="mb-3"
            withRightMargin
            answerId={fields.fundraiseEventTypeId}
            text={t("fields.eventType.answer")}
          />
          <Helper.Question questionId={fields.transactedAt} type="input">
            <DatePicker
              className="mb-4"
              isDateOnlyString
              isWithTimeSelect
              date={values.transactedAt}
              name={fields.transactedAt}
              label={t("fields.eventType.label")}
              isTouched={touched.transactedAt}
              error={errors.transactedAt}
              onChange={(date) => {
                setFieldValue(fields.transactedAt, date);
              }}
            />
          </Helper.Question>
          <Helper.Answer
            className="mb-3"
            withRightMargin
            answerId={fields.transactedAt}
            text={t("fields.eventType.answer")}
          />

          <Helper.Question questionId={fields.description} type="input">
            <TextArea
              isOptional
              className="mb-4"
              label={t("fields.description.label")}
              placeholder={t("fields.description.placeholder")}
              name={fields.description}
              value={values.description}
              isTouched={touched.description}
              error={errors.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Helper.Question>
          <Helper.Answer
            className="mb-3"
            withRightMargin
            answerId={fields.description}
            text={t("fields.description.answer")}
          />
        </Helper>
      </SlidePanel.Section>
      <SlidePanel.Section title={"Documentation"}>
        <UploadDocumentsField />
        <DocumentStatusField />
      </SlidePanel.Section>
      <SlidePanel.Actions>
        <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
          {values.fundingRoundId ? t("update") : t("add")}
        </Button>
        <Button className="ms-4" variant="secondary" isDisabled={isSubmitting} onClick={onCancel}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default SetFundingEventForm;
