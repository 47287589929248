import axios, { AxiosResponse } from "axios";

import store from "store/store";

export type CompanyDetailsServiceEquityResponse = {
  totalNumberOfShares: number;
  totalNumberOfSharesInPools: number;
  totalNumberOfSharesIssued: number;
  totalNumberOfSharesApproved: number;
};

const getActiveContextId = (): number => store.getState().activeCompanyModel.companyId || 0;

class CompanyDetailsService {
  static equity(
    companyId: string | number = getActiveContextId()
  ): Promise<AxiosResponse<CompanyDetailsServiceEquityResponse>> {
    return axios.get<CompanyDetailsServiceEquityResponse>(`/api/company/equity-summary/${companyId}`);
  }
}

export default CompanyDetailsService;
