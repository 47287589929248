import { useMemo } from "react";

import { useStoreState } from "../../store/store";

// this hook is created to have quick access to countries data within the app, check if current company is from Norway for inst.
// feel free to expand this hook depending on your needs

const useSelectedCountry = () => {
  const companies = useStoreState((state) => state?.account?.user?.companies);
  const countries = useStoreState((state) => state?.common?.dropdowns?.countries);
  const companyId = useStoreState((state) => state.activeCompanyModel?.companyId);

  const isSelectedCountryNorway = useMemo(() => {
    const selectedCompany = (companies || []).find((el) => el.id === Number(companyId));

    return countries?.find((el) => el.name.toLowerCase() === "norway")?.id === selectedCompany?.countryId;
  }, [companies, companyId, countries]);

  return { isSelectedCountryNorway };
};

export default useSelectedCountry;
