import { useMemo } from "react";
import { Modal } from "react-bootstrap";

import { useStoreActions, useStoreState } from "store/store";

import { GuideIds } from "../guide-configs/types";
import LearnAddCompanyValuation from "./learn-add-company-valuation/learn-add-company-valuation";
import LearnAddNominalValue from "./learn-add-nominal-value/learn-add-nominal-value";
import LearnAddRecentTransactions from "./learn-add-recent-transactions/learn-add-recent-transactions";
import LearnClearAddTransactions from "./learn-clear-add-transactions/learn-clear-add-transactions";
import LearnUploadCapTable from "./learn-upload-cap-table/learn-upload-cap-table";
import classes from "./styles.module.scss";

const LearnMoreWrapper = () => {
  const currentLearMoreModalId = useStoreState((state) => state.guideModel.currentLearMoreModalId);
  const guides = useStoreState((state) => state.guideModel.populatedGuides);
  const setCurrentLearMoreModalId = useStoreActions((actions) => actions.guideModel.setCurrentLearMoreModalId);

  const ModalContent = useMemo(() => {
    switch (currentLearMoreModalId) {
      case GuideIds.AddCompanyValuation:
        return LearnAddCompanyValuation;
      case GuideIds.UploadAsIsCapTable:
        return LearnUploadCapTable;
      case GuideIds.ClearAndAddTransactions:
        return LearnClearAddTransactions;
      case GuideIds.AddRecentTransactions:
        return LearnAddRecentTransactions;
      case GuideIds.AddNominalValue:
        return LearnAddNominalValue;
      default:
        return () => <></>;
    }
  }, [currentLearMoreModalId]);

  if (!guides || !currentLearMoreModalId) return null;

  const handleHide = () => setCurrentLearMoreModalId(null);

  return (
    <Modal size="lg" show={true} onHide={handleHide} centered dialogClassName={classes.width}>
      <ModalContent onHide={handleHide} />
    </Modal>
  );
};

export default LearnMoreWrapper;
