import { FC, memo, useCallback } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { OwnershipProgramTableData, sortingParams } from "common/types/Collapsible.types";

import { CurrentSortValue } from "./ProgramContainer";
import classes from "./ProgramContainer.module.scss";

type ProgramTableHeadProps = {
  rows: { key: string; value: string }[];
  sortedValue: CurrentSortValue;
  setSortedValue: (e: CurrentSortValue) => void;
};

const ProgramTableHead: FC<ProgramTableHeadProps> = ({ rows, sortedValue, setSortedValue }) => {
  return (
    <thead className={classes.programTableHead}>
      <tr>
        {rows.map((el) => (
          <HeadItem key={el.key} item={el} setSortedValue={setSortedValue} sortedValue={sortedValue} />
        ))}
      </tr>
    </thead>
  );
};

type HeadItemProps = {
  item: { key: string; value: string; disableSorting?: boolean };
  sortedValue: CurrentSortValue;
  setSortedValue: (e: CurrentSortValue) => void;
};

const HeadItem: FC<HeadItemProps> = ({ item, setSortedValue, sortedValue }) => {
  const handleSort = useCallback(() => {
    setSortedValue({
      field: item.key as keyof OwnershipProgramTableData,
      type: sortedValue.type === sortingParams.inc ? sortingParams.desc : sortingParams.inc,
    });
  }, [item.key, setSortedValue, sortedValue.type]);

  return (
    <th>
      <div className="d-flex align-items-center cursor-pointer" onClick={handleSort}>
        <Ui.xs>{item.value}</Ui.xs>

        {!item?.disableSorting && (
          <span
            className={cn(classes.sort, {
              invisible: sortedValue.field !== item.key,
              [classes["rotated"]]: sortedValue.field === item.key && sortedValue.type === sortingParams.inc,
            })}
          >
            <FilledArrowDownIcon />
          </span>
        )}
      </div>
    </th>
  );
};

export default memo(ProgramTableHead);
