import { ChangeEventHandler, FC, ReactNode } from "react";
import Form from "react-bootstrap/Form";
import cn from "classnames";

import classes from "./styles.module.scss";

type PT = {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur?(): void;
  checked: boolean;
  value: string;
  name: string;
  label: ReactNode;
  content: ReactNode;
  className?: string;
  id?: string;
};

// WIP
// extracted from incentive agreements in attempt
// to build some base for this style of radiobuttons

const CheckWithBorders: FC<PT> = ({
  handleBlur,
  handleChange,
  checked,
  value,
  name,
  label,
  content,
  className,
  id,
}) => {
  return (
    <div className={cn(classes.checkContainer, className, { [classes.selected]: checked })} id={id}>
      <Form.Check className={classes.typeRadio} type="radio">
        <Form.Check.Input
          type="radio"
          name={name}
          value={value}
          onChange={handleChange}
          checked={checked}
          onBlur={handleBlur}
        />
        <div>
          <Form.Check.Label className="mb-half">{label}</Form.Check.Label>
          {content}
        </div>
      </Form.Check>
    </div>
  );
};

export default CheckWithBorders;
