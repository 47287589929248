import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import {
  fields as stakeholderFields,
  getStakeholderInitialValues,
} from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { FilesDataType } from "common/types/DocumentFiles.types";
import { FundraiseShareSubscriptionGetDTO, FundraiseShareSubscriptionPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

export type NewSubscriptionFormValues = Partial<FundraiseShareSubscriptionPostDTO> & FilesDataType & {};
export const fields = {
  ...stakeholderFields,
  sharesSubscriptionId: "sharesSubscriptionId",
  fundraiseRoundId: "fundraiseRoundId",
  subscriptionStatusId: "subscriptionStatusId",
  companyId: "companyId",
  description: "description",
  subscriptionAmount: "subscriptionAmount",
  sharePrice: "sharePrice",
  shareClassId: "shareClassId",
  documentStatusId: "documentStatusId",
  filesData: "filesData",
} as const;

const tv = createTranslation(TranslationNS.validation);
const useNewSubscriptionSlidePanel = (data?: FundraiseShareSubscriptionGetDTO | null) => {
  const initialValues = useMemo<NewSubscriptionFormValues>(
    () => ({
      ...getStakeholderInitialValues(data),
      [fields.sharesSubscriptionId]: defaultTo(undefined, data?.sharesSubscriptionId),
      [fields.subscriptionStatusId]: defaultTo(1, data?.subscriptionStatusId),
      [fields.fundraiseRoundId]: defaultTo(1, data?.fundraiseEventId),
      [fields.description]: defaultTo("", data?.description),
      [fields.companyId]: defaultTo(undefined, data?.companyId),
      [fields.subscriptionAmount]: defaultTo(undefined, data?.subscriptionAmount),
      [fields.sharePrice]: defaultTo(undefined, data?.sharePrice),
      [fields.shareClassId]: defaultTo(undefined, data?.shareClassId),
      [fields.documentStatusId]: defaultTo(undefined, data?.documentStatusId),
      [fields.filesData]: {
        oldFiles: defaultTo([], data?.documentFiles),
        newFiles: [],
      },
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.firstName]: string().required(tv("required")),
        [fields.lastName]: string().required(tv("required")),
        [fields.email]: string()
          .email(tv("invalidEmail"))
          .when("sendInvitationEmail", {
            is: true,
            then: string().required(tv("required")),
          }),
        [fields.companyName]: string().when(fields.isCompanyOwned, {
          is: true,
          then: string().required(tv("required")),
        }),
        [fields.subscriptionAmount]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 })),
        [fields.sharePrice]: number()
          .min(0.000001, tv("minNumber", { number: "0,000001" }))
          .required(tv("required")),
        [fields.shareClassId]: number().required(tv("required")),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useNewSubscriptionSlidePanel;
