import { useCallback } from "react";
import axios from "axios";

import {
  AvailableShareClass,
  AvailableShareClassesPostDTO,
  AvailableShareClassesPostResponseDTO,
  UserTransactionsGetDTO,
} from "./types";

export const transactionsApiBase = "/api/ownership/transaction";

export const useTransactionsService = (companyId: string) => {
  // this request returns only transactions where user participated in (used in portfolio)
  const getUserTransactions = useCallback(async () => {
    try {
      const response = await axios.get<UserTransactionsGetDTO>(`${transactionsApiBase}/user/${companyId}`);

      if (response.status === 200) {
        return response.data.transactions;
      }

      return [];
    } catch (e) {
      console.error({ e });
    }
  }, [companyId]);

  const getAvailableShareClasses = useCallback(async (data: AvailableShareClassesPostDTO) => {
    try {
      const response = await axios.post<AvailableShareClassesPostResponseDTO>(
        `${transactionsApiBase}/available-shares`,
        data
      );
      if (response.status === 200) return response.data.shareClasses;
      return [] as AvailableShareClass[];
    } catch (e) {
      console.error({ e });
      return [] as AvailableShareClass[];
    }
  }, []);

  return {
    getUserTransactions,
    getAvailableShareClasses,
  };
};
