import { FC, useCallback } from "react";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import cn from "classnames";
import { useFormikContext } from "formik";
import { isNil } from "ramda";

import Button from "common/components/atoms/Button/Button";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Helper from "common/components/atoms/Helper/Helper";
import TextArea from "common/components/atoms/TextArea/TextArea";
import TextField from "common/components/atoms/TextField/TextField";
import { H } from "common/components/atoms/Typography";
import { InstrumentTypesIdsEnum, VestingTypesEnum } from "common/enums/enum";
import { DeleteIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { VestingDetailsForm, VestingDetailsProps } from "../../VestingDetails";
import classes from "./Condition.module.scss";

export type ConditionProps = {
  index: number;
  type: VestingDetailsProps["type"];
  instrumentType?: InstrumentTypesIdsEnum;
};

const t = createTranslation(TranslationNS.common, "program.components.programForm.vestingDetails");

const Condition: FC<ConditionProps> = ({ index, type, instrumentType }) => {
  const { handleBlur, values, errors, touched, setFieldValue, handleChange } = useFormikContext<VestingDetailsForm>();

  const isFormDisabled = !isNil(values.subscriptionAvailable) && !values.subscriptionAvailable.remainingAgreements;

  const disableWhenPlanActive = values.disableWhenPlanActive;

  const removeClickHandler = useCallback((): void => {
    setFieldValue(
      "conditions",
      (values.conditions || []).filter((_, i) => i !== index)
    );
  }, [index, setFieldValue, values.conditions]);

  return (
    <div className={cn(classes.condition, "p-3", "mt-4")}>
      <div className="d-flex justify-content-between align-items-center">
        <Helper.Question questionId={`conditions[${index}].title`}>
          <H.xxxs>{t("vestingConditions.title")}</H.xxxs>
        </Helper.Question>
        {(values.conditions?.length || 0) > 1 && (
          <Button
            className="p-0"
            variant="tertiary"
            onClick={removeClickHandler}
            isDisabled={isFormDisabled || disableWhenPlanActive}
          >
            <DeleteIcon className="icon-size-3" />
          </Button>
        )}
      </div>
      <Helper.Answer
        className="mt-1"
        answerId={`conditions[${index}].title`}
        text={t("vestingConditions.answer")}
        linkText={t("vestingConditions.readMore")}
        link="/"
      />

      <Row className="mt-3">
        <FormGroup as={Col} controlId={`conditions[${index}].title`}>
          <TextField
            isTouched={(touched.conditions as unknown as { title: boolean }[])?.[index]?.title}
            label={t("title")}
            error={typeof errors !== "string" ? (errors.conditions?.[index] as { title?: string })?.title : undefined}
            value={values.conditions?.[index].title}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isFormDisabled || disableWhenPlanActive}
          />
        </FormGroup>

        <Col />
      </Row>

      <FormGroup className="mt-3" controlId={`conditions[${index}].description`}>
        <TextArea
          isOptional
          error={(errors.conditions?.[index] as { description?: string })?.description}
          isTouched={(touched.conditions as unknown as { description: boolean }[])?.[index]?.description}
          label={t("vestingConditions.description.label")}
          placeholder={t("vestingConditions.description.placeholder")}
          value={values.conditions?.[index].description}
          onChange={handleChange}
          isDisabled={isFormDisabled || disableWhenPlanActive}
        />
      </FormGroup>

      <Row className="mt-3">
        <FormGroup as={Col} controlId={`conditions[${index}].targetDate`}>
          <Helper.Question questionId={`conditions[${index}].targetDate`} type="input">
            <DatePicker
              isDateOnlyString
              className={cn(classes["date-picker"], "me-1")}
              label={t("vestingConditions.targetDate.label")}
              date={values.conditions?.[index].targetDate}
              isDisabled={isFormDisabled || disableWhenPlanActive}
              onChange={(date) => setFieldValue(`conditions[${index}].targetDate`, date)}
            />
          </Helper.Question>
        </FormGroup>

        <Col>
          {type === "plan" &&
            (VestingTypesEnum.COMBINE_VESTING === values.vestingTypeId ||
              VestingTypesEnum.MILESTONE_VESTING === values.vestingTypeId) && (
              <Helper.Question questionId={`numberOfOptions.${index}`} type={"input"}>
                <TextField
                  isTouched={
                    (touched.conditions as unknown as { numberOfOptions: boolean }[])?.[index]?.numberOfOptions
                  }
                  type="number"
                  label={t(
                    `numberOfOptions.label${instrumentType === InstrumentTypesIdsEnum.RSA ? "Shares" : "Options"}`
                  )}
                  value={values.conditions?.[index]?.numberOfOptions}
                  error={(errors?.conditions?.[index] as { numberOfOptions?: string })?.numberOfOptions}
                  name={`conditions[${index}].numberOfOptions`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mt-3"
            answerId={`conditions[${index}].targetDate`}
            text={t("vestingConditions.targetDate.answer")}
            linkText={t("vestingConditions.targetDate.readMore")}
            link="/"
          />
          <Helper.Answer
            className="mt-3"
            answerId={`numberOfOptions.${index}`}
            text={t(
              `numberOfOptions.answerMilestone${instrumentType === InstrumentTypesIdsEnum.RSA ? "Shares" : "Options"}`
            )}
            linkText={t("numberOfOptions.readMore")}
            link={"/"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Condition;
