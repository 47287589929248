import { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { endOfYear, format, set, startOfYear } from "date-fns";

import { Ui } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { OptionsType } from "common/components/atoms/Dropdown/Dropdown";
import { Actions, DateInput, SelectInput, TextInput } from "common/components/atoms/ImportTable";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import ShareholderField from "pages/equity-management/ownership/transactions/components/forms/capital-increase/steps/capital-increase-transactions-table/TransactionTable/components/shareholder-field";
import { TransactionCategory } from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import {
  BuySellType,
  IssueShareType,
  PostFormTransaction,
  ShareClass,
  Stakeholder,
  TransactionCategoryDto,
} from "../types";
import CategoryField from "./fields/category-field";
import PricePerShareFiled from "./fields/price-per-share-filed";
import SourceField from "./fields/source-filed";
import classes from "./styles.module.scss";
import TransactionField from "./transaction-field";
import { fields } from "./use-edit-transactions-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable.headers");
const tBody = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable.body");

const useEditTransactionsColumns = (
  refetchData: () => Promise<void>,
  reportYear: number,
  shareClasses?: ShareClass[],
  transactionCategories?: TransactionCategoryDto[],
  issueSharesTypes?: IssueShareType[],
  buySellTypes?: BuySellType[],
  stakeholders?: Stakeholder[],
  currencyId?: number
) => {
  const { currencySymbol } = useCurrencyById(currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const [createdStakeholdersId, setCreatedStakeholdersId] = useState<Record<string, number | null>>({});

  const categories = useMemo(() => {
    return (
      transactionCategories?.map((category) => ({
        id: category.transactionCategoryId,
        name: category.transactionCategoryName,
      })) || []
    );
  }, [transactionCategories]);

  const issueTypes = useMemo(() => {
    return (
      issueSharesTypes?.map((type) => ({
        id: type.transactionTypeId,
        name: type.transactionTypeName,
      })) || []
    );
  }, [issueSharesTypes]);

  const sellTypes = useMemo(() => {
    return (
      buySellTypes?.map((type) => ({
        id: type.transactionTypeId,
        name: type.transactionTypeName,
      })) || []
    );
  }, [buySellTypes]);

  const shareClassesWithEmpty = useMemo(() => {
    return shareClasses ? [{ id: 0, name: tBody("notSelected") }, ...shareClasses] : [];
  }, [shareClasses]);

  const stakeholdersWithEmpty = useMemo(() => {
    const createNew = {
      id: -2,
      name: tBody("addStakeholder"),
    };

    return stakeholders ? [createNew, ...stakeholders] : [createNew];
  }, [stakeholders]);

  const sourceWithEmpty = useMemo(() => {
    return [{ id: -1, name: tBody("newShares") }];
  }, []);

  const dateLimits = useMemo(() => {
    const today = new Date(reportYear, 0, 1);

    const start = format(set(startOfYear(today), { hours: 0, minutes: 0, seconds: 0 }), dateFormatWithTime);
    const end = format(set(endOfYear(today), { hours: 23, minutes: 59, seconds: 59 }), dateFormatWithTime);

    return { start, end };
  }, [reportYear]);

  const columns = useMemo<ColumnDef<PostFormTransaction>[]>(() => {
    const cells: ColumnDef<PostFormTransaction>[] = [
      {
        header: "#",
        accessorKey: "transactionNumber",
        cell: (props) => <div className={classes.transactionNumber}>{props.row.index + 1}</div>,
        size: 50,
        footer: () => {
          return <span>{t("total")}</span>;
        },
      },
      {
        header: t("transactionDate") + " *",
        accessorKey: fields.transactedAt,
        cell: (props) => (
          <DateInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder="dd.mm.yyyy"
            isWithTimeSelect
            minDate={dateLimits.start}
            maxDate={dateLimits.end}
          />
        ),
        minSize: 170,
      },
      {
        header: t("category"),
        accessorKey: fields.transactionCategoryId,
        cell: (props) => (
          <CategoryField
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={categories}
            isSearchable={false}
            placeholder={tBody("notSelected")}
          />
        ),
        minSize: 210,
      },
      {
        header: t("type"),
        accessorKey: fields.transactionTypeId,
        cell: (props) => {
          const selectedCategory = props.row.original[fields.transactionCategoryId];

          let options: OptionsType[] = [];

          if (selectedCategory === TransactionCategory.Issue) {
            options = issueTypes || [];
          }

          if (selectedCategory === TransactionCategory.Sell) {
            options = sellTypes || [];
          }

          return (
            <TransactionField
              rowIndex={props.row.index}
              columnId={props.column.id}
              options={options}
              isSearchable={false}
              placeholder={tBody("notSelected")}
            />
          );
        },
        minSize: 210,
      },
      {
        header: t("altinType"),
        accessorKey: fields.altinTypeId,
        cell: (props) => {
          const selectedCategoryId = props.row.original[fields.transactionCategoryId];
          const selectedTypeId = props.row.original[fields.transactionTypeId];

          let aroType;

          if (selectedCategoryId === TransactionCategory.Issue) {
            const typeData = issueSharesTypes?.find((type) => type.transactionTypeId === selectedTypeId);
            aroType = typeData?.aroTransactionType;
          }

          if (selectedCategoryId === TransactionCategory.Sell) {
            const typeData = buySellTypes?.find((type) => type.transactionTypeId === selectedTypeId);
            aroType = typeData?.aroTransactionType;
          }

          return <Ui.s className="text-end">{aroType}</Ui.s>;
        },
        minSize: 190,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("numberOfShares") + " *",
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
          />
        ),
        minSize: 170,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);

          return (
            <div className={"text-end"}>
              <span>{fNumber(total, "amount")}</span>
            </div>
          );
        },
        size: 80,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("pricePerShare") + " *",
        accessorKey: fields.sharePrice,
        cell: (props) => {
          return <PricePerShareFiled row={props.row} rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 160,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("total"),
        accessorKey: fields.total,
        cell: (props) => {
          const numberOfShares = (props.row.getValue(fields.numberOfShares) as number) || 0;
          const price = (props.row.getValue(fields.sharePrice) as number) || 0;

          return <div className={classes.transactionNumber}>{fNumber(numberOfShares * price, "value")}</div>;
        },
        size: 140,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("shareClass") + " *",
        accessorKey: fields.shareClassId,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={shareClassesWithEmpty}
            isSearchable
            placeholder={tBody("notSelected")}
          />
        ),
        minSize: 170,
      },
      {
        header: t("shareholder") + " *",
        accessorKey: fields.toStakeholderId,
        cell: (props) => {
          const cellId = props?.cell?.id || "";

          const setStakeholderId = (stakeholderId: number | null) => {
            setCreatedStakeholdersId({
              ...createdStakeholdersId,
              [cellId]: stakeholderId,
            });
          };

          return (
            <ShareholderField
              rowIndex={props.row.index}
              columnId={props.column.id}
              stakeholdersOptions={stakeholdersWithEmpty}
              refetchCallback={refetchData}
              createdStakeholderId={createdStakeholdersId[cellId]}
              setCreatedStakeholderId={setStakeholderId}
              placeholder={tBody("notSelected")}
              isAroFlow
            />
          );
        },
        minSize: 170,
      },
      {
        header: t("source") + " *",
        accessorKey: fields.fromStakeholderId,
        cell: (props) => {
          const selectedCategory = props.row.original[fields.transactionCategoryId];

          return (
            <SourceField
              rowIndex={props.row.index}
              columnId={props.column.id}
              sourceOptions={selectedCategory === TransactionCategory.Issue ? sourceWithEmpty : stakeholders || []}
              placeholder={tBody("notSelected")}
            />
          );
        },
        minSize: 170,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <Actions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
            maxFreeStakeholders={null}
          />
        ),
        maxSize: 40,
      },
    ];

    return cells;
  }, [
    dateLimits.start,
    dateLimits.end,
    categories,
    issueTypes,
    sellTypes,
    issueSharesTypes,
    buySellTypes,
    fNumber,
    shareClassesWithEmpty,
    stakeholdersWithEmpty,
    refetchData,
    createdStakeholdersId,
    sourceWithEmpty,
    stakeholders,
  ]);

  return { columns };
};

export default useEditTransactionsColumns;
