import { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { motion } from "framer-motion";

import { Button, SlidePanel } from "common/components/atoms";
import { DocumentStatusField, ShareClassField, UploadDocumentsField } from "common/components/molecules/formikFields";
import { animationStakeholder } from "common/components/molecules/StakeholderSection/fields/RenderStakeholderFields/RenderStakeholderFields";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import ConvertibleInfo, { ConvertibleInfoProps } from "../../../ConvertibleInfo/ConvertibleInfo";
import Interest from "../components/Interest/Interest";
import SubscriptionAmount from "../components/SubscriptionAmount/SubscriptionAmount";
import ValuationBlock from "../components/ValuationBlock/ValuationBlock";
import AutomaticOrManualConversionField from "../fields/AutomaticOrManualConversionField";
import CommentField from "../fields/CommentField";
import PricePerShareField from "../fields/PricePerShareField";
import SharesAllocationField from "../fields/SharesAllocationField";
import NoteTotalBlock from "./block/NoteTotalBlock";
import { NoteConversionSlidePanelContext } from "./NoteConversionSlidePanel";
import { NoteConversionFormValues } from "./useNoteConversionSlidePanel";

type ConvertToteType = {
  convertibleNoteId: number;
} & ConvertibleInfoProps["convertible"];

type Props = {
  convertible?: ConvertToteType | null;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.notePanel");

const NoteConversionForm: FC<Props> = ({ convertible }) => {
  const { companyId } = useParams();

  const { onHide, fundraiseValuation } = useContext(NoteConversionSlidePanelContext);

  const fNumber = useFormatNumbers();

  const {
    isSubmitting,
    values: { useManualConversion },
  } = useFormikContext<NoteConversionFormValues>();

  return (
    <Form>
      <ToastFormikValidator />
      <SlidePanel.Section title={t("loanAgreement")}>
        <ConvertibleInfo convertible={convertible} type={"note"} />
      </SlidePanel.Section>
      <SlidePanel.Section title={t("conversion")}>
        <AutomaticOrManualConversionField />

        <motion.div
          initial="close"
          variants={animationStakeholder}
          animate={!useManualConversion ? "open" : "close"}
          onAnimationComplete={() => {
            console.log("Animation ended"); //todo add logic later
          }}
        >
          <ValuationBlock className="mt-4" valuation={fNumber(fundraiseValuation, "unitPrice")} />
          <Interest className="mt-4" />
        </motion.div>

        <motion.div initial="close" variants={animationStakeholder} animate={useManualConversion ? "open" : "close"}>
          <SharesAllocationField className="mt-4" />
          <PricePerShareField className="mt-4" />
        </motion.div>

        <ShareClassField className="mt-4" companyId={companyId ? +companyId : 0} />
        <CommentField className="mt-4" />

        <motion.div initial="close" variants={animationStakeholder} animate={useManualConversion ? "open" : "close"}>
          <SubscriptionAmount className="mt-4" />
        </motion.div>

        {!useManualConversion && (
          <NoteTotalBlock convertible={convertible} valuationAtConversion={fundraiseValuation} />
        )}
      </SlidePanel.Section>

      <SlidePanel.Section title={t("documentation")}>
        <UploadDocumentsField className="mt-4" />
        <DocumentStatusField className="mt-4" />
      </SlidePanel.Section>
      <SlidePanel.Actions>
        <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
          {t("update")}
        </Button>
        <Button className="ms-4" variant="secondary" isDisabled={isSubmitting} onClick={onHide}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default NoteConversionForm;
