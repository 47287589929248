import { FC, useCallback, useEffect, useRef } from "react";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H } from "common/components/atoms";
import { useRedirectTo } from "common/hooks/useRedirectTo";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardCloseConfirm, {
  WizardCloseConfirmRef,
} from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "createPool.closeConfirm");

const CloseConfirm: FC = () => {
  const redirectTo = useRedirectTo();

  const wizardCloseConfirmRef = useRef<WizardCloseConfirmRef>(null);
  const setCallBackToSkipModal = WizardLayoutStore?.useStoreActions((state) => state.setCallBackToSkipModal);

  const buttonContinueClickHandler = useCallback((): void => {
    wizardCloseConfirmRef.current?.close();
  }, []);

  const buttonLeaveClickHandler = useCallback((): void => {
    redirectTo(getEMPath(["plans", "poolsAndPrograms"]));
  }, [redirectTo]);

  useEffect(() => {
    setCallBackToSkipModal(buttonLeaveClickHandler);
  }, [buttonLeaveClickHandler, setCallBackToSkipModal]);

  return (
    <WizardCloseConfirm ref={wizardCloseConfirmRef}>
      <H.xxs className="mb-5 text-center">{t("main.text")}</H.xxs>

      <div className="d-flex justify-content-center">
        <Button variant="danger" className="w-max-content me-2" onClick={buttonLeaveClickHandler}>
          {t("main.leave")}
        </Button>

        <Button variant="secondary" className="w-max-content" onClick={buttonContinueClickHandler}>
          {t("main.continue")}
        </Button>
      </div>
    </WizardCloseConfirm>
  );
};

export default CloseConfirm;
