import React, { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import * as Yup from "yup";

import { SlidePanel } from "common/components/atoms";
import { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, UploadFileTypes } from "common/enums/enum";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import { TransactionCategory } from "../../../types";
import ManageDocumentsDetails from "./manage-documents-details";
import ManageDocumentsForm from "./manage-documents-form";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.manageDocuments"),
  createTranslation("validation"),
];

type ManageDocumentsFormValues = {
  documentStatusId: number;
  newFiles: File[];
  oldFiles: UploadedFile[];
  companyFileType?: UploadFileTypes;
  entityId: number;
  companyId: string;
};

const validationSchema = () =>
  Yup.object().shape({
    documentStatusId: Yup.number().required(tValidation("required")),
    newFiles: Yup.array().when("documentStatusId", {
      is: DocumentStatusEnum.DOCUMENT_UPLOADED,
      then: Yup.array().test("newFiles", tValidation("required"), (documents: any, obj) => {
        return documents && (documents?.length > 0 || obj.parent?.oldFiles?.length > 0);
      }),
    }),
  });

const ManageDocumentsPanel: FC = () => {
  const isManageDocumentsFormOpen = TransactionsContext.useStoreState((state) => state.isManageDocumentsFormOpen);
  const { setSelectedTransaction, setIsManageDocumentsFormOpen } = TransactionsContext.useStoreActions(
    (actions) => actions
  );

  const handleClose = useCallback(() => {
    setSelectedTransaction(null);
    setIsManageDocumentsFormOpen(false);
  }, [setIsManageDocumentsFormOpen, setSelectedTransaction]);

  return (
    <SlidePanel show={isManageDocumentsFormOpen} onHide={handleClose}>
      <SlidePanel.Header title={t("title")} onHide={handleClose} />
      <ManageDocumentsDetails />
      <ManageDocumentsFormik />
    </SlidePanel>
  );
};

const ManageDocumentsFormik: FC = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const transaction = TransactionsContext.useStoreState((state) => state.selectedTransaction);

  const { getCapitalIncreaseThunk, getTransactionsRequestThunk, setSelectedTransaction, setIsManageDocumentsFormOpen } =
    TransactionsContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setSelectedTransaction(null);
    setIsManageDocumentsFormOpen(false);
  }, [setIsManageDocumentsFormOpen, setSelectedTransaction]);

  const documentsFileType = useMemo<UploadFileTypes | undefined>(() => {
    if (transaction?.categoryId === TransactionCategory.Issue) {
      return UploadFileTypes.ShareIssuanceDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Sell) {
      return UploadFileTypes.BuySellDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Split) {
      return UploadFileTypes.SplitTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
      return UploadFileTypes.NominalValueTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.CapitalIncrease) {
      return UploadFileTypes.TransactionBundle;
    }
  }, [transaction?.categoryId]);

  const submitHandler = useCallback<FormikConfig<ManageDocumentsFormValues>["onSubmit"]>(
    async (values) => {
      try {
        if (values.companyFileType) {
          await updateCompanyDocuments(
            values.companyId,
            values.entityId,
            values.companyFileType,
            values.newFiles,
            values.oldFiles,
            values.documentStatusId
          );
        }

        notify(t("transactionUpdated"), true, "success");
        await getTransactionsRequestThunk(Number(companyId));

        if (transaction?.transactionBundleId) {
          await getCapitalIncreaseThunk(transaction.transactionBundleId!);
        }

        handleClose();
      } catch (e) {
        console.error({ e });
      }
    },
    [
      companyId,
      getCapitalIncreaseThunk,
      getTransactionsRequestThunk,
      handleClose,
      transaction?.transactionBundleId,
      updateCompanyDocuments,
    ]
  );

  const initialValues = useMemo<ManageDocumentsFormValues>(() => {
    return {
      companyId,
      documentStatusId: transaction?.documentStatusId || DocumentStatusEnum.REVIEW_LATER,
      newFiles: [],
      oldFiles: transaction?.files || [],
      companyFileType: documentsFileType,
      entityId: Math.abs(transaction?.transactionId || 0),
    };
  }, [companyId, documentsFileType, transaction?.documentStatusId, transaction?.files, transaction?.transactionId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <ManageDocumentsForm />
    </Formik>
  );
};

export default ManageDocumentsPanel;
