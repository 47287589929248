import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import { defaultTo, omit } from "ramda";
import * as Yup from "yup";

import { getEMPath } from "app/Router/RouterHelper";
import { DatePicker, Dropdown, H, Helper, InfoAlert, P, TextArea, TextField, Ui } from "common/components/atoms";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import { DocumentStatusEnum, UploadFileTypes } from "common/enums/enum";
import useCurrency from "common/hooks/useCurrency";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { ArrowRightIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../../transactions.context";
import { CapitalIncreaseFormGeneralPostDTO, TransactionStatus } from "../../../../../types";
import { documentationDetailsValidationSchema } from "../../../form-sections/documentation-details/validation";
import CapitalIncreaseContext from "../../capital-increase-context";
import { CapitalIncreaseTransactionsFields } from "../capital-increase-transactions-table/form-fields";
import { CapitalIncreaseGeneralFields } from "./form-fields";
import { capitalIncreaseValidationSchema } from "./validation";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.general"),
  createTranslation(TranslationNS.validation),
];

const SHARE_ISSUES_EVENT_TYPE_ID = 2;

const Content: FC<{ disable: boolean }> = ({ disable }) => {
  const { formattedCurrency } = useCurrency();
  const shareClasses = useStoreState((state) => state.shareClassModel.shareClassesCompany || []);
  const transactionTypesIssuing = useStoreState((state) => state.common.dropdowns)?.transactionTypesIssuing;

  const { isLoading, defaultEventTypeId, defaultShareClassId } = CapitalIncreaseContext.useStoreState((state) => state);
  const { setDefaultEventTypeId, setDefaultShareClassId } = CapitalIncreaseContext.useStoreActions(
    (actions) => actions
  );

  const skipModalShow = WizardLayoutStore?.useStoreState((actions) => actions.skipModalShow);
  const setSkipModalShow = WizardLayoutStore?.useStoreActions((actions) => actions.setSkipModalShow);

  const { values, touched, errors, dirty, handleChange, handleSubmit, setFieldValue } =
    useFormikContext<CapitalIncreaseFormGeneralPostDTO>();

  // Remove share issue from dropdown options list
  const eventTypesDropdownOptions = useMemo(
    () => defaultTo([], transactionTypesIssuing).filter((el) => el.id !== SHARE_ISSUES_EVENT_TYPE_ID),
    [transactionTypesIssuing]
  );

  useEffect(() => {
    if (dirty && !skipModalShow) {
      setSkipModalShow(true);
    }

    if (!dirty && skipModalShow) {
      setSkipModalShow(false);
    }
  }, [dirty, setSkipModalShow, skipModalShow]);

  return (
    <div>
      <ToastFormikValidator />
      <P.m className="mt-5 mb-4">{t("paragraph")}</P.m>
      <div className="pb-4 mb-5" style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}>
        <Helper>
          <div className="d-flex justify-content-between gap-4 mb-2">
            <div className="w-50">
              <Helper.Question
                questionId={CapitalIncreaseGeneralFields.eventName}
                className="w-100 mb-2"
                questionMarkClassName="mt-3"
              >
                <TextField
                  className="w-100"
                  label={t("eventName")}
                  name={CapitalIncreaseGeneralFields.eventName}
                  value={values.eventName}
                  isTouched={touched.eventName}
                  error={errors.eventName}
                  onChange={handleChange}
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId={CapitalIncreaseGeneralFields.eventName}>{t("eventNameHelper")}</Helper.Answer>
            </div>
            <div className="w-50">
              <Helper.Question questionId="eventType" className="w-100 mb-2" questionMarkClassName="mt-3">
                <Dropdown
                  optionsIsObject
                  className="w-100"
                  label={t("eventType")}
                  options={eventTypesDropdownOptions}
                  onChange={({ target }: ChangeEvent<HTMLInputElement>) => setDefaultEventTypeId(+target.value)}
                  selectedValue={defaultEventTypeId}
                  isOptional
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId="eventType">{t("eventTypeHelper")}</Helper.Answer>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4 mb-2">
            <div className="w-50">
              <Helper.Question
                questionId={CapitalIncreaseGeneralFields.numberOfShares}
                className="w-100 mb-2"
                questionMarkClassName="mt-3"
              >
                <TextField
                  className="w-100"
                  label={t("totalNumber")}
                  name={CapitalIncreaseGeneralFields.numberOfShares}
                  value={values.numberOfShares}
                  isTouched={touched.numberOfShares}
                  error={errors.numberOfShares}
                  onChange={handleChange}
                  type="number"
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId={CapitalIncreaseGeneralFields.numberOfShares}>
                {t("totalNumberHelper")}
              </Helper.Answer>
            </div>
            <div className="w-50">
              <Helper.Question
                questionId={CapitalIncreaseGeneralFields.sharePrice}
                className="w-100 mb-2"
                questionMarkClassName="mt-3"
              >
                <TextField
                  className="w-100"
                  label={t("sharePrice")}
                  name={CapitalIncreaseGeneralFields.sharePrice}
                  value={values.sharePrice}
                  isTouched={touched.sharePrice}
                  error={errors.sharePrice}
                  iconRight={formattedCurrency}
                  onChange={handleChange}
                  type="number"
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId={CapitalIncreaseGeneralFields.sharePrice}>{t("sharePriceHelper")}</Helper.Answer>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4 mb-2">
            <div className="w-50">
              <Helper.Question questionId="shareClass" className="w-100 mb-2" questionMarkClassName="mt-3">
                <Dropdown
                  className="w-100"
                  label={t("shareClass")}
                  options={shareClasses}
                  optionsIsObject
                  onChange={({ target }: ChangeEvent<HTMLInputElement>) => setDefaultShareClassId(+target.value)}
                  selectedValue={defaultShareClassId}
                  isOptional
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId="shareClass">{t("shareClassHelper")}</Helper.Answer>
            </div>
            <div className="w-50">
              <Helper.Question
                questionId={CapitalIncreaseGeneralFields.transactedAt}
                className="w-100 mb-2"
                questionMarkClassName="mt-3"
              >
                <DatePicker
                  className="w-100"
                  label={t("transactionDate")}
                  name={CapitalIncreaseGeneralFields.transactedAt}
                  date={values.transactedAt}
                  isTouched={touched.transactedAt}
                  error={errors.transactedAt}
                  onChange={(date) => setFieldValue(CapitalIncreaseGeneralFields.transactedAt, date)}
                  isWithTimeSelect
                  isDateOnlyString
                  isDisabled={isLoading || disable}
                />
              </Helper.Question>
              <Helper.Answer answerId={CapitalIncreaseGeneralFields.transactedAt}>
                {t("transactionDateHelper")}
              </Helper.Answer>
            </div>
          </div>
          <TextArea
            style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}
            label={t("description")}
            name={CapitalIncreaseGeneralFields.description}
            value={values.description}
            isTouched={touched.description}
            error={errors.description}
            placeholder={t("descriptionPlaceholder")}
            onChange={handleChange}
            isOptional
            isDisabled={isLoading || disable}
          />
        </Helper>
      </div>
      <div className="pb-4 mb-5" style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}>
        <H.xxs>{t("documentation")}</H.xxs>

        <P.m className="mt-2 mb-4">{t("documentationDescription")}</P.m>

        <DocumentUploadWithStatuses
          oldFilesName={CapitalIncreaseGeneralFields.prevFiles}
          newFilesName={CapitalIncreaseGeneralFields.documents}
          disabled={disable}
        />
      </div>
      <WizardContent.Controls.ContinueButton
        className="d-flex ms-auto"
        isDisabled={isLoading || disable}
        onClick={() => handleSubmit()}
        isOnlyIcon
        isLoading={isLoading}
      >
        <ArrowRightIcon />
      </WizardContent.Controls.ContinueButton>
    </div>
  );
};

const CapitalIncreaseGeneral: FC = () => {
  const { companyId = "0" } = useParams<{ companyId: string; transactionId: string }>();

  const navigate = useNavigate();
  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const [disable, setDisable] = useState(false);

  const { getShareClassesCompanyThunk } = useStoreActions((actions) => actions.shareClassModel);
  const { currentBundle } = CapitalIncreaseContext.useStoreState((state) => state);
  const { postCapitalIncreaseGeneralThunk, getCapitalIncreaseGeneralThunk } = TransactionsContext.useStoreActions(
    (actions) => actions
  );
  const { setIsLoading, setCurrentBundle, setStep, setDefaultShareClassId } = CapitalIncreaseContext.useStoreActions(
    (actions) => actions
  );

  const fetchDropdownsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getShareClassesCompanyThunk(Number(companyId));
      if (response.data?.length === 1) {
        setDefaultShareClassId(response.data[0].id);
      }
      const resp = await axios.get<{ hasPendingSplit: boolean }>(
        `/api/ownership/transaction/split/has-pending/${companyId}`
      );
      setDisable(resp.data?.hasPendingSplit);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [companyId, getShareClassesCompanyThunk, setDefaultShareClassId, setIsLoading]);

  useEffect(() => {
    setStep(1);
    fetchDropdownsData().then();
  }, [fetchDropdownsData, setStep]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setIsLoading(true);

        const data = await postCapitalIncreaseGeneralThunk(values);

        if (data && data.transactionBundleId) {
          await updateCompanyDocuments(
            companyId,
            data.transactionBundleId,
            UploadFileTypes.TransactionBundle,
            values.documents,
            values.prevFiles
          );

          const message = currentBundle.statusId === TransactionStatus.Pending ? t("notifyCreated") : t("notifyEdited");
          notify(message, true, "success");
          // TEMPORARY. LK Should provide all date in POST response
          const temporaryResponse = await getCapitalIncreaseGeneralThunk(data.transactionBundleId);

          // replace with "createdBundle"
          if (temporaryResponse) {
            setCurrentBundle(temporaryResponse);
            setIsLoading(false);
            setStep(2);

            navigate(
              getEMPath(["createCapitalIncrease", "transactions"], {
                companyId,
                transactionId: String(temporaryResponse.transactionBundleId),
              })
            );
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      companyId,
      currentBundle.statusId,
      getCapitalIncreaseGeneralThunk,
      navigate,
      postCapitalIncreaseGeneralThunk,
      setCurrentBundle,
      setIsLoading,
      setStep,
      updateCompanyDocuments,
    ]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ...capitalIncreaseValidationSchema(tValidation),
      ...documentationDetailsValidationSchema(tValidation),
    });
  }, []);

  const initialValues: CapitalIncreaseFormGeneralPostDTO = useMemo(() => {
    return {
      companyId: Number(companyId),
      documents: [],
      prevFiles: currentBundle?.documentFiles || [],
      transactedAt: currentBundle.transactedAt,
      description: currentBundle?.description || "",
      documentStatusId: DocumentStatusEnum.REVIEW_LATER,
      ...omit(
        [CapitalIncreaseTransactionsFields.transactions, "documentFiles", "description", "transactedAt"],
        currentBundle
      ),
    };
  }, [companyId, currentBundle]);

  return (
    <WizardContent.Content step={1}>
      <H.xs>{t("title")}</H.xs>

      {disable && (
        <InfoAlert
          className="p-3 mt-7 mb-5"
          type="Warning"
          customContent={
            <div className="d-flex">
              <WarningExclamationMarkIcon height={24} width={56} color={scssVariables.warning900} />
              <div className="ms-2">
                <Ui.m bold className="mb-1">
                  {t("pendingTransactionTitle")}
                </Ui.m>
                <Ui.s>{t("pendingTransactionContent")}</Ui.s>
              </div>
            </div>
          }
        />
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Content disable={disable} />
      </Formik>
    </WizardContent.Content>
  );
};

export default CapitalIncreaseGeneral;
