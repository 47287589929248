import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { H } from "common/components/atoms/Typography";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import VestingPlanDetails from "../../../create-plan/steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.vestingConditions");

const VestingConditions: FC = () => {
  const navigate = useNavigate();

  const handleStepBack = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "sharesDetails"]));
  }, [navigate]);

  const vestingTypesSelector = useStoreState((state) => state.common?.dropdowns)?.vestingTypes;

  const { values, handleSubmit, setFieldValue } = useFormikContext<OwnershipPlanForm>();

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    if (values.vestingTypeId === 0 && vestingTypesSelector?.length) {
      setFieldValue("vestingTypeId", vestingTypesSelector[0]?.id);
    }
  }, [vestingTypesSelector, setFieldValue, values.vestingTypeId]);

  return (
    <WizardContent.Content step={4}>
      <H.xs className="mb-3">{t("title")}</H.xs>

      <VestingPlanDetails />
      <div className="d-flex mt-8">
        <WizardContent.Controls.BackButton className="ms-auto me-2" onClick={handleStepBack} />
        <WizardContent.Controls.ContinueButton onClick={submitHandler} />
      </div>
    </WizardContent.Content>
  );
};

export default VestingConditions;
