import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import CompanyDetailsService, { CompanyDetailsServiceEquityResponse } from "common/company/CompanyDetailsService";
import { Button } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, Ui } from "common/components/atoms/Typography";
import { PoolTypesOfSource } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useWizardStepper } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import PoolApprovalByDropdown from "common/pool/components/PoolForm/PoolApprovalByDropdown";
import PoolApprovalDateField from "common/pool/components/PoolForm/PoolApprovalDateField";
import PoolDocumentsField from "common/pool/components/PoolForm/PoolDocumentsField";
import PoolDocumentStatusField from "common/pool/components/PoolForm/PoolDocumentStatusField";
import PoolExpiryDateField from "common/pool/components/PoolForm/PoolExpiryDateField";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import PoolHelper from "../../components/PoolHelper";
import { poolSubmitter } from "../../CreatePoolForm";
import { PoolFormValues } from "../../usePoolForm";

const t = createTranslation(TranslationNS.pages, "createPool.summary");

const Summary: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  useWizardStepper(3);
  const fNumber = useFormatNumbers();

  const { values, setFieldValue, isSubmitting } = useFormikContext<PoolFormValues>();
  const currentPool = useStoreState((state) => state.poolModel.pool);
  const getPoolThunk = useStoreActions((actions) => actions.poolModel.getPoolThunk);

  const [equity, setEquity] = useState<CompanyDetailsServiceEquityResponse>();
  const [loading, setLoading] = useState<boolean>(true);

  const summary = useMemo<ReactNode>(
    () =>
      t.el(`summary.${currentPool?.shareSourceTypeId === PoolTypesOfSource.new ? "newSource" : "existingSource"}`, {
        values: {
          authorizedShares: fNumber(currentPool?.numberOfShares, "amount"),
          numberOfShares: fNumber(currentPool?.numberOfShares, "amount"),
          percentOfCompanyShares: fNumber(
            ((currentPool?.numberOfShares || 1) / (equity?.totalNumberOfSharesIssued || 1)) * 100,
            "percent"
          ),
          shareSourceType: currentPool?.shareSourceTypeId,
        },
      }),
    [currentPool?.numberOfShares, currentPool?.shareSourceTypeId, equity?.totalNumberOfSharesIssued, fNumber]
  );

  useEffect(() => {
    CompanyDetailsService.equity()
      .then(({ data: equity }) => {
        setEquity(equity);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getPoolThunk, values.id]);

  const buttonBackClickHandler = useCallback((): void => {
    navigate(getEMPath(["createPool", "shares"]), { state });
  }, [navigate, state]);

  const handleSaveDraft = useCallback(() => {
    setFieldValue("submitter", poolSubmitter.draft);
  }, [setFieldValue]);

  const handleSaveAndApprove = useCallback(() => {
    setFieldValue("submitter", poolSubmitter.create);
  }, [setFieldValue]);

  return (
    <WizardContent.Content>
      <H.xs className=" mb-4">{t("title")}</H.xs>

      <Ui.m className="mb-4">{summary}</Ui.m>

      <hr style={{ borderColor: scssVariables.strokeHigh }} />
      <Helper>
        <H.xs className="mt-2">
          <Helper.Question questionId={"documentation"}>{t("documentation")}</Helper.Question>
        </H.xs>
        <Helper.Answer
          className="mt-2"
          answerId={"documentation"}
          text={t("documentationAnswer")}
          linkText={t("documentationReadMore")}
          link="/"
        />
        {currentPool && (
          <>
            <PoolDocumentStatusField className="my-5" />

            <PoolDocumentsField className="mb-5" poolId={currentPool?.poolId} />

            <PoolHelper questionId="approvalDate" answerText={t("approvalDateAnswer")}>
              <PoolApprovalDateField />
            </PoolHelper>

            <PoolApprovalByDropdown className="my-3 col-md-6" />

            <PoolExpiryDateField className="col-md-6" />

            <div className="d-flex align-items-center mt-9 ">
              <WizardContent.Controls.BackButton isDisabled={isSubmitting} onClick={buttonBackClickHandler} />

              <Button
                type="submit"
                variant="tertiary"
                className="ms-auto"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={handleSaveDraft}
              >
                {t("button.saveDraft")}
              </Button>

              <Button
                className="ms-1"
                type="submit"
                onClick={handleSaveAndApprove}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                {t("button.create")}
              </Button>
            </div>
          </>
        )}
      </Helper>

      {loading && <Spinner role="status" />}
    </WizardContent.Content>
  );
};

export default Summary;
