import { action, debug, thunk } from "easy-peasy";

import { generateGuides } from "common/guides/guide-configs/guide-configs";
import { GuideNamesById } from "common/guides/guide-configs/types";
import GuideController from "common/guides/guide-controller/guide-controller";
import GuidesService from "common/guides/guide-service/guide-service";

import { GuideModel } from "./modelTypes/guideModel.types";

export const guideModel: GuideModel = {
  // controls
  currentGuideId: null,
  currentStepId: null,
  setCurrentGuideId: action((state, guideId) => {
    if (state.populatedGuides?.[GuideNamesById[guideId]]) {
      // eslint-disable-next-line testing-library/no-debugging-utils
      GuideController.startGuide(debug(state.populatedGuides[GuideNamesById[guideId]].flowConfig));
      state.currentGuideId = guideId;
      state.currentStepId = GuideController.getCurrentGuideStep();
    }
  }),
  destroyGuide: action((state) => {
    state.currentGuideId = null;
    state.currentStepId = null;
  }),
  moveNext: thunk((_, __, { getState }) => {
    const state = getState();

    if (state.currentStepId !== null) {
      GuideController.moveToStep(state.currentStepId + 1);

      const nextStep = GuideController.getCurrentGuideStep();

      if (nextStep) {
        state.currentStepId = nextStep;
      } else {
        state.currentStepId = null;
        state.currentGuideId = null;
      }
    }
  }),
  // modal

  currentLearMoreModalId: null,
  setCurrentLearMoreModalId: action((state, id) => {
    state.currentLearMoreModalId = id;
  }),

  // guides data
  guides: [],
  filteredGuides: [],
  populatedGuides: undefined,
  setGuides: action((state, data) => {
    state.guides = data;
  }),
  setFilteredGuides: action((state, data) => {
    state.filteredGuides = data;
  }),
  setPopulatedGuides: action((state, data) => {
    state.populatedGuides = data;
  }),
  getGuidesThunk: thunk(async (actions, companyId) => {
    const result = await GuidesService.getGuides(companyId);

    if (result?.guides) {
      actions.setGuides(result.guides);

      const filtered = result.guides.filter((guide) => !guide.hidden);

      actions.setFilteredGuides(filtered);
      actions.setPopulatedGuides(
        generateGuides(
          filtered,
          () => {
            actions.destroyGuide();
          },
          (companyId: number, guideId: number) => {
            actions.doneThunk({ companyId, guideType: guideId });
            actions.getGuidesThunk(companyId);
          },
          companyId
        )
      );
    }
  }),
  doneThunk: thunk(async (actions, payload) => {
    return await GuidesService.postGuideDone(payload);
  }),
  hideThunk: thunk(async (actions, payload) => {
    return await GuidesService.postGuideHide(payload);
  }),

  // other
  isGuidesPlateExpanded: true,
  setIsGuidesPlateExpanded: action((state, isOpen) => {
    state.isGuidesPlateExpanded = isOpen;
  }),
};
