import { FC, useCallback, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Formik, FormikConfig, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";

import CloseConfirm from "./components/CloseConfirm/CloseConfirm";
import Basics from "./steps/Basics/Basics";
import SharesDetails from "./steps/SharesDetails/SharesDetails";
import Summary from "./steps/Summary/Summary";
import Termination from "./steps/Termination/Termination";
import Vesting from "./steps/Vesting/Vesting";
import useProgramForm, { CreateProgramFormData } from "./useProgramForm";
import useProgramFormValidation from "./useProgramFormValidation";

const Content = () => {
  const { dirty } = useFormikContext();

  const skipModalShow = WizardLayoutStore?.useStoreState((actions) => actions.skipModalShow);
  const setSkipModalShow = WizardLayoutStore?.useStoreActions((actions) => actions.setSkipModalShow);

  useEffect(() => {
    if (dirty && !skipModalShow) {
      setSkipModalShow(true);
    }

    if (!dirty && skipModalShow) {
      setSkipModalShow(false);
    }
  }, [dirty, setSkipModalShow, skipModalShow]);

  return (
    <Routes>
      <Route path={ROUTER_V2.equityManagement.createProgram.main} element={<Basics />} />
      <Route path={ROUTER_V2.equityManagement.createProgram.sharesDetails} element={<SharesDetails />} />
      <Route path={ROUTER_V2.equityManagement.createProgram.vesting} element={<Vesting />} />
      <Route path={ROUTER_V2.equityManagement.createProgram.termination} element={<Termination />} />
      <Route path={ROUTER_V2.equityManagement.createProgram.summary} element={<Summary />} />

      <Route path="*" element={<Navigate to={ROUTER_V2.equityManagement.createProgram.main} />} />
    </Routes>
  );
};

const CreateProgramForm: FC = () => {
  const navigate = useNavigate();
  const {
    state: { poolId, programId },
  } = useLocation();

  const { wizardStep, wizardTotalSteps } = useWizard();
  const program = useStoreState((state) => state.programModel.program);

  const { initialValues } = useProgramForm(program);
  const { validationSchema, validationSchemaStep } = useProgramFormValidation();

  const { setProgramThunk, getProgramThunk, approveProgramThunk } = useStoreActions((actions) => actions.programModel);

  const steps = useMemo(
    () =>
      ({
        1: "main",
        2: "sharesDetails",
        3: "vesting",
        4: wizardTotalSteps === 5 ? "termination" : "summary",
        5: "summary",
      } as const),
    [wizardTotalSteps]
  );

  const validStep = steps[wizardStep as keyof typeof steps];
  const validationDataSchema = useMemo(
    () => validationSchema.pick(validationSchemaStep(validStep)),
    [validStep, validationSchema, validationSchemaStep]
  );

  const handleSubmit = useCallback<FormikConfig<CreateProgramFormData>["onSubmit"]>(
    async (values, { setTouched, setFieldValue }) => {
      let programId = values.programId;
      try {
        if (!values.programId && wizardStep > 1) {
          const resp = await setProgramThunk(values);
          await setFieldValue("programId", resp.data.programId);
          programId = resp.data.programId;
        } else if (values.programId) {
          await setProgramThunk({
            ...values,
            excludePostTerminationClauses: values.isExcludedClause,
          });
        }

        if (programId) {
          await getProgramThunk(programId);
        }

        setTouched({});

        if (values.submitter) {
          if (values.submitter === "create" && values.programId) {
            await approveProgramThunk({
              id: values.programId,
              ...values,
            });
          }
          notify("Program  successfully created", true, "success");
          navigate(getEMPath(["plans", "poolsAndPrograms"], { createdProgramId: values.programId }));
        } else {
          const step = steps[(wizardStep + 1) as keyof typeof steps];
          navigate(getEMPath(["createProgram", step]), { state: { poolId, programId } });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [approveProgramThunk, getProgramThunk, navigate, poolId, setProgramThunk, steps, wizardStep]
  );

  useEffect(() => {
    if (programId && !program?.programId) {
      //get program by state.programId when reload page
      getProgramThunk(programId);
    }
  }, [getProgramThunk, program?.programId, programId]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationDataSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Content />
      </Formik>
      <CloseConfirm />
    </div>
  );
};

export default CreateProgramForm;
