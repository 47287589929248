import { FC } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";

import classes from "./TotalInfoBlock.module.scss";

type TotalListItem = {
  name: string;
  value: string;
  bold?: boolean;
};

type TotalInfoBlockPops = {
  className?: string;
  aboveDividerList: TotalListItem[];
  belowDividerList: TotalListItem[];
};

const TotalInfoBlock: FC<TotalInfoBlockPops> = ({ className, aboveDividerList, belowDividerList }) => {
  return (
    <div className={classNames(className, classes.wrap, "d-flex-column")}>
      {aboveDividerList.map((item, index) => (
        <div
          className={classNames("d-flex justify-content-between", {
            "mb-2": index !== aboveDividerList.length - 1,
            "mb-1": index === aboveDividerList.length - 1,
          })}
          key={index}
        >
          <Ui.s bold={item.bold} color="foregroundHigh">
            {item.name}
          </Ui.s>
          <Ui.s bold={item.bold} color="foregroundHigh">
            {item.value}
          </Ui.s>
        </div>
      ))}

      <div className={classNames(classes.divider, "mb-4")} />
      {belowDividerList.map((item, index) => (
        <div
          className={classNames("d-flex justify-content-between", {
            "mb-2": true,
          })}
          key={index}
        >
          <Ui.s bold={item.bold} color="foregroundHigh">
            {item.name}
          </Ui.s>
          <Ui.s bold={item.bold} color="foregroundHigh">
            {item.value}
          </Ui.s>
        </div>
      ))}
    </div>
  );
};

export default TotalInfoBlock;
