import { FC } from "react";

import { Button, H, ModalInfo, P } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { Transaction } from "../../types";
import MiniTransactionsList from "../transactions-list/mini-transactions-list/mini-transactions-list";

type PropsTypes = {
  transactions: Transaction[];
  onClose: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.dependentTransactionsModal");

const DependentTransactions: FC<PropsTypes> = ({ transactions, onClose }) => {
  return (
    <ModalInfo show handleClose={onClose} className="d-flex flex-column" contentClassName="text-start" size="lg">
      <H.m className="mb-2">{t("title")}</H.m>
      <P.m className="mb-4">{t("description")}</P.m>
      <MiniTransactionsList transactions={transactions} className="mb-4" />
      <div className="d-flex justify-content-end">
        <Button onClick={onClose}>{t("backBtn")}</Button>
      </div>
    </ModalInfo>
  );
};

export default DependentTransactions;
