import { FC, useCallback } from "react";

import { CloseIcon, UnlistedIcon } from "common/icons/svg";

import Button from "../../../components/atoms/Button/Button";
import WizardLayoutStore from "../store";
import classes from "./WizardHeaderBar.module.scss";

const WizardHeaderBar: FC = () => {
  const skipModalShow = WizardLayoutStore?.useStoreState((actions) => actions.skipModalShow);
  const setCloseConfirmOpenAction = WizardLayoutStore?.useStoreActions((actions) => actions.setCloseConfirmOpen);
  const callBackToSkipModal = WizardLayoutStore?.useStoreState((state) => state?.callBackToSkipModal);

  const closeButtonClickHandler = useCallback((): void => {
    setCloseConfirmOpenAction(true);
  }, [setCloseConfirmOpenAction]);

  return (
    <header className={`pt-3 ps-3 pe-1 mb-6 ${classes["header"]}`}>
      <UnlistedIcon width={68} height={68} className={classes["logo"]} />

      <Button
        variant="dark"
        size="s"
        isOnlyIcon
        onClick={skipModalShow ? closeButtonClickHandler : callBackToSkipModal}
      >
        <CloseIcon />
      </Button>
    </header>
  );
};

export default WizardHeaderBar;
