import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import { UploadFileTypes } from "common/enums/enum";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { notify } from "common/utils/notify/notifyFunction";
import { FundraiseLoanConversionGetDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";

import LoanConversionForm from "./LoanConversionForm";
import { LoanConversionSlidePanelContext } from "./LoanConversionSlidePanel";
import useLoanConversionSlidePanel, { LoanConversionFormValues } from "./useLoanConversionSlidePanel";

type Props = {};

const LoanConversionFormik: FC<Props> = () => {
  const { companyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { onSaved, onHide, convertibleLoanId } = useContext(LoanConversionSlidePanelContext);

  const [loanConversion, setLoanConversion] = useState<FundraiseLoanConversionGetDTO | null>(null);
  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const { initialValues, validationSchema } = useLoanConversionSlidePanel(loanConversion);

  const { getFundraiseLoanConversionThunk, setFundraiseLoanConversionThunk } = useStoreActions(
    (actions) => actions.fundraisingModel
  );

  const handleSubmit = useCallback(
    async (values: LoanConversionFormValues) => {
      await setFundraiseLoanConversionThunk({
        ...values,
        loanAmountRepaid: values.loanRepaymentAmount,
        interestNotConvertedToEquity: values.interestPaidInCash,
        overrideInterestAmount: values.interestAmount,
        overrideNumberOfShares: values.sharesAllocation,
        overrideSharePrice: values.sharePrice,
      });
      if (companyId && values.loanId) {
        await updateCompanyDocuments(
          companyId,
          values.loanId,
          UploadFileTypes.ConvertibleLoanConversionDocument,
          values.filesData.newFiles,
          values.filesData.oldFiles,
          values.documentStatusId
        );
      }
      notify(`Loan successfully ${values.loanId ? "updated" : "created"}`, true, "success");
      onSaved?.();
      onHide?.();
    },
    [companyId, onHide, onSaved, setFundraiseLoanConversionThunk, updateCompanyDocuments]
  );

  useEffect(() => {
    if (convertibleLoanId) {
      setIsLoading(true);
      getFundraiseLoanConversionThunk(convertibleLoanId)
        .then((res) => {
          setLoanConversion(res.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [convertibleLoanId, getFundraiseLoanConversionThunk]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <LoanConversionForm convertible={loanConversion} />
      </Formik>
    </SlidePanel.Body>
  );
};
export default LoanConversionFormik;
