import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import NoAccess from "common/access-control/no-access/NoAccess";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";

const AccessMiddlewareAro: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { pathname } = useLocation();

  const manageAccess = useMemo(() => {
    return {
      [getPath(["aro", "report"], { companyId })]: [
        FEATURES.companyInformation,
        FEATURES.transactions,
        FEATURES.stakeholdersManagement,
      ],
      [getPath(["aro", "pricing"], { companyId })]: [
        FEATURES.companyInformation,
        FEATURES.transactions,
        FEATURES.stakeholdersManagement,
      ],
      [getPath(["aro", "buyAroProduct"], { companyId })]: [
        FEATURES.companyInformation,
        FEATURES.transactions,
        FEATURES.stakeholdersManagement,
      ],
      [getPath(["aro", "sendDetailsToAltinn"], { companyId })]: [
        FEATURES.companyInformation,
        FEATURES.transactions,
        FEATURES.stakeholdersManagement,
      ],
    };
  }, [companyId]);

  const accessKey = manageAccess[pathname.replace(/\/$/, "")];
  const companyInformationFeatures = useFeatures(accessKey[0]);
  const transactionsFeatures = useFeatures(accessKey[1]);
  const stakeholdersFeatures = useFeatures(accessKey[2]);

  if (
    accessKey &&
    (!companyInformationFeatures.hasFullAccess ||
      !transactionsFeatures.hasFullAccess ||
      !stakeholdersFeatures.hasFullAccess)
  ) {
    return <NoAccess />;
  }

  return <Outlet />;
};

export default AccessMiddlewareAro;
