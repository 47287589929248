import { FC, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import * as R from "ramda";

import { getEMPath, getPath } from "app/Router/RouterHelper";
import UserProfileImage from "common/components/atoms/UserProfileImage/UserProfileImage";
import { CompanyIcon, PieChartIcon } from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu from "common/layout/MainLayout/Menu/Menu";
import UserAccountPage from "pages/user/account/account";
import GeneralProfilePage from "pages/user/general/general";
import UserNotificationPage from "pages/user/notifications/notifications";
import { useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import { ROUTER_V2 } from "../../app/Router/RouterV2.types";
import Page404 from "../statuses/page404/Page404";
import UserDashboard from "./dashboard/Dashboard";
import NewUserDashboard from "./dashboard/new-user/NewUserDashboard";

const translation = createTranslation(TranslationNS.pages, "user.sideBar");

const UserRouter: FC = () => {
  const location = useLocation();

  const user = useStoreState((state) => state.account.user);

  const sideBarRequirement =
    location.pathname !== getPath(["user", "noAccess"]) && location.pathname !== getPath(["user", "dashboard"]);

  const userMenu = useMemo(() => {
    const menuItems = [
      {
        title: translation("account.title"),
        items: [
          {
            to: getPath(["user", "general"]),
            label: translation("account.routes.general"),
            icon: <UserProfileImage width={20} height={20} />,
          },
          {
            to: getPath(["user", "account"]),
            label: translation("account.routes.account"),
            icon: <PieChartIcon />,
          },
          // {
          //   to: "/user/notifications",
          //   label: translation("account.routes.notifications"),
          //   icon: <NotificationIcon />,
          // },
          // {
          //   to: "/user/settings",
          //   label: translation("account.routes.settings"),
          //   icon: <SettingsIcon/>,
          // },
        ],
      },
    ];

    const quickLinksProperty = {
      title: translation("quickLinks.title"),
      items: R.flatten([
        (user?.companies || []).map((el) => [
          {
            to: getEMPath(["settings", "companySettings"], { companyId: el.id }),
            label: translation("quickLinks.routes.company", {
              companyName: el.name,
            }),
            icon: <CompanyIcon />,
          },
        ]),
      ]),
    };

    menuItems.push(quickLinksProperty);

    return menuItems;
  }, [user?.companies]);

  return (
    <MainLayout>
      {sideBarRequirement && (
        //style={{ maxHeight: window.innerHeight * 0.85, overflowY: "scroll" }}
        <MainLayout.Sidebar>
          {userMenu.map((item, index) => (item ? <Menu key={index} {...item} /> : null))}
        </MainLayout.Sidebar>
      )}

      <MainLayout.Content>
        <Routes>
          <Route path={ROUTER_V2.user.account} element={<UserAccountPage />} />
          <Route path={ROUTER_V2.user.general} element={<GeneralProfilePage />} />
          <Route path={ROUTER_V2.user.notifications} element={<UserNotificationPage />} />
          <Route
            path={ROUTER_V2.user.dashboard}
            element={user && !user?.companies?.length ? <NewUserDashboard /> : <UserDashboard />}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default UserRouter;
