import { useEffect } from "react";

import { useStoreActions, useStoreState } from "store/store";

import { GuideIds } from "../../guide-configs/types";

const UseGuideAddNominalValue3 = (isModalOpen: boolean) => {
  const currentGuideId = useStoreState((state) => state.guideModel.currentGuideId);
  const currentStepId = useStoreState((state) => state.guideModel.currentStepId);
  const moveNext = useStoreActions((actions) => actions.guideModel.moveNext);

  const isAddNominalValueGuideActive = currentGuideId === GuideIds.AddNominalValue;

  useEffect(() => {
    if (isModalOpen && isAddNominalValueGuideActive && currentStepId === 1) {
      moveNext();
    }
  }, [currentStepId, isAddNominalValueGuideActive, isModalOpen, moveNext]);
};

export default UseGuideAddNominalValue3;
