import { FC, useCallback, useEffect } from "react";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H } from "common/components/atoms";
import { useRedirectTo } from "common/hooks/useRedirectTo";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardCloseConfirm from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.closeConfirm");

const CloseConfirm: FC = () => {
  const redirectTo = useRedirectTo();

  const setCloseConfirmOpenAction = WizardLayoutStore.useStoreActions((actions) => actions.setCloseConfirmOpen);
  const setCallBackToSkipModal = WizardLayoutStore?.useStoreActions((state) => state.setCallBackToSkipModal);

  const handleStayClick = useCallback((): void => {
    setCloseConfirmOpenAction(false);
  }, [setCloseConfirmOpenAction]);

  const handleLeaveClick = useCallback((): void => {
    redirectTo(getEMPath(["ownership", "transactions"]));
  }, [redirectTo]);

  useEffect(() => {
    setCallBackToSkipModal(handleLeaveClick);
  }, [handleLeaveClick, setCallBackToSkipModal]);

  return (
    <WizardCloseConfirm onExit={handleLeaveClick}>
      <H.xxs className="mb-5 text-center">{t("text")}</H.xxs>

      <div className="d-flex justify-content-center">
        <Button variant="danger" className="w-max-content me-2" onClick={handleLeaveClick}>
          {t("leave")}
        </Button>

        <Button variant="secondary" className="w-max-content" onClick={handleStayClick}>
          {t("stay")}
        </Button>
      </div>
    </WizardCloseConfirm>
  );
};

export default CloseConfirm;
