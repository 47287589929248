import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps } from "common/components/atoms/ContextMenu/ContextMenu";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import { MenuTabBarVerticalIcon, PieChartIcon, RemoveIcon, TimeIcon } from "common/icons/svg";
import AdminContext from "pages/admin/AdminContext";

type ActionsProps = {
  id: number;
  name: string;
};

const Actions: FC<ActionsProps> = ({ id, name }) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const isLoading = AdminContext.useStoreState((state) => state.isLoading);

  const { getCompaniesThunk, setIsLoading, setManageSubscriptionId } = AdminContext.useStoreActions(
    (actions) => actions
  );

  const handleViewCompany = useCallback(() => {
    navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId: id }));
  }, [id, navigate]);

  const handleOpenUpdateSubscriptionModal = useCallback(() => {
    setManageSubscriptionId(id);
  }, [id, setManageSubscriptionId]);

  const handleDeleteCompany = useCallback(() => {
    setIsLoading(true);
    axios
      .delete(`/api/admin/company/${id}`)
      .then(() => {
        getCompaniesThunk().catch(() => {});
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setIsLoading(false);
      });
  }, [getCompaniesThunk, setIsLoading, id]);

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const menuItems: ContextMenuProps["items"] = [
      {
        key: "viewCompany",
        label: "Go to captable",
        icon: <PieChartIcon />,
        onClick: handleViewCompany,
      },
      {
        key: "addCompanySubscription",
        label: "Manage subscription",
        icon: <TimeIcon />,
        forceHideDropdown: true,
        onClick: handleOpenUpdateSubscriptionModal,
      },
      {
        key: "deleteCompany",
        label: `Delete ${name}`,
        icon: <RemoveIcon />,
        onClick: () => setShowDeleteModal(true),
      },
    ];
    return menuItems;
  }, [handleViewCompany, handleOpenUpdateSubscriptionModal, name]);

  const renderFooter = () => {
    return (
      <>
        <Button onClick={handleDeleteCompany} isLoading={isLoading}>
          Delete
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </>
    );
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  return (
    <div>
      <ContextMenu items={menuItems} drop="down">
        <Button size="m" isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn">
          <MenuTabBarVerticalIcon />
        </Button>
      </ContextMenu>

      <ModalInfo
        show={showDeleteModal}
        header="Are you sure you want to delete this company?"
        handleClose={handleClose}
        footer={renderFooter()}
      >
        <p>
          This will result in all shares, transactions, stakeholders, agreements, financial instruments and other data
          being deleted for this company.
        </p>
      </ModalInfo>
    </div>
  );
};

export default Actions;
