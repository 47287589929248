import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { LoaderContainer } from "common/components/atoms";
import { UploadFileTypes } from "common/enums/enum";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { useStoreActions, useStoreState } from "store/store";

import SetFundraiseForm from "./SetFundraiseForm";
import useFundraisingSlidePanel, { FundraisingFormValues } from "./useFundraisingSlidePanel";

type SetFundraiseFormikProps = {
  onHide: () => void;
  fundraiseId?: number;
  onSaved?: () => void;
};
const SetFundraiseFormik: FC<SetFundraiseFormikProps> = ({ onHide, fundraiseId, onSaved }) => {
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const { setFundraisingThunk, getFundraiseThunk, setFundraise } = useStoreActions(
    (actions) => actions.fundraisingModel
  );
  const fundraise = useStoreState((state) => state.fundraisingModel.fundraise);

  const { initialValues, validationSchema } = useFundraisingSlidePanel(fundraise);

  const handleSubmit = useCallback<FormikConfig<FundraisingFormValues>["onSubmit"]>(
    async (values) => {
      try {
        const { data } = await setFundraisingThunk({ ...values, companyId });

        await updateCompanyDocuments(
          companyId!,
          data.fundraiseId,
          UploadFileTypes.FundraiseDocument,
          values.filesData.newFiles,
          values.filesData.oldFiles,
          values.documentStatusId
        );
        onSaved?.();

        onHide();
      } catch (error) {
        console.error(error);
      }
    },
    [setFundraisingThunk, companyId, updateCompanyDocuments, onSaved, onHide]
  );

  useEffect(() => {
    if (fundraiseId) {
      setIsLoading(true);
      getFundraiseThunk(fundraiseId).finally(() => setIsLoading(false));
    }
  }, [fundraiseId, getFundraiseThunk]);

  useEffect(() => {
    setFundraise(null);
  }, [setFundraise]);

  return (
    <LoaderContainer variant="contentScreen" loading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <SetFundraiseForm status={!fundraise?.fundraiseId ? "start" : "update"} onHide={onHide} />
      </Formik>
    </LoaderContainer>
  );
};

export default SetFundraiseFormik;
