import { FC } from "react";
import cn from "classnames";

import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { FundraiseTransactionDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import classes from "./FundraiseShareIssue.module.scss";
type FundraiseShareIssueBodyProps = Partial<FundraiseTransactionDTO> & {};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const FundraiseShareIssueBody: FC<FundraiseShareIssueBodyProps> = ({ ...transaction }) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={cn("p-4", classes.bodyContainer, classes.darkBackground)}>
      <div
        className={cn("d-flex justify-content-evenly align-items-stretch mb-3", {
          // [classes.border]: !transaction.description,
        })}
      >
        <div className="d-flex flex-column align-items-center justify-content-between mb-3">
          <div className="d-flex flex-column align-items-center">
            <NewAvatar
              className="mb-2"
              imageUrl={transaction.toAvatar}
              company={transaction.toIsCompanyOwned}
              initials={transaction.toInitials}
            />
            <Ui.l bold className="mb-1">
              {transaction.toName}
            </Ui.l>
            {transaction.toRepresentativeName && (
              <Ui.s className="mb-2 text-medium">{transaction.toRepresentativeName}</Ui.s>
            )}
          </div>
          <Ui.xl className="mt-1" bold style={{ color: scssVariables.positive700 }}>
            {`+ ${fNumber(transaction.numberOfShares, "amount")}`}
          </Ui.xl>
        </div>
      </div>

      {/* Transaction info */}
      <div
        className={cn("d-flex flex-column mb-3 pb-3" /* classes.border*/)}
        style={{ color: scssVariables.foregroundMedium }}
      >
        <div className="d-flex justify-content-evenly mb-3">
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("transactionTotal")}</Ui.m>
            <Ui.m>{fNumber(transaction.transactionTotal, "value")}</Ui.m>
          </div>
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("numberOfShares")}</Ui.m>
            <Ui.m>{fNumber(transaction.numberOfShares, "amount")}</Ui.m>
          </div>
        </div>
        <div className="d-flex justify-content-evenly">
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("sharePrice")}</Ui.m>
            <Ui.m>{fNumber(transaction.sharePrice, "sharePrice")}</Ui.m>
          </div>
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("shareClass")}</Ui.m>
            <Ui.m>{transaction.shareClassName}</Ui.m>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FundraiseShareIssueBody;
