import { FC } from "react";
import classNames from "classnames";

import { P } from "../../components/atoms";
import { WarningIcon } from "../../icons/svg";
import classes from "./ChangingStockOptionsWarning.module.scss";

type Props = {
  className?: string;
};
const ChangingStockOptionsWarning: FC<Props> = ({ className }) => {
  return (
    <div className={classNames("d-flex", className, classes.wrap)}>
      <div>
        <WarningIcon fontSize={24} />
      </div>
      <P.s color="foregroundHigh">
        Changing the number of stock options in an active agreement will update the vesting schedule. Note that this
        change will not appear in any previously generated or uploaded documents.
      </P.s>
    </div>
  );
};

export default ChangingStockOptionsWarning;
