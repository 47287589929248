import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { ContextMenuProps, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, EditIcon, FileTextSearchIcon } from "common/icons/svg";
import { RoundInstrumentsDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import Actions from "../../../components/DashboardTable/Actions/Actions";
import NameCell from "../../../components/DashboardTable/NameCell/NameCell";
import ShareClassCell from "../../../components/DashboardTable/ShareClassCell/ShareClassCell";
import StatusCell from "../../../components/DashboardTable/StatusCell/StatusCell";

const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription");

const useRoundTable = (fundraiseRoundTypeId: number, menuClickCallback?: ContextMenuProps["menuClickCallback"]) => {
  const fNumber = useFormatNumbers();

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const items: ContextMenuProps["items"] = [];
    if (fundraiseRoundTypeId === 1) {
      //subscription type
      items.push({
        key: "viewSubscription",
        icon: <FileTextSearchIcon />,
        label: t("table.viewDetails"),
      });
      items.push({
        key: "editSubscriptions",
        icon: <EditIcon />,
        label: t("table.edit"),
      });
    } else if (fundraiseRoundTypeId === 2) {
      //conversion type
      items.push({
        key: "editConversions",
        icon: <EditIcon />,
        label: t("table.editConversion"),
      });
    }

    const initialItems: ContextMenuProps["items"] = [
      {
        key: "delete",
        label: t("table.delete"),
        icon: <DeleteIcon />,
        type: "delete",
      },
    ];

    return [...items, ...initialItems];
  }, [fundraiseRoundTypeId]);

  const additionalColumn = useMemo<ColumnDef<RoundInstrumentsDTO>[]>(() => {
    return fundraiseRoundTypeId === 1
      ? [
          {
            accessorKey: "statusId",
            cell: (info) => <StatusCell status={info.getValue() as number} />,
            header: () => <Ui.s tag="span">{t("table.status")}</Ui.s>,
            enableResizing: false,
          },
        ]
      : [];
  }, [fundraiseRoundTypeId]);

  const columns = useMemo<ColumnDef<RoundInstrumentsDTO>[]>(
    () => [
      {
        accessorKey: "stakeholderInitials",
        cell: (info) => {
          return (
            <NameCell
              initials={info.getValue() as string}
              name={info.row.original.stakeholderName}
              representByName={info.row.original.stakeholderRepresentedBy}
              isCompany={info.row.original.isCompanyOwned}
              isAttached={false}
              imageUrl={info.row.original?.avatarFilePath}
            />
          );
        },
        header: () => <Ui.s tag="span">{t("table.name")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "subscriptionAmount",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("table.subscriptionAmount")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "sharePrice",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number, "sharePrice")}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("table.sharePrice")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfShares",
        cell: (info) => <Ui.s>{fNumber(info.getValue() as number, "amount")}</Ui.s>,
        header: () => <Ui.s tag="span">{t("table.shares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "shareClassName",
        cell: (info) => <ShareClassCell name={info.getValue() as string} eventType={fundraiseRoundTypeId} />,
        header: () => <Ui.s tag="span">{t("table.shareClass")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "instrument",
        cell: (info) => <Ui.s>{info.getValue() as string}</Ui.s>,
        header: () => <Ui.s tag="span">{t("table.instrument")}</Ui.s>,
        // meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      ...additionalColumn,
      {
        accessorKey: "id",
        cell: (info) => (
          <Actions meta={info.row.original} menuItems={menuItems} menuClickCallback={menuClickCallback} />
        ),
        header: () => <Ui.s tag="span">{t("table.actions")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },

        enableSorting: false,
        maxSize: 56,
        enableResizing: false,
      },
    ],
    [additionalColumn, fNumber, fundraiseRoundTypeId, menuClickCallback, menuItems]
  );

  return { columns };
};
export default useRoundTable;
