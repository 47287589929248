import { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import useCompanyMenu from "common/hooks/useCompanyMenu";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu from "common/layout/MainLayout/Menu/Menu";
import { useStoreActions, useStoreState } from "store/store";

import AccessMiddlewareFundraise from "../../app/middleware/AccessMiddleware/AccessMiddlewareFundraise";
import useDocumentTitleUpdate from "../../common/hooks/useDocumentTitleUpdate";
import Fundraising from "./Fundraising";
import Overview from "./overview/Overview";
import PreviewResults from "./preview-results/PreviewResults";

const FundraisingRouter = () => {
  useDocumentTitleUpdate("Fundraise");
  const { companyId } = useParams<{ companyId: string }>();
  const { getShareClassesCompanyThunk } = useStoreActions((actions) => actions.shareClassModel);

  const isCompanyMenuOpen = useStoreState((state) => state.company.isCompanyMenuOpen);

  const { equityManagement, companySettings, reporting } = useCompanyMenu(companyId || 0);

  useEffect(() => {
    if (companyId) {
      getShareClassesCompanyThunk(Number(companyId));
    }
  }, [companyId, getShareClassesCompanyThunk]);

  return (
    <MainLayout>
      {isCompanyMenuOpen && (
        <MainLayout.Sidebar id="company-ownership-sidebar" isPlanInfoWidgetVisible>
          <Menu title={undefined} items={equityManagement} />
          <Menu title={"Reporting"} items={reporting} />
          <Menu title={"Settings"} items={companySettings} />
        </MainLayout.Sidebar>
      )}
      <Fundraising>
        <Routes>
          <Route element={<AccessMiddlewareFundraise />}>
            <Route path={ROUTER_V2.fundraising.overview} element={<Overview />} />
            <Route path={ROUTER_V2.fundraising.previewResult} element={<PreviewResults />} />
            <Route path="*" element={<Navigate to={ROUTER_V2.fundraising.overview} />} />
          </Route>
        </Routes>
      </Fundraising>
    </MainLayout>
  );
};

export default FundraisingRouter;
