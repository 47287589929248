import { FC } from "react";
import classNames from "classnames";

import { NewAvatar, Ui } from "common/components/atoms";

import classes from "./SubscriptionAmountBlock.module.scss";

type Props = {
  stakeholder?: any;
  className?: string;
  amount: string;
  hideAvatar?: boolean;
};
const SubscriptionAmountBlock: FC<Props> = ({ className, stakeholder, amount, hideAvatar = false }) => {
  return (
    <div className={classNames(className, classes.wrap)}>
      {!hideAvatar && (
        <NewAvatar
          size="l"
          company={!!stakeholder?.companyName}
          firstName={stakeholder?.firstName || "A"}
          lastName={stakeholder?.lastName}
          imageUrl={stakeholder?.imageUrl}
          companyName={stakeholder?.companyName}
        />
      )}
      <Ui.xl bold>{amount}</Ui.xl>
      <Ui.s>Subscription amount</Ui.s>
    </div>
  );
};
export default SubscriptionAmountBlock;
