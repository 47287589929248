import { CSSProperties, FC, ReactNode } from "react";
import cn from "classnames";

type PT = {
  children: ReactNode;
  color: string;
  className?: string;
  style?: CSSProperties;
};

const CircleIconContainer: FC<PT> = ({ children, color, className, style }) => (
  <div
    className={cn("d-flex justify-content-center align-items-center rounded-5 p-1", className)}
    style={{ ...style, backgroundColor: color }}
  >
    {children}
  </div>
);

export default CircleIconContainer;
