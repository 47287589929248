import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import { ChecksGroup, Helper, P, TextField } from "common/components/atoms";
import FileUploader, { FilesDataSingle } from "common/components/atoms/FileUploader/FileUploader";
import { InstrumentTypesIdsEnum, PlanDocumentStatusEnum, RelationshipTypesEnum } from "common/enums/enum";
import { PlanForm } from "pages/equity-management/plans/wizards/create-one-off-plans/types";
import { createTranslation, TranslationNS } from "translation";

import { PlanFormType } from "../../../pages/equity-management/plans/wizards/create-plan/steps/basics/sidebar/forms/plan-form";
import { newPlanFieldsNames as f } from "../planFormFields";
import UpdateOrganisationNumber from "./updateOrganisationNumber/updateOrganisationNumber";
import ViewAgreement from "./viewAgreement/viewAgreement";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar.generatePlanDocument");

type DocumentStatusForm = PlanForm;

type GeneratePlanDocumentProps = {
  autoGenerateAgreement: boolean;
  managerSignature: boolean;
  showReceiverEmailInput?: boolean;
  noinvitations?: boolean;
  withSigningManagerEmailField?: boolean;
  exercising?: boolean;
  planFormType?: PlanFormType;
};
const GeneratePlanDocument: FC<GeneratePlanDocumentProps> = ({
  autoGenerateAgreement,
  managerSignature,
  showReceiverEmailInput,
  noinvitations = false,
  withSigningManagerEmailField = true,
  exercising = false,
  planFormType,
}) => {
  const [organizationNumber, setOrganizationNumber] = useState<string | null>(null);

  const { values, setFieldValue, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormikContext<DocumentStatusForm>();

  const handleChangeFile = useCallback(
    (data: FilesDataSingle | null) => {
      setFieldValue(f.fileAgreement, data);
    },
    [setFieldValue]
  );

  const handleChangeManagerSignature = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked && Number(values.documentStatusId) !== 1) {
        setFieldValue(f.documentStatusId, PlanDocumentStatusEnum.UPLOAD);
      }
      setFieldValue(f.documentsNeedsSignature, e.target.checked);
    },
    [setFieldValue, values.documentStatusId]
  );

  const noDocuments = useMemo(
    () =>
      Number(values.documentStatusId) === PlanDocumentStatusEnum.LATER ||
      Number(values.documentStatusId) === PlanDocumentStatusEnum.NOT_REQUIRED ||
      Number(values.documentStatusId) === 0,
    [values.documentStatusId]
  );

  useEffect(() => {
    if (noDocuments) {
      setFieldValue(f.fileAgreement, {
        newFile: null,
        oldFile: null,
      });

      if (withSigningManagerEmailField) {
        setFieldValue(f.signingManagerEmail, "");
      }

      setFieldValue(f.documentsNeedsSignature, false);
    }
  }, [noDocuments, setFieldValue, withSigningManagerEmailField]);

  useEffect(() => {
    if (!values.sendEmailInvitation) {
      setFieldValue(f.documentsNeedsSignature, false);
    }
  }, [setFieldValue, values.sendEmailInvitation]);

  useEffect(() => {
    if (values.documentsNeedsSignature) {
      setFieldValue(f.sendEmailInvitation, true);
    }
  }, [setFieldValue, values.documentsNeedsSignature]);

  const errorFile = errors.fileAgreement as { newFile?: string; oldFile?: string };

  const disableWhenPlanActive = values.disableWhenPlanActive;

  return (
    <div className="pb-5 mt-0">
      <Helper>
        <P.m bold className={cn("mt-4")}>
          {exercising
            ? t("titleSOExercise")
            : values.planTypeId === InstrumentTypesIdsEnum.OPTION
            ? t("titleSO")
            : t("titleRSA")}
        </P.m>
        <ChecksGroup className="my-1" error={errors.documentStatusId} isTouched={touched.documentStatusId}>
          {autoGenerateAgreement &&
            (values?.relationshipTypeId === RelationshipTypesEnum.BOARD ||
              values?.relationshipTypeId === RelationshipTypesEnum.EMPLOYEE) && (
              <ChecksGroup.Check
                type="radio"
                label={t("generate.label")}
                description={t("generate.description")}
                checked={Number(values.documentStatusId) === PlanDocumentStatusEnum.GENERATE}
                name={f.documentStatusId}
                value={PlanDocumentStatusEnum.GENERATE}
                onChange={handleChange}
                disabled={
                  !organizationNumber || (values.isCompanyOwned && !values.organizationNumber) || disableWhenPlanActive
                }
              />
            )}
          <ChecksGroup.Check
            type="radio"
            label={t("upload.label")}
            description={t("upload.description")}
            checked={Number(values.documentStatusId) === PlanDocumentStatusEnum.UPLOAD}
            name={f.documentStatusId}
            value={PlanDocumentStatusEnum.UPLOAD}
            onChange={handleChange}
            disabled={disableWhenPlanActive}
          />
          <ChecksGroup.Check
            type="radio"
            label={t("later.label")}
            description={t("later.description")}
            checked={Number(values.documentStatusId) === PlanDocumentStatusEnum.LATER}
            name={f.documentStatusId}
            value={PlanDocumentStatusEnum.LATER}
            onChange={handleChange}
            disabled={disableWhenPlanActive}
          />
          <ChecksGroup.Check
            type="radio"
            label={t("notRequired.label")}
            description={t("notRequired.description")}
            checked={Number(values.documentStatusId) === PlanDocumentStatusEnum.NOT_REQUIRED}
            name={f.documentStatusId}
            value={PlanDocumentStatusEnum.NOT_REQUIRED}
            onChange={handleChange}
            disabled={disableWhenPlanActive}
          />
        </ChecksGroup>
        <div>
          {Number(values.documentStatusId) === PlanDocumentStatusEnum.UPLOAD && (
            <FileUploader
              className="mt-3"
              label={t("uploadAgreement")}
              onChange={handleChangeFile}
              multiple={false}
              prevFileData={values.fileAgreement?.oldFile}
              error={(errorFile?.newFile as string) || errorFile?.oldFile}
              isTouched={touched.fileAgreement}
            />
          )}
          {Number(values.documentStatusId) === PlanDocumentStatusEnum.GENERATE && (
            <ViewAgreement handleSubmit={handleSubmit} className="mt-3" />
          )}
          {(!organizationNumber || (values.isCompanyOwned && !values.organizationNumber)) &&
            autoGenerateAgreement &&
            (values?.relationshipTypeId === RelationshipTypesEnum.BOARD ||
              values?.relationshipTypeId === RelationshipTypesEnum.EMPLOYEE) && (
              <UpdateOrganisationNumber
                setOrganizationNumber={setOrganizationNumber}
                isCompanyOwner={values.isCompanyOwned}
                companyOrgNumber={organizationNumber}
                receiverOrgNumber={values.organizationNumber}
              />
            )}

          {noinvitations ? null : (
            <div className="mt-4">
              <ChecksGroup className="mb-2">
                <Helper.Question questionId={f.sendEmailInvitation}>
                  <ChecksGroup.Check
                    label={t("checkbox.receiver")}
                    onChange={handleChange}
                    checked={values.sendEmailInvitation}
                    name={f.sendEmailInvitation}
                    disabled={disableWhenPlanActive}
                  />
                </Helper.Question>
                <Helper.Answer
                  answerId={f.sendEmailInvitation}
                  className="mt-3 mb-3"
                  text={`${t("receiverAnswer")}
                   ${
                     planFormType !== PlanFormType.OneOffRSAPlan && planFormType !== PlanFormType.OneOffSOPlan
                       ? t("answerRraAndSoAdditionalInfo")
                       : ""
                   }`}
                />
                {showReceiverEmailInput && values.sendEmailInvitation && (
                  <>
                    <TextField
                      label={t("receiverEmail")}
                      name={f.email}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-50 mb-4 mt-2"
                      error={errors.email}
                      isTouched={touched.email}
                      isDisabled={disableWhenPlanActive}
                    />
                  </>
                )}
                {managerSignature && !noDocuments && (
                  <>
                    <Helper.Question questionId={f.documentsNeedsSignature}>
                      <ChecksGroup.Check
                        label={t("checkbox.manager")}
                        onChange={handleChangeManagerSignature}
                        checked={values.documentsNeedsSignature}
                        name={f.documentsNeedsSignature}
                        disabled={disableWhenPlanActive}
                      />
                    </Helper.Question>
                    <Helper.Answer
                      answerId={f.documentsNeedsSignature}
                      className="mt-3"
                      text={`
                      ${t("managerAnswer")} ${
                        planFormType &&
                        planFormType !== PlanFormType.OneOffRSAPlan &&
                        planFormType !== PlanFormType.OneOffSOPlan
                          ? t("answerRraAndSoAdditionalInfo")
                          : ""
                      }
                    `}
                    />
                  </>
                )}
              </ChecksGroup>
            </div>
          )}

          {values.documentsNeedsSignature && !noDocuments && withSigningManagerEmailField && (
            <TextField
              label={t("managerEmail")}
              name={f.signingManagerEmail}
              value={values.signingManagerEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-50 mb-2"
              error={errors.signingManagerEmail}
              isTouched={touched.signingManagerEmail}
            />
          )}
        </div>
      </Helper>
    </div>
  );
};
export default GeneratePlanDocument;
