import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../transactions.context";
import CloseConfirm from "../forms/capital-increase/close-confirm/close-confirm";
import CaptablePreview from "./steps/captable-preview/captable-preview";
import DownloadTemplate from "./steps/download-template/download-template";
import ImportStatus from "./steps/import-status/import-status";
import TransactionsPreview from "./steps/transactions-preview/transactions-preview";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions");

const Wizard = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { wizardStep } = useWizard();
  return (
    <>
      <WizardLayout.Header title={t("wizardTitle")} />
      <WizardContent maxStep={4} className={cn({ [classes.maxWidth]: wizardStep === 3 || wizardStep === 4 })}>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.importTransactions.downloadTemplate} element={<DownloadTemplate />} />
          <Route path={ROUTER_V2.equityManagement.importTransactions.importStatus} element={<ImportStatus />} />
          <Route path={ROUTER_V2.equityManagement.importTransactions.captablePreview} element={<CaptablePreview />} />
          <Route
            path={ROUTER_V2.equityManagement.importTransactions.transactionsPreview}
            element={<TransactionsPreview />}
          />
          <Route
            path="*"
            element={<Navigate to={getEMPath(["importTransactions", "downloadTemplate"], { companyId })} />}
          />
        </Routes>
      </WizardContent>
      <CloseConfirm />
    </>
  );
};

const ImportTransactionsWizard: FC = () => {
  return (
    <TransactionsContext.Provider>
      <WizardLayout>
        <Wizard />
      </WizardLayout>
    </TransactionsContext.Provider>
  );
};

export default ImportTransactionsWizard;
