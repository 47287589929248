import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Button, H, P } from "common/components/atoms";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import chartImg from "./Chart.png";
import { ImportMode } from "./company-information";
import ForeignImport from "./import-components/foreign-import";
import NorwayImport from "./import-components/norway-import";
import classes from "./styles.module.scss";
import { CompanyInformationValues } from "./types";
import { NORWAY_ID } from "./use-company-info-form";

const t = createTranslation(TranslationNS.pages, "onboard.company.companyInformation");

type PT = {
  importMode: ImportMode;
  setImportMode(mode: ImportMode): void;
};

const Form: FC<PT> = ({ importMode, setImportMode }) => {
  const { values, errors, touched, handleChange, handleBlur, isSubmitting } =
    useFormikContext<CompanyInformationValues>();
  const countriesDropDown = useStoreState((state) => state.common.dropdowns)?.countries;
  const setIntegration = useStoreActions((actions) => actions.companyOnboardingModel.setIntegration);
  const setCompany = useStoreActions((actions) => actions.companyOnboardingModel.setCompany);

  useEffect(() => {
    setIntegration({
      type: "all",
      data: null,
    });
    setCompany(null);
  }, [setCompany, setIntegration]);

  useEffect(() => {
    if (values.countryId === 0) {
      setImportMode(ImportMode.NotSelected);
    } else if (values.countryId === NORWAY_ID) {
      setImportMode(ImportMode.NorwayImport);
    } else {
      setImportMode(ImportMode.Foreign);
    }
  }, [setImportMode, values.countryId]);

  return (
    <div className={classes.wrap}>
      <Row className="p-0 m-0 h-100">
        <Col md={8} className="m-0 ps-5 pe-7 pt-6 pb-6 d-flex flex-column">
          <H.s>{t("title")}</H.s>
          <P.m className="mt-2 mb-6" color="foregroundMedium">
            {t("description")}
          </P.m>

          <Dropdown
            className="mb-4"
            isSearchable
            searchType="startsWith"
            searchPlaceholder="Country name"
            placeholder="Select"
            label={t("country")}
            options={countriesDropDown || []}
            optionsIsObject
            selectedValue={values?.countryId}
            name="countryId"
            onChange={handleChange}
            isTouched={touched.countryId}
            error={errors.countryId}
            onBlur={handleBlur}
          />

          {(importMode === ImportMode.NorwayImport || importMode === ImportMode.NorwayManual) && (
            <NorwayImport importMode={importMode} setImportMode={setImportMode} />
          )}
          {importMode === ImportMode.Foreign && <ForeignImport />}

          <Button isLoading={isSubmitting} type="submit" className="ms-auto mt-5">
            {t("continue")}
          </Button>
        </Col>
        <Col className="p-0 m-0">
          <div className="bg-secondary rounded-3  h-100 w-100 d-flex align-items-center justify-content-center">
            <img src={chartImg} alt="chart" className="w-700" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Form;
