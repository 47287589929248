import { useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { parse } from "date-fns";

import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import AdminContext, { CompanyType } from "pages/admin/AdminContext";
import AdminTable from "pages/admin/company/AdminTable";

import Actions from "./Actions";
import classes from "./CompanyAdmin.module.scss";
import ManageAdminSubscriptionModal from "./ManageAdminSubscriptionModal";

const CompanyAdmin = () => {
  const fNumber = useFormatNumbers();

  const tempRef = useRef(false);
  const [sorting, setSorting] = useState<SortingState>([{ id: "id", desc: false }]);

  const isLoading = AdminContext.useStoreState((state) => state.isLoading);
  const companies = AdminContext.useStoreState((state) => state.companies);
  const { getCompaniesThunk } = AdminContext.useStoreActions((actions) => actions);

  const data = useMemo(() => companies || [], [companies]);

  useEffect(() => {
    if (!tempRef.current) {
      tempRef.current = true;
      getCompaniesThunk();
    }
  }, [getCompaniesThunk]);

  const columns = useMemo<ColumnDef<CompanyType>[]>(
    () => [
      {
        accessorKey: "id",
        cell: (info) => info.getValue() as number,
        header: () => <span>Id</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "companyName",
        cell: (info) => `${info.row.original.companyName}`,
        header: () => <span>Company Name</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "createdAt",
        cell: (info) =>
          `${
            info?.row?.original?.createdAt &&
            transformDateToCommonDateFormat(parse(info?.row?.original?.createdAt, "yyyy-MM-dd", new Date()))
          }`,
        header: () => <span>Registered</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "latestValuation",
        cell: (info) => fNumber(info.getValue() as number, "value"),
        header: () => <span>Valuation</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfStakeholders",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <span>Stakeholders</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfTransactions",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <span>Transactions</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfIssuedSharesIssuances",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <span>Share issuances</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfPlans",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <span>Agreements</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "numberOfFinancialInstruments",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <span>Instruments</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "subscriptionExpireDate",
        cell: (info) => (
          <span>
            {info?.row?.original?.subscriptionExpireDate
              ? transformDateToCommonDateFormat(
                  parse(info?.row?.original?.subscriptionExpireDate, "yyyy-MM-dd", new Date())
                )
              : "No subscription"}
          </span>
        ),
        header: () => <span>Subscription expire</span>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "companyId",
        cell: (info) => <Actions id={info.row.original.id} name={info.row.original.companyName} />,
        header: "",
        enableSorting: false,
        maxSize: 48,
        enableResizing: true,
      },
    ],
    [fNumber]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className={classes.wrap}>
      <LoaderContainer loading={isLoading}>
        {data.length > 0 ? (
          <AdminTable withHover table={table} />
        ) : (
          <span className={classes.noRecord}>{"Ask to get access"}</span>
        )}

        <ManageAdminSubscriptionModal />
      </LoaderContainer>
    </div>
  );
};

export default CompanyAdmin;
