import React, { createContext, FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import NoteConversionFormik from "./NoteConversionFormik";

type Props = {
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
  convertibleNoteId?: number;
  fundraiseValuation?: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.notePanel");

type NoteConversionSlidePanelContextType = {
  fundraiseValuation?: number;
  convertibleNoteId?: number;
  onHide?: () => void;
  onSaved?: () => void;
};

export const NoteConversionSlidePanelContext = createContext<NoteConversionSlidePanelContextType>({});

const NoteConversionSlidePanel: FC<Props> = ({ show, onHide, convertibleNoteId, onSaved, fundraiseValuation }) => {
  const [isSaved, setSiSaved] = useState(false);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      onSaved?.();
      setSiSaved(false);
    }
  }, [onSaved, isSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t("title")} onHide={onHide} />
      <NoteConversionSlidePanelContext.Provider
        value={{
          convertibleNoteId: convertibleNoteId,
          fundraiseValuation,
          onHide: onHide,
          onSaved: onSaved,
        }}
      >
        <NoteConversionFormik />
      </NoteConversionSlidePanelContext.Provider>
    </SlidePanel>
  );
};
export default NoteConversionSlidePanel;
