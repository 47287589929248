import React, { FC, useContext, useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { SlidePanel, Ui } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../DashboardTable/DashboardTable";
import { InstrumentTableContext } from "./AddInstrumentsSlidePanel";
import useInstrumentsTable from "./useInstrumentsTable";

type InstrumentsContentProps = {};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.instruments");

const InstrumentsTable: FC<InstrumentsContentProps> = () => {
  const { instruments } = useContext(InstrumentTableContext);

  const [sorting, setSorting] = useState<SortingState>([{ id: "stakeholderName", desc: false }]);

  const { columns } = useInstrumentsTable();

  const table = useReactTable({
    data: instruments,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: false,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <SlidePanel.Section title={t("existingInstruments")}>
        <Ui.s color="foregroundHigh" className="mb-4">
          {t("existingInstrumentsDescription")}
        </Ui.s>
        <DashboardTable table={table} />
      </SlidePanel.Section>
    </div>
  );
};

export default InstrumentsTable;
