import { useCallback, useEffect, useState } from "react";

import { LoaderContent } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";

import ExpansionPanel from "../../../common/components/atoms/ExpansionPanel/ExpansionPanel";
import SetFundraiseSlidePanel from "../components/slide-panels/SetFundraisingSlidePanel/SetFundraiseSlidePanel";
import ConfirmedSubscription from "./ConfirmedSubscription/ConfirmedSubscription";
import Summary from "./Summary/Summary";

const Overview = () => {
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { isGetFundraisingLoading, fundraiseId } = useStoreState((state) => state.fundraisingModel);
  const summary = useStoreState((state) => state.fundraisingModel.summary);

  const [showFundraiseSlidePanel, setShowFundraiseSlidePanel] = useState(false);

  const handleManageFundraiseSlidePanel = useCallback(() => {
    setShowFundraiseSlidePanel((prev) => !prev);
  }, []);

  useEffect(() => {
    if (fundraiseId) {
      getFundraiseSummaryThunk(fundraiseId);
    }
  }, [fundraiseId, getFundraiseSummaryThunk]);

  if (!fundraiseId) {
    return null;
  }

  return (
    <LoaderContent show={isGetFundraisingLoading}>
      <ExpansionPanel name={"fundraising"} defaultActiveKey={["1"]} saveExpandedKeys={true}>
        <Summary />
        <div className="mt-3">
          <ConfirmedSubscription rounds={summary?.events} fundraiseId={fundraiseId} />
        </div>
      </ExpansionPanel>

      <SetFundraiseSlidePanel show={showFundraiseSlidePanel} onHide={handleManageFundraiseSlidePanel} />
    </LoaderContent>
  );
};
export default Overview;
