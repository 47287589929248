import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AdminRouter from "pages/admin/AdminRouter";
// import CompanyAdmin from "pages/admin/company/CompanyAdmin";
import AutoSignInPage from "pages/auto-sign-in/auto-sign-in";
import CreateShareClass from "pages/equity-management/ownership/share-classes/create-share-class/CreateShareClass";
import CreateGrantOneOffPlan from "pages/equity-management/plans/wizards/create-one-off-plans/CreateGrantOneOffPlan";
import CreatePlanWrapper from "pages/equity-management/plans/wizards/create-plan/create-plan-wrapper";
// import CreatePool from "pages/equity-management/plans/wizards/create-pool/CreatePool";
import CreateProgram from "pages/equity-management/plans/wizards/create-program/CreateProgram";
import InvitationsRouter from "pages/invitations/InvitationsRouter";
import ReceivingInvitationsRouter from "pages/receiving/ReceivingInvitationsRouter";
import UniMicroLandingPage from "pages/unimicro/unimicro-landing";
import UserRouter from "pages/user/userRouter";
import { useStoreState } from "store/store";

import LoaderContainer from "../common/components/atoms/LoaderContainer/LoaderContainer";
import AroRouter from "../pages/aro/aro-router";
import EquityManagementRouter from "../pages/equity-management/EquityManagementRouter";
import ImportCaptableWizard from "../pages/equity-management/ownership/cap-table/import-captable-wizard/import-captable-wizard";
import CapitalIncreaseWizard from "../pages/equity-management/ownership/transactions/components/forms/capital-increase/capital-increase-wizard";
import ImportTransactionsWizard from "../pages/equity-management/ownership/transactions/components/import-transactions-wizard/import-transactions-wizard";
import CreatePool from "../pages/equity-management/plans/wizards/create-pool/CreatePool";
import Congratulations from "../pages/fundraising/finish-campaign/Congratulations/Congratulations";
import FinishCampaign from "../pages/fundraising/finish-campaign/FinishCampaign";
import UpdateValuation from "../pages/fundraising/finish-campaign/UpdateValuation/UpdateValuation";
import FundraisingRouter from "../pages/fundraising/FundraisingRouter";
import MyPortfolioRouter from "../pages/my-porfolio/MyPortfolioRouter";
import OnboardRouter from "../pages/onboard/onboardRouter";
import SubscriptionAccepted from "../pages/public/subscription-accepted/SubscriptionAccepted";
import { Languages } from "../translation";
import CompanyCheckerRoute from "./CompanyCheckerRoute";
import AccessMiddlewareEM from "./middleware/AccessMiddleware/AccessMiddlewareEM";
import AccessMiddlewareFundraise from "./middleware/AccessMiddleware/AccessMiddlewareFundraise";
import useAuthMiddleware from "./middleware/AuthMiddleware";
import useOnboardingMiddleware from "./middleware/OnboardingMiddleware";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root/Root";
import { getPath } from "./Router/RouterHelper";
import { publicRouteList, ROUTER_V2 } from "./Router/RouterV2.types";

const Router: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const userStore = useStoreState((state) => state.account.user);

  useOnboardingMiddleware();
  const isLoading = useAuthMiddleware();

  const CompanyOnboard = useCallback(() => {
    if (userStore?.companies[0]?.id) {
      navigate(getPath(["equityManagement", "gettingStartedGuide"], { companyId: userStore.companies[0].id }));
    } else {
      navigate(getPath(["user", "dashboard"]));
    }
    return null;
  }, [navigate, userStore?.companies]);

  const Storybook = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BLOB_STORYBOOK_URL}`;
    return null;
  }, []);

  useEffect(() => {
    // ARO page forces NO language, and return it to EN on dismount (aro-router.tsx).
    // But there are cases when you open new tab, while you are at ARO page.
    // In these cases dismount not happening, and useEffect here is required to catch it.
    if (!location.pathname.startsWith(ROUTER_V2.aro.base) && i18n.language !== "en") {
      i18n.changeLanguage(Languages.en);
    }
  }, [i18n, location.pathname]);

  return (
    <LoaderContainer loading={isLoading}>
      <Routes>
        <Route path={publicRouteList.subscriptionAccepted} element={<SubscriptionAccepted />} />

        <Route path="/" element={<Root />}>
          <Route path={ROUTER_V2.signIn.base} element={<AutoSignInPage />} />
          <Route element={<PrivateRoute />}>
            <Route path={ROUTER_V2.uniMicroLanding.base} element={<UniMicroLandingPage />} />
            <Route path={ROUTER_V2.user.full} element={<UserRouter />} />
            <Route path={ROUTER_V2.onboard.base} element={<CompanyOnboard />} />
            <Route path={ROUTER_V2.invitation.full} element={<InvitationsRouter />} />
            <Route path={ROUTER_V2.onboard.full} element={<OnboardRouter />} />
            <Route path={ROUTER_V2.equityManagement.full} element={<CompanyCheckerRoute />}>
              <Route element={<AccessMiddlewareEM />}>
                <Route path={ROUTER_V2.equityManagement.createPool.relativeFull} element={<CreatePool />} />
                <Route path={ROUTER_V2.equityManagement.createProgram.relativeFull} element={<CreateProgram />} />
                <Route path={ROUTER_V2.equityManagement.createShareClass.relativeFull} element={<CreateShareClass />} />
                <Route path={ROUTER_V2.equityManagement.createPlan.relativeFull} element={<CreatePlanWrapper />} />
                <Route
                  path={ROUTER_V2.equityManagement.createOneOffPlan.relativeFull}
                  element={<CreateGrantOneOffPlan />}
                />
                <Route
                  path={ROUTER_V2.equityManagement.createCapitalIncrease.relativeFull}
                  element={<CapitalIncreaseWizard />}
                />
                <Route
                  path={ROUTER_V2.equityManagement.importTransactions.relativeFull}
                  element={<ImportTransactionsWizard />}
                />
                <Route
                  path={ROUTER_V2.equityManagement.importCaptable.relativeFull}
                  element={<ImportCaptableWizard />}
                />
              </Route>

              <Route path="*" element={<EquityManagementRouter />} />
            </Route>
            <Route path={ROUTER_V2.fundraising.full} element={<CompanyCheckerRoute />}>
              <Route element={<AccessMiddlewareFundraise />}>
                <Route path={ROUTER_V2.fundraising.finishCampaign.relativeFull} element={<FinishCampaign />} />
                <Route path={ROUTER_V2.fundraising.confirmation} element={<Congratulations />} />
                <Route path={ROUTER_V2.fundraising.updateValuation} element={<UpdateValuation />} />
              </Route>
              <Route path="*" element={<FundraisingRouter />} />
            </Route>
            <Route path={ROUTER_V2.myPortfolio.full} element={<MyPortfolioRouter />} />
            <Route path={ROUTER_V2.receive.full} element={<ReceivingInvitationsRouter />} />
            <Route path={ROUTER_V2.aro.full} element={<AroRouter />} />
            <Route path={ROUTER_V2.storybook.base} element={<Storybook />} />
          </Route>
          <Route path={ROUTER_V2.admin.full} element={<AdminRouter />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>
      </Routes>
    </LoaderContainer>
  );
};

export default Router;
