import { FC, useCallback } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { Button, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CommonFileIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import { SplitPreviewGetResponseDTO, Transaction } from "../../../../types";
import SplitAccordion from "./split-accordion/split-accordion";
import classes from "./styles.module.scss";

type PropsTypes = {
  splitPreview: SplitPreviewGetResponseDTO;
  isConfirmed: boolean;
  multiplier?: number;
  description?: string;
  transaction?: Transaction;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.splitDetails");

const SplitOverview: FC<PropsTypes> = ({ transaction, splitPreview, multiplier, isConfirmed, description }) => {
  const fNumber = useFormatNumbers();

  const isLoading = TransactionsContext.useStoreState((state) => state.isLocalLoading);
  const { setSelectedTransaction, setElementIdToScroll, setIsManageDocumentsFormOpen } =
    TransactionsContext.useStoreActions((actions) => actions);

  const statusColor = isConfirmed ? classes.colorPositive700 : classes.colorInfo700;

  const openManageDocumentsHandler = useCallback(() => {
    setSelectedTransaction(transaction as Transaction | null);
    setIsManageDocumentsFormOpen(true);
    setElementIdToScroll(transaction?.transactionId ? String(transaction?.transactionId) : null);
  }, [setElementIdToScroll, setIsManageDocumentsFormOpen, setSelectedTransaction, transaction]);

  return (
    <div className={cn("p-3", classes.overviewContainer)}>
      <div className="d-flex justify-content-between mb-3">
        <div className={classes["top-section"]}>
          <Ui.m bold className="text-medium mb-1">
            {t("beforeSplit")}
          </Ui.m>
          <div className={cn("p-3", classes.sharesContainer)}>
            <Ui.xxl bold className="text-medium">
              {fNumber(splitPreview.totalSharesBefore, "amount")}
            </Ui.xxl>
            <Ui.s className="text-low mb-2">{t("numberOfShares")}</Ui.s>

            <Ui.m bold className="text-medium">
              {fNumber(splitPreview.sharePriceBefore, "sharePrice")}
            </Ui.m>
            <Ui.s className="text-low mb-2">{t("sharePrice")}</Ui.s>

            <Ui.m bold className="text-medium">
              {fNumber(splitPreview.nominalShareValueBefore, "sharePrice")}
            </Ui.m>
            <Ui.s className="text-low">{t("nominalShareValue")}</Ui.s>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {isLoading ? <Spinner /> : <ArrowRightIcon width={40} height={40} color={scssVariables.strokeHigh} />}

          {multiplier && (
            <Tag variant="closed" className="mt-2">
              {"1:" +
                fNumber(multiplier, "unitPrice", {
                  hideCurrencySymbol: true,
                })}
            </Tag>
          )}
        </div>

        <div className={classes["top-section"]}>
          <Ui.m bold className="text-medium mb-1">
            {t("afterSplit")}
          </Ui.m>
          <div className={cn("p-3", classes.sharesContainer)}>
            <Ui.xxl bold className={statusColor}>
              {fNumber(splitPreview.totalSharesAfter, "amount")}
            </Ui.xxl>
            <Ui.s className="text-low mb-2">{t("numberOfShares")}</Ui.s>

            <Ui.m bold className={statusColor}>
              {fNumber(splitPreview.sharePriceAfter, "sharePrice")}
            </Ui.m>
            <Ui.s className="text-low mb-2">{t("sharePrice")}</Ui.s>

            <Ui.m bold className={statusColor}>
              {fNumber(splitPreview.nominalShareValueAfter, "sharePrice")}
            </Ui.m>
            <Ui.s className="text-low">{t("nominalShareValue")}</Ui.s>
          </div>
        </div>
      </div>

      {description && (
        <div className="mb-3">
          <P.s
            className="text-low"
            dangerouslySetInnerHTML={{
              __html: description?.replace(/\r\n/g, "<br />"),
            }}
          />
        </div>
      )}

      {isNil(transaction) ? null : (
        <div className={cn("my-3 px-3 py-2", classes["documents"])}>
          {!isEmpty(defaultTo([], transaction?.files)) ? (
            <div className="d-flex flex-wrap">
              {defaultTo([], transaction?.files).map((el) => {
                return (
                  <div
                    key={el.downloadId}
                    className={cn("d-flex align-items-center", classes["single-document"])}
                    onClick={() => {
                      openRemoteDocument(el?.downloadId);
                    }}
                  >
                    <CommonFileIcon fontSize={24} className="me-2" />
                    <Ui.s>{el.fileName}</Ui.s>
                  </div>
                );
              })}
            </div>
          ) : (
            <Button size="s" variant="tertiary" onClick={openManageDocumentsHandler} iconLeft={<PlusIcon />}>
              Add documents
            </Button>
          )}
        </div>
      )}

      <div>
        <Accordion alwaysOpen>
          <SplitAccordion
            eventKey="Shareholders"
            title={t("shareholders")}
            className="mb-2"
            isConfirmed={isConfirmed}
            realListLength={splitPreview.shareholdersCount}
            list={splitPreview.shareholders}
          />
          <SplitAccordion
            eventKey="Agreements"
            title={t("incentiveAgreements")}
            className="mb-2"
            isConfirmed={isConfirmed}
            realListLength={splitPreview.incentiveAgreementsCount}
            list={splitPreview.incentiveAgreements}
          />
          <SplitAccordion
            eventKey="Warrants"
            title={t("warrants")}
            className="mb-2"
            isConfirmed={isConfirmed}
            realListLength={splitPreview.warrantsCount}
            list={splitPreview.warrants}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default SplitOverview;
