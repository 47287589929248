import { useEffect } from "react";

import { Banner, LoaderContent, Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { InformationCircleIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FundraiseCapTable from "./FundraiseCapTable/FundraiseCapTable";
import FundraiseTransaction from "./FundraiseTransaction/FundraiseTransaction";

const t = createTranslation(TranslationNS.pages, "fundraising.preview.banner");
const PreviewResults = () => {
  const { isGetFundraisePreviewLoading, fundraiseId } = useStoreState((state) => state.fundraisingModel);
  const getFundraisePreviewThunk = useStoreActions((actions) => actions.fundraisingModel.getFundraisePreviewThunk);

  useEffect(() => {
    if (fundraiseId) {
      getFundraisePreviewThunk(fundraiseId);
    }
  }, [fundraiseId, getFundraisePreviewThunk]);

  if (!fundraiseId) {
    return null;
  }

  return (
    <LoaderContent show={isGetFundraisePreviewLoading}>
      <ExpansionPanel name={"fundraisingPreview"} defaultActiveKey={["1"]} saveExpandedKeys={true}>
        <Banner
          className="mb-3 mt-4"
          icon={<InformationCircleIcon />}
          variant="warning"
          title={t("title")}
          description={
            <Ui.s color="foregroundHigh" tag="span">
              {t("description_1")}
              <br />
              {t("description_2")}
            </Ui.s>
          }
        />

        <FundraiseTransaction />

        <div className="mt-3">
          <FundraiseCapTable />
        </div>
      </ExpansionPanel>
    </LoaderContent>
  );
};

export default PreviewResults;
