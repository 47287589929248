import { FC, useCallback, useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import { MenuItemMeta } from "common/components/atoms/ContextMenu/ContextMenu";
import { EventDTO, RoundInstrumentsDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../../components/DashboardTable/DashboardTable";
//import StakeholderViewModal from "../../../components/modals/StakeholderViewModal/StakeholderViewModal";
import classes from "./Round.module.scss";
import RoundHeader from "./RoundHeader";
import RoundTableActions, { RoundTableActionsProps } from "./RoundTableActions";
import useRoundTable from "./useRoundTable";

type RoundProps = {
  instruments: RoundInstrumentsDTO[];
  description: string;
  index: number;
  companyId: number;
  fundraiseId: number;
} & Omit<EventDTO, "instruments">;

const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription.round");

const RoundTable: FC<RoundProps> = ({ instruments, description, index, companyId, fundraiseId, ...eventProps }) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "stakeholderInitials", desc: false }]);
  const [manageModals, setManageModals] = useState<RoundTableActionsProps>({ show: false, type: "none" });

  const handleClose = useCallback(() => {
    setManageModals({ show: false, type: "none" });
  }, []);

  const handleActions = useCallback(
    (meta: MenuItemMeta) => {
      if (meta.key === "delete") {
        setManageModals({
          type: "delete",
          show: true,
          entityId: meta.entityId as number,
          instrumentEntityTypeId: meta.entityTypeId as number,
          stakeholderName: meta.stakeholderName as string,
          instrumentName: meta.instrument as string,
          roundName: eventProps.name,
        });
      } else if (meta.key === "editSubscriptions") {
        setManageModals({
          type: "editSubscriptions",
          show: true,
          fundraiseRoundId: eventProps.fundraiseEventId,
          sharesSubscriptionId: meta.entityId as number,
        });
      } else if (meta.key === "editConversions" && (meta.entityTypeId === 1 || meta.entityTypeId === 2)) {
        if (meta.entityTypeId === 1) {
          setManageModals({
            type: "editConvertibleNote",
            show: true,
            convertibleNoteId: meta.entityId as number,
          });
        } else if (meta.entityTypeId === 2) {
          setManageModals({
            type: "editConvertibleLoan",
            show: true,
            convertibleLoanId: meta.entityId as number,
          });
        }
      } else if (meta.key === "viewSubscription") {
        setManageModals({
          type: "viewSubscription",
          show: true,
          sharesSubscriptionId: meta.entityId as number,
          stakeholderId: meta.stakeholderId as number,
        });
      }
    },
    [eventProps.fundraiseEventId, eventProps.name]
  );

  const { columns } = useRoundTable(eventProps.fundingEventTypeId, handleActions);

  const table = useReactTable({
    data: instruments,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: false,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={classes.wrap}>
      <RoundHeader
        description={description}
        index={index}
        companyId={companyId}
        fundraiseId={fundraiseId}
        {...eventProps}
      />

      {instruments.length > 0 ? (
        <DashboardTable type="fundraiseTable" table={table} />
      ) : (
        <div className={classes.empty}>
          <Ui.s color="foregroundLow" className="p-4">
            {t("noInstruments", { roundName: eventProps.name })}
          </Ui.s>
        </div>
      )}

      <RoundTableActions {...manageModals} onHide={handleClose} fundraiseId={fundraiseId} />
    </div>
  );
};

export default RoundTable;
