import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { GetAllFundraisesDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import useFundraiseHistoryTable from "./useFundraiseHistoreTable";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.history");

const FundraiseHistory: FC<Props> = ({ className }) => {
  const { companyId } = useParams();
  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);
  const [fundraises, setFundraises] = useState<GetAllFundraisesDTO>([]);

  const getAllFundraisesThunk = useStoreActions((actions) => actions.fundraisingModel.getAllFundraisesThunk);

  useEffect(() => {
    if (companyId) {
      getAllFundraisesThunk(+companyId).then((res) => {
        setFundraises(res.data);
      });
    }
  }, [companyId, getAllFundraisesThunk]);

  const { columns } = useFundraiseHistoryTable();

  const table = useReactTable({
    data: fundraises,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: false,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={classNames(className, "p-5")} style={{ background: "white" }}>
      <Ui.xl className="mb-3" bold>
        {t("title")}
      </Ui.xl>
      {fundraises.length ? <DashboardTable table={table} type="bordered" /> : <Ui.s>No fundraises</Ui.s>}
    </div>
  );
};

export default FundraiseHistory;
