import { useLocation } from "react-router-dom";

import CompanyBillingDetails from "../../../../pages/equity-management/settings/company-settings/tabs/PlanAndBilling/CompanyBillingDetails";
import PricingTable from "../../../../pages/onboard/company/pricing-table/PricingTable";
import { H } from "../../../components/atoms";
import useSubscriptionType from "../../../hooks/useSubscriptionType";
import { FEATURES } from "../../types";
import { useFeatures } from "../../useFeatures";

const StripeManagePlan = () => {
  const { hasFullAccess } = useFeatures(FEATURES.companyInformation);

  const { pathname } = useLocation();

  const { hasSubscriptionType } = useSubscriptionType();

  if (!hasFullAccess) {
    return null;
  }

  return (
    <div id="stripeManagePlan" className="mt-4" style={{ width: "984px" }}>
      <H.l className=" text-center mb-4">
        {!hasSubscriptionType
          ? "Select a plan that best suits your business"
          : "The company’s active subscription plan"}
      </H.l>

      {!hasSubscriptionType && <PricingTable shortVersion redirectUrl={pathname} />}

      {hasSubscriptionType && (
        <div style={{ background: "white" }}>
          <CompanyBillingDetails hideTitle />
        </div>
      )}
    </div>
  );
};

export default StripeManagePlan;
