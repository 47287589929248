import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { Button, SlidePanel } from "common/components/atoms";
import { DocumentStatusField, ShareClassField, UploadDocumentsField } from "common/components/molecules/formikFields";
import StakeholderSection from "common/components/molecules/StakeholderSection/StakeholderSection";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";

import DescriptionField from "./fields/DescriptionField";
import InvestmentStatusField from "./fields/InvestmentStatusField";
import PurchasePriceField from "./fields/PurchasePriceField";
import SharesAllocationResult from "./fields/SharesAllocationResult";
import SubscriptionAmountField from "./fields/SubscriptionAmountField";
import { NewSubscriptionFormValues } from "./useNewSubscriptionSlidePanel";

type SetShareIssuesFormProps = {
  onHide: () => void;
  sharesSubscriptionId?: number;
};
const SetNewSubscriptionForm: FC<SetShareIssuesFormProps> = ({ onHide, sharesSubscriptionId }) => {
  const { companyId } = useParams();

  const { isSubmitting } = useFormikContext<NewSubscriptionFormValues>();

  return (
    <Form>
      <ToastFormikValidator />

      <SlidePanel.Section title={"Investor"}>
        <StakeholderSection />
      </SlidePanel.Section>

      <SlidePanel.Section title={"Investment"}>
        <SubscriptionAmountField className="mb-4" />
        <PurchasePriceField className="mb-4" />
        <ShareClassField
          companyId={companyId ? +companyId : 0}
          className="mb-4"
          answerText="Select the current subscription status to reflect the subscriber’s commitment level and progress in the funding process."
        />
        <InvestmentStatusField className="mb-4" />
        <DescriptionField />
        <SharesAllocationResult className="mt-4" />
      </SlidePanel.Section>

      <SlidePanel.Section title={"Documents"}>
        <UploadDocumentsField />
        <DocumentStatusField />
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button type="submit" variant="primary" className="me-3" isLoading={isSubmitting} isDisabled={isSubmitting}>
          {sharesSubscriptionId ? "Update" : "Add"}
        </Button>
        <Button onClick={onHide} type="button" variant="secondary" isDisabled={isSubmitting}>
          Cancel
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default SetNewSubscriptionForm;
