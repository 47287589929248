import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { Dropdown, Helper } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { fields, NewSubscriptionFormValues } from "../useNewSubscriptionSlidePanel";

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.newSubscription.fields");

type Props = {
  className?: string;
};
const InvestmentStatusField: FC<Props> = ({ className }) => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext<NewSubscriptionFormValues>();

  const options = useMemo(() => {
    return [
      { id: 1, name: "Draft" },
      { id: 2, name: "New" },
      { id: 3, name: "Suggestion" },
      { id: 4, name: "Document sent" },
      { id: 5, name: "Soft commit" },
      { id: 6, name: "Committed" },
      { id: 7, name: "Signed" },
      { id: 8, name: "Paid" },
    ];
  }, []);

  return (
    <div className={className}>
      <Helper>
        <Helper.Question type="input" questionId={fields.subscriptionStatusId}>
          <Dropdown
            name={fields.subscriptionStatusId}
            label={t("statusId.label")}
            selectedValue={values.subscriptionStatusId}
            error={errors.subscriptionStatusId}
            isTouched={touched.subscriptionStatusId}
            optionsIsObject
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Helper.Question>
        <Helper.Answer
          className="mt-3"
          withRightMargin
          answerId={fields.subscriptionStatusId}
          text={t("statusId.answer")}
        />
      </Helper>
    </div>
  );
};

export default InvestmentStatusField;
