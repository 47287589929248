import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { P } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import BodySection from "../../step-container/body-container/body-section";
import ShareholdersConflicts from "../shareholders-conflicts/shareholders-conflicts";
import ShareholdersUnmatched from "../shareholders-unmatched/shareholders-unmatched";
import ShareholdersTable from "./shareholders-table/shareholders-table";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.body");

const ShareholdersAtYearStartBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const year = useStoreState((state) => state.aroModel.aroDetails?.year);
  const stakeholders = useStoreState((state) => state.aroModel.shareholdersAtYearStart?.stakeholders);
  const isLoading = useStoreState((state) => state.aroModel.isShareholdersAtYearStartLoading);

  const getShareholderAtYearStart = useStoreActions((store) => store.aroModel.getShareholdersAtYearStartThunk);

  useEffect(() => {
    if (companyId) {
      getShareholderAtYearStart(companyId);
    }
  }, [companyId, getShareholderAtYearStart]);

  return (
    <Accordion flush defaultActiveKey="start">
      <ShareholdersUnmatched />
      <ShareholdersConflicts />

      {stakeholders?.length ? (
        <BodySection eventKey="start" hideToggle>
          <P.m className="mb-3" color="foregroundMedium">
            {t.el("listOfShareholders.paragraph", {
              components: [<Skeleton key={0} isLoading={isLoading} />],
              values: { date: year ? year - 1 : 2000 },
            })}
          </P.m>

          <ShareholdersTable isLoading={isLoading} shareholders={stakeholders} />
        </BodySection>
      ) : null}
    </Accordion>
  );
};

export default ShareholdersAtYearStartBody;
