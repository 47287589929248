import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FundraiseItemDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import FundraiseStatusCell, { FundraiseStatuses } from "./FundraiseStatusCell/FundraiseStatusCell";

const t = createTranslation(TranslationNS.pages, "fundraising.history.table");

const useFundraiseHistoryTable = () => {
  const fNumber = useFormatNumbers();

  const columns = useMemo<ColumnDef<FundraiseItemDTO>[]>(
    () => [
      {
        accessorKey: "name",
        cell: (info) => <Ui.s>{(info.getValue() as string) || "-"}</Ui.s>,
        header: () => <Ui.s tag="span">{t("fundraiseName")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "endsAt",
        cell: (info) => (
          <Ui.s>
            {format(new Date(info.row.original.startsAt), "dd.MM.yyy")} -{" "}
            {format(new Date(info.getValue() as string), "dd.MM.yyy")}
          </Ui.s>
        ),
        header: () => <Ui.s tag="span">{t("timeline")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "targetAmount",
        cell: (info) => {
          return <Ui.s>{info.getValue() ? fNumber(info.getValue() as number) : "-"}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("targetAmount")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "raisedFunds",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("raisedFunds")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "status",
        cell: (info) => (
          <FundraiseStatusCell
            statusName={info.row.original.fundraiseStatusName}
            status={info.getValue() as FundraiseStatuses}
          />
        ),
        header: () => <Ui.s tag="span">{t("status")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
    ],
    [fNumber]
  );

  return { columns };
};
export default useFundraiseHistoryTable;
