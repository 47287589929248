import { FC, ReactNode, useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { useStoreActions } from "store/store";
import { createTranslation } from "translation";

type Props = {
  children: ReactNode;
  handleNext: () => void;
  events?: { transactedAt: string; fundraiseEventId: number }[];
  fundraiseId?: number;
};

export type ReviewEventsFormikValues = {
  events: { transactedAt: string; fundraiseEventId: number }[];
  fundraiseId: number;
};

const tv = createTranslation("validation");
const ReviewEventsFormik: FC<Props> = ({ children, events, fundraiseId, handleNext }) => {
  const { setEventsTransactionDatesThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const initialValues = useMemo<ReviewEventsFormikValues>(() => {
    return {
      events: defaultTo([], events),
      fundraiseId: defaultTo(0, fundraiseId),
    };
  }, [events, fundraiseId]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      events: Yup.array().of(
        Yup.object().shape({
          transactedAt: Yup.string().required(tv("required")),
        })
      ),
    });
  }, []);

  const handleSubmit = useCallback(
    async (values: ReviewEventsFormikValues) => {
      await setEventsTransactionDatesThunk(values);
      handleNext();
    },
    [handleNext, setEventsTransactionDatesThunk]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <ToastFormikValidator />
        {children}
      </Form>
    </Formik>
  );
};

export default ReviewEventsFormik;
