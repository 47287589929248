import { MouseEventHandler, useCallback, useContext, useMemo } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import { useStoreActions } from "../../store/store";
import { createTranslation, TranslationNS } from "../../translation";
import { H, P } from "../components/atoms";
import { ChevronDownIcon } from "../icons/svg";
import { scssVariables } from "../utils/constants";
import { eventKey } from "./guides-plate";

const t = createTranslation(TranslationNS.common, "guidePlate");

const Header = () => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const setIsExpanded = useStoreActions((actions) => actions.guideModel.setIsGuidesPlateExpanded);

  const isToggleActive = useMemo(() => {
    if (!activeEventKey) {
      return false;
    }
    if (typeof activeEventKey === "string") {
      return activeEventKey === eventKey;
    }

    if (typeof activeEventKey === "object") {
      return activeEventKey.find((key) => key === eventKey);
    }
  }, [activeEventKey]);

  const wrappedOnClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      setIsExpanded(!isToggleActive);
      decoratedOnClick(event);
    },
    [decoratedOnClick, setIsExpanded, isToggleActive]
  );

  return (
    <div onClick={wrappedOnClick} className="d-flex justify-content-between align-items-center cursor-pointer">
      <div>
        <H.xxs className="mb-1">{t("title")}</H.xxs>
        <P.m color="foregroundMedium">{t("description")}</P.m>
      </div>
      <ChevronDownIcon
        className="me-2"
        fontSize={24}
        color={scssVariables.foregroundMedium}
        direction={isToggleActive ? "top" : "bottom"}
      />
    </div>
  );
};

export default Header;
