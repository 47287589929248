import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { Button, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");

const InterestAmountField: FC<Props> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext<ConversionFormValues>();
  const { currencySymbol } = useCurrency();

  useEffect(() => {
    if (values.interestPaidInCash) {
      setFieldValue(fields.interestAmount, 0);
    }
  }, [setFieldValue, values.interestPaidInCash]);

  const handleClick = useCallback(() => {
    if (values.interestAmount === null) {
      setFieldValue(fields.interestAmount, values.autoCalculatedInterest);
    } else {
      setFieldValue(fields.interestAmount, null);
    }
  }, [setFieldValue, values.autoCalculatedInterest, values.interestAmount]);

  return (
    <div className={classNames(className, "d-flex align-items-end gap-2")}>
      {values.interestAmount === null ? (
        <TextField
          className="flex-grow-1"
          name={fields.autoCalculatedInterest}
          label={t("interestAmount.label")}
          value={values.autoCalculatedInterest}
          isDisabled
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.autoCalculatedInterest}
          isTouched={touched.autoCalculatedInterest}
          type="number"
          iconRight={currencySymbol}
          info={"Amount based on the interest rate"}
        />
      ) : (
        <TextField
          className="flex-grow-1"
          name={fields.interestAmount}
          label={t("interestAmount.label")}
          value={values.interestAmount}
          isDisabled={Boolean(values.interestPaidInCash)}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.interestAmount}
          isTouched={touched.interestAmount}
          type="number"
          iconRight={currencySymbol}
        />
      )}
      <Button onClick={handleClick} isDisabled={Boolean(values.interestPaidInCash)} isLoading={values.isLoading}>
        {values.interestAmount === null ? "Change" : "Reset"}
      </Button>
    </div>
  );
};
export default InterestAmountField;
