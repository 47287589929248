import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { getEMPath } from "app/Router/RouterHelper";
import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext, { ConvertiblesType } from "../../MyPortfolioContext";
import OverviewTable from "../OverviewTable/OverviewTable";
import Actions from "./Actions";
import classes from "./Convertibles.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.convertibles");

const Convertibles = () => {
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);

  const convertibles = MyPortfolioContext.useStoreState((state) => state.portfolio?.convertibles);

  const data = useMemo(() => convertibles || [], [convertibles]);

  const columns = useMemo<ColumnDef<ConvertiblesType>[]>(
    () => [
      {
        accessorKey: "name",
        cell: (info) => {
          return (
            <>
              <NewAvatar
                initials={info.row.original.initials}
                imageUrl={info.row.original.avatarFilePath}
                company
                size="s"
              />
              <Ui.m className="ms-1" bold>
                {info.getValue() as string}
              </Ui.m>
            </>
          );
        },
        header: () => <Ui.xs tag="span">{t("table.company")}</Ui.xs>,
        enableResizing: false,
      },
      {
        accessorKey: "instrumentName",
        cell: (info) => info.getValue(),
        header: () => <Ui.xs tag="span">{t("table.instrument")}</Ui.xs>,
        enableResizing: false,
      },
      {
        accessorKey: "investmentAmount",
        cell: (info) =>
          fNumber(info.getValue() as number, "amount", { customCurrencySymbol: info.row.original.currencySymbol }),
        header: () => <Ui.xs tag="span">{t("table.investment")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "discount",
        cell: (info) => fNumber(info.getValue() as number, "percent"),
        header: () => <Ui.xs tag="span">{t("table.discount")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "interestRate",
        cell: (info) => fNumber(info.getValue() as number, "percent"),
        header: () => <Ui.xs tag="span">{t("table.interestRate")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "value",
        cell: (info) =>
          fNumber(info.getValue() as number, "value", { customCurrencySymbol: info.row.original.currencySymbol }),
        header: () => <Ui.xs tag="span">{t("table.value")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "companyId",
        cell: (info) => <Actions id={info.getValue() as number} hasAccess={info.row.original.hasAccessToCapTable} />,
        header: () => "",
        enableSorting: false,
        maxSize: 56,
        enableResizing: true,
      },
    ],
    [fNumber]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClickRow = useCallback(
    (data: ConvertiblesType) => {
      if (data.hasAccessToCapTable) {
        navigate(getEMPath(["ownership", "capTable"], { companyId: data.companyId }));
      } else {
        notify(t("restrictedAccess"), true, "warning");
      }
    },
    [navigate]
  );

  if (data.length === 0) return null;

  return (
    <div className={classes.portfolio}>
      <div className={classes.title}>
        <Ui.xl bold>{t("title")}</Ui.xl>
        <Ui.xs className={classes.subtitle}>{t("subtitle")}</Ui.xs>
      </div>
      <OverviewTable withHover table={table} handleClickRow={handleClickRow} />
    </div>
  );
};

export default Convertibles;
