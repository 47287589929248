import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { getEMPath, getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { Button, H, P } from "common/components/atoms";
import { CONTACT_US_LINK } from "common/utils/constants";
import { ChevronRightIcon, UnlistedIcon } from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Confetti from "common/components/atoms/Confetti/Confetti";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";
import SubscriptionImage from "common/icons/subscription-accepted.png";

import classes from "./SubscriptionAccepted.module.scss";

const t = createTranslation(TranslationNS.pages, "public.subscriptionAccepted");

const SubscriptionAccepted = () => {
  const navigate = useNavigate();

  const authenticated = useStoreState((state) => state.authentication.isAuthenticated);

  const invitedUser = useStoreState((state) => state.invitedUser?.invitedUser);

  useEffect(() => {
    if (invitedUser && invitedUser?.companyId) {
      navigate(getEMPath(["ownership", "capTable"], { companyId: invitedUser?.companyId }), { replace: true });
    }
  }, []);

  const navigateToDashboard = () => {
    if (invitedUser) {
      navigate(getPath(["onboard", "company", "getStarted"], { companyId: invitedUser?.companyId }), {
        replace: true,
      });
      return;
    }

    navigate(ROUTER_V2.user.dashboard, {
      replace: true,
    });
  };

  return (
    <MainLayout className="flex-fill">
      <div className={classes.icon}>
        <UnlistedIcon width="100%" height="100%" />
      </div>

      <div className={classes.container}>
        <Confetti />

        <img src={SubscriptionImage} alt="subscription-accepted" className={classes.image} />

        <H.xs className="my-3">{t("title")}</H.xs>

        <P.m className="text-center" color="foregroundMedium">
          {invitedUser?.navigationFromApp ? t("description") : t("newCompanyDescription")}
        </P.m>

        <P.m className="mt-3 text-center" color="foregroundMedium">
          {invitedUser?.navigationFromApp ? t("description1") : t("newCompanyDescription1")}
        </P.m>

        <div className="d-flex mt-7">
          {authenticated ? (
            invitedUser?.navigationFromApp ? (
              <Button iconRight={<ChevronRightIcon />} onClick={navigateToDashboard}>
                {t("continue")}
              </Button>
            ) : (
              <Link to={CONTACT_US_LINK}>
                <Button variant="secondary">{t("contactUs")}</Button>
              </Link>
            )
          ) : (
            <Link to={CONTACT_US_LINK}>
              <Button variant="secondary">{t("contactUs")}</Button>
            </Link>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default SubscriptionAccepted;
