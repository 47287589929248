import { FC } from "react";
import classNames from "classnames";
import { and, equals } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import { IssueEquityConvertibleLoanDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import { ConvertibleLoanTableSortValue } from "./ConvertibleLoanTable";

type Props = {
  sortedValue: ConvertibleLoanTableSortValue;
  rows: { key: string; value: string; className?: string }[];
  setSortedValue: (e: ConvertibleLoanTableSortValue) => void;
};

const ConvertibleLoanTableHead: FC<Props> = ({ rows, sortedValue, setSortedValue }) => {
  return (
    <thead className={classes.head}>
      <tr>
        {rows.map((el, i) => {
          return (
            <th
              key={el.key}
              className={classNames("cursor-pointer", classes["head-item"])}
              onClick={() => {
                setSortedValue({
                  field: el.key as keyof IssueEquityConvertibleLoanDTO,
                  type:
                    sortedValue.field !== el.key
                      ? sortingParams.inc
                      : sortedValue.type === sortingParams.inc
                      ? sortingParams.desc
                      : sortingParams.inc,
                });
              }}
            >
              <div
                className={classNames("d-flex fw-400 align-items-center", {
                  "justify-content-end": i > 0,
                })}
              >
                <Ui.xs>{el.value}</Ui.xs>

                <span
                  className={classNames(classes.sort, {
                    invisible: !equals(sortedValue.field, el.key),
                    [classes["rotated"]]: and(
                      equals(sortedValue.field, el.key),
                      equals(sortedValue.type, sortingParams.inc)
                    ),
                  })}
                >
                  <FilledArrowDownIcon />
                </span>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default ConvertibleLoanTableHead;
