import { FC, ReactNode } from "react";
import { Spinner } from "react-bootstrap";

import classes from "./LoaderContent.module.scss";

type LoaderContentProps = {
  children: ReactNode;
  show: boolean;
};

function getOffsetTop(element: any) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

const LoaderContent: FC<LoaderContentProps> = ({ children, show }) => {
  /*const ref = useRef<any>();

  console.log(window.scrollY, window.innerHeight, "window.scrollY, window.innerHeight");
  console.log(
    getOffsetTop(ref.current),
    ref.current?.clientHeight,
    "getOffsetTop(ref.current), ref.current?.clientHeight"
  );

  const calc = getOffsetTop(ref.current) - window.scrollY;
  const padTop = (window.innerHeight - calc) / 2;

  console.log((window.innerHeight - calc) / 2, "calc2", calc);*/

  return (
    <div className={classes.wrap}>
      {show && (
        <div /*ref={ref}*/ className={classes.loaderContent} /*style={{ paddingTop: padTop }}*/>
          <Spinner animation="border" />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoaderContent;
