import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link, useParams } from "react-router-dom";
import cn from "classnames";
import { Formik, FormikHelpers } from "formik";
import { isNil } from "ramda";

import { Banner, Button, H, Helper, P, TextField, ToggleSwitch, Ui } from "common/components/atoms";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { TriangleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../service/aro-service";
import { CreateLinkFormValues } from "../company-information/types";
import BodySection from "../step-container/body-container/body-section";
import Img1 from "./assets/img_1.jpg";
import Img2 from "./assets/img_2.jpg";
import Img3 from "./assets/img_3.jpg";
import Img4 from "./assets/img_4.jpg";
import Img5 from "./assets/img_5.jpg";
import Img6 from "./assets/img_6.png";
import Img7 from "./assets/img_7.png";
import classes from "./style.module.scss";
import useCreateLinkForm from "./use-create-link-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.createLink.body");

const MOCKED_UNLISTED_ORG_NUMBER = "925676756";
const EMAILS_FOR_TESTS = ["t9009919@gmail.com", "lkh@linuxspot.net", "pachinov01@gmail.com"];

const CreateLinkBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationMobileNumber, setVerificationMobileNumber] = useState<string>("");
  const [isVerifyFieldShown, setIsVerifyFieldShown] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>();

  const user = useStoreState((store) => store.account.user);
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);
  const companyInformation = useStoreState((store) => store.aroModel.companyInformation);
  const getAroDetailsThunk = useStoreActions((store) => store.aroModel.getAroDetailsThunk);
  const getCompanyInformation = useStoreActions((store) => store.aroModel.getCompanyInformationThunk);

  const { validationSchema, initialValues } = useCreateLinkForm();

  const switchToggleHandler = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (companyId) {
        try {
          setIsLoading(true);
          const success = await aroService.postAltinRegisteredAccess(companyId, e.target.checked);

          if (success) {
            notify("Altinn status was updated", true, "success");
            getAroDetailsThunk(companyId);
          }
        } catch (e) {
          console.error({ e });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [companyId, getAroDetailsThunk]
  );

  // the same submit callback will be used for both - send sms and update aro details API calls
  // depends on internal form value isVerificationSuccess - we're switching between 2 API calls
  const submit = useCallback(
    async (values: CreateLinkFormValues, actions: FormikHelpers<any>) => {
      setIsLoading(true);
      setResponseError(undefined);

      try {
        if (!values.isVerificationSuccess) {
          const request = await aroService.postSmsCode({
            companyId: Number(companyId),
            systemId: values.altinnId,
            systemPassword: values.dataSystemPassword,
            userPassword: values.loginPassword,
            userISIN: values.birthNumber,
          });

          if (request?.status === 200) {
            if (request.data.success) {
              notify(`Sms code has been sent to you mobile ${request.data.phoneNumber}`, true, "success");

              setVerificationMobileNumber(request.data.phoneNumber);
              setIsVerifyFieldShown(true);
              getAroDetailsThunk(companyId || "");
              actions.setFieldValue("isVerificationSuccess", true);
            }

            if (request.data.errorMessage) {
              setResponseError(request.data.errorMessage);
            }
          }
        } else {
          const response = await aroService.postAltinnDetails({
            companyId: Number(companyId),
            systemId: values.altinnId,
            systemPassword: values.dataSystemPassword,
            userPassword: values.loginPassword,
            userISIN: values.birthNumber,
            pinCode: values.verificationCode,
          });

          if (response?.status === 200 && response.data.success) {
            notify(t("step2.successConnection"), true, "success");

            getAroDetailsThunk(companyId || "");
          }

          if (response?.data.errorMessage) {
            setResponseError(response.data.errorMessage);
          }
        }
      } catch (e) {
        console.error({ e });
      } finally {
        setIsLoading(false);
      }
    },
    [companyId, getAroDetailsThunk]
  );

  useEffect(() => {
    if (isNil(companyInformation)) {
      getCompanyInformation(String(companyId));
    }
  }, [companyId, companyInformation, getCompanyInformation]);

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {({ handleSubmit, errors, touched, setFieldValue, handleChange, values }) => {
        return (
          <>
            <ToastFormikValidator />

            <div className={cn("mb-2 d-flex justify-content-between", classes["head-container"])}>
              <Col xs={6} className="m-0 p-0">
                <H.xxs>{t("topSection.title")}</H.xxs>
                <P.s color="foregroundMedium" className="mt-2 mb-3">
                  {t("topSection.description1")}
                </P.s>

                <P.s color="foregroundMedium">{t("topSection.description2")}</P.s>
                <P.s color="foregroundMedium" className="mb-2">
                  {t("topSection.description3")}
                </P.s>

                <P.s color="foregroundMedium">{t("topSection.description4")}</P.s>
              </Col>

              <Col xs={6} className="m-0 p-0">
                <div className={classes.video}>
                  <iframe
                    src="https://player.vimeo.com/video/1047817125?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                    title="Hvordan koble opp Unlisted til Altinn"
                  ></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </Col>
            </div>

            <Accordion flush alwaysOpen defaultActiveKey={["a", "b"]}>
              <BodySection
                eventKey="a"
                className="mb-1_half"
                title={t("step1.title")}
                status={
                  aroDetails?.altinnRegisteredAccess ? AroSectionStatuses["Utfylt"] : AroSectionStatuses["Ikke utfylt"]
                }
                customLeftItem={
                  <div className={cn("me-2", classes["step-circle"])}>
                    <Ui.m bold>2.1</Ui.m>
                  </div>
                }
              >
                <Ui.m bold color="foregroundMedium">
                  {t.el("step1.logInExplanation", {
                    components: [
                      <Link key="1" className={classes["link"]} to="https://info.altinn.no/" target="_blank" />,
                    ],
                  })}
                </Ui.m>

                <Image src={Img1} className="w-100 mt-3 mb-4 rounded-4" />

                <Ui.m bold color="foregroundMedium">
                  {t("step1.selectProfile")}
                </Ui.m>

                <Image src={Img2} className="w-100 mt-3 mb-4 rounded-4" />

                <Ui.m bold color="foregroundMedium" className="mb-3">
                  {t("step1.scrollToLoginInfo")}
                </Ui.m>

                <Ui.m color="foregroundMedium" className="mb-3">
                  {t("step1.recordLoginInfo")}
                </Ui.m>

                <Image src={Img3} className="w-100 mb-3 rounded-4" />

                <Banner variant="expired" description={t("step1.skipIfLogin")} />

                <P.s className="mt-3" color="foregroundMedium">
                  {t("step1.altinSignPoint1")}
                </P.s>
                <P.s color="foregroundMedium" className="mb-3">
                  {t("step1.altinSignPoint2")}
                </P.s>

                <Banner className="my-3" variant="expired" description={t("step1.avoidSpecialCharacters")} />

                <P.s color="foregroundMedium" className="mb-3">
                  {t("step1.rememberPassword")}
                </P.s>

                <P.s color="foregroundMedium">{t("step1.altinSignPoint3")}</P.s>
                <P.s color="foregroundMedium">{t("step1.altinSignPoint4")}</P.s>
                <P.s color="foregroundMedium">{t("step1.altinSignPoint5")}</P.s>

                <Ui.m bold color="foregroundMedium" className="mt-4">
                  {t("step1.registerDataSystem")}
                </Ui.m>

                <Ui.m color="foregroundMedium" className="my-3">
                  {t("step1.createIDNumber")}
                </Ui.m>

                <P.s color="foregroundMedium">{t("step1.createIDPoint1")}</P.s>
                <P.s color="foregroundMedium">{t("step1.createIDPoint2")}</P.s>
                <P.s color="foregroundMedium">{t("step1.createIDPoint3")}</P.s>

                <Banner className="my-2" variant="expired" description={t("step1.avoidSpecialCharactersSmall")} />

                <P.s color="foregroundMedium">{t("step1.createIDPoint4")}</P.s>

                <Image src={Img4} className="w-100 mt-3 mb-4 rounded-4" />

                <P.s color="foregroundMedium" className="mb-4">
                  {t("step1.checkFields")}
                </P.s>

                <div
                  className={cn(classes["banner"], {
                    [classes["success"]]: aroDetails?.altinnRegisteredAccess,
                  })}
                >
                  <div>
                    <Ui.m bold>{t("step1.finishedRegistration")}</Ui.m>
                    <P.s>{t("step1.activateStepAsCompleted")}</P.s>
                  </div>

                  <ToggleSwitch onChange={switchToggleHandler} checked={aroDetails?.altinnRegisteredAccess} />
                </div>
              </BodySection>

              {aroDetails?.altinnRegisteredAccess ? (
                <BodySection
                  eventKey="b"
                  title={t("step2.title")}
                  status={aroDetails?.altinnConnectionStatus}
                  className="mb-1_half position-relative"
                  customLeftItem={
                    <div className={cn("me-2", classes["step-circle"])}>
                      <Ui.m bold>2.2</Ui.m>
                    </div>
                  }
                >
                  <Helper>
                    <P.s color="foregroundMedium" className="mb-3">
                      {t("step2.description")}
                    </P.s>

                    <div className={classes["container"]}>
                      <H.xxs color="foregroundMedium">{t("step2.loginForm.title")}</H.xxs>

                      <P.s color="foregroundMedium" className="my-3">
                        {t("step2.loginForm.enterPasswordFromLoginInfo")}
                      </P.s>

                      <TextField
                        isPasswordType
                        error={errors.loginPassword}
                        isTouched={touched.loginPassword}
                        label={t("step2.loginForm.loginPassword")}
                        value={values.loginPassword}
                        name="loginPassword"
                        onChange={handleChange}
                      />

                      <Helper.Question
                        questionId="whereFindPassword"
                        UI={(active) => {
                          return (
                            <div className="mt-3" style={{ color: scssVariables.primary1 }}>
                              <TriangleIcon style={{ rotate: active ? "90deg" : "0deg" }} />

                              <a
                                style={{ color: scssVariables.primary1 }}
                                className="ms-half text-decoration-underline"
                              >
                                {t("step2.loginForm.whereFindPassword")}
                              </a>
                            </div>
                          );
                        }}
                      />

                      <Helper.Answer
                        answerId="whereFindPassword"
                        UI={
                          <div>
                            <P.s className="mt-semihalf mb-2">{t("step2.loginForm.needToSetPasswordFromAltinn")}</P.s>

                            <Image src={Img5} className="w-100 mt-3 mb-4 rounded-4" />
                          </div>
                        }
                      />

                      <TextField
                        className="mt-3"
                        error={errors.birthNumber}
                        isTouched={touched.birthNumber}
                        label={t("step2.loginForm.birthNumber")}
                        value={values.birthNumber}
                        name="birthNumber"
                        onChange={handleChange}
                      />

                      <Helper.Question
                        questionId="whyBirthAsk"
                        UI={(active) => {
                          return (
                            <div className="mt-3" style={{ color: scssVariables.primary1 }}>
                              <TriangleIcon style={{ rotate: active ? "90deg" : "0deg" }} />

                              <a
                                style={{ color: scssVariables.primary1 }}
                                className="ms-half text-decoration-underline"
                              >
                                {t("step2.loginForm.whyBirthAsk")}
                              </a>
                            </div>
                          );
                        }}
                      />

                      <Helper.Answer
                        answerId="whyBirthAsk"
                        UI={<P.s className="mt-semihalf">{t("step2.loginForm.giveIdenticalNumber")}</P.s>}
                      />

                      <div className={cn("my-3", classes["separator"])} />

                      <H.xxs color="foregroundMedium">{t("step2.dataSystemForm.title")}</H.xxs>

                      <P.s color="foregroundMedium" className="my-3">
                        {t("step2.dataSystemForm.description")}
                      </P.s>

                      <TextField
                        name="altinnId"
                        value={values.altinnId}
                        error={errors.altinnId}
                        isTouched={touched.altinnId}
                        label={t("step2.dataSystemForm.idForAltinn")}
                        onChange={handleChange}
                      />

                      <Helper.Question
                        questionId="whereFindPassOrLogin"
                        UI={(active) => {
                          return (
                            <div className="mt-3" style={{ color: scssVariables.primary1 }}>
                              <TriangleIcon style={{ rotate: active ? "90deg" : "0deg" }} />

                              <a
                                style={{ color: scssVariables.primary1 }}
                                className="ms-half text-decoration-underline"
                              >
                                {t("step2.dataSystemForm.whereFindPassOrLogin")}
                              </a>
                            </div>
                          );
                        }}
                      />

                      <Helper.Answer
                        answerId="whereFindPassOrLogin"
                        UI={
                          <div className="mt-semihalf">
                            <P.s className="mb-semihalf">{t("step2.loginForm.getIDWhenRegistered")}</P.s>

                            <Image src={Img7} className="w-100 mt-3 mb-4 rounded-4" />
                          </div>
                        }
                      />

                      <TextField
                        className="mt-3"
                        isPasswordType
                        error={errors.dataSystemPassword}
                        isTouched={touched.dataSystemPassword}
                        label={t("step2.dataSystemForm.dataSysPass")}
                        value={values.dataSystemPassword}
                        name="dataSystemPassword"
                        onChange={handleChange}
                      />

                      <Helper.Question
                        questionId="whereFindDataSysPass"
                        UI={(active) => {
                          return (
                            <div className="mt-3" style={{ color: scssVariables.primary1 }}>
                              <TriangleIcon style={{ rotate: active ? "90deg" : "0deg" }} />

                              <a
                                style={{ color: scssVariables.primary1 }}
                                className="ms-half text-decoration-underline"
                              >
                                {t("step2.dataSystemForm.whereFindDataSysPass")}
                              </a>
                            </div>
                          );
                        }}
                      />

                      <Helper.Answer
                        answerId="whereFindDataSysPass"
                        UI={
                          <div className="mt-semihalf">
                            <P.s className="mb-semihalf">{t("step2.loginForm.samePassAsInAltinn")}</P.s>

                            <Image src={Img6} className="w-100 mt-3 mb-4 rounded-4" />
                          </div>
                        }
                      />

                      <div className={cn("my-3", classes["separator"])} />

                      {aroDetails?.altinnConnectionStatus === AroSectionStatuses.Utfylt ? (
                        <>
                          {!isNil(responseError) ? (
                            <Banner
                              title="Feil ved verifisering"
                              description={responseError}
                              variant="error"
                              className="mb-3"
                            />
                          ) : null}

                          <Banner
                            variant="success"
                            title={t("step2.ready")}
                            description={t("step2.altinnConnectionCompleted")}
                          />
                        </>
                      ) : isVerifyFieldShown ? (
                        <div>
                          <Ui.m bold className="mb-1">
                            {t("step2.verifyCodeSent")}
                          </Ui.m>

                          <P.s color="foregroundMedium" className="mb-2">
                            {t("step2.codeSentToMobile", {
                              mobileNumber: verificationMobileNumber,
                            })}
                          </P.s>
                          <Col xs={5}>
                            <TextField
                              error={errors.verificationCode}
                              isTouched={touched.verificationCode}
                              label={t("step2.dataSystemForm.verificationCode")}
                              value={values.verificationCode}
                              name="verificationCode"
                              onChange={handleChange}
                            />
                          </Col>

                          {!isNil(responseError) ? (
                            <Banner
                              title="Feil ved verifisering"
                              description={responseError}
                              variant="error"
                              className="mb-3"
                            />
                          ) : null}

                          <div className="mt-4">
                            <Button
                              className="me-1"
                              isDisabled={isLoading || !values.verificationCode}
                              onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                            >
                              {t("step2.verifyData")}
                            </Button>

                            <Button
                              variant="tertiary"
                              onClick={() => {
                                setFieldValue("isVerificationSuccess", false).then(() => {
                                  handleSubmit();
                                });
                              }}
                            >
                              {t("step2.resendCode")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <P.s color="foregroundMedium" className="my-3">
                            {t("step2.verifyInfoPressingTheButton")}
                          </P.s>

                          {!isNil(responseError) ? (
                            <Banner
                              title="Feil ved verifisering"
                              description={responseError}
                              variant="error"
                              className="mb-3"
                            />
                          ) : null}

                          <Button
                            isLoading={isLoading}
                            isDisabled={
                              isLoading ||
                              !values.loginPassword ||
                              !values.birthNumber ||
                              !values.dataSystemPassword ||
                              !values.altinnId
                            }
                            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                          >
                            {t("step2.receiveSms")}
                          </Button>
                        </>
                      )}
                    </div>
                  </Helper>

                  {String(process.env.REACT_APP_API_BASE_URL).toString().includes("api-test") &&
                  companyInformation?.organizationNumber === MOCKED_UNLISTED_ORG_NUMBER &&
                  user &&
                  EMAILS_FOR_TESTS.includes(user.email) ? (
                    <button
                      className={classes["ugly-button"]}
                      onClick={() => {
                        submit(
                          {
                            isVerificationSuccess: true,
                            birthNumber: "12345612345",
                            loginPassword: "userPassword",
                            dataSystemPassword: "systemPassword",
                            altinnId: 123 as unknown as string,
                            verificationCode: "1234",
                          },
                          { setFieldValue } as FormikHelpers<any>
                        );
                      }}
                    >
                      Skip code verification for test
                    </button>
                  ) : null}
                </BodySection>
              ) : null}
            </Accordion>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateLinkBody;
