import React, { createContext, FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";
import { FundraisingInstrumentsGetDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";

import { createTranslation, TranslationNS } from "../../../../../translation";
import AddInstrumentsForm from "./AddInstrumentsForm";

type AddInstrumentsModalProps = {
  companyId: number;
  show: boolean;
  name: string;
  onHide: () => void;
  fundraiseRoundId: number;
  fundingRoundTypeId: number;
};

export type InstrumentTableContextType = {
  instruments: FundraisingInstrumentsGetDTO[];
  activeFundraiseRoundId: number;
  updateFundraiseRoundId: ({ entityId, roundId }: { entityId: number; roundId: number | null }) => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.instruments");

export const InstrumentTableContext = createContext<InstrumentTableContextType>({
  instruments: [],
  activeFundraiseRoundId: 0,
  updateFundraiseRoundId: () => {},
});
const AddInstrumentsSlidePanel: FC<AddInstrumentsModalProps> = ({
  companyId,
  show,
  onHide,
  name,
  fundraiseRoundId,
  fundingRoundTypeId,
}) => {
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { fundraiseId } = useStoreState((state) => state.fundraisingModel);

  const [updateFundraise, setUpdateFundraise] = useState(false);

  const handleOnExited = useCallback(() => {
    if (updateFundraise && fundraiseId) {
      getFundraiseSummaryThunk(fundraiseId);
      setUpdateFundraise(false);
    }
  }, [updateFundraise, getFundraiseSummaryThunk, fundraiseId]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t("title", { name })} onHide={onHide} />
      <AddInstrumentsForm
        activeFundraiseRoundId={fundraiseRoundId}
        companyId={companyId}
        fundingRoundTypeId={fundingRoundTypeId}
        onHide={onHide}
        setUpdateFundraise={setUpdateFundraise}
      />
    </SlidePanel>
  );
};

export default AddInstrumentsSlidePanel;
