import { useCallback, useRef } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { Button, H } from "common/components/atoms";
import WizardCloseConfirm, {
  WizardCloseConfirmRef,
} from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import classes from "./FinishCampaign.module.scss";
import ReviewCapTable from "./ReviewCapTable/ReviewCapTable";
import ReviewEvents from "./ReviewEvents/ReviewEvents";
import ReviewTransactions from "./ReviewTransactions/ReviewTransactions";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign");

const FinishCampaign = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const wizardCloseConfirmRef = useRef<WizardCloseConfirmRef>(null);

  const buttonContinueClickHandler = useCallback((): void => {
    wizardCloseConfirmRef.current?.close();
  }, []);

  const handleCloseCampaign = useCallback(() => {
    wizardCloseConfirmRef.current?.close();
    navigate(getPath(["fundraising", "overview"], { companyId }));
  }, [companyId, navigate]);

  return (
    <WizardLayout className="h-100">
      <WizardLayout.Header title={t("title")} />
      <WizardCloseConfirm ref={wizardCloseConfirmRef}>
        <H.xxs className="mb-5 text-center">{t("leaveModal.title")}</H.xxs>

        <div className="d-flex justify-content-center">
          <Button variant="danger" className="w-max-content me-2" onClick={handleCloseCampaign}>
            {t("leaveModal.leave")}
          </Button>

          <Button variant="secondary" className="w-max-content" onClick={buttonContinueClickHandler}>
            {t("leaveModal.cancel")}
          </Button>
        </div>
      </WizardCloseConfirm>
      <WizardContent maxStep={3} className={classes.wrap} stepClassName={classes.step}>
        <Routes>
          <Route path={ROUTER_V2.fundraising.finishCampaign.events} element={<ReviewEvents />} />
          <Route path={ROUTER_V2.fundraising.finishCampaign.transactions} element={<ReviewTransactions />} />
          <Route path={ROUTER_V2.fundraising.finishCampaign.capTable} element={<ReviewCapTable />} />
        </Routes>
      </WizardContent>
    </WizardLayout>
  );
};

export default FinishCampaign;
