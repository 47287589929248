import { Image } from "react-bootstrap";

import { P, Ui } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import FundraiseHistory from "../FundraiseHistory/FundraiseHistory";
import Img1 from "./kickstart.png";

const t = createTranslation(TranslationNS.pages, "fundraising.kickstart");
const Kickstart = () => {
  return (
    <div>
      <div className="p-5" style={{ background: "white" }}>
        <div className="d-flex gap-3 align-items-center">
          <div>
            <Ui.xl className="mb-3" bold>
              {t("title")}
            </Ui.xl>
            <P.m className="mb-3">{t("description_1")}</P.m>
            <P.m className="mb-3">{t("description_2")}</P.m>
            <P.m>{t("description_3")}</P.m>
          </div>
          <div>
            <Image src={Img1} />
          </div>
        </div>
      </div>
      <FundraiseHistory className="mt-3" />
    </div>
  );
};

export default Kickstart;
