import { FC, memo, MouseEventHandler, ReactNode, useCallback } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { motion } from "framer-motion";
import { and, isNil } from "ramda";

import { Tag, Tooltip, Ui } from "common/components/atoms";
import { getCurrentAgreementContext } from "common/components/organisms/agreements/components/common/functions";
import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon, CommonFileIcon, MultiplyIcon } from "common/icons/svg";
import { usePlanStatus } from "common/plan/planUtils";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import ActionRowMenu from "./ActionRowMenu";
import classes from "./ProgramContainer.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.table");

type ProgramTableRowProps = {
  programID?: number;
  isOneOff?: boolean;
  row: OwnershipProgramTableData;
};

const ProgramTableRow: FC<ProgramTableRowProps> = ({ row, programID, isOneOff }) => {
  const fNumber = useFormatNumbers();
  const status = usePlanStatus(row.statusId);
  const isSO = row.instrumentTypeId === InstrumentTypesIdsEnum.OPTION;

  const { getAgreementDetailsThunk } = getCurrentAgreementContext(
    row.instrumentTypeName as InstrumentTypesNamesEnum
  ).useStoreActions((actions) => actions);

  const handleClickView = useCallback<MouseEventHandler>(() => {
    getAgreementDetailsThunk(row.id);
  }, [row, getAgreementDetailsThunk]);

  const TdView = useCallback<FC<{ children?: ReactNode } & JSX.IntrinsicElements["td"]>>(
    ({ children, ...props }) => {
      return (
        <td className={classes.viewPlan} onClick={handleClickView} {...props}>
          {children}
        </td>
      );
    },
    [handleClickView]
  );

  return (
    <motion.tr className={classes.programTableRow}>
      <TdView>
        <Ui.s bold>{row.representedBy}</Ui.s>

        {and(row.isCompanyOwned, !isNil(row.stakholderCompanyName)) ? (
          <Ui.s className={classes.representedBy}>{row.stakholderCompanyName}</Ui.s>
        ) : row.stakeholderName !== row.representedBy ? (
          <Ui.s className={classes.representedBy}>{row.stakeholderName}</Ui.s>
        ) : null}
      </TdView>

      {isOneOff && (
        <TdView>
          <Ui.xs>{row.instrumentTypeName}</Ui.xs>
        </TdView>
      )}

      {(isSO || isOneOff) && (
        <TdView>
          <Ui.s>{fNumber(row.exercisePrice, "value")}</Ui.s>
        </TdView>
      )}

      <TdView>
        <div className="d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex">
            <Ui.xs color="foregroundLow">
              {fNumber(row.vestedShares, "amount")}/{fNumber(row.restrictedShares, "amount")}
            </Ui.xs>
            {!!row.sharesLostByTermination && (
              <Ui.xs className="ms-1" color="foregroundLow">
                (<s>{fNumber(row.sharesLostByTermination, "amount")}</s>)
              </Ui.xs>
            )}

            {row.statusId === PlanStatusesBasedOnAPIStatusId.expired && (
              <Ui.xs className="ms-1" color="foregroundLow">
                (<s>{fNumber(row.restrictedShares - row.vestedShares, "amount")}</s>)
              </Ui.xs>
            )}
          </div>
          <ProgressBar
            className={
              row.statusId === PlanStatusesBasedOnAPIStatusId.expired
                ? classes.expired
                : row.sharesLostByTermination
                ? classes.progressBarNegative
                : classes.progressBar
            }
            now={Math.ceil((row.vestedShares / row.restrictedShares) * 100)}
            bsPrefix="table-progress-bar"
          />
        </div>
      </TdView>
      {(isSO || isOneOff) && (
        <TdView>
          <Ui.s>
            {row.optionsExpirationDate
              ? fNumber(row.exercisedShares, "amount") + "/" + fNumber(row.vestedShares, "amount")
              : "-"}
          </Ui.s>
        </TdView>
      )}

      <TdView>
        <Ui.s>{transformDateToCommonDateFormat(row.startDate as string)}</Ui.s>
      </TdView>

      <TdView>
        <Ui.s>{row.endTime ? transformDateToCommonDateFormat(row.endTime as string) : "-"}</Ui.s>
      </TdView>

      <TdView>
        <Ui.s>
          {row.optionsExpirationDate ? transformDateToCommonDateFormat(row.optionsExpirationDate as string) : "-"}
        </Ui.s>
      </TdView>

      <TdView>
        <Tag variant={status?.variant}>{status?.label}</Tag>
      </TdView>

      <TdView>
        <div className="d-flex justify-content-center">
          {row.invitationSent && (
            <div className={classes.checkmark}>
              <Tooltip
                className={classes.tooltip}
                popupContent={t("inviteSent", { date: transformDateToCommonDateFormat(row.invitationDate) })}
              >
                <CheckIcon />
              </Tooltip>
            </div>
          )}
          {!row.invitationSent && (
            <div className={classes.email}>
              <Tooltip className={classes.tooltip} popupContent={t("inviteNotSent")}>
                <MultiplyIcon />
              </Tooltip>
            </div>
          )}
        </div>
      </TdView>

      <TdView>
        <div className="d-flex justify-content-center">
          {row.essentialTerms ? (
            <Tooltip
              popupContent={
                <div className="d-flex flex-column align-items-start" style={{ width: "200px" }}>
                  <Ui.xs className="mb-1" bold>
                    {t("termsTooltipTitle")}
                  </Ui.xs>
                  <Ui.xs className="text-start" color="foregroundLow" style={{ whiteSpace: "break-spaces" }}>
                    {row.essentialTerms}
                  </Ui.xs>
                </div>
              }
            >
              <CommonFileIcon fontSize={24} color={scssVariables.foregroundLow} />
            </Tooltip>
          ) : (
            "-"
          )}
        </div>
      </TdView>

      <TdView
        style={{ width: 40 }}
        onClick={(e) => {
          e.stopPropagation();
          document.body.click();
        }}
      >
        <ActionRowMenu row={row} programID={programID} />
      </TdView>
    </motion.tr>
  );
};

export default memo(ProgramTableRow);
