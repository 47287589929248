import { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { TextField, Ui } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ShareholdersRelationshipIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { AvailableShareClass } from "../../../../types";
import { ShareDetailsFieldsType } from "../shares-details/types";
import { SharesDetailsFields } from "./form-fields";

type PropsTypes = {
  selectedShareClass?: AvailableShareClass | null;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.sharesDetails");

const SharesDetailsV2: FC<PropsTypes> = ({ selectedShareClass, isDisabled }) => {
  const { values, errors, touched, handleChange } = useFormikContext<ShareDetailsFieldsType>();
  const { currencySymbol } = useCurrency();
  const fNumber = useFormatNumbers();

  const sharesLeft = useMemo(() => {
    if (values.numberOfShares && selectedShareClass?.sharesAvailable) {
      return selectedShareClass.sharesAvailable - values.numberOfShares;
    }

    return selectedShareClass?.sharesAvailable;
  }, [selectedShareClass?.sharesAvailable, values.numberOfShares]);

  return (
    <div>
      <Row>
        <Col>
          <TextField
            min={0}
            isTouched={!values.numberOfShares ? touched.numberOfShares : true}
            type="number"
            name={SharesDetailsFields.numberOfShares}
            label={t("amountOfShares")}
            value={values.numberOfShares}
            error={errors.numberOfShares}
            onChange={handleChange}
            isDisabled={!selectedShareClass || isDisabled}
            info={sharesLeft ? `${fNumber(sharesLeft, "amount")} ${t("sharesLeft")}` : undefined}
          />
        </Col>
        <Col>
          <TextField
            min={0}
            step={0.01}
            type="number"
            value={values.purchasePrice}
            error={errors.purchasePrice}
            isTouched={touched.purchasePrice}
            label={t("purchasePrice")}
            onChange={handleChange(SharesDetailsFields.purchasePrice)}
            iconRight={errors.purchasePrice && touched.purchasePrice ? undefined : currencySymbol}
            isDisabled={!selectedShareClass || isDisabled}
          />
        </Col>
      </Row>
      <div
        className="d-flex flex-column align-items-center rounded mt-4 p-3"
        style={{ backgroundColor: scssVariables.element2 }}
      >
        <ShareholdersRelationshipIcon />
        <Ui.xl className="my-2 text-medium">{fNumber(values.numberOfShares * values.purchasePrice, "value")}</Ui.xl>
        <Ui.s className="text-medium">{t("totalSellingPrice")}</Ui.s>
      </div>
    </div>
  );
};

export default SharesDetailsV2;
