import { FC } from "react";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowUpIcon, CalendarIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import formatTransactionDate from "../../../../../../equity-management/ownership/transactions/components/format-transaction-date";
import classes from "./capital-increase-header.module.scss";

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

type CapitalIncreaseHeaderProps = {
  eventName: string;
  numberOfBundledTransactions: number;
  categoryName: string;
  transactedAt: string;
  numberOfShares: number;
};

const CapitalIncreaseHeader: FC<CapitalIncreaseHeaderProps> = (props) => {
  const fNumber = useFormatNumbers();
  return (
    <div>
      <div className="d-flex mb-1">
        <Ui.m bold className="me-1">
          {props.eventName}
        </Ui.m>
        <Ui.xs className={classes.sizeOfBundle}>{fNumber(props.numberOfBundledTransactions, "amount")}</Ui.xs>
      </div>

      <div className="d-flex" style={{ color: scssVariables.foregroundLow }}>
        <Tag className="me-2" variant={"closed"}>
          <Ui.xs>{props.categoryName}</Ui.xs>
        </Tag>

        <div className="d-flex align-items-center me-2">
          <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
          <Ui.xs>{formatTransactionDate(props.transactedAt)}</Ui.xs>
        </div>

        <div className="d-flex align-items-center me-2">
          <ArrowUpIcon width={16} height={16} color={scssVariables.foregroundLow} />
          <Ui.xs className="ms-1">{fNumber(props.numberOfShares, "amount") + " " + t("existingShares")}</Ui.xs>
        </div>
      </div>
    </div>
  );
};

export default CapitalIncreaseHeader;
