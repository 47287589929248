import { FC, useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { SlidePanel, Ui } from "common/components/atoms";
import { FundraiseEventsReviewInstrument, FundraiseEventsReviewItem } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import ConfirmTransactionDate from "../ConfirmTransactionDate/ConfirmTransactionDate";
import ShareCapitalBlocks from "../ShareCapitalBlocks/ShareCapitalBlocks";
import classes from "./EventItem.module.scss";
import useEventItemTable from "./useEventItemTable";

type RoundProps = {
  instruments: FundraiseEventsReviewInstrument[];
  index: number;
} & Omit<FundraiseEventsReviewItem, "instruments">;

const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription.round");

const EventItem: FC<RoundProps> = ({ instruments, index, ...eventProps }) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "stakeholderInitials", desc: false }]);

  const { columns } = useEventItemTable();

  const table = useReactTable({
    data: instruments,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: true,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={classes.wrap}>
      <SlidePanel.Section
        title={
          <div className="d-flex align-items-center">
            <div className={classes.step}>
              <Ui.m bold>{index}</Ui.m>
            </div>
            <Ui.xl bold>{eventProps.name}</Ui.xl>
          </div>
        }
        isDividerVisible={false}
        containerClassName={classes.slidePanel}
        className={classes.slidePanelHeader}
      >
        <ConfirmTransactionDate className="mb-3" index={index - 1} />

        <ShareCapitalBlocks {...eventProps} className="mb-3" />

        {instruments.length > 0 ? (
          <DashboardTable type="bordered" table={table} withScroll />
        ) : (
          <div className={classes.empty}>
            <Ui.s color="foregroundLow" className="p-4">
              {t("noInstruments", { roundName: eventProps.name })}
            </Ui.s>
          </div>
        )}
      </SlidePanel.Section>
    </div>
  );
};

export default EventItem;
