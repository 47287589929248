import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { ContextMenuProps, Ui } from "common/components/atoms";
import { formatNumber } from "common/hooks/useFormatNumbers";
import { FundraiseCapTableGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import NameCell from "../../../components/DashboardTable/NameCell/NameCell";

const t = createTranslation(TranslationNS.pages, "fundraising.preview.capTable.table");

const useCapTablePreview = (currencySymbol?: string, _menuClickCallback?: ContextMenuProps["menuClickCallback"]) => {
  const columns = useMemo<ColumnDef<FundraiseCapTableGetDTO>[]>(
    () => [
      {
        accessorKey: "initials",
        cell: (info) => {
          return (
            <NameCell
              initials={info.getValue() as string}
              name={info.row.original.shareholderName}
              representByName={info.row.original.representedBy}
              isCompany={info.row.original.isCompanyOwned}
              isAttached={false}
              showBlueDot={!!info.row.original.newShares}
              imageUrl={info.row.original.avatarFilePath}
            />
          );
        },
        header: () => <Ui.s tag="span">{t("shareholder")}</Ui.s>,
        footer: ({ table }) => {
          return (
            <Ui.s tag="span" bold>
              {t("totalStakeholder", { count: table.getFilteredRowModel().rows.length })}
            </Ui.s>
          );
        },
        enableResizing: false,
        meta: { footerColSpan: 3 },
      },
      {
        accessorKey: "shareClasses",
        cell: (info) => <Ui.s>{(info.getValue() as string[])?.join(", ") || "-"}</Ui.s>,
        header: () => <Ui.s tag="span">{t("shareClass")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "relationship",
        cell: (info) => <Ui.s>{info.getValue() as string}</Ui.s>,
        header: () => <Ui.s tag="span">{t("relationship")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "newShares",
        cell: (info) => {
          return <Ui.s>{info.getValue() ? formatNumber(info.getValue() as number) : "-"}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("newShares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footClass: "right", footerColSpan: 1 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table.getFilteredRowModel().rows.reduce((sum, row) => +sum + +(row.original.newShares || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {formatNumber(total)}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "shares",
        cell: (info) => {
          return <Ui.s>{formatNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("newTotalShares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footClass: "right", footerColSpan: 1 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table.getFilteredRowModel().rows.reduce((sum, row) => +sum + +(row.original.shares || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {formatNumber(total)}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "ownershipPercentage",
        cell: (info) => (
          <Ui.s>{formatNumber(info.getValue() as number, { decimals: "percent", allowZeros: true })} %</Ui.s>
        ),
        header: () => <Ui.s tag="span">{t("ownership")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footerColSpan: 1 },
        enableResizing: false,
      },
    ],
    []
  );

  return { columns };
};
export default useCapTablePreview;
