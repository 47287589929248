import { FC } from "react";
import classNames from "classnames";

import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { FundraiseTransactionDTO } from "store/modelTypes";

import FundraiseShareIssueBody from "./FundraiseShareIssueBody";
import FundraiseShareIssueHeader from "./FundraiseShareIssueHeader";

type FundraiseTransactionItemProps = FundraiseTransactionDTO & {};
const FundraiseShareIssue: FC<FundraiseTransactionItemProps> = (props) => {
  return (
    <ExpansionPanel className={classNames("w-100")}>
      <ExpansionPanel.Toggle eventKey={"1"} className="ps-3">
        <FundraiseShareIssueHeader {...props} />
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey={"1"} className="px-3 pb-3 pt-1x">
        <FundraiseShareIssueBody {...props} />
      </ExpansionPanel.Collapse>
    </ExpansionPanel>
  );
};

export default FundraiseShareIssue;
