import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { LoadingIcon } from "common/icons/svg";
import { useStoreActions } from "store/store";

import classes from "./unimicro-landing.module.scss";

const UniMicroLandingPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const accessCode = useMemo(() => searchParams.get("code"), [searchParams]);
  const iss = useMemo(() => searchParams.get("iss"), [searchParams]);

  const { getUniMicroCompanyInfoThunk } = useStoreActions((actions) => actions.companyOnboardingModel);

  useEffect(() => {
    if (accessCode) {
      getUniMicroCompanyInfoThunk({ accessCode, iss })
        .then(() => {
          navigate(getPath(["onboard", "company", "getStarted"], { companyId: undefined }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [accessCode, getUniMicroCompanyInfoThunk, iss, navigate]);

  return (
    <div className={classes.wrap}>
      <div className={classes.container}>
        <LoadingIcon className={classes.spinner} />
      </div>
    </div>
  );
};

export default UniMicroLandingPage;
