import { FC } from "react";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type AmountToRiseFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");

const SharesAllocationField: FC<AmountToRiseFieldProps> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<ConversionFormValues>();
  const { currencySymbol } = useCurrency();
  return (
    <div className={className}>
      <Helper>
        <Helper.Question type="input" questionId={fields.sharesAllocation}>
          <TextField
            name={fields.sharesAllocation}
            label={t("sharesAllocation.label")}
            value={values.sharesAllocation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.sharesAllocation}
            isTouched={touched.sharesAllocation}
            type="number"
            iconRight={currencySymbol}
          />
        </Helper.Question>
        <Helper.Answer
          className="mt-3"
          withRightMargin
          answerId={fields.sharesAllocation}
          text={t("sharesAllocation.answer")}
        />
      </Helper>
    </div>
  );
};
export default SharesAllocationField;
