import React, { FC } from "react";
import { Modal } from "react-bootstrap";

import { Button, H, P } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.unsavedChangesModal");

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  discard: () => void;
};

const UnsavedChangesModal: FC<Props> = ({ isOpen, discard, handleClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      centered
      contentClassName="d-flex flex-column align-items-center text-center p-5"
    >
      <H.xs className="mb-3">{t("title")}</H.xs>
      <P.m className="mb-5">{t("description")}</P.m>
      <div>
        <Button className="me-2" onClick={discard} variant="danger">
          {t("discard")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("back")}
        </Button>
      </div>
    </Modal>
  );
};

export default UnsavedChangesModal;
