import { FC } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import { FundraiseEventsReviewItem } from "../../../../../store/modelTypes";
import { createTranslation, TranslationNS } from "../../../../../translation";
import classes from "./ShareCapitalBlocks.module.scss";

type Props = {
  className?: string;
} & Pick<
  FundraiseEventsReviewItem,
  | "numberOfSharesAfter"
  | "numberOfSharesBefore"
  | "numberOfSharesIncrease"
  | "shareCapitalIncrease"
  | "shareCapitalBefore"
  | "shareCapitalAfter"
>;

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.events.shareCapitalBlocks");
const ShareCapitalBlocks: FC<Props> = ({ className, ...props }) => {
  const fNumber = useFormatNumbers();
  return (
    <div className={classNames(className, classes.wrap, "d-flex gap-3")}>
      <div className={classes.block}>
        <Ui.s color="foregroundLow">{t("shareCapitalIncrease")}</Ui.s>
        <Ui.xl className="mt-1" color="foregroundHigh" bold>
          {fNumber(props.shareCapitalIncrease)}
        </Ui.xl>
        <div className={classNames(classes.item, classes.withUnderline)}>
          <Ui.s color="foregroundMedium">{t("beforeEvent")}</Ui.s>
          <Ui.s color="foregroundMedium">{fNumber(props.shareCapitalBefore)}</Ui.s>
        </div>
        <div className={classNames(classes.item)}>
          <Ui.s color="foregroundMedium">{t("afterEvent")}</Ui.s>
          <Ui.s color="foregroundMedium">{fNumber(props.shareCapitalAfter)}</Ui.s>
        </div>
      </div>

      <div className={classes.block}>
        <Ui.s color="foregroundLow">{t("newSharesIssued")}</Ui.s>
        <Ui.xl className="mt-1" color="foregroundHigh" bold>
          {fNumber(props.numberOfSharesIncrease, "amount")} shares
        </Ui.xl>
        <div className={classNames(classes.item, classes.withUnderline)}>
          <Ui.s color="foregroundMedium">{t("beforeEvent")}</Ui.s>
          <Ui.s color="foregroundMedium">{fNumber(props.numberOfSharesBefore, "amount")} </Ui.s>
        </div>
        <div className={classNames(classes.item)}>
          <Ui.s color="foregroundMedium">{t("afterEvent")}</Ui.s>
          <Ui.s color="foregroundMedium">{fNumber(props.numberOfSharesAfter, "amount")}</Ui.s>
        </div>
      </div>
    </div>
  );
};

export default ShareCapitalBlocks;
