import React, { useMemo } from "react";
import classNames from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";

import ValuationContext from "../../ValuationContext";
import classes from "./ValuationTabs.module.scss";

export type ValuationHistoryTabsTypes = "valuation" | "taxableValue";

type ValuationHistoryTabsManuItems = {
  label: string;
  tab: ValuationHistoryTabsTypes;
  onClick: () => void;
  isActive: boolean;
}[];

const ValuationTabs = () => {
  const valuationHistorySelectedTad = ValuationContext.useStoreState((state) => state.valuationHistorySelectedTad);
  const setValuationHistorySelectedTad = ValuationContext.useStoreActions(
    (actions) => actions.setValuationHistorySelectedTad
  );

  const menuItems: ValuationHistoryTabsManuItems = useMemo(() => {
    return [
      {
        tab: "valuation",
        label: "Company valuation",
        isActive: equals(valuationHistorySelectedTad, "valuation"),
        onClick: () => {
          setValuationHistorySelectedTad("valuation");
        },
      },
      {
        tab: "taxableValue",
        label: "Taxable value",
        isActive: equals(valuationHistorySelectedTad, "taxableValue"),
        onClick: () => {
          setValuationHistorySelectedTad("taxableValue");
        },
      },
    ];
  }, [setValuationHistorySelectedTad, valuationHistorySelectedTad]);

  return (
    <div className={classNames(classes["tabs-head"], "mb-3")}>
      {menuItems.map((el) => {
        return (
          <div
            key={el.tab}
            className={classNames(classes["tab-item"], {
              [classes["active"]]: el.isActive,
            })}
            onClick={el.onClick}
          >
            <Ui.s>{el.label}</Ui.s>
          </div>
        );
      })}
    </div>
  );
};

export default ValuationTabs;
