import React, { createContext, FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";

import { createTranslation, TranslationNS } from "../../../../../../translation";
import LoanConversionFormik from "./LoanConversionFormik";

type LoanConversionSlidePanelProps = {
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
  convertibleLoanId?: number;
  fundraiseValuation?: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.loanPanel");

type LoanConversionSlidePanelContextType = {
  fundraiseValuation?: number;
  convertibleLoanId?: number;
  onHide?: () => void;
  onSaved?: () => void;
};

export const LoanConversionSlidePanelContext = createContext<LoanConversionSlidePanelContextType>({});

const LoanConversionSlidePanel: FC<LoanConversionSlidePanelProps> = ({
  show,
  onHide,
  convertibleLoanId,
  fundraiseValuation,
  onSaved,
}) => {
  const [isSaved, setSiSaved] = useState(false);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      onSaved?.();
      setSiSaved(false);
    }
  }, [onSaved, isSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t("title")} onHide={onHide} />
      <LoanConversionSlidePanelContext.Provider
        value={{
          convertibleLoanId,
          fundraiseValuation,
          onHide: onHide,
          onSaved: onSaved,
        }}
      >
        <LoanConversionFormik />
      </LoanConversionSlidePanelContext.Provider>
    </SlidePanel>
  );
};
export default LoanConversionSlidePanel;
