import { FC, useEffect, useMemo, useState } from "react";

import { LoaderContent, P, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FundraiseShareSubscriptionGetDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";

import StatusCell from "../../../DashboardTable/StatusCell/StatusCell";
import SubscriptionAmountBlock from "../../../slide-panels/ConversionSlidePanels/components/SubscriptionAmount/SubscriptionAmountBlock";

type Props = {
  sharesSubscriptionId?: number;
};

const SubscriptionViewInfo: FC<Props> = ({ sharesSubscriptionId }) => {
  const fNumber = useFormatNumbers();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<FundraiseShareSubscriptionGetDTO | null>(null);

  const shareClassesCompany = useStoreState((state) => state.shareClassModel?.shareClassesCompany);

  const shareClassName = useMemo(() => {
    return shareClassesCompany?.find((shareClass) => shareClass.id === subscription?.shareClassId)?.name;
  }, [shareClassesCompany, subscription?.shareClassId]);

  const getFundraiseSubscriptionThunk = useStoreActions(
    (actions) => actions.fundraisingModel.getFundraiseSubscriptionThunk
  );

  useEffect(() => {
    if (sharesSubscriptionId) {
      setIsLoading(true);
      getFundraiseSubscriptionThunk(sharesSubscriptionId)
        .then((res) => {
          setSubscription(res.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getFundraiseSubscriptionThunk, sharesSubscriptionId]);

  return (
    <div>
      <LoaderContent show={isLoading}>
        <div className="d-flex mt-4 p-6 gap-5">
          <div style={{ flex: 1, textAlign: "left" }}>
            {subscription?.description && <P.m className="mb-4">{subscription?.description}</P.m>}
            <div className="d-flex justify-content-between">
              <Ui.m bold>Subscription status</Ui.m>
              <Ui.m>
                <StatusCell status={subscription?.subscriptionStatusId || 1} />
              </Ui.m>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Ui.m bold>Purchase price per share</Ui.m>
              <Ui.m>{fNumber(subscription?.sharePrice, "sharePrice")}</Ui.m>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Ui.m bold>Number of shares</Ui.m>
              <Ui.m>{fNumber(subscription?.numberOfShares, "amount")}</Ui.m>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Ui.m bold>Share class</Ui.m>
              <Ui.m>{shareClassName}</Ui.m>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Ui.m bold>Instrument</Ui.m>
              <Ui.m>Share issuance</Ui.m>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <SubscriptionAmountBlock amount={fNumber(subscription?.subscriptionAmount, "sharePrice")} hideAvatar />
          </div>
        </div>
      </LoaderContent>
    </div>
  );
};

export default SubscriptionViewInfo;
