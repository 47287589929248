import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import { Button, ExpansionPanel, H, LoaderContainer, P } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import EventItem from "./EventItem/EventItem";
import classes from "./ReviewEvents.module.scss";
import ReviewEventsFormik, { ReviewEventsFormikValues } from "./ReviewEventsFormik";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.events");
const ReviewEvents = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const summary = useStoreState((state) => state.fundraisingModel.fundraiseEventsReview);
  const { getOngoingFundraiseThunk, getFundraiseEventsReviewThunk } = useStoreActions(
    (actions) => actions.fundraisingModel
  );

  const handleClose = useCallback(() => {
    navigate(getPath(["fundraising", "overview"], { companyId }));
  }, [companyId, navigate]);

  const handleNext = useCallback(() => {
    navigate(getPath(["fundraising", "finishCampaign", "transactions"], { companyId }));
  }, [companyId, navigate]);

  const handleLoadPage = useCallback(() => {
    setLoading(true);
    getOngoingFundraiseThunk(companyId ? +companyId : 0)
      .then((res) => {
        if (res.data.fundraiseId) {
          getFundraiseEventsReviewThunk(res.data.fundraiseId).finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [companyId, getFundraiseEventsReviewThunk, getOngoingFundraiseThunk]);

  const formikEvents = useMemo(() => {
    return summary?.events.map((event) => ({
      transactedAt: event.transactedAt,
      fundraiseEventId: event.fundraiseEventId,
    }));
  }, [summary?.events]);

  useEffect(() => {
    handleLoadPage();
  }, [handleLoadPage]);

  return (
    <WizardContent.Content className={classes.wrap} step={1}>
      <LoaderContainer loading={loading} variant="contentScreen">
        <ReviewEventsFormik fundraiseId={summary?.fundraiseId} events={formikEvents} handleNext={handleNext}>
          <H.xs>{t("title")}</H.xs>
          <P.m className="mt-5">{t("description")}</P.m>

          <ExpansionPanel>
            {summary?.events.map((round, index) => (
              <EventItem key={round.fundraiseEventId} {...round} instruments={round.instruments} index={index + 1} />
            ))}
          </ExpansionPanel>

          <ButtonsActions handleClose={handleClose} />
        </ReviewEventsFormik>
      </LoaderContainer>
    </WizardContent.Content>
  );
};

type ButtonsActionsProps = {
  handleClose: () => void;
};
const ButtonsActions: FC<ButtonsActionsProps> = ({ handleClose }) => {
  const { isSubmitting } = useFormikContext<ReviewEventsFormikValues>();
  return (
    <div className="d-flex justify-content-between mt-5">
      <Button variant={"secondary"} isOnlyIcon onClick={handleClose} isDisabled={isSubmitting}>
        <ArrowLeftIcon />
      </Button>
      <Button type="submit" variant="primary" isLoading={isSubmitting}>
        {t("confirm")}
      </Button>
    </div>
  );
};

export default ReviewEvents;
