import { FC, useMemo } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { SortOptions, SortSettings } from "./table-view";

type PropsTypes = {
  sortSettings: SortSettings;
  setSortSettings(settings: SortSettings): void;
};

type HeadRow = {
  key: SortOptions;
  title: string;
  isSelected: boolean;
  sortType: sortingParams;
  onClick(): void;
};

const generateHandler = (
  field: SortOptions,
  callback: (settings: SortSettings) => void,
  sortSettings: SortSettings
) => {
  return () =>
    callback({
      field: field,
      type:
        sortSettings.field === field
          ? sortSettings.type === sortingParams.inc
            ? sortingParams.desc
            : sortingParams.inc
          : sortingParams.desc,
    });
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.table");

const TableHead: FC<PropsTypes> = ({ sortSettings, setSortSettings }) => {
  const rows: HeadRow[] = useMemo(
    () => [
      {
        key: SortOptions.Name,
        title: t("stakeholder"),
        isSelected: sortSettings.field === SortOptions.Name,
        sortType: sortSettings.field === SortOptions.Name ? sortSettings.type : sortingParams.desc,
        onClick: generateHandler(SortOptions.Name, setSortSettings, sortSettings),
      },
      {
        key: SortOptions.Email,
        title: t("email"),
        isSelected: sortSettings.field === SortOptions.Email,
        sortType: sortSettings.field === SortOptions.Email ? sortSettings.type : sortingParams.desc,
        onClick: generateHandler(SortOptions.Email, setSortSettings, sortSettings),
      },
      {
        key: SortOptions.Relationship,
        title: t("relationship"),
        isSelected: sortSettings.field === SortOptions.Relationship,
        sortType: sortSettings.field === SortOptions.Relationship ? sortSettings.type : sortingParams.desc,
        onClick: generateHandler(SortOptions.Relationship, setSortSettings, sortSettings),
      },
    ],
    [setSortSettings, sortSettings]
  );

  return (
    <thead>
      <tr>
        {rows.map((row, i) => (
          <th key={row.key} className="cursor-pointer" onClick={row.onClick} colSpan={i === 2 ? 2 : undefined}>
            <span className="d-flex align-items-center">
              <Ui.xs color="foregroundMedium" className="me-half">
                {row.title}
              </Ui.xs>

              <FilledArrowDownIcon
                className={cn({ invisible: !row.isSelected })}
                style={{
                  transition: "all 0.2s",
                  transform: row.sortType === sortingParams.desc ? "rotate(0deg)" : "rotate(180deg)",
                }}
              />
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
