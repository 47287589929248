import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { Formik } from "formik";

import { getPath } from "app/Router/RouterHelper";

import UpdateValuationForm from "./UpdateValuationForm";
import { UpdateValuationFormValues, useUpdateValuationForm } from "./useUpdateValuationForm";

const UpdateValuationFormik = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSubmit = useCallback(
    async (values: UpdateValuationFormValues) => {
      if (values.updateType === "update") {
        await axios.post("/api/company/valuation", {
          title: values.title,
          validFrom: format(new Date(), "yyyy-MM-dd"),
          description: values.description,
          valuation: values.postMoneyValuation,
          sendEmail: values.notifyStakeholderByEmail,
          emailComment: values.additionalComments,
          id: Number(companyId),
        });
      }
      navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId }));
    },
    [companyId, navigate]
  );

  const { initialValues, validationSchema } = useUpdateValuationForm({
    title: state?.fundraiseName,
    currentCompanyValuation: state?.currentCompanyValuation,
    postMoneyValuation: state?.postMoneyValuation,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <UpdateValuationForm />
    </Formik>
  );
};

export default UpdateValuationFormik;
