import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { Button, ModalInfo } from "common/components/atoms";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

export type CancelFundraiseModalProps = {
  fundraiseId: number;
  show: boolean;
  onHide: () => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.modals.cancelFundraise");

const CancelFundraiseModal: FC<CancelFundraiseModalProps> = (props) => {
  const { show, fundraiseId } = props;
  const { getOngoingFundraiseThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const [updateFundraise, setUpdateFundraise] = useState(false);

  const handleOnExited = useCallback(() => {
    if (updateFundraise) {
      getOngoingFundraiseThunk(fundraiseId);
      setUpdateFundraise(false);
    }
  }, [updateFundraise, getOngoingFundraiseThunk, fundraiseId]);

  return (
    <ModalInfo onExited={handleOnExited} size="sm" show={show} header={t("title")}>
      <RoundModalForm setUpdateFundraise={setUpdateFundraise} {...props} />
    </ModalInfo>
  );
};

type CancelFundraiseModalContent = CancelFundraiseModalProps & {
  setUpdateFundraise: Dispatch<SetStateAction<boolean>>;
};
const RoundModalForm: FC<CancelFundraiseModalContent> = ({ onHide, setUpdateFundraise, fundraiseId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { cancelFundraiseThunk, setFundraiseId, setFundraiseSummary } = useStoreActions(
    (actions) => actions.fundraisingModel
  );

  const handleRemoveInstrument = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await cancelFundraiseThunk(fundraiseId);
      setFundraiseId(undefined);
      setFundraiseSummary(null);
      setUpdateFundraise(true);
      onHide();
    } finally {
      setIsSubmitting(false);
    }
  }, [cancelFundraiseThunk, fundraiseId, setFundraiseId, setFundraiseSummary, setUpdateFundraise, onHide]);
  return (
    <div style={{ width: 275, margin: "0 auto" }}>
      <div>{t("description")}</div>
      <div className="mt-5">
        <Button
          onClick={handleRemoveInstrument}
          type="submit"
          variant="danger"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          {t("confirm")}
        </Button>
        <Button isDisabled={isSubmitting} onClick={onHide} className="ms-2" variant="secondary">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};
export default CancelFundraiseModal;
