import {
  ChangeEvent,
  CSSProperties,
  FocusEventHandler,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BDropdown from "react-bootstrap/Dropdown";
import { DropdownProps } from "@restart/ui/Dropdown";
import cn from "classnames";
import { isNil } from "ramda";

import { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import { InputHeaderProps } from "../input-blocks/input-header/input-header";
import { TextFieldProps } from "../TextField/TextField";
import classes from "./Dropdown.module.scss";
import DropdownMenu from "./DropdownMenu";
import DropdownToggle from "./DropdownToggle";

export type OptionsType = {
  id: number | string;
  name: string;
  element?: ReactNode;
  hidden?: boolean;
  isDisabled?: boolean;
};

export type SearchType = "includes" | "startsWith";

export type DropdownOnChange<T extends boolean = false> = T extends true
  ? (selectedValue: ChangeEvent<HTMLInputElement>) => void
  : (selectedValue: string) => void;

export type DropdownPropsNew<T extends boolean = false> = InputHeaderProps &
  InputFeedbackProps & {
    name?: string;
    testId?: string;
    className?: string;
    placeholder?: string;
    style?: CSSProperties;
    toggleTestId?: string;
    isSearchable?: boolean;
    searchType?: SearchType;
    searchPlaceholder?: string;
    withoutToggleChevron?: boolean;
    searchChangeCallback?: (value: string) => void;
    onBlur?: FocusEventHandler<HTMLElement>;
    isLoading?: boolean;
    showSearchIcon?: boolean;
    isClearable?: boolean;
    filterOff?: boolean;
    isSearchComponent?: boolean;
    optionsIsObject?: T;
    options: T extends true ? OptionsType[] : string[];
    selectedValue?: T extends true ? number | string : string;
    onChange: DropdownOnChange<T>;
    MenuHeadSection?: ReactNode | null;
    CustomToggleElement?: ReactNode | null;
    menuOpen?: boolean;
  };

const Dropdown = <T extends boolean = false>({
  label,
  error,
  meta,
  isOptional = false,
  isTouched = false,
  isDisabled = false,
  isSearchable = false,
  options,
  className,
  style,
  selectedValue,
  placeholder,
  searchPlaceholder,
  testId,
  onChange,
  optionsIsObject,
  name,
  onBlur,
  withoutToggleChevron,
  searchChangeCallback,
  isLoading,
  showSearchIcon,
  isClearable,
  filterOff,
  isSearchComponent,
  MenuHeadSection,
  CustomToggleElement,
  searchType = "includes",
  menuOpen,
}: DropdownPropsNew<T>) => {
  const ref = useRef<TextFieldProps>(null);
  const [searchValue, setSearchValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocusable, setIsFocusable] = useState(false);

  const transformOptions = useMemo(() => {
    if (optionsIsObject) {
      return options as OptionsType[];
    } else {
      return (options as string[]).map((el) => ({ id: el, name: el, isDisabled: false })) as OptionsType[];
    }
  }, [options, optionsIsObject]);


  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isSearchable) {
        setSearchValue(e.target.value);
        searchChangeCallback?.(e.target.value);
      }
    },
    [isSearchable, searchChangeCallback]
  );

  const selectedDataValue = useMemo(() => {
    return transformOptions.find((el) => el.id == selectedValue);
  }, [selectedValue, transformOptions]);

  const toggleValue = useMemo(() => {
    if (isSearchable && isMenuOpen) return searchValue;
    return selectedDataValue?.name || "";
  }, [isSearchable, isMenuOpen, searchValue, selectedDataValue]);

  const menuValues = useMemo(() => {
    return transformOptions.filter((o) => !o.hidden);
  }, [transformOptions]);

  useEffect(() => {
    if (isSearchable && !isMenuOpen) {
      setSearchValue("");
    }
  }, [isMenuOpen, isSearchComponent, isSearchable]);

  const handleSelect = useCallback(
    (value: OptionsType) => {
      const foundOption = transformOptions.find((el) => el.id === value.id);

      if (optionsIsObject) {
        (onChange as DropdownOnChange<true>)({
          target: {
            name: name || "",
            value: foundOption?.id === undefined ? "" : foundOption.id,
          },
        } as ChangeEvent<HTMLInputElement>);
        if (isSearchable && isSearchComponent) {
          setSearchValue(foundOption ? foundOption.name : "");
        } else {
          setSearchValue("");
        }
      } else {
        (onChange as DropdownOnChange)(foundOption?.name || "");
        if (isSearchable && isSearchComponent) {
          setSearchValue(foundOption?.name || "");
        } else {
          setSearchValue("");
        }
      }

      // don't  touch  this part
      setTimeout(() => {
        // @ts-ignore
        ref.current?.blur();
      }, 10);
    },
    [isSearchComponent, isSearchable, name, onChange, optionsIsObject, transformOptions]
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLButtonElement>>(
    (e) => {
      e.target.name = name || "";
      onBlur?.(e);
      setIsFocusable(false);
    },
    [name, onBlur]
  );

  const handleClear = useCallback(() => {
    if (optionsIsObject) {
      (onChange as DropdownOnChange<true>)({
        target: {
          name: name || "",
          value: null as unknown,
        },
      } as ChangeEvent<HTMLInputElement>);
    } else {
      (onChange as DropdownOnChange)("");
    }
    setSearchValue("");
    searchChangeCallback?.("");
  }, [name, onChange, optionsIsObject, searchChangeCallback]);

  const handleToggleClick = useCallback<Required<DropdownProps>["onToggle"]>(
    (nextShow) => {
      if (!isNil(menuOpen)) {
        return;
      }

      setIsMenuOpen(nextShow);
    },
    [menuOpen]
  );

  useEffect(() => {
    if (!isNil(menuOpen)) {
      setIsMenuOpen(menuOpen);
    }
  }, [menuOpen]);

  return (
    <div
      className={cn(className)}
      data-testid={testId ? testId : `dropdown-container-${name || label}-test-id`}
      style={style}
    >
      <BDropdown
        show={isMenuOpen}
        onToggle={handleToggleClick}
        data-testid={
          error && isTouched ? `dropdown-${name || label}-error-test-id` : `dropdown-${name || label}-test-id`
        }
        className={cn(classes["dropdown"], {
          [classes["disabled"]]: isDisabled,
          [classes.isSearchable]: isSearchable,
        })}
      >
        <BDropdown.Toggle
          ref={ref}
          as={DropdownToggle}
          isTouched={isTouched}
          isDisabled={isDisabled}
          isOptional={isOptional}
          label={label}
          meta={meta}
          isFocusable={isFocusable}
          isSearchable={isSearchable}
          isMenuOpen={isMenuOpen}
          placeholder={selectedDataValue?.name || placeholder}
          searchPlaceholder={searchPlaceholder}
          searchValue={searchValue}
          onSearchChange={handleSearchChange}
          value={toggleValue}
          onBlur={handleBlur}
          onFocus={() => {
            setIsFocusable(true);
          }}
          error={error}
          name={name}
          withoutToggleChevron={withoutToggleChevron}
          handleClear={handleClear}
          isLoading={isLoading}
          showSearchIcon={showSearchIcon}
          isClearable={isClearable}
          valueIsSelected={!!selectedDataValue}
          CustomToggleElement={CustomToggleElement}
        />

        <BDropdown.Menu
          as={DropdownMenu}
          options={menuValues}
          selected={selectedDataValue?.id}
          searchValue={searchValue}
          className={classes.menu}
          data-testid="dropdown-menu-container"
          handleChange={handleSelect}
          filterOff={filterOff}
          filterType={searchType}
          MenuHeadSection={MenuHeadSection}
        />
      </BDropdown>
    </div>
  );
};

export default memo(Dropdown) as typeof Dropdown;
