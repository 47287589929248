export enum TranslationNS {
  common = "common",
  layout = "layout",
  pages = "pages",
  validation = "validation",
  guides = "guides",
}

export enum Languages {
  en = "en",
  no = "no",
}

export { createTranslation } from "./helpers";

export const config = {
  ns: Object.keys(TranslationNS),
  fallbackLng: Languages.en,
  supportedLngs: ["en", "no"],
  interpolation: {
    escapeValue: false,
  },
};
