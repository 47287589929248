import { useMemo } from "react";
import { defaultTo } from "ramda";

import { DocumentStatusEnum, InstrumentTypesIdsEnum, PeriodTimeEnum, VestingTypesEnum } from "common/enums/enum";
import { ProgramGetDTO, ProgramPostDTO } from "store/modelTypes";

export type CreateProgramFormData = ProgramPostDTO & {
  submitter?: "create" | "draft" | "approve";
  programTypeId: InstrumentTypesIdsEnum;
  programId?: number;
  isExcludedClause: boolean;
  numberOfSharesLeft: number;
  programNumberOfShares: number;
  approvalDate?: string;
  vestingRelativeExpiry?: boolean;
  filesData?: {
    files: File[];
    oldFiles: ProgramPostDTO["documentFiles"];
  };
};

export const fields = {
  name: "name",
  purpose: "purpose",
  poolId: "poolId",
  programTypeId: "programTypeId",
  numberOfShares: "numberOfShares",
  purchasePrice: "purchasePrice",
  exercisePrice: "exercisePrice",
  optionsExpiration: "optionsExpiration",
  optionsExpirationTimeUnit: "optionsExpirationTimeUnit",
  numberOfSharesLeft: "numberOfSharesLeft",
  vestingTypeId: "vestingTypeId",
  vestingPeriod: "vestingPeriod",
  vestingInterval: "vestingInterval",
  vestingCliff: "vestingCliff",
  conditions: "conditions",
  allowAcceleratedVesting: "allowAcceleratedVesting",
  retirementValue: "retirementValue",
  retirementUnit: "retirementUnit",
  disabilityValue: "disabilityValue",
  disabilityUnit: "disabilityUnit",
  terminationWithCauseValue: "terminationWithCauseValue",
  terminationWithCauseUnit: "terminationWithCauseUnit",
  involuntaryTerminationValue: "involuntaryTerminationValue",
  involuntaryTerminationUnit: "involuntaryTerminationUnit",
  voluntaryTerminationValue: "voluntaryTerminationValue",
  voluntaryTerminationUnit: "voluntaryTerminationUnit",
  terminationByPassingValue: "terminationByPassingValue",
  terminationByPassingUnit: "terminationByPassingUnit",
  programId: "programId",
  documentStatusId: "documentStatusId",
  approvalDate: "approvalDate",
  isExcludedClause: "isExcludedClause",
  managerId: "managerId",
  filesData: "filesData",
  submitter: "submitter",
  programNumberOfShares: "programNumberOfShares",
  essentialTerms: "essentialTerms",
  vestingRelativeExpiry: "vestingRelativeExpiry",
} as const;

const useProgramForm = (program?: ProgramGetDTO | null) => {
  const initialValues = useMemo<CreateProgramFormData>(
    () => ({
      [fields.name]: defaultTo("", program?.name),
      [fields.purpose]: defaultTo("", program?.purpose),
      [fields.essentialTerms]: defaultTo("", program?.essentialTerms),
      [fields.poolId]: defaultTo(0, program?.poolId),
      [fields.programTypeId]: defaultTo(InstrumentTypesIdsEnum.OPTION, program?.instrumentTypeId),
      [fields.numberOfShares]: defaultTo(undefined, program?.numberOfShares),
      [fields.programNumberOfShares]: defaultTo(0, program?.numberOfShares),

      // RSA
      [fields.purchasePrice]: defaultTo(
        0,
        program?.instrumentTypeId === InstrumentTypesIdsEnum.RSA ? program?.purchasePrice : 0
      ),

      // Options
      [fields.exercisePrice]: defaultTo(undefined, program?.exercisePrice),
      [fields.optionsExpiration]: defaultTo(undefined, program?.optionsExpiration),
      [fields.optionsExpirationTimeUnit]: defaultTo(PeriodTimeEnum.YEAR, program?.optionsExpirationTimeUnit),
      // number of shares left, below present useEffect which updates this value
      [fields.numberOfSharesLeft]: 0,

      [fields.vestingTypeId]: defaultTo(VestingTypesEnum.TIME_VESTING, program?.vestingTypeId),
      [fields.vestingPeriod]: defaultTo(undefined, program?.vestingPeriod),
      [fields.vestingInterval]: defaultTo(undefined, program?.vestingInterval),
      [fields.vestingCliff]: defaultTo(undefined, program?.vestingCliff),
      [fields.conditions]: defaultTo(
        [],
        program?.conditions?.map((item) => ({
          id: item.id,
          title: defaultTo("", item.title),
          description: defaultTo("", item.description),
          targetDate: item?.targetDate,
        }))
      ),
      [fields.allowAcceleratedVesting]: defaultTo(false, program?.allowAcceleratedVesting),
      //
      [fields.retirementValue]: defaultTo(undefined, program?.retirement),
      [fields.retirementUnit]: program?.retirementTimeUnit || PeriodTimeEnum.MONTH,
      [fields.disabilityValue]: defaultTo(undefined, program?.disability),
      [fields.disabilityUnit]: program?.disabilityTimeUnit || PeriodTimeEnum.MONTH,
      [fields.terminationWithCauseValue]: defaultTo(undefined, program?.terminationWithCause),
      [fields.terminationWithCauseUnit]: program?.terminationWithCauseTimeUnit || PeriodTimeEnum.MONTH,
      [fields.involuntaryTerminationValue]: defaultTo(undefined, program?.involuntaryTermination),
      [fields.involuntaryTerminationUnit]: program?.involuntaryTerminationTimeUnit || PeriodTimeEnum.MONTH,
      [fields.voluntaryTerminationValue]: defaultTo(undefined, program?.voluntaryTermination),
      [fields.voluntaryTerminationUnit]: program?.voluntaryTerminationTimeUnit || PeriodTimeEnum.MONTH,
      [fields.terminationByPassingValue]: defaultTo(undefined, program?.byPassingAway),
      [fields.terminationByPassingUnit]: program?.byPassingAwayTimeUnit || PeriodTimeEnum.MONTH,
      [fields.programId]: defaultTo(undefined, program?.programId),
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.REVIEW_LATER, program?.documentStatusId),
      [fields.filesData]: {
        files: [],
        oldFiles: program?.documentFiles || [],
        oldFileIds: program?.documentFiles?.map((data) => data.fileId) || [],
      },
      [fields.approvalDate]: defaultTo(undefined, program?.approvedAt),
      [fields.isExcludedClause]: defaultTo(false, program?.excludePostTerminationClauses),
      [fields.managerId]: defaultTo(null, program?.managerId),
      [fields.vestingRelativeExpiry]: defaultTo(false, program?.vestingRelativeExpiry),
    }),
    [program]
  );

  return {
    initialValues,
  };
};

export default useProgramForm;
