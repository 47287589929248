import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { dissoc, equals, isNil } from "ramda";

import { Button } from "common/components/atoms";

import ManagePlansContext, { initialFiltersState } from "../../managePlansContext";
import classes from "./AgreementsAppliedFilters.module.scss";
import AgreementsStakeholderSearch from "./components/AgreementsStakeholderSearch";
import DatesFiltersDropdown from "./components/DatesFiltersDropdown";
import StatusFiltersDropdown from "./components/StatusFiltersDropdown";

const AgreementsAppliedFilters = () => {
  const { companyId = "0" } = useParams();

  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const filtersForCurrentCompany = savedAppliedFilters?.[companyId];

  const isFiltersEquals = useMemo(
    () => (!isNil(filtersForCurrentCompany) ? equals(filtersForCurrentCompany, initialFiltersState) : true),
    [filtersForCurrentCompany]
  );

  const clearFiltersHandler = useCallback(() => {
    setSavedAppliedFilters(dissoc(companyId, savedAppliedFilters || {}));
  }, [companyId, savedAppliedFilters, setSavedAppliedFilters]);

  return (
    <div className={classNames("my-4 px-3 d-flex align-items-center", classes["container"])}>
      <StatusFiltersDropdown />

      <DatesFiltersDropdown />

      <AgreementsStakeholderSearch />

      {!isFiltersEquals ? (
        <Button size="s" className="ms-1" variant="tertiary" onClick={clearFiltersHandler}>
          Clear filters
        </Button>
      ) : null}
    </div>
  );
};

export default AgreementsAppliedFilters;
