import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { and, equals } from "ramda";

import { Ui } from "common/components/atoms";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../valuation.module.scss";
import { ValuationTaxableValueTableSortValue } from "./ValuationTaxableValueTable";

const t = createTranslation(TranslationNS.pages, "company.valuation");

type Props = {
  sortedValue: ValuationTaxableValueTableSortValue;
  setSortedValue: (e: ValuationTaxableValueTableSortValue) => void;
};

const ValuationTaxableValueTableHead: FC<Props> = ({ sortedValue, setSortedValue }) => {
  const rows = useMemo(
    () => [
      {
        key: "year",
        value: t("valuationTaxableValueTable.taxYear"),
      },

      {
        key: "registeredDate",
        value: t("valuationTaxableValueTable.registeredDate"),
      },
      {
        key: "editor",
        value: t("valuationTaxableValueTable.editor"),
      },

      {
        key: "description",
        value: t("valuationTaxableValueTable.description"),
        sortDisabled: true,
      },
      {
        key: "valuePerShare",
        value: t("valuationTaxableValueTable.taxableValue"),
      },
    ],
    []
  );

  return (
    <thead className={classes["table-head"]}>
      <tr>
        {rows.map((el, i, array) => (
          <th
            key={el.key}
            onClick={
              el?.sortDisabled
                ? undefined
                : () => {
                    setSortedValue({
                      field: el.key as ValuationTaxableValueTableSortValue["field"],
                      type:
                        sortedValue.field !== el.key
                          ? sortingParams.inc
                          : sortedValue.type === sortingParams.inc
                          ? sortingParams.desc
                          : sortingParams.inc,
                    });
                  }
            }
          >
            <div
              className={classNames("d-flex align-items-center", {
                "justify-content-end": i === array.length - 1,
                "cursor-pointer": !el?.sortDisabled,
              })}
            >
              <Ui.xs color="foregroundMedium">{el.value}</Ui.xs>

              <span
                className={classNames(classes.sort, {
                  invisible: !equals(sortedValue.field, el.key),
                  [classes["rotated"]]: and(
                    equals(sortedValue.field, el.key),
                    equals(sortedValue.type, sortingParams.inc)
                  ),
                })}
              >
                <FilledArrowDownIcon />
              </span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ValuationTaxableValueTableHead;
