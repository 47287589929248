import { useMemo } from "react";
import { format } from "date-fns";

import { SharePriceTypeEnum, VestingTypesEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { Item } from "pages/receiving/receive-plan/common/agreement-summary/agreement-summary";
import { ShareClassSingleType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.receivePlan");
const tCommon = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

export type SOPlanDetails = {
  totalShares: number;
  exercisePrice: number;
  pricePerShare: number;
  sharePriceType: SharePriceTypeEnum;
  shareMarketValue: number;
  vestingCliff: number;
  vestingPeriod: number;
  shareClass: null | ShareClassSingleType;
  allowAcceleratedVesting: boolean;
  vestingStartsAt: string;
  vestingEndsAt: string;
  exerciseExpiryDate: string;
  vestingTypeId: number;
  vestingMilestones?: any[];
  currencyId: number;
  essentialTerms?: string;
};
type SOPlanDetailsExtended = SOPlanDetails & {
  retirement: number;
  retirementTimeUnit: string;
  terminationWithCause: number;
  terminationWithCauseTimeUnit: string;
  disability: number;
  disabilityTimeUnit: string;
  involuntaryTermination: number;
  involuntaryTerminationTimeUnit: string;
  voluntaryTermination: number;
  voluntaryTerminationTimeUnit: string;
  byPassingAway: number;
  byPassingAwayTimeUnit: string;
};
export type PostTerminationClauses = {
  retirementText: string;
  terminationWithCauseText: string;
  disabilityText: string;
  involuntaryTerminationText: string;
  voluntaryTerminationText: string;
  passingAwayText: string;
};

const useSOAgreementDetails = <T extends PostTerminationClauses | null | undefined>(
  planDetails: T extends undefined ? SOPlanDetailsExtended | null : SOPlanDetails | null,
  postTerminationClauses?: T,
  isInvitationFlow: boolean = false
) => {
  const { currencySymbol } = useCurrencyById(planDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const postTermination = useMemo(() => {
    if (postTerminationClauses === undefined) {
      const agreementDetails = planDetails as SOPlanDetailsExtended;
      return {
        retirementText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.retirement || 0,
          unit: agreementDetails?.retirementTimeUnit?.toLowerCase(),
        }),
        terminationWithCauseText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.terminationWithCause || 0,
          unit: agreementDetails?.terminationWithCauseTimeUnit?.toLowerCase(),
        }),
        disabilityText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.disability || 0,
          unit: agreementDetails?.disabilityTimeUnit?.toLowerCase(),
        }),
        involuntaryTerminationText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.involuntaryTermination || 0,
          unit: agreementDetails?.involuntaryTerminationTimeUnit?.toLowerCase(),
        }),
        voluntaryTerminationText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.voluntaryTermination || 0,
          unit: agreementDetails?.voluntaryTerminationTimeUnit?.toLowerCase(),
        }),
        passingAwayText: tCommon("infoWidget.SO.commonPeriodUnit", {
          count: agreementDetails?.byPassingAway || 0,
          unit: agreementDetails?.byPassingAwayTimeUnit?.toLowerCase(),
        }),
      };
    }
    return postTerminationClauses as PostTerminationClauses;
  }, [planDetails, postTerminationClauses]);

  const vestingStartsAt = planDetails?.vestingStartsAt
    ? format(new Date(planDetails?.vestingStartsAt), "d MMMM yyyy")
    : "-";

  const vestingEndsAt = planDetails?.vestingEndsAt ? format(new Date(planDetails?.vestingEndsAt), "d MMMM yyyy") : "-";

  const isActualSharePrice = planDetails?.sharePriceType === SharePriceTypeEnum.ACTUAL;

  const marketValue = isInvitationFlow ? "stockOptionsMarketValue" : "vestedOptionsMarketValue";

  const agreementItems: Item[] = useMemo(
    () => [
      {
        description: tCommon("agreementType"),
        content: tCommon("infoWidget.SO.agreementTypeSO"),
        title: "Stock options",
      },
      {
        description: tCommon("SO.numberOfStocks"),
        content: tCommon("infoWidget.SO.numberOfStocks"),
        title: fNumber(planDetails?.totalShares, "amount"),
      },
      {
        description: tCommon("SO.exercisePricePerOption"),
        content: tCommon("infoWidget.SO.exercisePricePerOption"),
        title: <div className="d-flex align-items-center">{fNumber(planDetails?.exercisePrice, "unitPrice")}</div>,
      },
      {
        description: tCommon(isActualSharePrice ? "pricePerShare" : "pricePerShareFd"),
        content: tCommon(`infoWidget.SO.${isActualSharePrice ? "pricePerShare" : "pricePerShareFd"}`),
        title: <div className="d-flex align-items-center">{fNumber(planDetails?.pricePerShare, "sharePrice")}</div>,
      },
      {
        description: tCommon(`SO.${isActualSharePrice ? marketValue : `${marketValue}Fd`}`),
        content: tCommon(`infoWidget.SO.${isActualSharePrice ? marketValue : `${marketValue}Fd`}`),
        title: <div className="d-flex align-items-center">{fNumber(planDetails?.shareMarketValue, "value")}</div>,
      },
      {
        description: tCommon("cliff"),
        content: tCommon("infoWidget.cliff"),
        title:
          planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING
            ? "-"
            : tCommon("cliffValue", { count: planDetails?.vestingCliff || 0 }),
      },
      {
        description: tCommon("vestingPeriod"),
        content: tCommon("infoWidget.SO.vestingPeriod"),
        title:
          planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING
            ? "-"
            : tCommon("vestingPeriodValue", { count: planDetails?.vestingPeriod }),
      },
      {
        description: tCommon("shareClass"),
        content: tCommon("infoWidget.SO.shareClass"),
        title: planDetails?.shareClass?.name || "-",
        isShareClass: true,
        shareClass: planDetails?.shareClass,
      },
      {
        description: tCommon("acceleratedVesting"),
        content: planDetails?.allowAcceleratedVesting
          ? tCommon("infoWidget.SO.withAcceleratedVesting")
          : tCommon("infoWidget.SO.withoutAcceleratedVesting"),
        title: planDetails?.allowAcceleratedVesting ? t("yes") : t("no"),
      },
      {
        description: tCommon("vestingStartDate"),
        content: tCommon("infoWidget.vestingStartDate"),
        title: planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING ? "-" : vestingStartsAt,
      },
      {
        description: tCommon("vestingEndDate"),
        content: tCommon("infoWidget.SO.vestingEndDate"),
        title: planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING ? "-" : vestingEndsAt,
      },
      {
        description: tCommon("SO.exerciseExpiry"),
        content: tCommon("infoWidget.SO.exerciseExpiry"),
        title: planDetails?.exerciseExpiryDate ? format(new Date(planDetails?.exerciseExpiryDate), "d MMMM yyyy") : "-",
      },
    ],
    [
      fNumber,
      isActualSharePrice,
      marketValue,
      planDetails?.allowAcceleratedVesting,
      planDetails?.exerciseExpiryDate,
      planDetails?.exercisePrice,
      planDetails?.pricePerShare,
      planDetails?.shareClass,
      planDetails?.shareMarketValue,
      planDetails?.totalShares,
      planDetails?.vestingCliff,
      planDetails?.vestingPeriod,
      planDetails?.vestingTypeId,
      vestingEndsAt,
      vestingStartsAt,
    ]
  );

  const postTerminationClauseItems = useMemo(
    () =>
      postTermination
        ? [
            {
              title: postTermination.retirementText || "-",
              description: tCommon("SO.retirement"),
              content: tCommon("infoWidget.SO.retirement"),
            },
            {
              title: postTermination.terminationWithCauseText || "-",
              description: tCommon("SO.termWithClause"),
              content: tCommon("infoWidget.SO.termWithClause"),
            },
            {
              title: postTermination.disabilityText || "-",
              description: tCommon("SO.disability"),
              content: tCommon("infoWidget.SO.disability"),
            },
            {
              title: postTermination.involuntaryTerminationText || "-",
              description: tCommon("SO.involuntaryTerm"),
              content: tCommon("infoWidget.SO.involuntaryTerm"),
            },
            {
              title: postTermination.voluntaryTerminationText || "-",
              description: tCommon("SO.voluntaryTerm"),
              content: tCommon("infoWidget.SO.voluntaryTerm"),
            },
            {
              title: postTermination.passingAwayText || "-",
              description: tCommon("SO.passingAway"),
              content: tCommon("infoWidget.SO.passingAway"),
            },
          ]
        : [],
    [postTermination]
  );

  const isShowScheduleChart = useMemo(
    () =>
      planDetails?.vestingTypeId === VestingTypesEnum.TIME_VESTING ||
      planDetails?.vestingTypeId === VestingTypesEnum.COMBINE_VESTING,
    [planDetails]
  );

  const isShowMilestones = useMemo(
    () =>
      Boolean(
        (planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING ||
          planDetails?.vestingTypeId === VestingTypesEnum.COMBINE_VESTING) &&
          planDetails?.vestingMilestones?.length
      ),
    [planDetails]
  );

  return { agreementItems, postTerminationClauseItems, isShowScheduleChart, isShowMilestones };
};

export default useSOAgreementDetails;
