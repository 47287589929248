import React, { FC } from "react";
import classNames from "classnames";
import { and, equals } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import classes from "../../../ownership/valuation/valuation.module.scss";
import { AccessControlSortValue } from "./table-view";

type Props = {
  sortedValue: AccessControlSortValue;
  setSortedValue: (e: AccessControlSortValue) => void;
};

const TableHead: FC<Props> = ({ sortedValue, setSortedValue }) => {
  return (
    <thead style={{ height: 40 }}>
      <tr>
        <th
          onClick={() => {
            setSortedValue({
              field: "fullName",
              type:
                sortedValue.field !== "fullName"
                  ? sortingParams.inc
                  : sortedValue.type === sortingParams.inc
                  ? sortingParams.desc
                  : sortingParams.inc,
            });
          }}
        >
          <div className="d-flex align-items-center cursor-pointer">
            <Ui.xs>Name</Ui.xs>

            <span
              className={classNames(classes.sort, {
                invisible: !equals(sortedValue.field, "fullName"),
                [classes["rotated"]]: and(
                  equals(sortedValue.field, "fullName"),
                  equals(sortedValue.type, sortingParams.inc)
                ),
              })}
            >
              <FilledArrowDownIcon />
            </span>
          </div>
        </th>
        <th
          onClick={() => {
            setSortedValue({
              field: "email",
              type:
                sortedValue.field !== "email"
                  ? sortingParams.inc
                  : sortedValue.type === sortingParams.inc
                  ? sortingParams.desc
                  : sortingParams.inc,
            });
          }}
        >
          <div className="d-flex align-items-center cursor-pointer">
            <Ui.xs>Email</Ui.xs>

            <span
              className={classNames(classes.sort, {
                invisible: !equals(sortedValue.field, "email"),
                [classes["rotated"]]: and(
                  equals(sortedValue.field, "email"),
                  equals(sortedValue.type, sortingParams.inc)
                ),
              })}
            >
              <FilledArrowDownIcon />
            </span>
          </div>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};

export default TableHead;
