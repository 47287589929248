import { Action, ActionOn, Thunk } from "easy-peasy";

import { FilesDataSingle, UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum } from "common/enums/enum";

import { SingleCommonDocumentType } from "../documents/DocumentsContext";
import { BuySellFields } from "./components/forms/buy-sell/form-fields";
import { CapitalIncreaseGeneralFields } from "./components/forms/capital-increase/steps/capital-increase-general/form-fields";
import { CapitalIncreaseTransactionsFields } from "./components/forms/capital-increase/steps/capital-increase-transactions-table/form-fields";
import { ImportTransactionFormValues } from "./components/forms/capital-increase/steps/capital-increase-transactions-table/use-import-transaction-form";
import { ChangeNominalValueFields } from "./components/forms/change-nominal-value/form-fields";
import { DocumentationDetailsFieldsType } from "./components/forms/form-sections/documentation-details/types";
import { ReceiveDetailsFieldsType } from "./components/forms/form-sections/receiver-details/types";
import { SellerDetailsFieldsType } from "./components/forms/form-sections/seller-details/types";
import { ShareDetailsFieldsType } from "./components/forms/form-sections/shares-details/types";
import { SplitFieldsTypes } from "./components/forms/form-sections/split-details/types";
import { TransactionDetailsFieldsType } from "./components/forms/form-sections/transaction-details/types";
import { IssueSharesFields } from "./components/forms/issue-shares/form-fields";

export const TransactionCategory = {
  Issue: 1,
  Sell: 2,
  Split: 3,
  ChangeNominalValue: 4,
  CapitalIncrease: 5,
  Deletion: 6, // Used only for 1 company as exception there is no way for creating it with UI
  ShareTransfer: 7,
} as const;

export const TransactionStatus = {
  Pending: 1,
  Confirmed: 2,
} as const;

export const TransactionTypes = {
  RsaSharesIssued: 1,
  InvestorSharesIssued: 2,
  OptionsExercised: 3,
  NoteConversion: 4,
  LoanConversion: 5,
  WarrantSettlement: 6,
  PlanTermination: 7,
  CaptableSetup: 8,
  BuySell: 9,
  Foundation: 12,
  Split: 13,
  StandAloneRsa: 14,
  NominalValue: 15,
  CapitalIncreaseBundle: 16,
  CashDeposit: 17,
  ReceivableConversion: 18,
  Other: 19,
  AllCompanySharesDeleted: 20,
  StakeholderSharesDeleted: 21,
  Inheritance: 22,
  Gift: 25,
  InheritanceWithoutTaxContinuity: 37,
  GiftWithoutTaxContinuity: 38,
} as const;

export const TransactionSharesType: Record<TransactionCategoryIds, string> = {
  [TransactionCategory.Issue]: "newShares",
  [TransactionCategory.Sell]: "existingShares",
  [TransactionCategory.Split]: "splitShares",
  [TransactionCategory.ChangeNominalValue]: "changeNominalValue",
  [TransactionCategory.CapitalIncrease]: "CapitalIncrease",
  [TransactionCategory.Deletion]: "deletion",
  [TransactionCategory.ShareTransfer]: "shareTransfer",
} as const;

export type TransactionCategoryIds = (typeof TransactionCategory)[keyof typeof TransactionCategory];
export type TransactionStatusIds = (typeof TransactionStatus)[keyof typeof TransactionStatus];
export type TransactionTypesIds = (typeof TransactionTypes)[keyof typeof TransactionTypes];

export type Transaction = {
  transactionId: number;
  transactedAt: string;
  typeName?: string;
  categoryName?: string;
  transactionTypeId: TransactionTypesIds;
  categoryId: TransactionCategoryIds;
  statusId: TransactionStatusIds;
  numberOfShares: number;
  fromName?: string;
  fromAvatar?: string;
  fromInitials?: string;
  fromRepresentativeName?: string;
  fromIsCompanyOwned: boolean;
  toName?: string;
  toInitials?: string;
  toAvatar?: string;
  toIsCompanyOwned: boolean;
  toRepresentativeName?: string;
  currencySymbol?: string;
  sharePremium?: number;
  sharePrice: number;
  shareClassName?: string;
  description?: string;
  shareSeries: string[]; // "1-500"
  transactionTotal: number;
  totalSharePremium?: number;
  shareCapital?: number;
  editedAfterConfimation: boolean; // typo in API

  multiplier?: number; // only for split transactions
  sharePriceBefore?: number; // only for split transactions
  numberOfSharesBefore?: number; // only for split transactions

  numberOfBundledTransactions?: number; // only for bundle
  transactionBundleId?: number; // only for bundle
  eventName?: string; // only for bundle
  bundledTransactions: Transaction[] | null;

  canEditConfirmed: boolean;
  numbersOfSharesInCompany?: number;
  numberOfFiles: number;
  files?: UploadedFile[];
  documentStatusId: number;
};

export type GetTransactionsDTO = {
  transactions: Transaction[];
  companyHasShares: boolean;
  transactedAtMax: string;
  nominalValue: number;
};

// issue shares
export type IssueSharesGetResponseDTO = TransactionDetailsFieldsType &
  ShareDetailsFieldsType &
  ReceiveDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    transactionBundleId?: number;
    statusId: TransactionStatusIds;
    documentFiles: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
  };

export type IssueSharesPostDTO = {
  [IssueSharesFields.companyId]: string;
} & TransactionDetailsFieldsType &
  ShareDetailsFieldsType &
  ReceiveDetailsFieldsType &
  DocumentationDetailsFieldsType;

export type IssueSharesEditDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    [IssueSharesFields.companyId]: number;
  };

export type IssueSharesCheckDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType & {
    transactionId: number;
    [IssueSharesFields.companyId]: number;
  };

// buy sell

// used for initial seller in form
export type SellerDetails = {
  stakeholderId: number;
  firstName: string;
  lastName: string;
  isCompanyOwned: boolean;
  companyName: string;
  avatarFilePath: string;
};

export type BuySellGetResponseDTO = TransactionDetailsFieldsType &
  ReceiveDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    transactionId: number;
    transactionTypeId?: number;
    transactionBundleId?: number;
    sellerDetails: SellerDetails;
    documentFiles: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
    canEditConfirmed: boolean;
  };

export type BuySellPostResponseDTO = { transactionId: number };

export type BuySellPostDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    [BuySellFields.transactionTypeId]?: number;
  };

export type BuySellEditDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType &
  DocumentationDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    transactionId: number;
    [BuySellFields.transactionTypeId]?: number;
  };

export type BuySellCheckDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  ReceiveDetailsFieldsType &
  SellerDetailsFieldsType &
  ShareDetailsFieldsType & {
    [BuySellFields.companyId]: number;
    transactionId: number;
  };

export type CheckResponseDTO = {
  failedTransactions: Transaction[];
  hasErrorsAfterEdit: boolean;
};

export type ConfirmIssueSharesTransactionPostDTO = {
  transactionId: number;
  transactionBundleId?: number;
  transactedAt?: string;
} & Omit<TransactionDetailsFieldsType, "transactionTypeId">;

export type AvailableShareClassesPostDTO = {
  companyId: number;
  stakeholderId: number;
  transactionDate: string;
  transactionId?: number; // needed if API used for editing case. It will include shares from transaction as available in shareclass
};

export type AvailableShareClass = {
  shareClassId: number;
  shareClassName: string;
  shareClassType: string;
  sharesAvailable: number;
  pledgedShares: number;
};

export type AvailableShareClassesPostResponseDTO = {
  shareClasses: AvailableShareClass[];
};

// Split
type CommonShareholderDTO = {
  firstName: string;
  lastName: string;
  companyName: string;
  initials: string;
  isCompanyOwned: boolean;
  avatarFilePath?: string;
};

export type SplitShareholder = CommonShareholderDTO & {
  stakeholderId: number;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitPlan = CommonShareholderDTO & {
  planId: number;
  instrumentTypeId: InstrumentTypesIdsEnum;
  instrumentTypeName: InstrumentTypesNamesEnum;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitWarrant = CommonShareholderDTO & {
  warrantId: number;
  sharesBefore: number;
  sharesAfter: number;
};

export type SplitPreviewGetResponseDTO = {
  totalSharesBefore: number;
  totalSharesAfter: number;
  sharePriceBefore: number;
  sharePriceAfter: number;
  nominalShareValueBefore: number;
  nominalShareValueAfter: number;
  shareholders: SplitShareholder[];
  incentiveAgreements: SplitPlan[];
  warrants: SplitWarrant[];
  shareholdersCount: number;
  incentiveAgreementsCount: number;
  warrantsCount: number;
};

export type NominalValueGetResponseDTO = {
  transactionId: number;
  transactionDate: string;
  description?: string;
  companyId: number;
  documentStatusId: number;
  nominalShareValue: number;
  documentFiles?: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
};

export type SplitPostDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  SplitFieldsTypes &
  DocumentationDetailsFieldsType & { companyId: number };

export type SplitPostResponseDTO = { transactionId: number };

export type SplitEditDTO = Omit<TransactionDetailsFieldsType, "transactionTypeId"> &
  SplitFieldsTypes &
  DocumentationDetailsFieldsType & { companyId: number; transactionId: number };

export type SplitGetResponseDTO = TransactionDetailsFieldsType &
  SplitFieldsTypes & {
    companyId: number;
    transactionId: number;
    documentFiles: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
  };

export type ConfirmSplitTransactionPostDTO = { transactionId: number; transactedAt: string } & Omit<
  TransactionDetailsFieldsType,
  "transactionTypeId"
> &
  SplitFieldsTypes;

export type CapitalIncreaseGetDTO = {
  companyId: number;
  transactionBundleId: number;
  transactionId: number;
  statusId: TransactionStatusIds;
  transactions: Transaction[];
  transactedAt: string;
  name: string;
  sharePrice: number;
  totalIncrease: number;
  totalSharePremium: number;
  description?: string;
  nominalValuePerShare: number;
  shareCapital: number;
  sharePremium: number;
  shareClassName?: string;
};

export type CapitalIncreaseTransaction = {
  transactionId?: number;
  numberOfShares: number;
  receivingShareholderId: number;
  shareClassId: number;
  sharePrice: number;
  sourceShareholderId: number;
  transactionDate: string;
  transactionType: number;
  description: string;
};

export type CapitalIncreaseFormGetDTO = {
  transactionId?: number;
  [CapitalIncreaseGeneralFields.transactionBundleId]?: number;
  [CapitalIncreaseGeneralFields.eventName]: string;
  [CapitalIncreaseGeneralFields.sharePrice]: number;
  [CapitalIncreaseGeneralFields.numberOfShares]: number;
  [CapitalIncreaseGeneralFields.transactedAt]: string;
  [CapitalIncreaseGeneralFields.description]?: string;
  [CapitalIncreaseTransactionsFields.transactions]: CapitalIncreaseTransaction[];
  statusId: TransactionStatusIds;
  documentFiles: {
    fileId: number;
    fileName: string;
    downloadId: string;
  }[];
};

export type CapitalIncreaseFormGeneralPostDTO = {
  companyId: number;
  [CapitalIncreaseGeneralFields.transactionBundleId]?: number;
  [CapitalIncreaseGeneralFields.eventName]: string;
  [CapitalIncreaseGeneralFields.sharePrice]: number;
  [CapitalIncreaseGeneralFields.numberOfShares]: number;
  [CapitalIncreaseGeneralFields.transactedAt]: string;
  [CapitalIncreaseGeneralFields.description]?: string;
} & DocumentationDetailsFieldsType;

export type CapitalIncreaseTransactionsPostDTO = {
  companyId: number;
  transactionBundleId: number;
  transactions: ImportTransactionFormValues[];
  transactionIdsToDelete: number[];
};

export type ConfirmCapitalIncreaseTransactionPostDTO = {
  transactionBundleId: number;
  transactedAt: string;
  description: string;
};

export type ConfirmNominalValuePostDTO = { transactionId: number; transactionDate: string; description?: string };

export type ChangeNominalValuePostDTO = {
  [ChangeNominalValueFields.companyId]: number;
  [ChangeNominalValueFields.nominalShareValue]?: number;
  [ChangeNominalValueFields.transactionDate]: string;
  [ChangeNominalValueFields.transactionId]?: number;
  [ChangeNominalValueFields.description]?: string;
} & DocumentationDetailsFieldsType;

export type UserTransactionsGetDTO = {
  transactions: Transaction[];
};

export type CapTableChange = {
  stakeholderId: number;
  initials: string;
  shareholderName: string;
  representedBy: string;
  organizationNumber: string;
  shares: number;
  pledgedShares: number;
  ownershipPercentage: number;
  totalValue: number;
  shareClasses: string[];
  relationship: string;
  hasPledges: boolean;
  descriptionCount: number;
  documentCount: number;
  isCompanyOwned: boolean;
  avatarFilePath: string;
  invitationStatus: number;
  hasChanges: boolean;
  changeInShares: number;
  removedFromCaptable: boolean;
};

export type NewTransaction = Transaction & {
  transactionValidationStatus?: number;
  transactionFailureReason?: string;
  nominalValuePerShare?: number;
  bundleData?: CapitalIncreaseGetDTO;
};

type Contact = {
  name: string;
  isPrimary: boolean;
};

type Stakeholder = {
  stakeholderId: number;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  avatarFilePath: string;
  initials: string;
  relationship: string;
  hasSentInvite: boolean;
  isActiveUser: boolean;
  canDelete: boolean;
  invitationStatus: number;
  contacts: Contact[];
};

export type ImportCaptableExcelPostResponseDTO = {
  hasErrors: boolean;
  hasWarnings: boolean;
  transactionFailedReasons: string[];
  capTableChanges: CapTableChange[];
  transactionWarningReasons: string[];
};

export type ImportCaptableExcelPostBodyDTO = {
  companyId: number;
  file: File;
};

export type ImportTransactionsExcelPostBodyDTO = {
  companyId: number;
  deleteExistingTransactions: boolean;
  file: File;
};

export type ImportTransactionsExcelPostResponseDTO = {
  hasErrors: boolean;
  hasWarnings: boolean;
  transactionFailedReasons: string[];
  transactionWarningReasons: string[];
  capTableChanges: CapTableChange[];
  capTableChangesPerStakeholder: CapTableChange[];
  newTransactions: NewTransaction[];
  newStakeholders: Stakeholder[];
};

export interface TransactionsContextModel {
  // common data
  isLoading: boolean;
  setLoading: Action<this, this["isLoading"]>;

  isLocalLoading: boolean;
  setIsLocalLoading: Action<this, this["isLocalLoading"]>;

  elementIdToScroll: string | null;
  setElementIdToScroll: Action<this, this["elementIdToScroll"]>;

  clearFormsAction: Action<this>;
  clearFormsInternalActionOnNotUse: ActionOn<this>;

  // global transactions
  transactions: GetTransactionsDTO;
  setTransactions: Action<this, this["transactions"]>;
  transactionIdToDelete: number | null;
  setTransactionIdToDelete: Action<this, this["transactionIdToDelete"]>;
  confirmedTransactionToRollback: Transaction | null;
  setConfirmedTransactionToRollback: Action<this, this["confirmedTransactionToRollback"]>;
  getTransactionsRequestThunk: Thunk<this, number>;
  deleteTransactionThunk: Thunk<this, number>;
  rollbackTransactionThunk: Thunk<this, { companyId: number; id: number }>;

  isManageDocumentsFormOpen: boolean;
  setIsManageDocumentsFormOpen: Action<this, this["isManageDocumentsFormOpen"]>;

  isConfirmModalOpen: boolean;
  setIsConfirmModalOpen: Action<this, this["isConfirmModalOpen"]>;

  // adding new transaction modal
  isAddTransactionModalOpen: boolean;
  setIsAddTransactionModalOpen: Action<this, this["isAddTransactionModalOpen"]>;

  // selected transaction with Transaction type
  // note: use this prop and action to set transaction current transaction in different places
  // it might be transaction to edit, transaction to manage documents or some other cases
  selectedTransaction: Transaction | null;
  setSelectedTransaction: Action<this, this["selectedTransaction"]>;

  // buy/sell transaction
  isBuySellFormOpen: boolean;
  setIsBuySellFormOpen: Action<this, this["isBuySellFormOpen"]>;
  buySellTransactionDetails: BuySellGetResponseDTO | null;
  setBuySellTransactionDetails: Action<this, this["buySellTransactionDetails"]>;
  checkBuySellTransactionThunk: Thunk<this, BuySellCheckDTO>;
  getBuySellTransactionDetailsThunk: Thunk<this, number>;
  postBuySellTransactionThunk: Thunk<this, FormData>;
  editBuySellTransactionThunk: Thunk<this, FormData>;

  // capital increase transaction
  capitalIncreaseDetails: { [key: string]: CapitalIncreaseGetDTO } | null;
  setCapitalIncreaseDetails: Action<this, this["capitalIncreaseDetails"]>;
  capitalIncreaseIdToDelete: number | null;
  setCapitalIncreaseIdToDelete: Action<this, this["capitalIncreaseIdToDelete"]>;
  getCapitalIncreaseThunk: Thunk<this, number>;
  getCapitalIncreaseGeneralThunk: Thunk<this, number>;
  postCapitalIncreaseGeneralThunk: Thunk<this, any>;
  checkCapitalIncreaseThunk: Thunk<this, CapitalIncreaseTransactionsPostDTO>;
  postCapitalIncreaseThunk: Thunk<this, CapitalIncreaseTransactionsPostDTO>;
  postConfirmCapitalIncreaseThunk: Thunk<this, ConfirmCapitalIncreaseTransactionPostDTO>;
  deleteCapitalIncreaseThunk: Thunk<this, number>;
  rollbackCapitalIncreaseThunk: Thunk<this, { id: number; companyId: number }>;

  // issue shares transaction
  issueSharesTransaction: IssueSharesGetResponseDTO | null;
  setIssueSharesTransaction: Action<this, this["issueSharesTransaction"]>;
  isIssueSharesFormOpen: boolean;
  setIsIssueSharesFormOpen: Action<this, this["isIssueSharesFormOpen"]>;
  checkIssueSharesTransactionThunk: Thunk<this, IssueSharesCheckDTO>;
  getIssueSharesTransactionThunk: Thunk<this, number>;
  postIssueSharesTransactionThunk: Thunk<this, FormData>;
  postConfirmIssueSharesTransactionThunk: Thunk<this, ConfirmIssueSharesTransactionPostDTO>;
  editPendingIssueSharesTransactionThunk: Thunk<this, FormData>;
  editConfirmedIssueSharesTransactionThunk: Thunk<this, FormData>;

  // nominal value transaction
  isNominalValueFormOpen: boolean;
  setIsNominalValueFormOpen: Action<this, this["isNominalValueFormOpen"]>;
  isNominalValueDataUpdated: boolean;
  setIsNominalValueDataUpdated: Action<this, this["isNominalValueDataUpdated"]>;
  nominalValueDetails: NominalValueGetResponseDTO | null;
  setNominalValueDetails: Action<this, this["nominalValueDetails"]>;
  getNominalValueDetailsThunk: Thunk<this, number>;
  postConfirmNominalValueThunk: Thunk<this, ConfirmNominalValuePostDTO>;
  postChangeNominalValueThunk: Thunk<this, FormData>;

  // split transaction
  splitTransaction: SplitGetResponseDTO | null;
  setSplitTransaction: Action<this, this["splitTransaction"]>;
  isSplitFormOpen: boolean;
  setIsSplitFormOpen: Action<this, this["isSplitFormOpen"]>;
  getSplitPreviewThunk: Thunk<this, { companyId: string; multiplier: number; date: string }>;
  getSplitSnapshotThunk: Thunk<this, number>;
  getSplitTransactionThunk: Thunk<this, number>;
  postSplitTransactionThunk: Thunk<this, FormData>;
  postConfirmSplitTransactionThunk: Thunk<this, ConfirmSplitTransactionPostDTO>;

  // import transactions with Excel
  importFile: FilesDataSingle | null;
  deleteExistingTransactions: boolean;
  currentWarnings: string[];
  currentErrors: string[];
  captablePreview: CapTableChange[];
  transactionsPreview: NewTransaction[];
  setImportFile: Action<this, this["importFile"]>;
  setDeleteExistingTransactions: Action<this, this["deleteExistingTransactions"]>;
  setCurrentWarnings: Action<this, this["currentWarnings"]>;
  setCurrentErrors: Action<this, this["currentErrors"]>;
  setCaptablePreview: Action<this, this["captablePreview"]>;
  setTransactionsPreview: Action<this, this["transactionsPreview"]>;
  postImportTransactionsExcel: Thunk<this, ImportTransactionsExcelPostBodyDTO>;
  postImportCaptableExcel: Thunk<this, ImportCaptableExcelPostBodyDTO>;
  postConfirmImportTransaction: Thunk<this, ImportTransactionsExcelPostBodyDTO>;
  postConfirmImportCaptable: Thunk<this, ImportCaptableExcelPostBodyDTO>;
}
