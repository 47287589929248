import { FC, useCallback, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import CreateBuySellTransactionForm from "./create-buy-sell-transaction-form";
import EditBuySellTransactionForm from "./edit-buy-sell-transaction-form";
import { EditBuySellFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const BuySellContainer: FC<PropsTypes> = ({ onSubmit, onClose, isDisabled }) => {
  const formRef = useRef<HTMLDivElement>(null);

  const buySellTransactionDetails = TransactionsContext.useStoreState((state) => state.buySellTransactionDetails);
  const isBuySellFormOpen = TransactionsContext.useStoreState((state) => state.isBuySellFormOpen);
  const { postBuySellTransactionThunk, editBuySellTransactionThunk } = TransactionsContext.useStoreActions(
    (actions) => actions
  );

  const handleSubmitCreateBuySellForm = useCallback(
    async (transaction: FormData, restartForm?: boolean) => {
      const createdSuccessfully = await postBuySellTransactionThunk(transaction);

      if (createdSuccessfully) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(0), restartForm);
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postBuySellTransactionThunk]
  );

  const handleSubmitEditBuySellForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully = await editBuySellTransactionThunk(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");
        if (buySellTransactionDetails?.transactionBundleId) {
          onSubmit(String(buySellTransactionDetails.transactionBundleId));
        }
        onSubmit(String(transaction.get(EditBuySellFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [buySellTransactionDetails?.transactionBundleId, editBuySellTransactionThunk, onSubmit]
  );

  return (
    <>
      <SlidePanel show={isBuySellFormOpen} customRef={formRef}>
        <CreateBuySellTransactionForm
          onSubmit={handleSubmitCreateBuySellForm}
          onClose={onClose}
          formRef={formRef.current}
          isDisabled={isDisabled}
        />
      </SlidePanel>

      <SlidePanel show={!!buySellTransactionDetails}>
        <AnimatePresence>
          {!!buySellTransactionDetails && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <EditBuySellTransactionForm
                onSubmit={handleSubmitEditBuySellForm}
                onClose={onClose}
                transaction={buySellTransactionDetails}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </SlidePanel>
    </>
  );
};

export default BuySellContainer;
