import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { Button, ModalInfo } from "common/components/atoms";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

export type DeleteRoundModalProps = {
  roundName?: string;
  fundraiseRoundId: number;
  fundraiseId: number;
  show: boolean;
  onHide: () => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.modals.deleteRound");

const DeleteRoundModal: FC<DeleteRoundModalProps> = (props) => {
  const { roundName, show, fundraiseId } = props;
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const [updateFundraise, setUpdateFundraise] = useState(false);

  const handleOnExited = useCallback(() => {
    if (updateFundraise) {
      getFundraiseSummaryThunk(fundraiseId);
      setUpdateFundraise(false);
    }
  }, [updateFundraise, getFundraiseSummaryThunk, fundraiseId]);

  return (
    <ModalInfo onExited={handleOnExited} size="sm" show={show} header={t("title", { roundName })}>
      <RoundModalForm setUpdateFundraise={setUpdateFundraise} {...props} />
    </ModalInfo>
  );
};

type DeleteRoundModalContent = DeleteRoundModalProps & {
  setUpdateFundraise: Dispatch<SetStateAction<boolean>>;
};
const RoundModalForm: FC<DeleteRoundModalContent> = ({ onHide, setUpdateFundraise, fundraiseRoundId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { deleteRoundThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleRemoveInstrument = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await deleteRoundThunk(fundraiseRoundId);
      setUpdateFundraise(true);
      onHide();
    } finally {
      setIsSubmitting(false);
    }
  }, [deleteRoundThunk, fundraiseRoundId, setUpdateFundraise, onHide]);
  return (
    <div style={{ width: 275, margin: "0 auto" }}>
      <div>{t("description")}</div>
      <div className="mt-5">
        <Button
          onClick={handleRemoveInstrument}
          type="submit"
          variant="danger"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          {t("delete")}
        </Button>
        <Button isDisabled={isSubmitting} onClick={onHide} className="ms-2" variant="secondary">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};
export default DeleteRoundModal;
