import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { H, P } from "common/components/atoms";
import { CloseIcon, UnlistedIcon } from "common/icons/svg";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./UpdateValuation.module.scss";
import UpdateValuationFormik from "./UpdateValuationFormik";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.valuation");

const UpdateValuation = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const setIsWizardLayoutAction = useStoreActions((actions) => actions.app.setIsWizardLayout);

  useEffect(() => {
    setIsWizardLayoutAction(true);
    return () => {
      setIsWizardLayoutAction(false);
    };
  }, [setIsWizardLayoutAction]);

  const handleSkip = useCallback(() => {
    navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId }));
  }, [companyId, navigate]);

  return (
    <div className={classes.wrap}>
      <div className="p-5 d-flex justify-content-between">
        <UnlistedIcon fontSize={28} />
        <CloseIcon className={classes.close} fontSize={32} onClick={handleSkip} />
      </div>
      <div className={classes.container}>
        <H.xs color="foregroundHigh">{t("title")}</H.xs>
        <P.m color="foregroundMedium" className="mt-3">
          {t("description")}
        </P.m>
        <UpdateValuationFormik />
      </div>
    </div>
  );
};

export default UpdateValuation;
