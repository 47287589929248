import { useCallback } from "react";
import { useFormikContext } from "formik";

import { ToggleButton } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.loanPanel");

const AutomaticOrManualConversionField = () => {
  const { values, setFieldValue } = useFormikContext<ConversionFormValues>();

  const handleToggle = useCallback(
    (value: string | number) => {
      setFieldValue(fields.useManualConversion, value === "manual");
    },
    [setFieldValue]
  );

  const activeConversion = values.useManualConversion ? "manual" : "automatic";

  return (
    <ToggleButton
      firstVariant={{
        title: t("automatic"),
        value: "automatic",
      }}
      secondVariant={{
        title: t("manual"),
        value: "manual",
      }}
      selected={activeConversion}
      onClick={handleToggle}
    />
  );
};

export default AutomaticOrManualConversionField;
