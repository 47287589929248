import { FC, useCallback, useMemo } from "react";
import cn from "classnames";

import { Button, H, ModalInfo, Ui } from "common/components/atoms";
import GuideElementsIds from "common/guides/guide-configs/guide-elements-ids";
import useGuideAddNominalValue3 from "common/guides/guide-hooks/add-nominal-value/use-guide-add-nominal-value-3";
import {
  ArrowUpIcon,
  CloseIcon,
  CommonFileAddIcon,
  SplitIcon,
  SynchronizeArrowsSquareIcon,
  TotalValueIcon,
} from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../transactions.context";
import AddTransactionItem from "./add-transaction-item";
import classes from "./add-transaction-modal.module.scss";

type AddTransactionModalProps = {
  hasPendingSplitTransaction: boolean;
  handleOpenCapitalIncrease: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.addTransaction");

const AddTransactionModal: FC<AddTransactionModalProps> = ({ handleOpenCapitalIncrease }) => {
  const { companyHasShares } = TransactionsContext.useStoreState((state) => state.transactions);
  const isAddTransactionModalOpen = TransactionsContext.useStoreState((state) => state.isAddTransactionModalOpen);

  const {
    setIsNominalValueFormOpen,
    setIsAddTransactionModalOpen,
    setIsIssueSharesFormOpen,
    setIsBuySellFormOpen,
    setIsSplitFormOpen,
  } = TransactionsContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setIsAddTransactionModalOpen(!isAddTransactionModalOpen);
  }, [isAddTransactionModalOpen, setIsAddTransactionModalOpen]);

  const suggestedItems = useMemo(() => {
    return [
      {
        title: t("menu.issueSharesSingle.title"),
        description: [t("menu.issueSharesSingle.description_1")],
        icon: <CommonFileAddIcon />,
        onClick: () => {
          setIsIssueSharesFormOpen(true);
          handleClose();
        },
      },
      {
        title: t("menu.issueSharesMultiple.title"),
        description: [t("menu.issueSharesMultiple.description_1")],
        icon: <ArrowUpIcon />,
        isDisabled: false,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipPendingSpit"),
        onClick: handleOpenCapitalIncrease,
      },
      {
        title: t("menu.buySell.title"),
        description: [t("menu.buySell.description_1")],
        icon: <SynchronizeArrowsSquareIcon />,
        isDisabled: !companyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsBuySellFormOpen(true);
          handleClose();
        },
      },
    ];
  }, [handleOpenCapitalIncrease, companyHasShares, handleClose, setIsBuySellFormOpen, setIsIssueSharesFormOpen]);

  const otherItems = useMemo(() => {
    return [
      {
        id: GuideElementsIds.ChangeNominalValueBtn,
        title: t("menu.chaneNominalValue.title"),
        description: [t("menu.chaneNominalValue.description_1")],
        icon: <TotalValueIcon />,
        isDisabled: !companyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsNominalValueFormOpen(true);
          handleClose();
        },
      },
      {
        title: t("menu.split.title"),
        description: [t("menu.split.description_1")],
        icon: <SplitIcon />,
        isDisabled: !companyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsSplitFormOpen(true);
          handleClose();
        },
      },
    ];
  }, [companyHasShares, handleClose, setIsNominalValueFormOpen, setIsSplitFormOpen]);

  const Header = useCallback(() => {
    return (
      <H.m className="d-flex justify-content-between mb-5 align-items-center">
        <span>{t("modalTitle")}</span>
        <Button size="s" isOnlyIcon variant="dark" onClick={handleClose}>
          <CloseIcon />
        </Button>
      </H.m>
    );
  }, [handleClose]);

  useGuideAddNominalValue3(isAddTransactionModalOpen);

  return (
    <ModalInfo size="lg" show={isAddTransactionModalOpen} onHide={handleClose}>
      <div className="pt-1 ps-2 pe-2">
        <Header />
        <Ui.m className={classes.category}>{t("suggested")}</Ui.m>
        {suggestedItems.map((item, index) => (
          <AddTransactionItem
            key={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
            onClick={item.onClick}
            isDisabled={item.isDisabled}
            tooltipTitle={item.tooltipTitle}
            tooltipDescription={item.tooltipDescription}
          />
        ))}
        <Ui.m className={cn(classes.category, "mt-4")}>{t("other")}</Ui.m>
        {otherItems.map((item, index) => (
          <AddTransactionItem
            id={item.id}
            key={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
            onClick={item.onClick}
            isDisabled={item.isDisabled}
            tooltipTitle={item.tooltipTitle}
            tooltipDescription={item.tooltipDescription}
          />
        ))}
      </div>
    </ModalInfo>
  );
};

export default AddTransactionModal;
