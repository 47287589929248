import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { InstrumentTypesIdsEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { useOneOffPlanValidation } from "common/plan/planValidationSchema";
import usePlanInitialValues from "common/plan/usePlanInitialValues";
import { notify } from "common/utils/notify/notifyFunction";
import OneOffRSA from "pages/equity-management/plans/wizards/create-one-off-plans/steps/SharesDetails/OneOffRSA/OneOffRSA";
import { PlanForm } from "pages/equity-management/plans/wizards/create-one-off-plans/types";
import { PlanGetDTO, PlanPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import VestingPlanDetails from "../../create-plan/steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../create-plan/steps/basics/sidebar/forms/plan-form";
import ShareDetailsWrapper from "../components/share-details-wrapper/share-details-wrapper";
import { OwnershipPlanForm } from "../CreateGrantOneOffPlan";
import Footer from "./footer/footer";

type PropsType = {
  setIsSidebarOpen: (isOpen: boolean) => void;
  planToEdit: PlanGetDTO | null;
  onSave?: () => void;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const OneOffRsaPlan: FC<PropsType> = ({ setIsSidebarOpen, planToEdit, onSave = () => undefined }) => {
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const isPostPlanLoading = useStoreState((state) => state.planModel.isPostPlanLoading);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);
  const { setOneOffPlanRSAThunk } = useStoreActions((actions) => actions.planModel);

  useEffect(() => {
    if (planToEdit && planToEdit.programId) {
      getProgramThunk(planToEdit.programId).then();
    }
  }, [getProgramThunk, planToEdit]);

  const initialValues = usePlanInitialValues(companyId, planToEdit);

  const onSubmit = useCallback(
    async (values: OwnershipPlanForm, helpers: FormikHelpers<OwnershipPlanForm>) => {
      const createPlanDTO: PlanPostDTO = {
        ...values,
        countryId: values?.countryId || null,
        file: values?.fileAgreement?.newFile,
        oldFileId: values?.fileAgreement?.oldFile?.fileId,
      };
      const res = await setOneOffPlanRSAThunk(createPlanDTO);

      if (res?.status) {
        helpers.resetForm();
        helpers.setSubmitting(false);
        onSave?.();
        notify(t("notificationSuccess"), true, "success");
        setIsSidebarOpen(false);
      }
    },
    [onSave, setIsSidebarOpen, setOneOffPlanRSAThunk]
  );

  const validation = useOneOffPlanValidation();

  const validationSchema = useMemo(() => {
    return validation.omit([
      f.retirement,
      f.disability,
      f.terminationWithCause,
      f.involuntaryTermination,
      f.voluntaryTermination,
      f.byPassingAway,
    ]);
  }, [validation]);

  return (
    <div data-testid="new-rsa-plan-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <FormData handleClose={setIsSidebarOpen} isLoading={isPostPlanLoading} planStatus={planToEdit?.statusId} />
      </Formik>
    </div>
  );
};

type FormDataProps = {
  handleClose: (isOpen: boolean) => void;
  isLoading: boolean;
  planStatus?: PlanStatusesBasedOnAPIStatusId;
};
const FormData: FC<FormDataProps> = ({ isLoading, planStatus, handleClose }) => {
  const { handleSubmit, setFieldValue, values } = useFormikContext<PlanForm>();

  const handleEditPlan = useCallback(() => {
    setFieldValue(f.saveAsDraft, true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleGrantPlanAction = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(
    () => (values.sendEmailInvitation ? { email: { isTopField: true, meta: { isOptional: false } } } : undefined),
    [values.sendEmailInvitation]
  );

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <SlidePanel.Section title={t("receiverBasics")}>
          <StakeholderSection updatedFields={updatedFields} />
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="shareDetailsCollapsible" title={t("shareDetails")}>
          <ShareDetailsWrapper>
            <OneOffRSA />
          </ShareDetailsWrapper>
        </SlidePanel.Section>

        <SlidePanel.Section
          data-testid="vestingDetailsCollapsible"
          title={t("vestingDetails")}
          isDividerVisible={false}
        >
          <VestingPlanDetails />
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} data-testid="exerciseDocumentCollapsible" title={t("document")}>
          <GeneratePlanDocument
            autoGenerateAgreement={false}
            managerSignature={!values.existingPlan}
            planFormType={PlanFormType.OneOffRSAPlan}
          />
        </SlidePanel.Section>

        <Footer
          handleClose={handleClose}
          handleGrantPlan={planStatus === PlanStatusesBasedOnAPIStatusId.draft ? handleGrantPlanAction : undefined}
          handleEdit={handleEditPlan}
          isSubmitting={isLoading}
        />
      </Helper>
    </Form>
  );
};

export default OneOffRsaPlan;
