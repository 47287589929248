import axios from "axios";
import { action, persist, thunk } from "easy-peasy";

import { PostCompanyResponseBody } from "../pages/onboard/company/company-information/types";
import {
  BreggCompanyGetDTO,
  BreggSearchCompanyDTO,
  CompanyOnboardingModel,
  GetCompanyOnboardingResultDTO,
  RegularIntegration,
  UniMicroCompanyGetDTO,
} from "./modelTypes";

export const companyOnboardingModel: CompanyOnboardingModel = {
  company: null,
  isGetCompanyLoading: false,
  isPostCompanyLoading: false,
  aroOnboarding: persist(
    {
      isActive: false,
    },
    {
      storage: "localStorage",
    }
  ),
  lastSavedAt: "",
  integrations: persist({
    bregg: null,
    uniMicro: null,
    regular: null,
    aro: null,
  }),
  companyOnboardingResult: null,
  //actions
  setCompany: action((state, company) => {
    state.company = company;
  }),
  setGetCompanyLoading: action((state, payload) => {
    state.isGetCompanyLoading = payload;
  }),
  setPostCompanyLoading: action((state, payload) => {
    state.isPostCompanyLoading = payload;
  }),
  setIntegration: action((state, payload) => {
    switch (payload.type) {
      case "bregg":
        state.integrations.bregg = payload.data as BreggCompanyGetDTO;
        break;
      case "uniMicro":
        state.integrations.uniMicro = payload.data as UniMicroCompanyGetDTO;
        break;
      case "regular":
        state.integrations.regular = payload.data as RegularIntegration;
        break;
      case "all":
        state.integrations.bregg = null;
        state.integrations.uniMicro = null;
        state.integrations.regular = null;
        break;
    }
  }),
  setLastSavedAt: action((state, payload) => {
    state.lastSavedAt = payload;
  }),
  setAroOnboarding: action((state, payload) => {
    state.aroOnboarding = payload;
  }),
  setCompanyOnboardingResult: action((state, payload) => {
    state.companyOnboardingResult = payload;
  }),
  // thunks
  getCompanyThunk: thunk((_, companyId, { fail }) => {
    return axios.get(`/api/onboarding/company/${companyId}`).catch(fail);
  }),
  searchBrregCompanyThunk: thunk(async (_, searchString) => {
    return await axios.post<BreggSearchCompanyDTO>("/api/onboarding/company/search", { searchString });
  }),
  getBreggCompanyInfoThunk: thunk(async (actions, payload) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.get<BreggCompanyGetDTO>(`/api/onboarding/company/brreg/${payload}`);

      actions.setIntegration({ type: "bregg", data: req.data });

      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  getUniMicroCompanyInfoThunk: thunk(async (actions, { iss, accessCode }) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.post<UniMicroCompanyGetDTO>("/api/onboarding/company/unimicro", { accessCode, iss });

      actions.setIntegration({ type: "uniMicro", data: req.data });

      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  postNorwegianCompanyThunk: thunk(async (actions, companyDTO) => {
    actions.setGetCompanyLoading(true);
    try {
      const response = await axios.post<PostCompanyResponseBody>("api/onboarding/company/norwegian", companyDTO);

      if (response.data.companyId) {
        return response.data.companyId;
      }
    } catch (err) {
      console.log(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  postForeignCompanyThunk: thunk(async (actions, companyDTO) => {
    actions.setGetCompanyLoading(true);
    try {
      const response = await axios.post<PostCompanyResponseBody>("api/onboarding/company/foreign", companyDTO);

      if (response.data.companyId) {
        return response.data.companyId;
      }
    } catch (err) {
      console.log(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  getCompanyOnboardingResultThunk: thunk(async (actions, companyId) => {
    try {
      const response = await axios.get<GetCompanyOnboardingResultDTO>(
        `api/onboarding/company/results-popup/${companyId}`
      );

      if (response.data) {
        actions.setCompanyOnboardingResult(response.data);
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  }),
  // actionsOn
};
