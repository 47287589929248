import { ChangeEventHandler, FC, useCallback } from "react";
import { useFormikContext } from "formik";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");
const InterestPaidInCashField: FC<Props> = ({ className }) => {
  const { values, handleBlur, setFieldValue } = useFormikContext<ConversionFormValues>();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { name, checked } = e.target;
      setFieldValue(name, checked).then();
    },
    [setFieldValue]
  );

  return (
    <div className={className}>
      <ChecksGroup.Check
        isNoFocus
        type="checkbox"
        name={fields.interestPaidInCash}
        label={t("interestPaidInCash.label")}
        checked={Boolean(values.interestPaidInCash)}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default InterestPaidInCashField;
