import React, { FC, useCallback } from "react";
import { Col } from "react-bootstrap";
import axios from "axios";
import classNames from "classnames";
import { differenceInCalendarDays, format } from "date-fns";
import { defaultTo } from "ramda";

import SubscriptionManagerInfo from "common/access-control/no-subscription/SubscriptionManagerInfo";
import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, P, Tag, Ui } from "common/components/atoms";
import { StripeSubscriptionTypes } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { ChevronRightIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../CompanyInformation.module.scss";

const t = createTranslation(TranslationNS.pages, "companyProfile.general.planAndBilling");

type enumSubscriptionKeys = keyof typeof StripeSubscriptionTypes;

type Props = {
  hideTitle?: boolean;
};
const companyFeatures = [CompanyFeatures.PlanAndBills];

const CompanyBillingDetails: FC<Props> = ({ hideTitle }) => {
  const { hasFullAccess } = useFeatures(FEATURES.companyInformation, companyFeatures);

  const fn = useFormatNumbers();
  const { subscription } = useStripeSubscription();

  const updateStripePaymentDetails = useCallback(async () => {
    try {
      const currentUrl = window.location.href;

      // triggering API side session initialization to provide stripe customer portal for the user
      const billingRequest = await axios.post<{ sessionUrl: string }>("/api/stripe/billing-url", {
        customerId: subscription?.customerId || "",
        returnUrl: currentUrl,
      });

      if (billingRequest.status === 200 && billingRequest.data.sessionUrl) {
        const link = document.createElement("a");

        link.href = billingRequest.data.sessionUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.click();
      }
    } catch (e) {
      console.error({ e });
    }
  }, [subscription?.customerId]);

  const startPlanTitle: enumSubscriptionKeys = "Start";
  const growthPlanTitle: enumSubscriptionKeys = "Growth";

  const isOutdatedSubscription =
    differenceInCalendarDays(
      new Date(),
      subscription?.currentPeriodEnd ? new Date(subscription?.currentPeriodEnd || "") : new Date()
    ) >= 0;

  return (
    <div className={classes.wrap}>
      {!hideTitle && (
        <>
          <Ui.xl bold className="mb-2">
            {t("title")}
          </Ui.xl>

          <P.m className="mb-5">{t("managePlan")}</P.m>
        </>
      )}

      <div className={classNames(classes["section-head"], "d-flex justify-content-between")}>
        <Ui.xs color="foregroundLow">{t("currentPlan")}</Ui.xs>
        <Ui.xs color="foregroundLow">{t("price")}</Ui.xs>
      </div>

      <div className="fw-500 d-flex align-items-center justify-content-between my-2">
        <div className="d-flex align-items-center">
          <Ui.l>
            {subscription?.subscriptionType === StripeSubscriptionTypes.Start && startPlanTitle}

            {subscription?.subscriptionType === StripeSubscriptionTypes.Growth && growthPlanTitle}
          </Ui.l>

          <Tag variant={isOutdatedSubscription ? "closed" : "complete"} className="ms-1">
            {isOutdatedSubscription ? "Inactive" : "Active"}
          </Tag>
        </div>

        <Ui.l>
          {subscription?.currency?.toUpperCase()}{" "}
          {fn(defaultTo(0, subscription?.currentPrice), "value", {
            hideCurrencySymbol: true,
          })}
          /{subscription?.interval}
        </Ui.l>
      </div>

      <div className="d-flex flex-wrap">
        {/*{!isNil(productDetails) &&*/}
        {/*!isNil(productDetails?.marketing_features) &&*/}
        {/*!isEmpty(productDetails?.marketing_features)*/}
        {/*  ? productDetails?.marketing_features?.map((el, i, array) => (*/}
        {/*      <Ui.m key={i} color="foregroundMedium">*/}
        {/*        {el.name}*/}
        {/*        {i !== array.length - 1 ? <>&nbsp;+&nbsp;</> : null}*/}
        {/*      </Ui.m>*/}
        {/*    ))*/}
        {/*  : null}*/}
      </div>

      <Ui.m color="foregroundMedium" className="d-flex">
        {t.el("amountOfStakeholders", {
          components: [<Ui.m key="1" color="foregroundHigh" className="ms-1" />],
          values: {
            amount:
              subscription?.subscriptionType === StripeSubscriptionTypes.Start
                ? t("notAvailable")
                : `Max ${subscription?.quantity}`,
          },
        })}
      </Ui.m>

      <Ui.xs color={subscription?.isCanceled ? "critical500" : "foregroundMedium"} className="mt-2 mb-5">
        {subscription?.isCanceled
          ? isOutdatedSubscription
            ? t("wasCanceled", {
                date: subscription?.currentPeriodEnd
                  ? format(new Date(subscription?.currentPeriodEnd || ""), "MMMM dd, yyyy")
                  : new Date(),
              })
            : t("willBeCanceled", {
                date: subscription?.currentPeriodEnd
                  ? format(new Date(subscription?.currentPeriodEnd || ""), "MMMM dd, yyyy")
                  : new Date(),
              })
          : t("renewalDate", {
              date: subscription?.currentPeriodEnd
                ? format(new Date(subscription?.currentPeriodEnd || ""), "MMMM dd, yyyy")
                : new Date(),
            })}
      </Ui.xs>

      {hasFullAccess ? (
        <Button size="s" variant="secondary" iconRight={<ChevronRightIcon />} onClick={updateStripePaymentDetails}>
          {t("manage")}
        </Button>
      ) : (
        <Col xs={6}>
          <Ui.xs className="mb-2" bold>
            Account owner:
          </Ui.xs>
          <SubscriptionManagerInfo
            buyerLastName={subscription?.buyerLastName}
            buyerInitials={subscription?.buyerInitials}
            buyerEmail={subscription?.buyerEmail}
            buyerFirstName={subscription?.buyerFirstName}
            buyerAvatarFilePath={subscription?.buyerAvatarFilePath}
          />
        </Col>
      )}
    </div>
  );
};

export default CompanyBillingDetails;
