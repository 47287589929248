import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { Button, ChecksGroup, DatePicker, ModalInfo, P, Tag, TextArea, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, ArrowUpIcon, CalendarIcon, CloseIcon, TimeIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../transactions.context";
import {
  ConfirmCapitalIncreaseTransactionPostDTO,
  ConfirmIssueSharesTransactionPostDTO,
  ConfirmNominalValuePostDTO,
  ConfirmSplitTransactionPostDTO,
  Transaction,
  TransactionCategory,
  TransactionCategoryIds,
  TransactionSharesType,
} from "../../types";
import formatTransactionDate from "../format-transaction-date";
import { TransactionDetailsFields } from "../forms/form-sections/transaction-details/form-fields";
import bundleClasses from "../transactions-list/transaction-item/transaction-headers/capital-inscrease-header/capital-increase-header.module.scss";
import classes from "./confirm-transaction-modal.module.scss";

type PropsTypes = {
  onClose: () => void;
};

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.confirmTransactionModal"),
  createTranslation(TranslationNS.validation),
];

const ConfirmTransactionModal: FC<PropsTypes> = ({ onClose }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>();
  const [transactedAt, setTransactedAt] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();

  // not showing warning block initially and variable to close warning section
  const [isBundleTransactionsMatch, setIsBundleTransactionsMatch] = useState<boolean>(true);
  const [isBundleWarningClose, setIsBundleWarningClose] = useState<boolean>(false);

  const isLoading = TransactionsContext.useStoreState((state) => state.isLocalLoading);
  const { transactedAtMax } = TransactionsContext.useStoreState((state) => state.transactions);
  const transaction = TransactionsContext.useStoreState((state) => state.selectedTransaction);
  const isConfirmModalOpen = TransactionsContext.useStoreState((state) => state.isConfirmModalOpen);
  const capitalIncreaseList = TransactionsContext.useStoreState((state) => state.capitalIncreaseDetails);
  const {
    postConfirmIssueSharesTransactionThunk,
    postConfirmSplitTransactionThunk,
    postConfirmNominalValueThunk,
    postConfirmCapitalIncreaseThunk,
    getCapitalIncreaseThunk,
    getTransactionsRequestThunk,
    setElementIdToScroll,
  } = TransactionsContext.useStoreActions((actions) => actions);

  const capitalIncreaseDetails = useMemo(
    () => capitalIncreaseList?.[String(Math.abs(transaction?.transactionId || 0))],
    [capitalIncreaseList, transaction?.transactionId]
  );

  const fNumber = useFormatNumbers(transaction?.currencySymbol);

  const handleSubmitConfirmTransaction = useCallback(
    async (
      confirmTransactionData: ConfirmIssueSharesTransactionPostDTO | ConfirmSplitTransactionPostDTO,
      transactionCategory: TransactionCategoryIds
    ) => {
      let confirmationSuccess;

      if (transactionCategory === TransactionCategory.Issue) {
        confirmationSuccess = await postConfirmIssueSharesTransactionThunk(
          confirmTransactionData as ConfirmIssueSharesTransactionPostDTO
        );
      }

      if (transactionCategory === TransactionCategory.Split) {
        confirmationSuccess = await postConfirmSplitTransactionThunk(
          confirmTransactionData as ConfirmSplitTransactionPostDTO
        );
      }

      if (transactionCategory === TransactionCategory.ChangeNominalValue) {
        confirmationSuccess = await postConfirmNominalValueThunk({
          transactionId: confirmTransactionData.transactionId,
          transactionDate: confirmTransactionData.transactedAt,
          description: confirmTransactionData.description,
        } as ConfirmNominalValuePostDTO);
      }

      if (transactionCategory === TransactionCategory.CapitalIncrease) {
        confirmationSuccess = await postConfirmCapitalIncreaseThunk({
          transactionBundleId: confirmTransactionData.transactionId,
          transactedAt: confirmTransactionData.transactedAt,
          description: confirmTransactionData.description,
        } as ConfirmCapitalIncreaseTransactionPostDTO);
      }

      if (confirmationSuccess) {
        notify(t("successTransactionConfirmation"), true, "success", 5000, false, "top-center");
        await getTransactionsRequestThunk(Number(companyId));

        onClose();

        //@ts-ignore
        setElementIdToScroll(confirmTransactionData.transactionBundleId);
        return true;
      }

      return false;
    },
    [
      companyId,
      getTransactionsRequestThunk,
      onClose,
      postConfirmCapitalIncreaseThunk,
      postConfirmIssueSharesTransactionThunk,
      postConfirmNominalValueThunk,
      postConfirmSplitTransactionThunk,
      setElementIdToScroll,
    ]
  );

  const { isBundle, isSplit, isNominalValue, isIssueOrBuySell } = useMemo(() => {
    return {
      isIssueOrBuySell:
        transaction?.categoryId === TransactionCategory.Issue || transaction?.categoryId === TransactionCategory.Sell,
      isSplit: transaction?.categoryId === TransactionCategory.Split,
      isBundle: transaction?.categoryId === TransactionCategory.CapitalIncrease,
      isNominalValue: transaction?.categoryId === TransactionCategory.ChangeNominalValue,
    };
  }, [transaction]);

  const minDateInfo = useMemo(() => {
    if (transactedAtMax && (isSplit || isBundle || isNominalValue)) {
      return t("minDateInfo", { minDate: transformDateToCommonDateFormat(transactedAtMax) });
    }
  }, [isBundle, isSplit, isNominalValue, transactedAtMax]);

  // get list of transactions for bundle transaction

  const getCapitalIncreaseRequest = useCallback(async () => {
    try {
      await getCapitalIncreaseThunk(Math.abs(transaction?.transactionId || 0));
    } catch (e) {
      console.error({ e });
    }
  }, [getCapitalIncreaseThunk, transaction?.transactionId]);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      // buySell confirmed by default
      if (isIssueOrBuySell) {
        await handleSubmitConfirmTransaction(
          {
            transactionId: transaction?.transactionId || 0,
            transactedAt: defaultTo("", transactedAt),
            description,
            transactionBundleId: transaction?.transactionBundleId ? -transaction?.transactionBundleId : undefined,
          },
          transaction?.categoryId || 1
        );
      }

      if (isSplit) {
        await handleSubmitConfirmTransaction(
          {
            transactionId: transaction?.transactionId || 0,
            transactedAt: defaultTo("", transactedAt),
            description,
            multiplier: transaction?.multiplier,
          },
          transaction?.categoryId || 1
        );
      }

      if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
        await handleSubmitConfirmTransaction(
          {
            transactionId: transaction?.transactionId,
            transactedAt: defaultTo("", transactedAt),
            description,
          },
          transaction?.categoryId
        );
      }

      if (isBundle && transaction?.transactionBundleId) {
        await handleSubmitConfirmTransaction(
          {
            transactionId: transaction?.transactionBundleId,
            transactedAt,
            description,
          },
          transaction?.categoryId
        );
      }
    },
    [
      description,
      handleSubmitConfirmTransaction,
      isBundle,
      isIssueOrBuySell,
      isSplit,
      transactedAt,
      transaction?.categoryId,
      transaction?.multiplier,
      transaction?.transactionBundleId,
      transaction?.transactionId,
    ]
  );

  useEffect(() => {
    if (isBundle) {
      getCapitalIncreaseRequest();
    }
  }, [getCapitalIncreaseRequest, isBundle]);

  useEffect(() => {
    if (!isNil(capitalIncreaseDetails) && isBundle) {
      const data = defaultTo([] as Transaction[], capitalIncreaseDetails?.transactions).reduce(
        (acc, curr) => acc + curr.numberOfShares,
        0
      );

      if (capitalIncreaseDetails?.totalIncrease !== data) {
        setIsBundleTransactionsMatch(false);
      }
    } else {
      setIsBundleTransactionsMatch(true);
    }
  }, [capitalIncreaseDetails, capitalIncreaseDetails?.totalIncrease, capitalIncreaseDetails?.transactions, isBundle]);

  useEffect(() => {
    if (!isNil(transaction)) {
      setTransactedAt(transaction?.transactedAt);
      setDescription(transaction?.description);
      setIsSubmitAllowed(transaction?.canEditConfirmed);
    }
  }, [transaction]);

  return (
    <ModalInfo
      show={isConfirmModalOpen}
      header={t(isBundle ? "capitalIncreaseConfirmTitle" : "title")}
      handleClose={isLoading ? undefined : onClose}
      dialogClassName={classes.modal}
    >
      <form onSubmit={handleSubmit} className="mt-3" style={{ textAlign: "start" }}>
        <div className="p-2 mb-3" style={{ backgroundColor: scssVariables.element2 }}>
          {isBundle && (
            <div className="d-flex mb-2">
              <Ui.m bold className="me-1">
                {transaction?.eventName}
              </Ui.m>
              <Ui.xs className={bundleClasses.sizeOfBundle}>{transaction?.numberOfBundledTransactions}</Ui.xs>
            </div>
          )}

          {isSplit && (
            <div className="d-flex">
              <Ui.s bold className="mb-1 me-half">
                {fNumber(transaction?.numberOfSharesBefore, "amount")}
              </Ui.s>
              <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
              <Ui.s bold className="ms-half">{`${fNumber(transaction?.numberOfShares, "amount")} ${t("shares")} • ${
                transaction?.categoryName
              } 1:${transaction?.multiplier}`}</Ui.s>
            </div>
          )}

          {isNominalValue && (
            <div className="d-flex">
              <Ui.s bold className="mb-1">
                {defaultTo(0, transaction?.sharePriceBefore) > defaultTo(0, transaction?.sharePrice)
                  ? "Nominal value decreased"
                  : "Nominal value increased"}
              </Ui.s>
            </div>
          )}

          {isIssueOrBuySell && (
            <Ui.s bold className="mb-1">{`${transaction?.numberOfShares} ${t(
              TransactionSharesType[transaction?.categoryId || 1]
            )} • ${transaction?.typeName}`}</Ui.s>
          )}

          <div className="d-flex align-items-center">
            <Tag variant="information" className="d-flex align-items-center me-3">
              {isNominalValue ? (
                <>
                  <TimeIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
                  <Ui.xs>{formatTransactionDate(defaultTo(new Date().toDateString(), transactedAt))}</Ui.xs>
                </>
              ) : (
                <>
                  <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
                  <Ui.xs>{formatTransactionDate(defaultTo(new Date().toDateString(), transactedAt))}</Ui.xs>
                </>
              )}
            </Tag>
            {isSplit && (
              <div className="d-flex">
                <Ui.xs className="me-half">
                  {`${fNumber(transaction?.sharePriceBefore, "sharePrice")}
                  /${t("shares")}`}
                </Ui.xs>

                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />

                <Ui.xs className="ms-half">{`${fNumber(transaction?.sharePrice, "sharePrice")}/${t("shares")}`}</Ui.xs>
              </div>
            )}
            {isIssueOrBuySell && (
              <div className="d-flex">
                <Ui.xs className="me-half">{transaction?.fromName || "..."}</Ui.xs>
                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
                <Ui.xs className="ms-half">{transaction?.toName || "..."}</Ui.xs>
              </div>
            )}
            {isNominalValue && (
              <div className="d-flex">
                <Ui.xs className="me-half">{fNumber(transaction?.sharePriceBefore, "sharePrice")}</Ui.xs>

                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />

                <Ui.xs className="ms-half">{fNumber(transaction?.sharePrice, "sharePrice")}</Ui.xs>
              </div>
            )}
            {isBundle && (
              <div className="d-flex">
                <ArrowUpIcon height={16} width={16} color={scssVariables.foregroundLow} />
                <Ui.xs className="ms-half">{fNumber(transaction?.numberOfShares, "amount") + " " + t("shares")}</Ui.xs>
              </div>
            )}
          </div>
        </div>

        <P.m className="mb-4">
          {isBundle
            ? t.el("explanationBundle", {
                components: [<strong key={1} />],
                values: { numberOfTransactions: transaction?.numberOfBundledTransactions },
              })
            : t("explanation")}
        </P.m>

        {!isBundleTransactionsMatch && !isBundleWarningClose ? (
          <div className={cn("p-2 d-flex mb-4", classes["shares-not-match"])}>
            <WarningIcon fontSize={24} color={scssVariables.warning900} className="me-2" />

            <div>
              <Ui.m bold className="mb-1">
                {t("sharesNotMatch")}
              </Ui.m>
              <P.s>{t("sharesNotMatchDescription")}</P.s>
            </div>

            <CloseIcon
              fontSize={24}
              className={classes["close-btn"]}
              color={scssVariables.foregroundMedium}
              onClick={() => {
                setIsBundleWarningClose(true);
              }}
            />
          </div>
        ) : null}

        <DatePicker
          // name={TransactionDetailsFields.transactedAt}
          name="transactionDate"
          label={t("transactionDate")}
          date={transactedAt}
          isDateOnlyString
          isWithTimeSelect
          onChange={(date) => {
            setTransactedAt((date ? date : undefined) as string);
          }}
          className="w-50 mb-4"
          minDate={isBundle ? undefined : transactedAtMax ? new Date(transactedAtMax) : undefined}
          info={minDateInfo}
          isTouched
          error={isNil(transactedAt) || isEmpty(transactedAt) ? tValidation("required") : undefined}
        />

        <TextArea
          isOptional
          label={t("description")}
          placeholder={t("descriptionPlaceholder")}
          name={TransactionDetailsFields.description}
          id={TransactionDetailsFields.description}
          onChange={(e) => setDescription(e.target.value)}
          value={description || ""}
          className="mb-4"
        />

        {!transaction?.canEditConfirmed && (
          <ChecksGroup className="mb-5">
            <ChecksGroup.Check
              customLabel={<P.m className="ms-1">{t("submitWarning")}</P.m>}
              type="checkbox"
              onChange={(e) => setIsSubmitAllowed(e.target.checked)}
              checked={isSubmitAllowed}
            />
          </ChecksGroup>
        )}

        <div className="d-flex justify-content-center">
          <Button
            type="submit"
            isLoading={isLoading}
            isDisabled={(!isNil(isSubmitAllowed) && !isSubmitAllowed) || isNil(transactedAt) || isEmpty(transactedAt)}
            className="me-2"
          >
            {t("confirmBtn")}
          </Button>
          <Button variant="secondary" isDisabled={isLoading} onClick={onClose}>
            {t("cancelBtn")}
          </Button>
        </div>
      </form>
    </ModalInfo>
  );
};

export default ConfirmTransactionModal;
