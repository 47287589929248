import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { fields, FundraisingFormValues } from "../useFundraisingSlidePanel";

type AmountToRiseFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising.fields");

const CompanyValuationField: FC<AmountToRiseFieldProps> = ({ className }) => {
  const fNumber = useFormatNumbers();
  const { companyId } = useParams();
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FundraisingFormValues>();
  const [currentValuation, setCurrentValuation] = useState(0);

  const { currencySymbol } = useCurrency();

  const info = t("companyValuation.current", { currentValuation: fNumber(currentValuation) });

  useEffect(() => {
    axios.get<{ latestValuation: number }>(`/api/company/valuation/${companyId}`).then((res) => {
      setCurrentValuation(res.data?.latestValuation || 0);
    });
  }, [companyId]);

  return (
    <Row className={className}>
      <Col>
        <Helper>
          <Helper.Question type="input" questionId={fields.preMoneyValuation}>
            <TextField
              name={fields.preMoneyValuation}
              label={t("companyValuation.label")}
              value={values.preMoneyValuation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.preMoneyValuation}
              isTouched={touched.preMoneyValuation}
              iconRight={currencySymbol}
              info={info}
              type="number"
            />
          </Helper.Question>
          <Helper.Answer
            className="mt-3"
            withRightMargin
            answerId={fields.preMoneyValuation}
            text={t("companyValuation.answer")}
          />
        </Helper>
      </Col>
    </Row>
  );
};
export default CompanyValuationField;
