import { FC } from "react";
import { useFormikContext } from "formik";

import { TextField } from "common/components/atoms";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CompanyInformationValues } from "../types";

const t = createTranslation(TranslationNS.pages, "onboard.company.companyInformation");

const ForeignImport: FC = () => {
  const currenciesDropdown = useStoreState((state) => state.common.dropdowns?.currencies);
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<CompanyInformationValues>();

  return (
    <>
      <Dropdown
        className="mb-3"
        isSearchable
        searchType="startsWith"
        searchPlaceholder="Currency"
        label={t("currency")}
        options={currenciesDropdown || []}
        optionsIsObject
        selectedValue={values?.currencyId}
        name="currencyId"
        onChange={handleChange}
        isTouched={touched.currencyId}
        error={errors.currencyId}
        onBlur={handleBlur}
      />
      <TextField
        className="mb-3"
        label={t("companyName")}
        value={values.companyName}
        isTouched={touched.companyName}
        error={errors.companyName}
        name="companyName"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        isOptional
        className="mb-3"
        label={t("orgNumber")}
        value={values.orgNumber}
        isTouched={touched.orgNumber}
        error={errors.orgNumber}
        name="orgNumber"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        className="mb-3"
        label={t("city")}
        value={values.city}
        isTouched={touched.city}
        error={errors.city}
        name="city"
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </>
  );
};

export default ForeignImport;
