import * as Yup from "yup";

import { createTranslation } from "translation";

import { CompanyInformationValues } from "./types";

export const NORWAY_ID = 166;
export const KRONER_ID = 1;

const t = createTranslation("validation");

const useCompanyInfoForm = () => {
  const userLanguage = navigator.language.split("-")[0];

  const initialValues: CompanyInformationValues = {
    countryId: userLanguage === "no" ? NORWAY_ID : 0,
    currencyId: 0,
    companyName: "",
    city: "",
    orgNumber: "",

    selectedCompanyId: undefined,
  };

  const validationSchema = Yup.object().shape({
    countryId: Yup.string().when("selectedCompanyId", {
      is: (val?: string) => !val,
      then: Yup.string().notOneOf(["0"], t("required")).required(t("required")),
      otherwise: Yup.string().nullable(),
    }),
    currencyId: Yup.string().when("selectedCompanyId", {
      is: (val?: string) => !val,
      then: Yup.string().notOneOf(["0"], t("required")).required(t("required")),
      otherwise: Yup.string().nullable(),
    }),
    companyName: Yup.string().when("selectedCompanyId", {
      is: (val?: string) => !val,
      then: Yup.string().trim().required(t("required")),
      otherwise: Yup.string().nullable(),
    }),
    city: Yup.string().when("selectedCompanyId", {
      is: (val?: string) => !val,
      then: Yup.string().trim().required(t("required")),
      otherwise: Yup.string().nullable(),
    }),
    selectedCompanyId: Yup.string(),
  });

  return { initialValues, validationSchema };
};

export default useCompanyInfoForm;
