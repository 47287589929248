import { FC, useMemo } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowUpIcon, CommonFileAddIcon, InformationCircleIcon, TotalValueIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { FundraiseTransactionDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import FundraiseShareIssueList from "../../FundraiseShareIssueList";
import classes from "./capital-increase-body.module.scss";

type PropsTypes = {
  transactions: FundraiseTransactionDTO[];
  numberOfShares: number;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const CapitalIncreaseBody: FC<PropsTypes> = ({ transactions, numberOfShares }) => {
  const fNumber = useFormatNumbers();

  const totalAmount = useMemo(() => {
    return transactions.reduce((acc: number, curr) => acc + curr.numberOfShares * curr.sharePrice, 0);
  }, [transactions]);

  const sharePrice = useMemo(() => {
    return transactions.reduce((acc: number, curr) => acc + curr.sharePrice, 0) / transactions.length;
  }, [transactions]);

  return (
    <div className="">
      <div className={classNames("p-4", classes.bodyContainer, classes.darkBackground)}>
        <div>
          <div
            className="d-flex justify-content-evenly pb-4 mb-4"
            style={{ borderBottom: `1px solid ${scssVariables.strokeHigh}` }}
          >
            <div className="d-flex flex-fill">
              <CommonFileAddIcon color={scssVariables.foregroundLow} width={40} height={40} />
              <div className="ms-2">
                <Ui.xxl bold>{fNumber(numberOfShares, "amount")}</Ui.xxl>
                <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
              </div>
            </div>

            <div className="d-flex flex-fill">
              <ArrowUpIcon width={40} height={40} color={scssVariables.foregroundLow} />
              <div className="ms-2">
                <Ui.xxl bold>{fNumber(totalAmount, "value")}</Ui.xxl>
                <Ui.xs className="text-medium">{t("totalAmount")}</Ui.xs>
              </div>
            </div>

            <div className="d-flex flex-fill">
              <TotalValueIcon width={40} height={40} color={scssVariables.foregroundLow} />
              <div className="ms-2">
                <Ui.xxl bold>{fNumber(sharePrice, "sharePrice")}</Ui.xxl>
                <Ui.xs className="text-medium">{t("sharePrice")}</Ui.xs>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-2">
            <Ui.m bold className="text-medium">
              {t("transactions")}
            </Ui.m>
          </div>
          {transactions.length ? (
            <FundraiseShareIssueList transactions={transactions} />
          ) : (
            <div className="border-1 rounded-2 py-2 px-3" style={{ backgroundColor: scssVariables.surface1 }}>
              <Ui.l bold className="mb-3">
                {t("emptyTitle")}
              </Ui.l>
              <div className="d-flex align-items-center text-low">
                <InformationCircleIcon />
                <Ui.xs className="ms-2">{t("emptyDescription")}</Ui.xs>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CapitalIncreaseBody;
