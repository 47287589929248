import { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { differenceInDays } from "date-fns";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Ui } from "common/components/atoms";
import { EditIcon, FileTextSearchIcon, PieLineGraph2Icon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./FundraiseMenu.module.scss";

type FundraiseMenuProps = {
  handleEditFundraise: () => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.tabMenu");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const FundraiseMenu: FC<FundraiseMenuProps> = ({ handleEditFundraise }) => {
  const { hasFullAccess } = useFeatures(FEATURES.fundraise, [CompanyFeatures.Growth]);
  const data = useParams();

  const fundraise = useStoreState((state) => state.fundraisingModel.fundraise);

  const route = data["*"];

  const menu = useMemo(() => {
    return [
      {
        name: t("subscriptions"),
        path: ROUTER_V2.fundraising.overview,
        active: ROUTER_V2.fundraising.overview === route,
        icon: <PieLineGraph2Icon fontSize={20} />,
        count: undefined,
      },
      {
        name: t("previewResults"),
        path: ROUTER_V2.fundraising.previewResult,
        active: ROUTER_V2.fundraising.previewResult === route,
        icon: <FileTextSearchIcon fontSize={20} />,
      },
    ];
  }, [route]);

  const endDay = new Date(fundraise?.endsAt || "02-04-2022");

  const daysLeft = differenceInDays(endDay, new Date()) < 0 ? 0 : differenceInDays(endDay, new Date());

  return (
    <div className={classes.wrap}>
      <div className={classNames("d-flex align-items-center justify-content-between", classes.campaign)}>
        <div>
          <Ui.xl bold color="foregroundHigh">
            {fundraise?.eventName || "Fundraising"}
          </Ui.xl>
          <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
            {t("progress", { count: daysLeft })}
          </Ui.xs>
        </div>
        <Button
          variant="secondary"
          isOnlyIcon
          size="s"
          onClick={handleEditFundraise}
          isFocusDisabled
          isDisabled={!hasFullAccess}
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
        >
          <EditIcon />
        </Button>
      </div>
      <div className={classes.menu}>
        {menu.map((item) => (
          <Link
            key={item.path}
            className={classNames(classes.menuItem, { [classes.active]: item.active })}
            to={item.path}
          >
            {item.icon}
            <Ui.s tag="span">{item.name}</Ui.s>
            {item.count !== undefined && (
              <Ui.xs className={classes.menuCircle} tag="span">
                {item.count}
              </Ui.xs>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FundraiseMenu;
