import { FC } from "react";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CalendarIcon, DollarCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { FundraiseTransactionDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import formatTransactionDate from "../../../../../equity-management/ownership/transactions/components/format-transaction-date";
import {
  TransactionCategory,
  TransactionSharesType,
  TransactionStatus,
} from "../../../../../equity-management/ownership/transactions/types";

type PropsTypes = Partial<FundraiseTransactionDTO> & {
  isMini?: boolean;
  isPortfolio?: boolean;
  className?: string;
};

const tTransactions = createTranslation(TranslationNS.pages, "company.transactions");
const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const FundraiseShareIssueHeader: FC<PropsTypes> = ({ isMini, isPortfolio, className, ...transaction }) => {
  const isPending = transaction.statusId === TransactionStatus.Pending;
  const fNumber = useFormatNumbers(transaction.currencySymbol);

  const additionalName = `${transaction.toName || "..."} • `;

  const tagName = transaction.typeName === "Shares issue" ? "" : `(${transaction.typeName})`;

  return (
    <div className={className}>
      <Ui.m bold className="mb-1 d-flex">{`${additionalName} ${fNumber(transaction.numberOfShares, "amount")} ${t(
        TransactionSharesType[TransactionCategory.Sell]
      )} ${tagName}`}</Ui.m>

      <div className="d-flex" style={{ color: scssVariables.foregroundLow }}>
        <Tag className="me-2" variant={isPending ? "closed" : "complete"}>
          <Ui.xs>{"Share issue"}</Ui.xs>
        </Tag>

        {isPending ? (
          <Tag variant="information" className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>
              {transaction?.transactedAt &&
                formatTransactionDate(transaction.transactedAt) + ` • ${tTransactions("pending")}`}
            </Ui.xs>
          </Tag>
        ) : (
          <div className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt || "")}</Ui.xs>
          </div>
        )}

        <div className="d-flex align-items-center me-2">
          <Ui.xs className="me-half">{"New shares"}</Ui.xs>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
          <Ui.xs className="ms-half">{transaction.toName || "..."}</Ui.xs>
        </div>

        {!isMini && (
          <div className="d-flex align-items-center me-2">
            <DollarCircleIcon />
            <Ui.xs className="ms-half">
              {`${fNumber(transaction.transactionTotal, "value")} • ${fNumber(
                transaction.sharePrice,
                "sharePrice"
              )}/${t("share")}`}
            </Ui.xs>
          </div>
        )}
      </div>
    </div>
  );
};

export default FundraiseShareIssueHeader;
