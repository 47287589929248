import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Divider, H, P } from "common/components/atoms";
import { ChevronLeftIcon, ChevronRightIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import MessagesList from "./messages-list";

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions.importStatus");

const ImportStatus = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const errors = TransactionsContext.useStoreState((store) => store.currentErrors);
  const warnings = TransactionsContext.useStoreState((store) => store.currentWarnings);

  const handleTryAgain = () => {
    navigate(getEMPath(["importTransactions", "downloadTemplate"], { companyId }));
  };

  const handleSubmit = () => {
    navigate(getEMPath(["importTransactions", "captablePreview"], { companyId }));
  };

  useEffect(() => {
    if (!errors.length && !warnings.length) {
      handleSubmit();
    }
  }, [errors.length, handleSubmit, warnings.length]);

  return (
    <WizardContent.Content step={2}>
      <H.s className="mb-4">{t("title")}</H.s>
      <P.m className="mb-2">{t("description1")}</P.m>
      <P.m className="mb-4">{t("description2")}</P.m>
      <Divider className="mb-5" />
      <H.xxs className="mb-4">{t("subtitle")}</H.xxs>
      <MessagesList className="mb-5" type="errors" messages={errors} />
      <MessagesList className="mb-5" type="warnings" messages={warnings} />

      <div className="d-flex justify-content-between">
        <Button variant="secondary" iconLeft={<ChevronLeftIcon />} onClick={handleTryAgain}>
          {t("tryAgain")}
        </Button>
        <Button iconRight={<ChevronRightIcon />} onClick={handleSubmit} isDisabled={!!errors.length}>
          {t("preview")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default ImportStatus;
