import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiValue } from "react-select";
import { assocPath, defaultTo, isEmpty, isNil } from "ramda";

import { MultiSelect } from "common/components/atoms";
import { useStoreState } from "store/store";

import ManagePlansContext from "../../../managePlansContext";

const AgreementsStakeholderSearch = () => {
  const { companyId = "0" } = useParams();

  const ownershipPlans = useStoreState((state) => state.company.ownershipPlans);
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const appliedFilters = savedAppliedFilters?.[companyId];

  const [stakeholders, setStakeholders] = useState<{ id: number; value: string; label: string; email?: string }[]>([]);
  const [selectedStakeholders, setSelectedStakeholders] = useState<
    { id: number; value: string; label: string; isCompanyOwned: boolean; email?: string }[]
  >([]);

  const handleChange = useCallback(
    (e: MultiValue<any>) => {
      const transformedData = e.map((el) => ({
        id: el.id,
        value: el.value,
        label: el.value,
        email: el?.email,
        isCompanyOwned: el.isCompanyOwned,
      }));

      setSelectedStakeholders(transformedData);

      const selectedStakeholders = ownershipPlans?.stakeholdersWithAgreements?.filter((el) =>
        transformedData.some((item) => item.id === el.stakeholderId)
      );

      setSavedAppliedFilters(
        assocPath([companyId, "selectedStakeholders"], defaultTo([], selectedStakeholders), savedAppliedFilters)
      );
    },
    [companyId, ownershipPlans?.stakeholdersWithAgreements, savedAppliedFilters, setSavedAppliedFilters]
  );

  useEffect(() => {
    if (!isEmpty(ownershipPlans?.stakeholdersWithAgreements)) {
      const transformedData = (ownershipPlans?.stakeholdersWithAgreements || []).map((data) => ({
        id: data.stakeholderId,
        value: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        label: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        email: data.stakeholderEmail,
        isCompanyOwned: data.isCompanyOwned,
      }));

      setStakeholders(transformedData);
    }
  }, [ownershipPlans?.stakeholdersWithAgreements]);

  useEffect(() => {
    if (!isNil(appliedFilters) && !isEmpty(appliedFilters.selectedStakeholders)) {
      const transformedData = (appliedFilters.selectedStakeholders || []).map((data) => ({
        id: data.stakeholderId,
        value: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        label: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        email: data?.stakeholderEmail,
        isCompanyOwned: data.isCompanyOwned,
      }));

      setSelectedStakeholders(transformedData);
    }

    if (isNil(appliedFilters)) {
      setSelectedStakeholders([]);
    }
  }, [appliedFilters]);

  return (
    <div className="ms-1" style={{ flexBasis: "35%" }}>
      <MultiSelect
        label=""
        isDraggable
        maxInputCount={20}
        options={stakeholders}
        placeholder="Filter stakeholders"
        defaultValues={selectedStakeholders}
        onChange={handleChange}
      />
    </div>
  );
};

export default AgreementsStakeholderSearch;
