import { FC, Fragment } from "react";

import Step from "common/components/atoms/Step/Step";
import { ArrowUpIcon } from "common/icons/svg";
import { FundraiseTransactionBundlesDTO } from "store/modelTypes";

import FundraiseCapitalIncrease from "./FundraiseCapitalIncrease/FundraiseCapitalIncrease";

type FundraiseTransactionListProps = {
  transactions: FundraiseTransactionBundlesDTO[];
};
const FundraiseCapitalIncreaseList: FC<FundraiseTransactionListProps> = ({ transactions }) => {
  return (
    <div className="pt-2 pb-2 ps-4 pe-4">
      <Step>
        {transactions.map((transactionBundle, index) => {
          return (
            <Fragment key={index}>
              <Step.Item id={String(index)} variant={"pill"} status={"pending-transaction"}>
                <div>
                  <Step.Point icon={<ArrowUpIcon width={24} height={24} />} variant={"pill"} />
                </div>
                <FundraiseCapitalIncrease transactionBundle={transactionBundle} />
              </Step.Item>
            </Fragment>
          );
        })}
      </Step>
    </div>
  );
};

export default FundraiseCapitalIncreaseList;
