import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { isNil } from "ramda";

import TransactionsContext from "../../transactions.context";
import { Transaction, TransactionCategory } from "../../types";
import Confirm from "./confirm";
import DependentTransactions from "./dependent-transactions";

type PropsTypes = {
  onSubmit: (transactionId: number, isRollback: boolean, isBundle: boolean) => Promise<boolean>;
  onClose: () => void;
};

const RollbackConfirmedTransaction: FC<PropsTypes> = ({ onClose, onSubmit }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const [dependentTransactions, setDependentTransactions] = useState<Transaction[]>();

  const isLoading = TransactionsContext.useStoreState((state) => state.isLocalLoading);
  const confirmedTransactionToRollback = TransactionsContext.useStoreState(
    (state) => state.confirmedTransactionToRollback
  );
  const { rollbackTransactionThunk, rollbackCapitalIncreaseThunk } = TransactionsContext.useStoreActions(
    (actions) => actions
  );

  const handleSubmit = async () => {
    const isBundle = confirmedTransactionToRollback?.categoryId === TransactionCategory.CapitalIncrease;
    const bundleId = confirmedTransactionToRollback?.transactionBundleId || 0;

    const { hasErrorsAfterEdit, failedTransactions } = isBundle
      ? await rollbackCapitalIncreaseThunk({ id: bundleId, companyId: Number(companyId) })
      : await rollbackTransactionThunk({
          id: confirmedTransactionToRollback?.transactionId || 0,
          companyId: Number(companyId),
        });

    if (hasErrorsAfterEdit && failedTransactions.length) {
      setDependentTransactions(failedTransactions);
    }

    if (hasErrorsAfterEdit) {
      return false;
    }

    const isDeleted = await onSubmit(
      isBundle ? bundleId : confirmedTransactionToRollback?.transactionId || 0,
      true,
      isBundle
    );

    if (isDeleted) {
      onClose();
    }
  };

  return !dependentTransactions?.length ? (
    <Confirm
      onSubmit={handleSubmit}
      onClose={onClose}
      isLoading={isLoading}
      open={!isNil(confirmedTransactionToRollback)}
      shareholderName={confirmedTransactionToRollback?.toName || ""} // case for bundle not covered yet
      numberOfShares={confirmedTransactionToRollback?.numberOfShares || 0}
    />
  ) : (
    <DependentTransactions
      transactions={dependentTransactions}
      onClose={() => {
        setDependentTransactions([]);
        onClose();
      }}
    />
  );
};

export default RollbackConfirmedTransaction;
