import { FC, useMemo } from "react";

import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps, MenuItemMeta } from "common/components/atoms/ContextMenu/ContextMenu";
import { MenuTabBarVerticalIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { CompanyFeatures, FEATURES } from "../../../../../common/access-control/types";
import { useFeatures } from "../../../../../common/access-control/useFeatures";

type ActionsProps = {
  meta?: { [key: string]: string | number | boolean };
  menuItems: ContextMenuProps["items"];
  menuClickCallback?: (meta: MenuItemMeta) => void;
};

const tCommon = createTranslation(TranslationNS.common, "noAccess");

const Actions: FC<ActionsProps> = ({ meta = {}, menuClickCallback, menuItems }) => {
  const { hasFullAccess } = useFeatures(FEATURES.fundraise, [CompanyFeatures.Growth]);

  const modifiedMenuItems = useMemo(() => {
    return menuItems.map((item) => {
      return {
        ...item,
        meta: {
          ...item.meta,
          ...meta,
        },
      };
    });
  }, [menuItems, meta]);

  return (
    <ContextMenu items={modifiedMenuItems} menuClickCallback={menuClickCallback} isDisabled={!hasFullAccess}>
      <Button
        size="s"
        isOnlyIcon
        variant="tertiary"
        data-testid="plan-dropdown-btn"
        isFocusDisabled
        isDisabled={!hasFullAccess}
        tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
        tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
      >
        <MenuTabBarVerticalIcon color="#2B011F" />
      </Button>
    </ContextMenu>
  );
};

export default Actions;
