import axios from "axios";

import { GetGuidesResponseDTO, PostGuidesDoneRequestDTO, PostGuidesHideRequestDTO } from "./types";

const API_BASE = "api/guide";

const GuidesService = {
  async getGuides(companyId: number) {
    if (companyId) {
      try {
        const response = await axios.get<GetGuidesResponseDTO>(`${API_BASE}/company/${companyId}`);

        if (response.status === 200) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },

  async postGuideDone(body: PostGuidesDoneRequestDTO) {
    try {
      const response = await axios.post(API_BASE + "/done", body);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },

  async postGuideHide(body: PostGuidesHideRequestDTO) {
    try {
      const response = await axios.post(API_BASE + "/hide", body);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },
};

export default GuidesService;
