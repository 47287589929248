import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { DocumentStatusEnum } from "common/enums/enum";
import { FilesDataType } from "common/types/DocumentFiles.types";
import { FundraiseLoanConversionGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { fields as fieldsConversion } from "../fields/fieldsConversion";

export type LoanConversionFormValues = {
  loanId?: number;
  useManualConversion?: boolean;
  conversionDate?: string;
  shareClassId?: number;
  comment?: string;
  interestAmount?: number | null;
  loanRepaymentAmount?: number;
  sharePrice?: number;
  interestPaidInCash?: boolean;
  sharesAllocation?: number;
  documentStatusId?: number;
  numberOfShares?: number;
  autoCalculatedInterest?: number;
} & FilesDataType;

export const fields = {
  loanId: "loanId",
  ...fieldsConversion,
} as const;

const tv = createTranslation(TranslationNS.validation);
const useLoanConversionSlidePanel = (data?: FundraiseLoanConversionGetDTO | null) => {
  const initialValues = useMemo<LoanConversionFormValues>(
    () => ({
      [fields.loanId]: defaultTo(undefined, data?.convertibleLoanId),
      [fields.useManualConversion]: defaultTo(false, data?.useManualConversion),
      [fields.conversionDate]: defaultTo(createDateString(new Date()), data?.conversionDate),
      [fields.shareClassId]: defaultTo(0, data?.shareClassId),
      [fields.comment]: defaultTo("", data?.comment),
      [fields.loanRepaymentAmount]: defaultTo(undefined, data?.loanRepaymentAmount),
      [fields.sharePrice]: defaultTo(undefined, data?.sharePrice),
      [fields.interestAmount]: defaultTo(null, data?.interestAmount),
      [fields.interestPaidInCash]: defaultTo(false, data?.interestPaidInCash),
      [fields.sharesAllocation]: defaultTo(undefined, data?.sharesAllocation),
      [fields.autoCalculatedInterest]: 0,
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.NO_DOCUMENT_REQUIRED, data?.documentStatusId),
      [fields.filesData]: {
        oldFiles: defaultTo([], data?.documentFiles),
        newFiles: [],
      },
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.conversionDate]: string().when(fields.useManualConversion, {
          is: false,
          then: string().required(tv("required")),
        }),
        [fields.interestAmount]: number().when(fields.useManualConversion, {
          is: false,
          then: number()
            .nullable(true)
            .transform((value, originalValue) => {
              // console.log(originalValue === "" ? -1 : value, "tupac");
              return originalValue === null ? 1 : value;
            })
            .required(tv("required"))
            .min(0, tv("minNumber", { number: 1 })),
        }),
        [fields.loanRepaymentAmount]: number().when(fields.useManualConversion, {
          is: false,
          then: number().required(tv("required")),
        }),
        [fields.shareClassId]: number().required(tv("required")).min(1, tv("required")),
        [fields.sharesAllocation]: number().when(fields.useManualConversion, {
          is: true,
          then: number()
            .required(tv("required"))
            .min(1, tv("minNumber", { number: 1 })),
        }),
        [fields.sharePrice]: number().when(fields.useManualConversion, {
          is: true,
          then: number()
            .required(tv("required"))
            .min(1, tv("minNumber", { number: "0,000001" })),
        }),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useLoanConversionSlidePanel;
