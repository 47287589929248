import { FC, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import CreateSplitTransactionForm from "./create-split-transaction-form";
import EditSplitTransactionForm from "./edit-split-transaction-form";
import { EditSplitFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const SplitContainer: FC<PropsTypes> = ({ onSubmit, onClose }) => {
  const isSplitFormOpen = TransactionsContext.useStoreState((state) => state.isSplitFormOpen);
  const splitTransaction = TransactionsContext.useStoreState((state) => state.splitTransaction);
  const { postSplitTransactionThunk } = TransactionsContext.useStoreActions((actions) => actions);

  const handleSubmitCreateSplitForm = useCallback(
    async (transaction: FormData) => {
      const createdSuccessfully = await postSplitTransactionThunk(transaction);

      if (createdSuccessfully) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(0));
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postSplitTransactionThunk]
  );

  const handleSubmitEditSplitForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully = await postSplitTransactionThunk(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");

        onSubmit(String(transaction.get(EditSplitFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [postSplitTransactionThunk, onSubmit]
  );

  return (
    <>
      <SlidePanel show={isSplitFormOpen}>
        <CreateSplitTransactionForm onSubmit={handleSubmitCreateSplitForm} onClose={onClose} />
      </SlidePanel>
      <SlidePanel show={!!splitTransaction}>
        <AnimatePresence>
          {!!splitTransaction && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <EditSplitTransactionForm onSubmit={handleSubmitEditSplitForm} onClose={onClose} />
            </motion.div>
          )}
        </AnimatePresence>
      </SlidePanel>
    </>
  );
};

export default SplitContainer;
