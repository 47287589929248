import { generatePath } from "react-router-dom";

import store from "../../store/store";
import { MainPathType, ROUTER_V2 } from "./RouterV2.types";

// export type PathType<T extends object | unknown> = keyof T extends string ? keyof Omit<T, MainPathType> : never;

type NestedPath<T extends object | unknown, B = keyof Omit<T, MainPathType> | undefined> = T extends object
  ? B extends keyof T
    ? [B, ...NestedPath<T[B]>]
    : []
  : [];

export const getPath = (
  paths: NestedPath<typeof ROUTER_V2>,
  options?: { [key: string]: string | number | null | undefined }
): string => {
  let router: { [key: string]: string | { base?: string } } = ROUTER_V2;

  if (paths.length) {
    const path = (paths as string[]).reduce((acc, curr) => {
      let newAcc;
      if (typeof router[curr] === "object") {
        newAcc = acc + (router[curr] as { base?: string }).base;
        router = router[curr] ? (router[curr] as { base?: string }) : {};
      } else if (typeof router[curr] === "string") {
        newAcc = acc + (router[curr] ? "/" + router[curr] : "");
      }
      return newAcc || "";
    }, "");
    return options ? generatePath(path, options) : path;
  }
  return "";
};

// get Equity Management path
export const getEMPath = (
  paths: NestedPath<(typeof ROUTER_V2)["equityManagement"]>,
  options?: { [key: string]: string | number | null | undefined }
): string => {
  const companyId = store.getState().activeCompanyModel.companyId || 0;
  return getPath(["equityManagement", ...paths], { companyId, ...options });
};

// get company profile path
export const getFundraisePath = (
  paths: NestedPath<(typeof ROUTER_V2)["fundraising"]>,
  options?: { [key: string]: string | number | null | undefined }
): string => {
  const companyId = store.getState().activeCompanyModel.companyId;
  return getPath(["fundraising", ...paths], { companyId, ...options });
};

// get company profile path
/*export const getCSPath = (paths: NestedPath<(typeof ROUTER_V2)["companySettings"]>, options?: {}): string => {
  const companyId = store.getState().activeCompanyModel.companyId;
  return getPath(["companySettings", ...paths], { companyId, ...options });
};*/
