import { FC, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import CapitalIncreaseContext from "./capital-increase-context";
import classes from "./capital-increase-wizard.module.scss";
import CloseConfirm from "./close-confirm/close-confirm";
import CapitalIncreaseGeneral from "./steps/capital-increase-general/capital-increase-general";
import CapitalIncreaseTransactions from "./steps/capital-increase-transactions-table/capital-increase-transactions";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm");

const Wizard: FC = () => {
  const { companyId = "0", transactionId } = useParams<{ companyId: string; transactionId: string }>();

  const { getCapitalIncreaseGeneralThunk } = TransactionsContext.useStoreActions((actions) => actions);
  const { step } = CapitalIncreaseContext.useStoreState((state) => state);
  const { setIsLoading, setCurrentBundle } = CapitalIncreaseContext.useStoreActions((actions) => actions);

  useEffect(() => {
    const getData = async () => {
      if (transactionId !== "new" && transactionId) {
        setIsLoading(true);
        const response = await getCapitalIncreaseGeneralThunk(Number(transactionId));
        if (response) {
          setCurrentBundle(response);
          setIsLoading(false);
        }
      }
    };

    getData();
  }, [getCapitalIncreaseGeneralThunk, setCurrentBundle, setIsLoading, transactionId]);

  return (
    <WizardLayout>
      <WizardLayout.Header title={step === 1 ? t("1title") : t("2title")} />
      <WizardContent maxStep={2} className={cn({ [classes.wizardContent]: step === 2 })}>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.createCapitalIncrease.general} element={<CapitalIncreaseGeneral />} />
          <Route
            path={ROUTER_V2.equityManagement.createCapitalIncrease.transactions}
            element={<CapitalIncreaseTransactions />}
          />
          <Route path="*" element={<Navigate to={getEMPath(["createCapitalIncrease", "general"], { companyId })} />} />
        </Routes>
      </WizardContent>
      <CloseConfirm />
    </WizardLayout>
  );
};

const CapitalIncreaseWizard: FC = () => {
  return (
    <TransactionsContext.Provider>
      <CapitalIncreaseContext.Provider>
        <Wizard />
      </CapitalIncreaseContext.Provider>
    </TransactionsContext.Provider>
  );
};
export default CapitalIncreaseWizard;
