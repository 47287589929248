import { FC, useState } from "react";

import Table from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";

import { AccessControlUserDTO } from "../types";
import TableBody from "./body";
import TableHead from "./header";
import classes from "./table-view.module.scss";

type PropsTypes = {
  users: AccessControlUserDTO[];
  handleOpenEditAccessSidebar: (id: number) => void;
};

export type AccessControlSortValue = {
  field: keyof (Pick<AccessControlUserDTO, "email"> & { fullName: string });
  type: keyof typeof sortingParams;
};

const TableView: FC<PropsTypes> = ({ users, handleOpenEditAccessSidebar }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<AccessControlSortValue>({
    field: "fullName",
    type: sortingParams.inc,
  });

  return (
    <Table
      className={classes["table"]}
      headComponent={<TableHead sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />}
      bodyComponent={
        <TableBody
          users={users.map((item) => ({ ...item, fullName: `${item.firstName} ${item.lastName}` }))}
          sortedValue={activeSortedValue}
          handleOpenEditAccessSidebar={handleOpenEditAccessSidebar}
        />
      }
    />
  );
};

export default TableView;
