import { scssVariables } from "../../../utils/constants";

const MultiSelectStyles = {
  control: () => ({
    minHeight: 36,
    width: "100%",
    display: "flex",
    borderRadius: 8,
    border: `1px solid ${scssVariables.strokeMedium}`,

    "&:active": {
      border: `1px solid ${scssVariables.primary1}`,
    },
    "&:focus-within": {
      border: `1px solid ${scssVariables.primary1}`,
    },
  }),
  multiValue: (base: any) => {
    return {
      ...base,
      height: "auto",
      fontSize: 16,
      marginRight: 8,
      borderRadius: 20,
      padding: "0 8px",
      backgroundColor: scssVariables.element3,
      opacity: 1,

      "&:[drop-active=true]": {
        backgroundColor: "red",
      },
    };
  },
  multiValueLabel: (base: any) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    color: scssVariables.foregroundMedium,
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    marginLeft: 8,
    color: scssVariables.foregroundMedium,

    "&:hover": {
      transform: "scale(1)",
      backgroundColor: "transparent",
      color: scssVariables.foregroundMedium,
    },
  }),
  option: (base: any) => ({
    ...base,
    color: scssVariables.foregroundHigh,
    backgroundColor: scssVariables.surface1,

    "&:active": {
      backgroundColor: scssVariables.surface1,
    },
    "&:hover": {
      backgroundColor: scssVariables.element3,
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
};

export default MultiSelectStyles;
