import { FC } from "react";
import cn from "classnames";
import * as R from "ramda";
import { defaultTo, isNil } from "ramda";

import { AnimatedText, Helper, P, ProgressChart, Tag, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId, SharePriceTypeEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import RSAAgreementContext from "../../../contexts/RSAAgreementContext";
import SimulationActions from "../../common/simulation-actions/SimulationActions";
import classes from "./Overview.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.rsa.overview");

const Overview: FC<JSX.IntrinsicElements["div"]> = ({ className, ...props }) => {
  const { agreementDetails, overviewSimulationValue } = RSAAgreementContext.useStoreState((state) => state);
  const { currencySymbol } = useCurrencyById(agreementDetails?.currencyId);
  const fNumber = useFormatNumbers();

  const isAgreementTerminated = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.terminated;
  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;

  const multiplicator = !isNil(overviewSimulationValue) ? parseInt(overviewSimulationValue) : 1;

  return (
    <div className={cn(classes.overview, className)} {...props}>
      <Helper>
        <div className={classes.charts}>
          <div className="mt-3 mb-5">
            <div className="d-flex align-items-center justify-content-between mb-half">
              <div>
                <Helper.Question questionId="title">
                  <Ui.l bold className="mb-half">
                    {t("status")}
                  </Ui.l>
                </Helper.Question>
                <Ui.xs style={{ color: scssVariables.foregroundMedium }}>
                  {t("totalStatus", {
                    shares: fNumber(agreementDetails?.totalShares, "amount"),
                  })}
                </Ui.xs>
              </div>

              <div>
                <Helper.Question questionId="description" questionMarkClassName="me-0">
                  <Ui.xl
                    bold
                    className="ms-auto"
                    style={{
                      marginBottom: 4,
                      color: !isNil(overviewSimulationValue) ? scssVariables.positive700 : scssVariables.foregroundHigh,
                    }}
                  >
                    <AnimatedText
                      from={0}
                      to={multiplicator * defaultTo(0, agreementDetails?.totalSharesValue)}
                      formatterConfig={{
                        type: "value",
                        config: { customCurrencySymbol: currencySymbol },
                      }}
                    />
                  </Ui.xl>
                </Helper.Question>

                <SimulationActions type={InstrumentTypesNamesEnum.RSA} />
              </div>
            </div>

            <Helper.Answer answerId="title" text={t("helperAnswerText")} />

            <Helper.Answer answerId="description">
              <P.s>
                {/*{t("statusDescriptionActual", {
                  percent: fNumber(R.defaultTo(0, agreementDetails?.planPercentageOfCompanyShares), "percent"),
                })}*/}
                {t(
                  agreementDetails?.sharePriceType === SharePriceTypeEnum.ACTUAL
                    ? "statusDescriptionActual"
                    : "statusDescriptionFullyDiluted",
                  {
                    percent: fNumber(agreementDetails?.planPercentageOfCompanyShares, "percent"),
                  }
                )}
              </P.s>
            </Helper.Answer>
          </div>

          <ProgressChart
            withoutLabel
            withLeftBorder
            total={agreementDetails?.totalShares}
            totalSharesBarColor={
              isAgreementTerminated || isAgreementExpired ? scssVariables.critical050 : scssVariables.surface1
            }
            totalSharesBorderColor={
              isAgreementTerminated || isAgreementExpired ? scssVariables.critical700 : scssVariables.positive900
            }
            data={[
              {
                value: R.defaultTo(0, agreementDetails?.vestedShares),
                id: "shares",
                withThumb: true,
                showTooltip: true,
                color: scssVariables.positive500,
                thumbColor: scssVariables.positive500,
                labelColor: "red",
              },
            ]}
          />
        </div>

        <div className={cn("mt-4", classes["statistic"])}>
          <div className="mt-2 mb-2" style={{ flexBasis: "40%" }}>
            <div className="mb-2 d-flex align-items-center justify-content-center">
              <div className={classes["circle"]} />
              <Ui.xs className="ms-1">{t("unRestrictedShares")}</Ui.xs>
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m bold className="mb-1">
                  {fNumber(agreementDetails?.vestedShares, "amount")}
                </Ui.m>
                <p className="m-0 ui-xs">{t("shares")}</p>
              </div>
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: !isNil(overviewSimulationValue) ? scssVariables.positive700 : scssVariables.foregroundHigh,
                  }}
                >
                  <AnimatedText
                    from={0}
                    to={multiplicator * R.defaultTo(0, agreementDetails?.vestedSharesValue)}
                    formatterConfig={{
                      type: "value",
                      config: { customCurrencySymbol: currencySymbol },
                    }}
                  />
                </Ui.m>

                <div className="d-flex align-items-center justify-content-center">
                  <Ui.xs>{t("value")}</Ui.xs>

                  {!isNil(overviewSimulationValue) ? (
                    <Tag variant="complete" size="s" className="ms-1">
                      {overviewSimulationValue}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className={cn(classes["share-price"])}>
            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: !isNil(overviewSimulationValue) ? scssVariables.positive700 : scssVariables.foregroundHigh,
                  }}
                >
                  <AnimatedText
                    from={0}
                    to={multiplicator * defaultTo(0, agreementDetails?.sharePrice)}
                    formatterConfig={{
                      type: "sharePrice",
                      config: { customCurrencySymbol: currencySymbol },
                    }}
                  />
                </Ui.m>

                <div className="d-flex align-items-center justify-content-center">
                  <Ui.xs>{t("sharePrice")}</Ui.xs>

                  {!isNil(overviewSimulationValue) ? (
                    <Tag variant="complete" size="s" className="ms-1">
                      {overviewSimulationValue}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ flexBasis: "35%" }}>
            <div className="mb-2 d-flex align-items-center justify-content-center">
              <div
                className={cn(classes["circle"], {
                  [classes["dotted"]]: true,
                  [classes["danger"]]: isAgreementTerminated || isAgreementExpired,
                })}
              />
              <Ui.xs className="ms-1">
                {isAgreementTerminated || isAgreementExpired ? t("optionsLost") : t("restrictedShares")}
              </Ui.xs>
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color: isAgreementTerminated || isAgreementExpired ? scssVariables.critical700 : undefined,
                    textDecoration: isAgreementTerminated || isAgreementExpired ? "line-through" : undefined,
                  }}
                >
                  {fNumber(agreementDetails?.restrictedShares, "amount")}
                </Ui.m>
                <Ui.xs>{t("shares")}</Ui.xs>
              </div>
              <div className="text-center">
                <Ui.m
                  bold
                  className="mb-1"
                  style={{
                    color:
                      isAgreementTerminated || isAgreementExpired
                        ? scssVariables.critical700
                        : !isNil(overviewSimulationValue)
                        ? scssVariables.positive700
                        : scssVariables.foregroundHigh,
                    textDecoration: isAgreementTerminated || isAgreementExpired ? "line-through" : undefined,
                  }}
                >
                  <AnimatedText
                    from={0}
                    to={multiplicator * R.defaultTo(0, agreementDetails?.restrictedSharesValue)}
                    formatterConfig={{
                      type: "value",
                      config: { customCurrencySymbol: currencySymbol },
                    }}
                  />
                </Ui.m>

                <div className="d-flex align-items-center justify-content-center">
                  <Ui.xs>{t("value")}</Ui.xs>

                  {!isNil(overviewSimulationValue) ? (
                    <Tag variant="complete" size="s" className="ms-1">
                      {overviewSimulationValue}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Helper>
    </div>
  );
};

export default Overview;
