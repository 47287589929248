import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig } from "formik";
import { defaultTo } from "ramda";

import { SharePriceTypeEnum } from "common/enums/enum";
import { notify } from "common/utils/notify/notifyFunction";

import classes from "./IncentiveAgreementsSettings.module.scss";
import IncentiveAgreementsSettingsForm from "./IncentiveAgreementsSettingsForm";

export type StockOptionsSettingsFormValues = Pick<
  IncentiveAgreementSettingsGetDto,
  "calculationMethod" | "includeUnusedAuthorizedShares" | "includeValueOfFinancialInstruments"
> & {
  companyId: number;
};

export type IncentiveAgreementSettingsGetDto = {
  calculationMethod?: number;
  includeUnusedAuthorizedShares?: boolean;
  includeValueOfFinancialInstruments?: boolean;
  sharePrice: number;
  fdExclValueSharePrice: number;
  fdInclValueSharePrice: number;
  fdExclValueInclUnusedPoolsSharePrice: number;
  fdInclValueInclUnusedPoolsSharePrice: number;
};

export const fields: {
  [key in keyof Required<StockOptionsSettingsFormValues>]: key;
} = {
  calculationMethod: "calculationMethod",
  includeUnusedAuthorizedShares: "includeUnusedAuthorizedShares",
  includeValueOfFinancialInstruments: "includeValueOfFinancialInstruments",
  companyId: "companyId",
} as const;

const IncentiveAgreementsSettings = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const [soSettings, setSoSettings] = useState<IncentiveAgreementSettingsGetDto | null>(null);

  const onSubmit = useCallback<FormikConfig<StockOptionsSettingsFormValues>["onSubmit"]>(async (values) => {
    try {
      await axios.post("/api/company/incentive-agreements-settings", values);
      notify("You successfully saved settings", true, "success");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const initialValues = useMemo<StockOptionsSettingsFormValues>(
    () => ({
      calculationMethod: defaultTo(SharePriceTypeEnum.ACTUAL, soSettings?.calculationMethod),
      includeUnusedAuthorizedShares: defaultTo(false, soSettings?.includeUnusedAuthorizedShares),
      includeValueOfFinancialInstruments: defaultTo(false, soSettings?.includeValueOfFinancialInstruments),
      companyId: companyId ? +companyId : 0,
    }),
    [
      companyId,
      soSettings?.includeUnusedAuthorizedShares,
      soSettings?.includeValueOfFinancialInstruments,
      soSettings?.calculationMethod,
    ]
  );
  //call api to get data
  useEffect(() => {
    axios
      .get<IncentiveAgreementSettingsGetDto>(`/api/company/incentive-agreements-settings/${companyId}`)
      .then((response) => {
        setSoSettings(response.data);
      });
  }, [companyId]);

  return (
    <div className={classes.wrap}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        <IncentiveAgreementsSettingsForm sharePrices={soSettings} />
      </Formik>
    </div>
  );
});

export default IncentiveAgreementsSettings;
