import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import { defaultTo, isEmpty } from "ramda";

import Helper from "common/components/atoms/Helper/Helper";
import { H } from "common/components/atoms/Typography";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import NameField from "common/shareClass/components/ShareClassForm/NameField";
import PurposeField from "common/shareClass/components/ShareClassForm/PurposeField";
import { ShareClassServiceUpdateData } from "common/shareClass/ShareClassService";
import { getShareClassValidationObject } from "common/shareClass/validationSchema";
import { createTranslation, TranslationNS } from "translation";

import CreateShareClassStore from "../../store";

export type BasicsFormData = Pick<ShareClassServiceUpdateData, "name" | "purpose">;

const t = createTranslation(TranslationNS.pages, "createShareClass.basics");

const FormContent = () => {
  const { values, dirty } = useFormikContext<BasicsFormData>();

  const skipModalShow = WizardLayoutStore?.useStoreState((actions) => actions.skipModalShow);
  const setSkipModalShow = WizardLayoutStore?.useStoreActions((actions) => actions.setSkipModalShow);

  useEffect(() => {
    if (dirty && !skipModalShow) {
      setSkipModalShow(true);
    }

    if (!dirty && skipModalShow) {
      setSkipModalShow(false);
    }
  }, [dirty, setSkipModalShow, skipModalShow]);

  return (
    <Form>
      <Helper>
        <Row>
          <Col xs={12} md={6}>
            <Helper.Question questionId="name" type="input">
              <NameField />
            </Helper.Question>
            <Helper.Answer
              className="mt-3"
              answerId={"name"}
              link="/"
              linkText={t("nameReadMore")}
              text={t.el("nameAnswer")}
              withRightMargin
            />
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>

        <Helper.Question className="mt-4" questionId="description" type="input">
          <PurposeField />
        </Helper.Question>
        <Helper.Answer
          className="mt-3"
          answerId={"description"}
          link="/"
          linkText={t("descriptionReadMore")}
          text={t.el("descriptionAnswer")}
          withRightMargin
        />

        <div className="d-flex mt-8">
          <WizardContent.Controls.ContinueButton className="ms-auto" isDisabled={isEmpty(values.name)} />
        </div>
      </Helper>
    </Form>
  );
};

const Basics: FC = () => {
  const { nextWizardStep } = useWizard();

  const formDataState = CreateShareClassStore.useStoreState((state) => state.formData);
  const updateFormDataAction = CreateShareClassStore.useStoreActions((actions) => actions.updateFormData);

  const initialValues = useMemo<BasicsFormData>(
    () => ({
      name: defaultTo("", formDataState.name),
      purpose: defaultTo("", formDataState.purpose),
    }),
    [formDataState.name, formDataState.purpose]
  );

  const validationSchema = useMemo(() => getShareClassValidationObject().pick(["name", "purpose"]), []);

  const submitHandler = useCallback(
    (values: BasicsFormData): void => {
      updateFormDataAction(values);

      nextWizardStep();
    },
    [nextWizardStep, updateFormDataAction]
  );

  return (
    <WizardContent.Content>
      <H.xs className="text-right mb-5">{t("title")}</H.xs>

      <Formik<BasicsFormData>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <FormContent />
      </Formik>
    </WizardContent.Content>
  );
};

export default Basics;
