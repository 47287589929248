import { FC, SyntheticEvent, useCallback, useMemo } from "react";

import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps, MenuItemMeta } from "common/components/atoms/ContextMenu/ContextMenu";
import { DeleteIcon, EditIcon, LockIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareSeriesTable.head.actions");

export type ShareSeriesTypes = "markAsPledged" | "editPledge" | "removePledge";

type ActionsProps = {
  id: number;
  hasAccess?: boolean;
  pledged?: boolean;
  pledgeNotes?: string;
  onSelect?: (type: ShareSeriesTypes, data: { id: number; pledgeNotes?: string }) => void;
};
const ActionsCell: FC<ActionsProps> = ({ id, hasAccess, pledged, onSelect, pledgeNotes }) => {
  const handleMarkAsPledged = useCallback(
    (e?: SyntheticEvent, item?: MenuItemMeta) => {
      if (item?.key) {
        onSelect?.(item.key as ShareSeriesTypes, { id, pledgeNotes });
      }
    },
    [id, onSelect, pledgeNotes]
  );

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const menuItems: ContextMenuProps["items"] = [];
    if (!pledged) {
      menuItems.push({
        key: "markAsPledged",
        label: t("markAsPledged"),
        icon: <LockIcon />,
        onClick: handleMarkAsPledged,
        isDisabled: !hasAccess,
        forceHideDropdown: true,
      });
    } else {
      menuItems.push(
        {
          key: "editPledge",
          label: t("editPledge"),
          icon: <EditIcon />,
          onClick: handleMarkAsPledged,
          isDisabled: !hasAccess,
        },
        {
          key: "removePledge",
          label: "Remove pledge",
          icon: <DeleteIcon />,
          onClick: handleMarkAsPledged,
          isDisabled: !hasAccess,
          type: "delete",
        }
      );
    }

    return menuItems;
  }, [handleMarkAsPledged, hasAccess, pledged]);

  return (
    <ContextMenu items={menuItems}>
      <Button size="s" isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn">
        <MenuTabBarVerticalIcon color="#2B011F" />
      </Button>
    </ContextMenu>
  );
};

export default ActionsCell;
