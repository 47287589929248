import { useCallback, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { getEMPath } from "app/Router/RouterHelper";
import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext, { StockOptionsType } from "../../MyPortfolioContext";
import OverviewTable from "../OverviewTable/OverviewTable";
import ProfitOrLoss from "../Ownership/ProfitOrLoss";
import Actions from "./Actions";
import classes from "./StockOptions.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.stockOptions");

const StockOptions = () => {
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);

  const stockOptions = MyPortfolioContext.useStoreState((state) => state.portfolio?.stockOptions);

  const data = useMemo(() => stockOptions || [], [stockOptions]);

  const columns = useMemo<ColumnDef<StockOptionsType>[]>(
    () => [
      {
        accessorKey: "name",
        cell: (info) => {
          return (
            <>
              <NewAvatar
                initials={info.row.original.initials}
                imageUrl={info.row.original.avatarFilePath}
                company
                size="s"
              />
              <Ui.m className="ms-1" bold>
                {info.getValue() as string}
              </Ui.m>
            </>
          );
        },
        header: () => <Ui.xs tag="span">{t("table.company")}</Ui.xs>,
        enableResizing: false,
      },
      {
        accessorKey: "vestedShares",
        cell: (info) => (
          <div className={classes.progressBar}>
            <Ui.xs className="mb-1">
              {fNumber(info.getValue() as number, "amount")}/{fNumber(info.row.original.numberOfShares, "amount")}
            </Ui.xs>
            <ProgressBar variant="success" now={info.getValue() as number} max={info.row.original.numberOfShares} />
          </div>
        ),
        header: () => <Ui.xs tag="span">{t("table.progress")}</Ui.xs>,
        enableResizing: false,
      },
      {
        accessorKey: "exercisePrice",
        cell: (info) =>
          fNumber(info.getValue() as number, "unitPrice", {
            customCurrencySymbol: info.row.original.currencySymbol,
          }),
        header: () => <Ui.xs tag="span">{t("table.exercisePrice")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "optionsExpirationDate",
        cell: (info) => `${transformDateToCommonDateFormat(info.getValue() as string)}`,
        header: () => <Ui.xs tag="span">{t("table.optionsExpirationDate")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "value",
        cell: (info) =>
          fNumber(info.getValue() as number, "value", { customCurrencySymbol: info.row.original.currencySymbol }),
        header: () => <Ui.xs tag="span">{t("table.value")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "profitLossValue",
        cell: (info) => {
          const row = info.row.original;
          return (
            <ProfitOrLoss
              value={info.getValue() as number | null}
              status={row.profit ? "profit" : "loss"}
              currency={row.currencySymbol}
              percentage={row.profitLossPercentage}
            />
          );
        },
        header: () => <Ui.xs tag="span">{t("table.profit")}</Ui.xs>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "companyId",
        cell: (info) => <Actions id={info.getValue() as number} hasAccess={info.row.original.hasAccessToCapTable} />,
        header: () => "",
        enableSorting: false,
        maxSize: 56,
        enableResizing: true,
      },
    ],
    [fNumber]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClickRow = useCallback(
    (data: StockOptionsType) => {
      if (data.hasAccessToCapTable) {
        navigate(getEMPath(["ownership", "capTable"], { companyId: data.companyId }));
      } else {
        notify(t("restrictedAccess"), true, "warning");
      }
    },
    [navigate]
  );

  if (data.length === 0) return null;

  return (
    <div className={classes.portfolio}>
      <div className={classes.title}>
        <Ui.xl bold>{t("title")}</Ui.xl>
        <Ui.xs className={classes.subtitle}>{t("subtitle")}</Ui.xs>
      </div>
      <OverviewTable withHover table={table} handleClickRow={handleClickRow} />
    </div>
  );
};

export default StockOptions;
