import { FC, ReactNode } from "react";
import cn from "classnames";

import classes from "./label.module.scss";

interface LabelProps {
  children?: ReactNode;
  className?: string | undefined;
  type?: "primary" | "warning" | "success" | "danger" | "iris";
}

const Label: FC<LabelProps> = ({ children, className, type }) => {
  return <span className={cn(classes.label, classes[type || "primary"], className)}>{children}</span>;
};

export default Label;
