import { FC, useCallback, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Formik, FormikConfig } from "formik";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { PoolStatuses } from "common/enums/enum";
import usePoolForm, { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import usePoolFormValidation from "pages/equity-management/plans/wizards/create-pool/usePoolFormValidation";
import { useStoreActions, useStoreState } from "store/store";

import Form, { FormProps } from "./Form/Form";

export type ContentProps = Pick<FormProps, "onCancel"> & {
  onSave(): void;
  onDelete?(): void;
  poolId?: number;
  minNumberOfShares?: number;
};

const Content: FC<ContentProps> = ({ poolId, onSave, onCancel, onDelete, minNumberOfShares }) => {
  const { getPoolThunk, createOrUpdatePoolThunk, approvePoolThunk } = useStoreActions((actions) => actions.poolModel);
  const pool = useStoreState((state) => state.poolModel?.pool);
  const isGetPoolLoading = useStoreState((state) => state.poolModel?.isGetPoolLoading);

  const { initialValues } = usePoolForm(pool);
  const validationSchema = usePoolFormValidation(minNumberOfShares);

  const offCanvasSubmitHandler = useCallback<FormikConfig<PoolFormValues>["onSubmit"]>(
    async ({ submitter, ...values }) => {
      values.sharePrice = !values?.sharePrice?.toString() ? null : values.sharePrice;
      await createOrUpdatePoolThunk(values);
      if (submitter === "create") {
        await approvePoolThunk(values);
      }
      onSave();
    },
    [approvePoolThunk, createOrUpdatePoolThunk, onSave]
  );

  useEffect(() => {
    if (poolId) {
      getPoolThunk(poolId);
    }
  }, [getPoolThunk, poolId]);

  if (isGetPoolLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <SlidePanel.Header title={pool?.name} isDraft={pool?.statusId === PoolStatuses.draft} onHide={onCancel} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={offCanvasSubmitHandler}
        enableReinitialize
      >
        <Form onCancel={onCancel} onDelete={onDelete} statusId={pool?.statusId} />
      </Formik>
    </>
  );
};

export default Content;
