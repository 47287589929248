import { FC } from "react";
import { useFormikContext } from "formik";

import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import { NewSubscriptionFormValues } from "../useNewSubscriptionSlidePanel";

type Props = {
  className?: string;
};

const SharesAllocationResult: FC<Props> = ({ className }) => {
  const fNumber = useFormatNumbers();

  const { values } = useFormikContext<NewSubscriptionFormValues>();
  if (!values.companyName && !values.lastName && !values.firstName) {
    return null;
  }

  const shares = Math.floor((values.subscriptionAmount || 0) / (values.sharePrice || 1));
  return (
    <div className={className}>
      <Ui.m style={{ fontWeight: 500 }}>Shares allocation result</Ui.m>
      <div className="mt-1 p-3 d-flex" style={{ background: "#FBFAF9", borderRadius: "8px" }}>
        <div style={{ marginRight: "14px" }}>
          <NewAvatar
            className="me-2"
            size="l"
            companyName={values.companyName}
            lastName={values.lastName}
            firstName={values.firstName}
            company={values.isCompanyOwned}
          />
        </div>
        <div>
          <Ui.xl bold color="positive700">
            + {fNumber(shares, "amount")} shares{" "}
          </Ui.xl>
          <Ui.s color="foregroundLow">
            To be allocated upon completion of the fundraise, based on the current setup.
          </Ui.s>
        </div>
      </div>
    </div>
  );
};

export default SharesAllocationResult;
