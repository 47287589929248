import { FC, useCallback, useEffect, useState } from "react";
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { FormikErrors, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import { ImportErrorHandler, ImportTable } from "common/components/atoms/ImportTable";
import { manageRow } from "common/components/atoms/ImportTable/ImportTableHelper";
import { PlusIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { ImportTransactionFormValues } from "../use-import-transaction-form";
import classes from "./TransactionTable.module.scss";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.transactions");

type OwnershipTableProps = {
  columns: ColumnDef<ImportTransactionFormValues>[];
  initialItems: ImportTransactionFormValues;
  companyId?: number;
};

const TransactionTable: FC<OwnershipTableProps> = ({ columns, initialItems }) => {
  const { values, setValues, isSubmitting, isValid } = useFormikContext<ImportTransactionFormValues[]>();

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [listErrors, setListErrors] = useState<FormikErrors<ImportTransactionFormValues[]>>([]);

  const [globalFilter, setGlobalFilter] = useState("");

  const errorFilterData: FilterFnOption<ImportTransactionFormValues> = useCallback(
    (row: Row<ImportTransactionFormValues>) => {
      return !!listErrors[Number(row?.id)];
    },
    [listErrors]
  );

  const updateData = useCallback(
    (rowIndex: number, type: string) => {
      setValues(manageRow(values, rowIndex, type, initialItems, ["transactionId"]));
    },
    [initialItems, setValues, values]
  );

  const table = useReactTable({
    data: values,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { updateData, footerShow: true },
    state: {
      globalFilter: globalFilter,
    },
    globalFilterFn: errorFilterData,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleAddNewRow = useCallback(() => {
    setValues([...values, { ...initialItems }]);
  }, [initialItems, setValues, values]);

  useEffect(() => {
    if (isSubmitting) {
      setShowErrorMessage(!isValid);
    }
  }, [isSubmitting, isValid]);

  return (
    <div className={classes.content}>
      {showErrorMessage && (
        <ImportErrorHandler
          updateErrorCallback={setListErrors}
          isFilterActive={!!globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <ImportTable table={table} tableType="founders" />
      <div className={classes.bottomActions}>
        {!globalFilter ? (
          <Button isDisabled={!!globalFilter} onClick={handleAddNewRow} iconLeft={<PlusIcon />} variant="secondary">
            {t("addTransaction")}
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
export default TransactionTable;
