import { useMemo } from "react";
import { defaultTo } from "ramda";
import { object, string } from "yup";

import { createTranslation, TranslationNS } from "translation";

const tv = createTranslation(TranslationNS.validation);

export type UpdateValuationFormValues = {
  updateType: "update" | "keep";
  title: string;
  description: string;
  notifyStakeholderByEmail: boolean;
  additionalComments: string;
  currentCompanyValuation: number;
  postMoneyValuation: number;
};

export const fields: { [K in keyof Required<UpdateValuationFormValues>]: K } = {
  updateType: "updateType",
  title: "title",
  description: "description",
  notifyStakeholderByEmail: "notifyStakeholderByEmail",
  additionalComments: "additionalComments",
  currentCompanyValuation: "currentCompanyValuation",
  postMoneyValuation: "postMoneyValuation",
} as const;

export const useUpdateValuationForm = (data?: Partial<UpdateValuationFormValues>) => {
  const initialValues = useMemo<UpdateValuationFormValues>(
    () => ({
      [fields.updateType]: defaultTo("update", data?.updateType),
      [fields.currentCompanyValuation]: defaultTo(0, data?.currentCompanyValuation),
      [fields.postMoneyValuation]: defaultTo(0, data?.postMoneyValuation),
      [fields.title]: defaultTo("", data?.title),
      [fields.description]: defaultTo("", data?.description),
      [fields.notifyStakeholderByEmail]: defaultTo(false, data?.notifyStakeholderByEmail),
      [fields.additionalComments]: defaultTo("", data?.additionalComments),
    }),
    [data]
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      [fields.title]: string().required(tv("required")),
    });
  }, []);

  return {
    initialValues,
    validationSchema,
  };
};
