import { FC, useCallback, useEffect, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import { assoc, dissoc } from "ramda";

import { InstrumentTypesIdsEnum, PeriodTimeEnum } from "common/enums/enum";
import { useOneOffPlanValidation } from "common/plan/planValidationSchema";
import usePlanInitialValues from "common/plan/usePlanInitialValues";
import { notify } from "common/utils/notify/notifyFunction";
import { PlanGetDTO, PlanPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../../../create-one-off-plans/CreateGrantOneOffPlan";
import { PlanForm } from "../../../types";
import { availableSharesInProgram } from "../../../utils";
import FormData from "./new-options-form-data";

type PropsType = {
  setIsSidebarOpen: (isOpen: boolean) => void;
  planToEdit: PlanGetDTO | null;
  onSave?: () => void;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const NewOptionsPlanForm: FC<PropsType> = ({ setIsSidebarOpen, planToEdit, onSave }) => {
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const selectedProgram = useStoreState((state) => state.programModel.program);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);
  const { setRegularPlanSOThunk } = useStoreActions((actions) => actions.planModel);

  useEffect(() => {
    if (planToEdit && planToEdit.programId) {
      getProgramThunk(planToEdit.programId).then();
    }
  }, [getProgramThunk, planToEdit]);

  const planData = useMemo(() => {
    const numberOfSharesLeft = availableSharesInProgram(selectedProgram, planToEdit);

    if (planToEdit) return { ...planToEdit, numberOfSharesLeft };

    return {
      programId: selectedProgram?.programId,
      poolId: selectedProgram?.poolId,
      vestingPeriod: selectedProgram?.vestingPeriod,
      vestingInterval: selectedProgram?.vestingInterval,
      vestingTypeId: selectedProgram?.vestingTypeId,
      vestingCliff: selectedProgram?.vestingCliff,
      allowAcceleratedVesting: selectedProgram?.allowAcceleratedVesting,
      conditions: selectedProgram?.conditions.map((condition) => ({
        title: condition.title,
        description: condition.description,
        targetDate: condition.targetDate,
        numberOfOptions: 0,
      })),
      timeVestedShares: selectedProgram?.timeVestedShares,
      numberOfSharesLeft,
      retirement: selectedProgram?.retirement,
      retirementTimeUnit: selectedProgram?.retirementTimeUnit,
      disability: selectedProgram?.disability,
      disabilityTimeUnit: selectedProgram?.disabilityTimeUnit,
      terminationWithCause: selectedProgram?.terminationWithCause,
      terminationWithCauseTimeUnit: selectedProgram?.terminationWithCauseTimeUnit,
      involuntaryTermination: selectedProgram?.involuntaryTermination,
      involuntaryTerminationTimeUnit: selectedProgram?.involuntaryTerminationTimeUnit,
      voluntaryTermination: selectedProgram?.voluntaryTermination,
      voluntaryTerminationTimeUnit: selectedProgram?.voluntaryTerminationTimeUnit,
      byPassingAway: selectedProgram?.byPassingAway,
      byPassingAwayTimeUnit: selectedProgram?.byPassingAwayTimeUnit,
      exercisePrice: selectedProgram?.exercisePrice || 0,
      purchasePrice:
        selectedProgram?.instrumentTypeId === InstrumentTypesIdsEnum.RSA ? selectedProgram?.purchasePrice || 0 : 0,
      optionsExpiration: selectedProgram?.optionsExpiration || 0,
      optionsExpirationTimeUnit: (selectedProgram?.optionsExpirationTimeUnit as PeriodTimeEnum) || PeriodTimeEnum.MONTH,
      excludePostTerminationClauses: selectedProgram?.excludePostTerminationClauses,
      approvedAt: selectedProgram?.approvedAt,
      essentialTerms: selectedProgram?.essentialTerms,
      vestingRelativeExpiry: selectedProgram?.vestingRelativeExpiry,
      planTypeId: selectedProgram?.instrumentTypeId,
    };
  }, [planToEdit, selectedProgram]);

  const initialValues = assoc(
    "planTypeId",
    InstrumentTypesIdsEnum.OPTION,
    usePlanInitialValues(companyId, planData)
  ) as OwnershipPlanForm;

  const onSubmit = useCallback(
    async ({ ...values }: PlanForm, helpers: FormikHelpers<PlanForm>) => {
      const formValues = dissoc("isExcludedClause", values);

      const createPlanDTO: PlanPostDTO = {
        ...formValues,
        countryId: values?.countryId || null,
        existingPlan: false,
        file: values?.fileAgreement?.newFile,
        oldFileId: values?.fileAgreement?.oldFile?.fileId,
        excludePostTerminationClauses: values.isExcludedClause,
      };

      const res = await setRegularPlanSOThunk(createPlanDTO);

      if (res?.data) {
        helpers.resetForm();
        setIsSidebarOpen(false);
        helpers.setSubmitting(false);
        onSave?.();
        if (selectedProgram) {
          await getProgramThunk(selectedProgram.programId).then();
        }
        notify(t("notificationSuccess"), true, "success");
      }
    },
    [getProgramThunk, onSave, selectedProgram, setIsSidebarOpen, setRegularPlanSOThunk]
  );

  const valSchema = useOneOffPlanValidation();

  return (
    <div data-testid="new-options-plan-form">
      <Formik enableReinitialize initialValues={initialValues} validationSchema={valSchema} onSubmit={onSubmit}>
        <FormData planStatus={planToEdit?.statusId} handleClose={setIsSidebarOpen} isEdit={!!planToEdit} />
      </Formik>
    </div>
  );
};

export default NewOptionsPlanForm;
