import { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { PoolStatuses, PoolTypesOfSource } from "common/enums/enum";
import PoolHelper from "pages/equity-management/plans/wizards/create-pool/components/PoolHelper";
import { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "translation";

import CompanyDetailsService, {
  CompanyDetailsServiceEquityResponse,
} from "../../../../../company/CompanyDetailsService";
import useToastFormikValidator from "../../../../../hooks/useToastFormikValidator";
import DocumentStatusField from "../../../../../program/components/ProgramForm/DocumentStatusField";
import PoolApprovalByDropdown from "../../../PoolForm/PoolApprovalByDropdown";
import PoolApprovalDateField from "../../../PoolForm/PoolApprovalDateField";
import PoolAuthorizedSharesField from "../../../PoolForm/PoolAuthorizedSharesField";
import PoolDescriptionField from "../../../PoolForm/PoolDescriptionField";
import PoolDocumentsField from "../../../PoolForm/PoolDocumentsField";
import PoolExistingShareClassesField from "../../../PoolForm/PoolExistingShareClassesField";
import PoolExpiryDateField from "../../../PoolForm/PoolExpiryDateField";
import PoolNameField from "../../../PoolForm/PoolNameField";
import PoolShareClassField from "../../../PoolForm/PoolShareClassField/PoolShareClassField";
import PoolShareholderSearchField from "../../../PoolForm/PoolShareholderSearchField";
import PoolSharePriceField from "../../../PoolForm/PoolSharePriceField";
import PoolShareSourceTypeField from "../../../PoolForm/PoolShareSourceTypeField";

export type FormProps = {
  onCancel?(): void;
  onDelete?(): void;
  statusId?: number;
};

const t = createTranslation(TranslationNS.common, "pool.components.poolFormOffcanvas.form");
const th = createTranslation(TranslationNS.pages, "createPool");

const Buttons: FC<FormProps> = ({ onCancel }) => {
  const {
    setFieldValue,
    isSubmitting,
    values: { submitter },
  } = useFormikContext<PoolFormValues>();

  const handleSaveDraft = useCallback(() => {
    setFieldValue("submitter", "draft");
  }, [setFieldValue]);

  const handleCreate = useCallback(() => {
    setFieldValue("submitter", "create");
  }, [setFieldValue]);

  return (
    <SlidePanel.Actions>
      <Button
        isLoading={isSubmitting && submitter === "create"}
        isDisabled={isSubmitting}
        type="submit"
        className="me-2"
        onClick={handleCreate}
      >
        {t("button.create")}
      </Button>

      <Button
        type="submit"
        variant="secondary"
        isLoading={isSubmitting && submitter === "draft"}
        isDisabled={isSubmitting}
        onClick={handleSaveDraft}
      >
        {t("button.saveDraft")}
      </Button>

      {!!onCancel && (
        <Button className="ms-2" variant="tertiary" isDisabled={isSubmitting} onClick={onCancel}>
          {t("button.cancel")}
        </Button>
      )}
    </SlidePanel.Actions>
  );
};

const FormData: FC<FormProps> = ({ onCancel, statusId }) => {
  const [equity, setEquity] = useState<CompanyDetailsServiceEquityResponse>();
  const { values, isSubmitting } = useFormikContext<PoolFormValues>();

  useToastFormikValidator();

  useEffect(() => {
    CompanyDetailsService.equity().then(({ data: equity }) => {
      setEquity(equity);
    });
  }, []);

  return (
    <Form>
      <>
        <SlidePanel.Section title={t("title")}>
          <PoolHelper colInputSize={12} questionId="poolName" answerText={th.el("basics.nameAnswer")} className="mb-3">
            <PoolNameField />
          </PoolHelper>

          <PoolHelper
            colInputSize={12}
            questionId="poolDescription"
            answerText={th.el("basics.descriptionAnswer")}
            className="mb-3"
          >
            <PoolDescriptionField />
          </PoolHelper>

          <PoolHelper
            colInputSize={12}
            questionId="basics.poolShareSource"
            answerText={th("shares.shareSourceAnswer")}
            className="mb-3"
          >
            <PoolShareSourceTypeField isDisabled={statusId === PoolStatuses.active} />
          </PoolHelper>

          {+values.shareSourceTypeId === PoolTypesOfSource.existing && (
            <PoolHelper
              colInputSize={12}
              questionId="poolSourceStakeholderId"
              answerText={th("shares.shareholderSearchAnswer")}
              className="mb-3"
            >
              <PoolShareholderSearchField name="shareSourceStakeholderId" />
            </PoolHelper>
          )}

          {+values.shareSourceTypeId === PoolTypesOfSource.existing ? (
            <PoolHelper
              colInputSize={12}
              questionId="poolShareClass"
              answerText={th("shares.shareClassAnswer")}
              className="mb-3"
            >
              <PoolExistingShareClassesField />
            </PoolHelper>
          ) : (
            <PoolHelper
              colInputSize={12}
              questionId="poolShareClass"
              answerText={th("shares.shareClassAnswer")}
              className="mb-1"
            >
              <PoolShareClassField isDisabled={statusId === PoolStatuses.active} />
            </PoolHelper>
          )}

          <PoolHelper
            colInputSize={12}
            questionId="poolNumberOfShares"
            answerText={th.el("shares.numberOfSharesAnswer", {
              components: [<Link to="/" key={1}></Link>],
            })}
            className="mb-3"
          >
            <PoolAuthorizedSharesField totalNumberOfShares={equity?.totalNumberOfSharesIssued} className="mb-3" />
          </PoolHelper>
          <PoolHelper
            colInputSize={12}
            questionId="poolsharePrice"
            answerText={th("shares.sharePriceAnswer")}
            className="mb-3"
          >
            <PoolSharePriceField />
          </PoolHelper>

          <PoolHelper
            colInputSize={12}
            questionId="approvalDate"
            answerText={th("summary.approvalDateAnswer")}
            className="mb-3"
          >
            <PoolApprovalDateField />
          </PoolHelper>

          <PoolApprovalByDropdown />

          <PoolExpiryDateField />
        </SlidePanel.Section>

        <SlidePanel.Section title={t("documents")} isDividerVisible={false}>
          <PoolDocumentsField className="mb-3" />

          <DocumentStatusField className="mb-5" />
        </SlidePanel.Section>

        {statusId === PoolStatuses.draft ? (
          <Buttons onCancel={onCancel} />
        ) : (
          <SlidePanel.Actions>
            <Button type="submit" isLoading={isSubmitting}>
              {t("button.saveChanges")}
            </Button>

            {!!onCancel && (
              <Button className="ms-2" variant="secondary" onClick={onCancel} isDisabled={isSubmitting}>
                {t("button.cancel")}
              </Button>
            )}
          </SlidePanel.Actions>
        )}
      </>
    </Form>
  );
};

export default FormData;
