import { FC, ReactNode } from "react";
import cn from "classnames";

import { createTranslation, TranslationNS } from "translation";

import { DownloadIcon } from "../../../icons/svg";
import { Ui } from "../Typography";
import classes from "./styles.module.scss";

type PT = {
  downloadUrl: string;
  className?: string;
  text?: string;
};

const t = createTranslation(TranslationNS.common, "components.downloadButton");

const DownloadButton: FC<PT> = ({ downloadUrl, className, text }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={downloadUrl}
      tabIndex={0}
      className={cn(classes.plumColors, className, "d-flex align-items-center rounded-3 py-1_half px-4 cursor-pointer")}
    >
      <DownloadIcon fontSize={26} />
      <Ui.m className="ms-3" underline>
        {text || t("download")}
      </Ui.m>
    </a>
  );
};

export default DownloadButton;
