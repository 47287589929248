import React, { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";
import { AgreementIcon, BankIcon, ClockFileCashIcon, PieLineGraph2Icon } from "common/icons/svg";

import classes from "../CompanyInformation.module.scss";

type Props = {
  activeTab: CompanyTabsTypes;
  hideTabs?: CompanyTabsTypes[];
};

export type CompanyTabsTypes = "details" | "billing" | "bank-details" | "stock-options";

const CompanyInfoTabs: FC<Props> = ({ activeTab, hideTabs = [] }) => {
  const [_, setSearchParams] = useSearchParams();

  const menuItems = useMemo(() => {
    const tabs = [
      {
        tab: "details" as CompanyTabsTypes,
        label: "Company details",
        icon: <PieLineGraph2Icon />,
        onClick: () => {
          setSearchParams("tab=details");
        },
        isActive: equals(activeTab, "details"),
      },
      {
        tab: "billing" as CompanyTabsTypes,
        label: "Plan and Billing",
        icon: <ClockFileCashIcon />,
        onClick: () => {
          setSearchParams("tab=billing");
        },
        isActive: equals(activeTab, "billing"),
      },
      {
        tab: "bank-details" as CompanyTabsTypes,
        label: "Bank details",
        icon: <BankIcon />,
        onClick: () => {
          setSearchParams("tab=bank-details");
        },
        isActive: equals(activeTab, "bank-details"),
      },
      {
        tab: "stock-options" as CompanyTabsTypes,
        label: "Incentive agreements",
        icon: <AgreementIcon />,
        onClick: () => {
          setSearchParams("tab=stock-options");
        },
        isActive: equals(activeTab, "stock-options"),
      },
    ];

    return tabs.filter((tab) => !hideTabs.includes(tab.tab));
  }, [activeTab, hideTabs, setSearchParams]);

  return (
    <div className={classNames(classes["tabs-head"])}>
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={classNames(classes["tab-item"], {
            [classes["active"]]: item.isActive,
          })}
          onClick={item.onClick}
        >
          {item.icon}
          <Ui.s className="ms-1">{item.label}</Ui.s>
        </div>
      ))}
    </div>
  );
};

export default CompanyInfoTabs;
