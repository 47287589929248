import { MouseEventHandler, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, P, TextField, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from "common/icons/svg";
import { scrollToTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../service/aro-service";
import classes from "./styles.module.scss";

const [t, tVal] = [
  createTranslation(TranslationNS.pages, "aro.buyAroProduct"),
  createTranslation(TranslationNS.validation),
];

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email(tVal("invalidEmail")).required(tVal("required")),
  });

const BuyAroProduct = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  useEffect(() => {
    scrollToTop();
  }, []);

  const submitPaymentHandler = async (values: FormikValues) => {
    setIsLoading(true);

    const success = await aroService.postSendInvoice({
      companyId: Number(companyId),
      receiverEmail: values.email,
    });

    if (success) {
      notify(t("sendInvoiceToast"), true, "success");
      getAccountThunk();

      navigate(
        getPath(["aro", "sendDetailsToAltinn"], {
          companyId,
        })
      );
    }

    setIsLoading(false);
  };

  const { values, handleChange, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: submitPaymentHandler,
  });

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="d-flex flex-column align-items-center mx-auto" style={{ width: "55%" }}>
      <H.l className="mb-3">
        {t.el("title", {
          components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
          values: { year: year || 2024 },
        })}
      </H.l>

      <div className={classes["container"]}>
        <H.s>{t("soonReadyToSubmit")}</H.s>

        <P.l className="mt-2 mb-3" color="foregroundMedium">
          {t("paymentLeft")}
        </P.l>

        <div className={classes["product-info"]}>
          <Ui.xxxl bold className="mb-1">
            NOK 990,–
          </Ui.xxxl>
          <Ui.s color="foregroundMedium">Engangsbetaling • eks. mva</Ui.s>

          <div className={cn("my-3", classes["divider"])} />

          <div className="d-flex column-gap-6">
            <div className="d-flex flex-column gap-1">
              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s bold>Innsendelse av aksjonærregisteroppgave</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Aksjonærregister (aksjeeierbok)</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Tildel aksjer</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Loggfør aksjetransaksjoner</Ui.s>
              </div>
            </div>

            <div className="d-flex flex-column gap-1">
              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Administrer aksjeklasser</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Oppdater selskapsverdsettelse over tid</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Tilby dine eiere en egen investor-/eierportal</Ui.s>
              </div>

              <div className={classes["feature"]}>
                <CheckIcon />
                <Ui.s>Opptil 25 aksjonærer</Ui.s>
              </div>
            </div>
          </div>

          <Ui.xs color="foregroundMedium" className="mt-3">
            {t.el("readMore", {
              components: [<Link key={1} to="https://www.unlisted.ai/" target="_blank" className={classes["link"]} />],
            })}
          </Ui.xs>
        </div>

        <Ui.xl bold className="mt-6 mb-1">
          {t("receivePaymentLink")}
        </Ui.xl>

        <P.s className="mb-3" color="foregroundMedium">
          {t("sendPaymentLinkToEmail")}
        </P.s>

        <div className="d-flex">
          <TextField
            name="email"
            label={t("email")}
            value={values.email}
            error={errors.email}
            isTouched={touched.email}
            onChange={handleChange}
          />

          <Button
            variant="secondary"
            className="mt-auto ms-3"
            isDisabled={isLoading}
            isLoading={isLoading}
            iconRight={<ArrowRightIcon />}
            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          >
            {t("sendLink")}
          </Button>
        </div>

        <Button
          variant="tertiary"
          className="mt-8"
          isDisabled={isLoading}
          isLoading={isLoading}
          iconLeft={<ArrowLeftIcon />}
          onClick={goBackHandler}
        >
          {t("backToOverview")}
        </Button>
      </div>
    </div>
  );
};

export default BuyAroProduct;
