import { FC, memo } from "react";
import { Spinner as BSpinner } from "react-bootstrap";

import Spinner from "../Spinner/Spinner";
import classes from "./LoaderContent.module.scss";

type LoaderContainerProps = {
  loading: boolean;
  children?: any;
  variant?: "fullScreen" | "contentScreen";
};

const LoaderContainer: FC<LoaderContainerProps> = ({ loading, children, variant = "fullScreen" }) => {
  if (loading) {
    return (
      <div className={classes.wrap}>
        {variant === "fullScreen" ? (
          <Spinner />
        ) : (
          <div className={classes.loaderContainer}>
            <BSpinner animation="border" />
          </div>
        )}
      </div>
    );
  }

  return children;
};

export default memo(LoaderContainer);
