import { FC, useMemo } from "react";
import cn from "classnames";
import { defaultTo } from "ramda";

import { P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import useSelectedCountry from "common/hooks/useSelectedCountry";
import { ArrowUpIcon, InformationCircleIcon, ShareClassesIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import {
  CapitalIncreaseGetDTO,
  NewTransaction,
  Transaction,
  TransactionCategory,
  TransactionStatus,
} from "../../../../types";
import TransactionsList from "../../transactions-list";
import { TransactionContextMenuHandlers } from "../body";
import classes from "../transaction-item.module.scss";

type PropsTypes = {
  bundle: NewTransaction;
  eventKey: string;
  contextMenuHandlers: TransactionContextMenuHandlers;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

type OverviewPT = {
  bundleData?: CapitalIncreaseGetDTO;
};

const Overview: FC<OverviewPT> = ({ bundleData }) => {
  const fNumber = useFormatNumbers();

  const { isSelectedCountryNorway } = useSelectedCountry();

  return (
    <div
      className="d-flex justify-content-evenly pb-4 mb-3 column-gap-2 row-gap-2 flex-wrap"
      style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}
    >
      {isSelectedCountryNorway ? (
        <div className="d-flex flex-column" style={{ flexBasis: "32%" }}>
          <div className={cn("p-3 d-flex", classes["body-item-section"])}>
            <ArrowUpIcon />

            <div className="ms-2">
              <Ui.xxl bold>{fNumber(bundleData?.totalIncrease, "amount")}</Ui.xxl>
              <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
            </div>
          </div>

          <div className={cn("mt-2 p-3 d-flex", classes["body-item-section"])}>
            <ShareClassesIcon />

            <div className="ms-2">
              <Ui.xxl bold>{bundleData?.shareClassName}</Ui.xxl>
              <Ui.xs className="text-medium">share class</Ui.xs>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cn("p-3 d-flex", classes["body-item-section"], {
            [classes["large-container"]]: !isSelectedCountryNorway,
          })}
        >
          <ArrowUpIcon />

          <div className="ms-2">
            <Ui.xxl bold>{fNumber(bundleData?.totalIncrease, "amount")}</Ui.xxl>
            <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
          </div>
        </div>
      )}

      <div
        className={cn("p-3", classes["body-item-section"], {
          [classes["large-container"]]: !isSelectedCountryNorway,
        })}
      >
        <div>
          <Ui.xxl bold>{fNumber((bundleData?.shareCapital || 0) + (bundleData?.totalSharePremium || 0))}</Ui.xxl>
          <Ui.xs className="text-medium">{t("totalAmount")}</Ui.xs>
        </div>

        {isSelectedCountryNorway ? (
          <>
            <div className="d-flex justify-content-between mt-3">
              <Ui.s>Premium total</Ui.s>
              <Ui.s className="text-medium">{fNumber(bundleData?.totalSharePremium)}</Ui.s>
            </div>

            <div className="my-2" style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

            <div className="d-flex justify-content-between">
              <Ui.s>Share capital</Ui.s>
              <Ui.s className="text-medium">{fNumber(bundleData?.shareCapital)}</Ui.s>
            </div>
          </>
        ) : null}
      </div>

      {!isSelectedCountryNorway ? (
        <div className={cn("p-3 d-flex", classes["body-item-section"], [classes["large-container"]])}>
          <ShareClassesIcon />

          <div className="ms-2">
            <Ui.xxl bold>{bundleData?.shareClassName}</Ui.xxl>
            <Ui.xs className="text-medium">share class</Ui.xs>
          </div>
        </div>
      ) : null}

      <div
        className={cn("p-3", classes["body-item-section"], {
          [classes["large-container"]]: !isSelectedCountryNorway,
        })}
      >
        <div>
          <Ui.xxl bold>{fNumber(bundleData?.sharePrice, "sharePrice")}</Ui.xxl>
          <Ui.xs className="text-medium">{t("sharePrice")}</Ui.xs>
        </div>

        {isSelectedCountryNorway ? (
          <>
            <div className="d-flex justify-content-between mt-3">
              <Ui.s>Premium</Ui.s>
              <Ui.s className="text-medium">{fNumber(bundleData?.sharePremium, "sharePrice")}</Ui.s>
            </div>

            <div className="my-2" style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

            <div className="d-flex justify-content-between">
              <Ui.s>Nominal value</Ui.s>
              <Ui.s className="text-medium">{fNumber(bundleData?.nominalValuePerShare, "sharePrice")}</Ui.s>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const CapitalIncreaseImportBody: FC<PropsTypes> = ({ bundle, contextMenuHandlers }) => {
  const fNumber = useFormatNumbers();

  const totalAmountOfSharesInsideTransactions = useMemo(
    () =>
      defaultTo([] as Transaction[], bundle.bundleData?.transactions).reduce(
        (acc: number, curr: Transaction) => acc + curr.numberOfShares,
        0
      ),
    [bundle.bundleData?.transactions]
  );

  return (
    <>
      <div className={cn("p-4 mb-2", classes.bodyContainer, classes.darkBackground)}>
        <Overview bundleData={bundle.bundleData} />

        {bundle.bundleData?.description && (
          <div>
            <P.s
              className="text-medium mb-4"
              dangerouslySetInnerHTML={{
                __html: bundle.bundleData?.description?.replace(/\r\n/g, "<br />"),
              }}
            />
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between mb-2">
          <Ui.m bold className="text-medium">
            {t("transactions")}
          </Ui.m>

          {bundle.bundleData?.totalIncrease === totalAmountOfSharesInsideTransactions ? null : (
            <Tag variant="draft">
              <WarningIcon className="me-1" />
              {defaultTo(0, bundle.bundleData?.totalIncrease) > totalAmountOfSharesInsideTransactions
                ? t("sharesRemaining", {
                    shares: fNumber(
                      defaultTo(0, bundle.bundleData?.totalIncrease) - totalAmountOfSharesInsideTransactions,
                      "amount"
                    ),
                  })
                : t("sharesTooMany", {
                    shares: fNumber(
                      totalAmountOfSharesInsideTransactions - defaultTo(0, bundle.bundleData?.totalIncrease),
                      "amount"
                    ),
                  })}
            </Tag>
          )}
        </div>

        {(bundle.bundleData?.transactions?.length || -1) > 0 ? (
          <TransactionsList
            isMini
            transactions={bundle.bundleData?.transactions || []}
            bundleView
            {...contextMenuHandlers}
          />
        ) : (
          <div className="border-1 rounded-2 py-2 px-3" style={{ backgroundColor: scssVariables.surface1 }}>
            <Ui.l bold className="mb-3">
              {t("emptyTitle")}
            </Ui.l>
            <div className="d-flex align-items-center text-low">
              <InformationCircleIcon />
              <Ui.xs className="ms-2">{t("emptyDescription")}</Ui.xs>
            </div>
          </div>
        )}
      </div>
      {bundle.categoryId === TransactionCategory.CapitalIncrease && bundle.statusId === TransactionStatus.Confirmed && (
        <Ui.s color="foregroundLow" className={cn("py-2 px-4", classes.bodyContainer, classes.darkBackground)}>{`${t(
          "totalSharesAfterTransactions"
        )}: ${fNumber(bundle.numbersOfSharesInCompany, "amount")}`}</Ui.s>
      )}
    </>
  );
};

export default CapitalIncreaseImportBody;
