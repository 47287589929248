import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Divider, H, P } from "common/components/atoms";
import { ChevronLeftIcon, ChevronRightIcon, ExclamationMarkCircle } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DashboardTable from "../../../../../../../fundraising/components/DashboardTable/DashboardTable";
import TransactionsContext from "../../../../transactions.context";
import useCapTablePreview from "./use-captable-preview";

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions.captablePreview");

const CaptablePreview = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const previewData = TransactionsContext.useStoreState((store) => store.captablePreview);
  const warnings = TransactionsContext.useStoreState((store) => store.currentWarnings);

  const { columns } = useCapTablePreview();

  const table = useReactTable({
    data: previewData,
    columns,
    meta: {
      footerShow: true,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const handleBack = () => {
    if (!warnings.length) {
      navigate(getEMPath(["importTransactions", "downloadTemplate"], { companyId }));
    } else {
      navigate(getEMPath(["importTransactions", "importStatus"], { companyId }));
    }
  };

  const handleReapload = () => {
    navigate(getEMPath(["importTransactions", "downloadTemplate"], { companyId }));
  };

  const handleSubmit = () => {
    navigate(getEMPath(["importTransactions", "transactionsPreview"], { companyId }));
  };

  return (
    <WizardContent.Content step={3}>
      <H.s className="mb-4">{t("title")}</H.s>
      <P.m className="mb-3">{t("description")}</P.m>
      <div
        className="d-flex justify-content-center py-1_half px-2 mb-4 rounded-2"
        style={{ backgroundColor: scssVariables.warning050 }}
      >
        <ExclamationMarkCircle fontSize={24} color={scssVariables.warning900} />
        <P.s className="ms-2">{t("warningMessage")}</P.s>
      </div>
      <DashboardTable type="capTable" table={table} />
      <P.m className="mb-2 mt-7">{t("reuploadDescription")}</P.m>
      <Button variant="secondary" className="mb-4" onClick={handleReapload}>
        {t("reuploadBtn")}
      </Button>
      <Divider className="mb-7" />
      <div className="d-flex justify-content-between mt-7">
        <Button isOnlyIcon variant="secondary" onClick={handleBack}>
          <ChevronLeftIcon />
        </Button>
        <Button iconRight={<ChevronRightIcon />} onClick={handleSubmit}>
          {t("previewBtn")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default CaptablePreview;
