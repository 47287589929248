import { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Badge, Button, Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { EventDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import SetFundingEventSlidePanel from "../../components/slide-panels/SetFundingEventSlidePanel/SetFundingEventSlidePanel";
import RoundTable from "./RoundTable/RoundTable";

type ConfirmedSubscriptionProps = {
  rounds?: EventDTO[];
  fundraiseId: number;
};
const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription");

const tCommon = createTranslation(TranslationNS.common, "noAccess");

const ConfirmedSubscription: FC<ConfirmedSubscriptionProps> = ({ rounds, fundraiseId }) => {
  const { companyId } = useParams();
  const { hasFullAccess } = useFeatures(FEATURES.fundraise, [CompanyFeatures.Growth]);

  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const [showCreateRound, setShowCreateRound] = useState(false);

  const handleManageCreateRound = useCallback(() => setShowCreateRound((prev) => !prev), []);

  const onSaveRound = useCallback(() => {
    getFundraiseSummaryThunk(fundraiseId).then();
  }, [fundraiseId, getFundraiseSummaryThunk]);

  const subscriptionLength = useMemo(() => {
    return rounds?.reduce((prev, curr) => prev + curr.instruments.length, 0) || 0;
  }, [rounds]);

  if (!rounds) return null;

  return (
    <div>
      <ExpansionPanel.Toggle eventKey={"confirmedSubscription"}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh" className="d-flex align-items-center gap-1">
              {t("title")} <Badge>{subscriptionLength}</Badge>
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              {t("subtitle")}
            </Ui.xs>
          </div>
          <ExpansionPanel.ToggleIgnore className="me-3">
            <Button
              variant="secondary"
              size="s"
              onClick={handleManageCreateRound}
              isFocusDisabled
              isDisabled={!hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              {t("addFundingRound")}
            </Button>
          </ExpansionPanel.ToggleIgnore>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse className="pt-1 pb-1" eventKey={"confirmedSubscription"}>
        {rounds.map((round, index) => (
          <RoundTable
            {...round}
            key={round.fundraiseEventId}
            instruments={round.instruments}
            description={t("fundingRound")}
            index={index + 1}
            companyId={companyId ? +companyId : 0}
            fundraiseId={fundraiseId}
          />
        ))}
      </ExpansionPanel.Collapse>

      <SetFundingEventSlidePanel
        onHide={handleManageCreateRound}
        show={showCreateRound}
        fundraiseId={fundraiseId}
        onSaved={onSaveRound}
      />
    </div>
  );
};

export default ConfirmedSubscription;
