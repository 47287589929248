import { InfoAlert, P, Ui } from "common/components/atoms";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "aro.main.banner");

const TopBanner = () => (
  <InfoAlert
    type="Info"
    closable
    customContent={
      <div className="d-flex">
        <InformationCircleIcon
          fontSize={24}
          style={{ minWidth: "24px" }}
          className="me-2"
          color={scssVariables.information900}
        />
        <div>
          <Ui.m bold className="mb-half">
            {t("title")}
          </Ui.m>
          <P.s className="mb-1_half">{t("content")}</P.s>
          <a
            style={{ color: scssVariables.information900 }}
            target="_blank"
            rel="noopener noreferrer"
            href={"https://help.unlisted.ai/nb/articles/10353569-innlevering-av-aksjonaerregisteroppgaven#h_3c6d561efa"}
            className="text-decoration-underline"
          >
            <Ui.s>{t("link")}</Ui.s>
          </a>
        </div>
      </div>
    }
  />
);

export default TopBanner;
