import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, Tag, Tooltip, Ui } from "common/components/atoms";
import { QuestionCircleIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FundraiseMenu from "./components/FundraiseMenu/FundraiseMenu";
import CancelFundraiseModal from "./components/modals/CancelFundraiseModal/CancelFundraiseModal";
import SetFundraiseSlidePanel from "./components/slide-panels/SetFundraisingSlidePanel/SetFundraiseSlidePanel";
import Kickstart from "./overview/components/Kickstart/Kickstart";

type FundraisingProps = {
  children: ReactNode;
};

const t = createTranslation(TranslationNS.pages, "fundraising");
const tCommon = createTranslation(TranslationNS.common, "noAccess");
const Fundraising: FC<FundraisingProps> = ({ children }) => {
  const { hasSubscriptionAccess, hasSysAdminAccess, hasFullAccess, hasViewAccess } = useFeatures(FEATURES.fundraise, [
    CompanyFeatures.Growth,
  ]);

  const { companyId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { getOngoingFundraiseThunk, getFundraiseThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { fundraiseId } = useStoreState((state) => state.fundraisingModel);
  const hasMissingShareClasses = useStoreState((state) => state.fundraisingModel?.summary?.hasMissingShareClasses);

  const [showFundraiseSlidePanel, setShowFundraiseSlidePanel] = useState(false);
  const [showCancelFundraiseModal, setShowCancelFundraiseModal] = useState(false);

  const handleManageFundraiseSlidePanel = useCallback(() => {
    setShowFundraiseSlidePanel((prev) => !prev);
  }, []);

  const handleCancelFundraiseModal = useCallback(() => {
    setShowCancelFundraiseModal((prev) => !prev);
  }, []);

  const handleFinishFundraise = useCallback(() => {
    navigate(getPath(["fundraising", "finishCampaign", "events"], { companyId }));
  }, [companyId, navigate]);

  const handleLoadPage = useCallback(() => {
    setLoading(true);
    getOngoingFundraiseThunk(companyId ? +companyId : 0)
      .then((res) => {
        if (res.data.fundraiseId) {
          getFundraiseThunk(res.data.fundraiseId).finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [companyId, getFundraiseThunk, getOngoingFundraiseThunk]);

  useEffect(() => {
    handleLoadPage();
  }, [handleLoadPage]);

  if (((!hasFullAccess && !hasViewAccess) || !hasSubscriptionAccess) && !hasSysAdminAccess) {
    return children;
  }

  return (
    <PageContent style={{ minWidth: "600px", width: "100%" }}>
      <LoaderContainer loading={loading}>
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>
          {!fundraiseId ? (
            <div>
              <Button
                onClick={handleManageFundraiseSlidePanel}
                isFocusDisabled
                isDisabled={!hasFullAccess}
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              >
                {t("startNewFundraise")}
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              <Button
                variant="tertiary"
                onClick={handleCancelFundraiseModal}
                isFocusDisabled
                isDisabled={!hasFullAccess}
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              >
                {t("cancelFundraise")}
              </Button>
              <Button
                isFocusDisabled
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
                onClick={handleFinishFundraise}
                className="ms-2 d-flex align-items-center gap-1"
                isDisabled={hasMissingShareClasses || !hasFullAccess}
              >
                {t("finishFundraise")}
                <Tooltip
                  popupContent={
                    <div style={{ width: 148, textAlign: "left" }}>
                      <Ui.xs bold color="foregroundMedium">
                        {t("finishTooltipTitle")}
                      </Ui.xs>
                      <Ui.xs color="foregroundLow">{t("finishTooltipDescription")}</Ui.xs>
                    </div>
                  }
                >
                  <div>
                    <Ui.m style={{ cursor: "pointer" }}>
                      <QuestionCircleIcon fontSize={20} />
                    </Ui.m>
                  </div>
                </Tooltip>
              </Button>
            </div>
          )}
        </PageContent.Header>
        {fundraiseId && <FundraiseMenu handleEditFundraise={handleManageFundraiseSlidePanel} />}

        {fundraiseId ? children : <Kickstart />}
        <SetFundraiseSlidePanel
          show={showFundraiseSlidePanel}
          onHide={handleManageFundraiseSlidePanel}
          fundraiseId={fundraiseId}
          onSaved={handleLoadPage}
        />
        <CancelFundraiseModal
          fundraiseId={fundraiseId || 0}
          onHide={handleCancelFundraiseModal}
          show={showCancelFundraiseModal}
        />
      </LoaderContainer>
    </PageContent>
  );
};
export default Fundraising;
