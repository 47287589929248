import { FC, useCallback, useMemo, useState } from "react";
import { format } from "date-fns";

import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { MenuItem } from "common/components/atoms/ContextMenu/ContextMenu";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon, PlusIcon } from "common/icons/svg";
import { EventDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DeleteRoundModal from "../../../components/modals/DeleteRoundModal/DeleteRoundModal";
import AddInstrumentsSlidePanel from "../../../components/slide-panels/AddInstrumentsSlidePanel/AddInstrumentsSlidePanel";
import SetFundingEventSlidePanel from "../../../components/slide-panels/SetFundingEventSlidePanel/SetFundingEventSlidePanel";
import SetNewSubscriptionSidePanel from "../../../components/slide-panels/SetNewSubscriptionSidePanel/SetNewSubscriptionSlidePanel";
import classes from "./Round.module.scss";

type RoundHeaderProps = {
  index: number;
  description: string;
  companyId: number;
  fundraiseId: number;
} & Omit<EventDTO, "instruments">;

const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription.round");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const RoundHeader: FC<RoundHeaderProps> = ({ index, description, companyId, fundraiseId, ...eventProps }) => {
  const fNumber = useFormatNumbers();
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { hasFullAccess } = useFeatures(FEATURES.fundraise, [CompanyFeatures.Growth]);

  const [showAddInstruments, setShowAddInstruments] = useState(false);
  const [showUpdateRound, setShowUpdateRound] = useState(false);
  const [showDeleteRoundModal, setShowDeleteRoundModal] = useState(false);
  const [showCreateShareIssue, setShowCreateShareIssue] = useState(false);

  const handleManageDeleteRound = useCallback(() => {
    setShowDeleteRoundModal((prev) => !prev);
  }, []);

  const manageAddInstruments = useCallback(() => {
    setShowAddInstruments((prev) => !prev);
  }, []);

  const handleManageUpdateRound = useCallback(() => {
    setShowUpdateRound((prev) => !prev);
  }, []);

  const handleManageCreateShareIssue = useCallback(() => {
    setShowCreateShareIssue((prev) => !prev);
  }, []);

  const handleRoundSaved = useCallback(() => {
    getFundraiseSummaryThunk(fundraiseId).then();
  }, [fundraiseId, getFundraiseSummaryThunk]);

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const items: MenuItem[] = [
      {
        key: "edit",
        icon: <EditIcon />,
        label: t("edit"),
        onClick: handleManageUpdateRound,
      },
    ];

    if (eventProps.fundraiseEventId !== 1) {
      items.push({
        key: "delete",
        icon: <DeleteIcon />,
        label: t("delete"),
        onClick: handleManageDeleteRound,
        type: "delete",
      });
    }

    return items;
  }, [eventProps.fundraiseEventId, handleManageDeleteRound, handleManageUpdateRound]);

  return (
    <div className={classes.header}>
      <div className="d-flex align-items-center">
        <div className={classes.step}>
          <Ui.m bold>{index}</Ui.m>
        </div>
        <div>
          <Ui.l bold color="foregroundMedium">
            {eventProps.name}
          </Ui.l>
          <Ui.xs className={classes.description} color="foregroundMedium">
            {description}
          </Ui.xs>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(eventProps.totalAmount)}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("totalAmount")}</Ui.xs>
        </div>

        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(eventProps.newShares, "amount")}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("newShares")}</Ui.xs>
        </div>

        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(eventProps.totalIssuedShares, "amount")}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("totalIssuedShares")}</Ui.xs>
        </div>

        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {format(new Date(eventProps.transactedAt), "dd.LL.yyyy HH:mm")}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("transactionDate")}</Ui.xs>
        </div>

        <div className={classes.item}>
          <Button
            variant="secondary"
            isOnlyIcon
            size="s"
            onClick={eventProps.fundingEventTypeId === 1 ? handleManageCreateShareIssue : manageAddInstruments}
            isFocusDisabled
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            <PlusIcon />
          </Button>
          <ContextMenu items={menuItems} isDisabled={!hasFullAccess}>
            <Button
              className="ms-2"
              variant="secondary"
              isOnlyIcon
              size="s"
              isFocusDisabled
              isDisabled={!hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </div>
      </div>

      <AddInstrumentsSlidePanel
        companyId={companyId}
        show={showAddInstruments}
        name={eventProps.name}
        onHide={manageAddInstruments}
        fundraiseRoundId={eventProps.fundraiseEventId}
        fundingRoundTypeId={eventProps.fundingEventTypeId}
      />

      <DeleteRoundModal
        fundraiseRoundId={eventProps.fundraiseEventId}
        fundraiseId={fundraiseId}
        roundName={eventProps.name}
        onHide={handleManageDeleteRound}
        show={showDeleteRoundModal}
      />

      <SetFundingEventSlidePanel
        show={showUpdateRound}
        onHide={handleManageUpdateRound}
        fundraiseId={fundraiseId}
        fundraiseRoundId={eventProps.fundraiseEventId}
        onSaved={handleRoundSaved}
      />

      <SetNewSubscriptionSidePanel
        show={showCreateShareIssue}
        onHide={handleManageCreateShareIssue}
        fundraiseRoundId={eventProps.fundraiseEventId}
        onSaved={handleRoundSaved}
      />
    </div>
  );
};

export default RoundHeader;
