import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { fields, PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { useStoreState } from "../../../../store/store";
import { createTranslation, TranslationNS } from "../../../../translation";
import TextField from "../../../components/atoms/TextField/TextField";
import useCurrencyById from "../../../hooks/useCurrencyById";

type Props = {
  className?: string;
};
const t = createTranslation(TranslationNS.common, "pool.components.poolForm.sharePriceField");

const PoolSharePriceField: FC<Props> = ({ className }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Pick<PoolFormValues, "sharePrice">>();

  const account = useStoreState((state) => state.account);
  const activeCompanyModel = useStoreState((state) => state.activeCompanyModel);

  const selectedCompany = useMemo(
    () => account?.user?.companies.find((el) => el.id === activeCompanyModel.companyId),
    [account?.user?.companies, activeCompanyModel.companyId]
  );

  const { currencySymbol } = useCurrencyById(selectedCompany?.currencyId);

  return (
    <TextField
      type="number"
      className={className}
      label={t("label")}
      isTouched={touched.sharePrice}
      error={errors.sharePrice}
      value={values.sharePrice as number}
      name={fields.sharePrice}
      onChange={handleChange}
      onBlur={handleBlur}
      iconRight={currencySymbol}
    />
  );
};

export default PoolSharePriceField;
