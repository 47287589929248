import { FC, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions } from "store/store";

import { getEMPath, getFundraisePath } from "../../../app/Router/RouterHelper";
import StripeManagePlan from "./StripeManagePlan/StripeManagePlan";
import DefaultNoSubscriptionTemplate from "./templates/DefaultNoSubscriptionTemplate";
import DocumentsTemplate from "./templates/DocumentsTemplate";
import ExerciseTemplate from "./templates/ExercisingTemplate";
import FinancialInstrumentsTemplate from "./templates/FinancialInstrumentsTemplate";
import FundraiseTemplate from "./templates/FundraiseTemplate";
import IncentiveAgreementsTemplate from "./templates/IncentiveAggreementsTemplate";
import PoolsAndProgramTemplate from "./templates/PoolsAndProgramTemplate";

const NoSubscription: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { pathname } = useLocation();

  const getSubscriptionContactInfoThunk = useStoreActions((actions) => actions.company.getSubscriptionContactInfoThunk);

  useEffect(() => {
    if (companyId) {
      getSubscriptionContactInfoThunk(companyId).then();
    }
  }, [companyId, getSubscriptionContactInfoThunk]);

  const SubscriptionTemplate = useMemo(() => {
    const path = {
      [getEMPath(["plans", "poolsAndPrograms"], { companyId })]: PoolsAndProgramTemplate,
      [getEMPath(["plans", "agreements"], { companyId })]: IncentiveAgreementsTemplate,
      [getEMPath(["plans", "exercising"], { companyId })]: ExerciseTemplate,
      [getEMPath(["ownership", "financialInstruments"], { companyId })]: FinancialInstrumentsTemplate,
      [getEMPath(["ownership", "documents"], { companyId })]: DocumentsTemplate,
      [getEMPath(["createPool", "basic"], { companyId })]: PoolsAndProgramTemplate,
      [getEMPath(["createProgram", "main"], { companyId })]: PoolsAndProgramTemplate,
      [getEMPath(["createPlan", "start"], { companyId })]: IncentiveAgreementsTemplate,
      [getEMPath(["createOneOffPlan", "planReceiver"], { companyId })]: IncentiveAgreementsTemplate,
      [getFundraisePath(["overview"], { companyId })]: FundraiseTemplate,
    };
    return path[pathname.replace(/\/$/, "")];
  }, [companyId, pathname]);

  return (
    <PageContent>
      {SubscriptionTemplate ? <SubscriptionTemplate /> : <DefaultNoSubscriptionTemplate />}
      <StripeManagePlan />
    </PageContent>
  );
};

export default NoSubscription;
