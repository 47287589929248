import { useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Divider, H, P } from "common/components/atoms";
import { ChevronLeftIcon, ChevronRightIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import TransactionsList from "../../../transactions-list/transactions-list";

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions.transactionsPreview");

const TransactionsPreview = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const importFile = TransactionsContext.useStoreState((store) => store.importFile);
  const deleteExistingTransactions = TransactionsContext.useStoreState((store) => store.deleteExistingTransactions);
  const transactionsPreview = TransactionsContext.useStoreState((store) => store.transactionsPreview);
  const postConfirmImportTransaction = TransactionsContext.useStoreActions(
    (actions) => actions.postConfirmImportTransaction
  );

  const handleReapload = () => {
    navigate(getEMPath(["importTransactions", "downloadTemplate"], { companyId }));
  };

  const handleBack = () => {
    navigate(getEMPath(["importTransactions", "captablePreview"], { companyId }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (companyId && importFile?.newFile) {
      const success = await postConfirmImportTransaction({
        companyId: +companyId,
        file: importFile.newFile,
        deleteExistingTransactions,
      });

      if (success) {
        navigate(getEMPath(["ownership", "transactions"], { companyId }));
      }
    }
    setIsLoading(false);
  };

  return (
    <WizardContent.Content step={4}>
      <H.s className="mb-4">{t("title")}</H.s>
      <P.m className="mb-3">{t("description")}</P.m>

      <TransactionsList isMini portfolioView transactions={transactionsPreview} bundleView importView />
      <P.m className="mb-2">{t("reuploadDescription")}</P.m>
      <Button variant="secondary" className="mb-4" onClick={handleReapload} isDisabled={isLoading}>
        {t("reuploadBtn")}
      </Button>
      <Divider className="mb-7" />
      <div className="d-flex justify-content-between">
        <Button variant="secondary" isOnlyIcon onClick={handleBack} isDisabled={isLoading}>
          <ChevronLeftIcon />
        </Button>
        <Button iconRight={<ChevronRightIcon />} onClick={handleSubmit} isLoading={isLoading}>
          {t("finish")}
        </Button>
      </div>
    </WizardContent.Content>
  );
};

export default TransactionsPreview;
