import { FC } from "react";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { fields, UpdateValuationFormValues } from "../useUpdateValuationForm";
import classes from "./UpdateValuationField.module.scss";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.valuation.fields.valuation");

const UpdateValuationField: FC<Props> = ({ className }) => {
  const fNumber = useFormatNumbers();

  const { values, handleChange } = useFormikContext<UpdateValuationFormValues>();

  const isUpdate = values.updateType === "update";

  return (
    <div className={classNames(classes.wrap, className)}>
      <label className={classNames(classes.block, { [classes.isChecked]: isUpdate })}>
        <div>
          <Ui.m color={isUpdate ? "primary1" : "foregroundMedium"}>{t("label_1")}</Ui.m>
          <Ui.xxxl color={isUpdate ? "primary1" : "foregroundMedium"} bold className="mt-2">
            {fNumber(values.postMoneyValuation)}
          </Ui.xxxl>
          <P.s color="foregroundMedium" className="mt-2 mb-2">
            {t("description_1")}
          </P.s>
          <Tag className="mt-2" variant="information">
            {t("mostCommon")}
          </Tag>
        </div>
        <div className={classes.radio}>
          <Form.Check.Input
            type="radio"
            name={fields.updateType}
            value="update"
            checked={isUpdate}
            onChange={handleChange}
          />
        </div>
      </label>
      <label className={classNames(classes.block, { [classes.isChecked]: !isUpdate })}>
        <div>
          <Ui.m> {t("label_2")}</Ui.m>
          <Ui.xxxl bold className="mt-2">
            {fNumber(values.currentCompanyValuation)}
          </Ui.xxxl>
          <P.s color="foregroundMedium" className="mt-2 mb-2">
            {t("description_2")}
          </P.s>
        </div>
        <div className={classes.radio}>
          <Form.Check.Input
            type="radio"
            name={fields.updateType}
            value="keep"
            checked={!isUpdate}
            onChange={handleChange}
          />
        </div>
      </label>
    </div>
  );
};

export default UpdateValuationField;
