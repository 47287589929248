import { FC, useMemo } from "react";

import { sortCaptableData } from "common/utils/functions";
import { ValuationSingle } from "store/types";

import classes from "../../valuation.module.scss";
import ValuationContext from "../../ValuationContext";
import { ValuationHistoryTableSortValue } from "./ValuationHistoryTable";
import ValuationTableRow from "./ValuationTableRow";

type ValuationTableBodyProps = {
  data: ValuationSingle[];
  sortedValue: ValuationHistoryTableSortValue;
};

const ValuationTableBody: FC<ValuationTableBodyProps> = ({ data, sortedValue }) => {
  const { setRemoveValuation } = ValuationContext.useStoreActions((actions) => actions);

  const sortedData = useMemo(() => {
    return sortCaptableData({
      data,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [data, sortedValue.field, sortedValue.type]);

  return (
    <tbody className={classes["table-body"]}>
      {sortedData.map((el) => (
        <ValuationTableRow key={el.id} {...el} onValuationRemove={setRemoveValuation} />
      ))}
    </tbody>
  );
};

export default ValuationTableBody;
