import axios from "axios";
import { action, thunk } from "easy-peasy";
import { isEmpty } from "ramda";

import { CompanyModel, PoolsAndPrograms, SigningManagerType } from "./types";

export const company: CompanyModel = {
  // selectors
  isCompanyMenuOpen: true,
  generalInfo: null,
  bankInformation: null,
  createdCompany: null,
  contactPersonDropDown: null,
  currency: {
    id: 1,
    name: "Norwegian Krone",
    symbol: "kr",
  },
  ownershipOverview: null,
  ownershipPlans: null,
  poolsAndPrograms: null,
  valuation: null,
  isLoading: false,
  companySigningManagers: null,
  subscriptionContactInfo: null,
  // actions
  setIsCompanyMenuOpen: action((state, payload) => {
    state.isCompanyMenuOpen = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setGeneralInfo: action((state, payload) => {
    state.generalInfo = payload;
  }),
  setBankInformation: action((state, payload) => {
    state.bankInformation = payload;
  }),
  setCompanyLogo: action((state, payload) => {
    if (state.generalInfo) {
      state.generalInfo.companyLogo = payload;
    }
  }),
  setContactPersonDropDown: action((state, payload) => {
    state.contactPersonDropDown = payload.map((person) => {
      if (!isEmpty(person.firstName)) {
        return {
          name: `${person.firstName} ${person.lastName}`,
          teamMemberId: person.teamMemberId,
        };
      } else {
        return {
          name: person.email,
          teamMemberId: person.teamMemberId,
        };
      }
    });
  }),
  setCreatedCompany: action((state, payload) => {
    state.createdCompany = payload;
  }),
  setCurrency: action((state, payload) => {
    state.currency = payload;
  }),
  setOwnershipPlans: action((state, payload) => {
    state.ownershipPlans = payload;
  }),
  setOwnershipOverview: action((state, payload) => {
    state.ownershipOverview = payload;
  }),
  setPoolsAndPrograms: action((state, payload) => {
    state.poolsAndPrograms = payload;
  }),
  setValuation: action((state, payload) => {
    state.valuation = payload;
  }),
  setCompanySigningManagers: action((state, payload) => {
    state.companySigningManagers = payload;
  }),
  setSubscriptionContactInfo: action((state, payload) => {
    state.subscriptionContactInfo = payload;
  }),
  //thunks
  getGeneralInfoThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/company/details/${id}`);

      if (request.status === 200) {
        actions.setGeneralInfo(request.data);
        if (request.data.logoFileDownloadPath) {
          actions.setCompanyLogo(process.env.REACT_APP_BLOB_PUBLIC_URL + request.data.logoFileDownloadPath);
        }
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),

  getOwnershipOverviewThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/ownership-overview/${id}`);

      if (request.status === 200) {
        actions.setOwnershipOverview(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getOwnershipPlansThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/ownership-plan/manage/${id}`);

      if (request.status === 200) {
        actions.setOwnershipPlans(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getPoolsAndProgramsThunk: thunk(async (actions, id, { getStoreState }) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get<PoolsAndPrograms>(
        `/api/pools-and-programs/${id || getStoreState().activeCompanyModel?.companyId}`
      );

      if (request.status === 200) {
        actions.setPoolsAndPrograms(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getValuationThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/company/valuation/${id}`);

      if (request.status === 200) {
        actions.setValuation(request.data);

        return request.data;
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getBankInformationThunk: thunk(async (actions, companyId, { fail }) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/bank-information/${companyId}`);
      actions.setBankInformation(request.data);
      return request.data;
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      actions.setIsLoading(false);
    }
  }),
  setBankInformationThunk: thunk(async (actions, payload, { fail }) => {
    try {
      actions.setIsLoading(true);
      await axios.post("/api/ownership/bank-information", payload);
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getCompanySigningManagersThunk: thunk(async (actions, id, { fail }) => {
    try {
      // actions.setIsLoading(true);
      const request = await axios.get<{ managers?: SigningManagerType[] }>(`/api/ownership/plan/managers/${id}`);

      if (request.status === 200) {
        actions.setCompanySigningManagers(request.data?.managers || []);
      }
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      // actions.setIsLoading(false);
    }
  }),
  getSubscriptionContactInfoThunk: thunk(async (actions, companyId) => {
    try {
      const request = await axios.get(`/api/company/subscription-contact-info/${companyId}`);
      actions.setSubscriptionContactInfo(request.data);

      return request;
    } catch (e) {
      return Promise.reject(e);
    }
  }),
};
