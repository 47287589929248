import { H, P, Ui } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import useSubscriptionType from "../../../hooks/useSubscriptionType";
import BottomBanner from "./components/BottomBanner";
import classes from "./templates.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription.fundraiseTemplate");

const FundraiseTemplate = () => {
  const { hasSubscriptionType } = useSubscriptionType();

  return (
    <div className={classes.wrap}>
      <div className={classes.topBanner}>
        <div className="w-50">
          <H.xs color="foregroundHigh">{hasSubscriptionType ? t("title_u") : t("title_s")}</H.xs>
          <P.m color="foregroundHigh" className="mt-3">
            {t("description_1")}
          </P.m>
          <P.m color="foregroundHigh" className="mt-3">
            {t("description_2")}
          </P.m>
          <Ui.m bold className="mt-3">
            {" "}
            {t("description_3")}
          </Ui.m>
        </div>
        <div className="w-50">
          <img src={"/banners/fundraiseTopBanner.png"} alt="Pools and Programs" />
        </div>
      </div>
      <BottomBanner
        bannerImage="/banners/fundraiseBottomBanner.png"
        title={t(`bottomBannerTitle_${hasSubscriptionType ? "u" : "s"}`)}
      />
    </div>
  );
};

export default FundraiseTemplate;
