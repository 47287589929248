import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, LoaderContainer, P } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FundraiseCapitalIncreaseList from "../../preview-results/FundraiseTransaction/FundraiseTransactionList/FundraiseCapitalIncreaseList";
import classes from "../ReviewEvents/ReviewEvents.module.scss";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.transactions");

const ReviewTransactions = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fundraisePreview = useStoreState((state) => state.fundraisingModel.fundraisePreview);

  const { getOngoingFundraiseThunk, getFundraisePreviewThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleClose = useCallback(() => {
    navigate(getPath(["fundraising", "finishCampaign", "events"], { companyId }));
  }, [companyId, navigate]);

  const handleNext = useCallback(() => {
    navigate(getPath(["fundraising", "finishCampaign", "capTable"], { companyId }));
  }, [companyId, navigate]);

  const handleLoadPage = useCallback(() => {
    setLoading(true);
    getOngoingFundraiseThunk(companyId ? +companyId : 0)
      .then((res) => {
        if (res.data.fundraiseId) {
          getFundraisePreviewThunk(res.data.fundraiseId).finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [companyId, getFundraisePreviewThunk, getOngoingFundraiseThunk]);

  useEffect(() => {
    handleLoadPage();
  }, [handleLoadPage]);

  const data = fundraisePreview?.transactionBundles || [];

  return (
    <WizardContent.Content className={classes.wrap} step={2}>
      <LoaderContainer loading={loading} variant="contentScreen">
        <H.xs>{t("title")}</H.xs>
        <P.m className="mt-5">{t("description")}</P.m>

        <FundraiseCapitalIncreaseList transactions={data} />

        <div className="d-flex justify-content-between mt-5">
          <Button variant={"secondary"} isOnlyIcon onClick={handleClose}>
            <ArrowLeftIcon />
          </Button>
          <Button variant={"primary"} onClick={handleNext}>
            {t("confirm")}
          </Button>
        </div>
      </LoaderContainer>
    </WizardContent.Content>
  );
};

export default ReviewTransactions;
