import { FC } from "react";

import { Divider, P, Ui } from "common/components/atoms";
import { ExclamationMarkCircle, WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

type PT = {
  type: "warnings" | "errors";
  messages: string[];
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.importTransactions.importStatus");

const MessagesList: FC<PT> = ({ type, messages, className }) => {
  if (messages.length === 0) return null;

  return (
    <div className={className}>
      <div className="d-flex align-items-center mb-2">
        <Ui.m className="me-1">
          {type === "warnings" && t("warnings")}
          {type === "errors" && t("errors")}
        </Ui.m>
        <Ui.xs
          className="rounded-5 px-half text-center"
          color="foregroundLow"
          style={{ backgroundColor: scssVariables.element3 }}
        >
          {messages.length}
        </Ui.xs>
      </div>
      {messages.map((message) => (
        <div
          key={message}
          className="d-flex p-2 rounded-3 mb-2"
          style={{ backgroundColor: type === "warnings" ? scssVariables.warning050 : scssVariables.critical050 }}
        >
          {type === "warnings" && <ExclamationMarkCircle fontSize={24} color={scssVariables.warning900} />}
          {type === "errors" && <WarningExclamationMarkIcon fontSize={24} color={scssVariables.critical900} />}
          <P.s className="ms-2">{message}</P.s>
        </div>
      ))}
      <Divider className="mt-4" />
    </div>
  );
};

export default MessagesList;
