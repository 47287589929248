import { ReactNode, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import AccessMiddlewareEM from "app/middleware/AccessMiddleware/AccessMiddlewareEM";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu from "common/layout/MainLayout/Menu/Menu";
import { useStoreActions, useStoreState } from "store/store";

import useCompanyMenu from "../../common/hooks/useCompanyMenu";
import GettingStartedGuide from "./getting-started-guide/GettingStartedGuide";
import OwnershipRouter from "./ownership/OwnershipRouter";
import PlansRouter from "./plans/PlansRouter";
import SettingRouter from "./settings/SettingRouter";

export type CompanyRouterItem = {
  path: string;
  component: ReactNode;
};

const EquityManagementRouter = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { getShareClassesCompanyThunk } = useStoreActions((actions) => actions.shareClassModel);
  const getGuidesThunk = useStoreActions((actions) => actions.guideModel.getGuidesThunk);

  const isCompanyMenuOpen = useStoreState((state) => state.company.isCompanyMenuOpen);

  const { equityManagement, companySettings, reporting } = useCompanyMenu(companyId || 0);

  useEffect(() => {
    if (companyId) {
      getShareClassesCompanyThunk(Number(companyId));
      getGuidesThunk(Number(companyId));
    }
  }, [companyId, getGuidesThunk, getShareClassesCompanyThunk]);

  return (
    <MainLayout>
      {isCompanyMenuOpen && (
        <MainLayout.Sidebar id="company-ownership-sidebar" isPlanInfoWidgetVisible>
          <Menu title={undefined} items={equityManagement} />
          <Menu title={"Reporting"} items={reporting} />
          <Menu title={"Settings"} items={companySettings} />
        </MainLayout.Sidebar>
      )}

      <Routes>
        <Route element={<AccessMiddlewareEM />}>
          <Route path={ROUTER_V2.equityManagement.ownership.relativeFull} element={<OwnershipRouter />} />
          <Route path={ROUTER_V2.equityManagement.plans.relativeFull} element={<PlansRouter />} />
          <Route path={ROUTER_V2.equityManagement.gettingStartedGuide} element={<GettingStartedGuide />} />
          <Route path={ROUTER_V2.equityManagement.settings.relativeFull} element={<SettingRouter />} />

          <Route path="*" element={<Navigate to={ROUTER_V2.equityManagement.ownership.capTable.base} />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default EquityManagementRouter;
