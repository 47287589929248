import { useMemo } from "react";
import { isBefore, isValid, parse } from "date-fns";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import { FundraiseGetDTO, FundraisingPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { DocumentStatusEnum } from "../../../../../common/enums/enum";
import { FilesDataType } from "../../../../../common/types/DocumentFiles.types";

export type FundraisingFormValues = Partial<FundraisingPostDTO> & FilesDataType & {};

export const fields: { [K in keyof Required<FundraisingFormValues>]: K } = {
  fundraiseId: "fundraiseId",
  eventName: "eventName",
  targetAmount: "targetAmount",
  preMoneyValuation: "preMoneyValuation",
  startsAt: "startsAt",
  endsAt: "endsAt",
  documentStatusId: "documentStatusId",
  filesData: "filesData",
  companyId: "companyId",
} as const;

const parseDateString = (value: string) => {
  const parsedDate = parse(value, "yyyy-MM-dd", new Date());
  return isValid(parsedDate) ? parsedDate : null;
};

const tv = createTranslation(TranslationNS.validation);
const useFundraisingSlidePanel = (fundraise?: FundraiseGetDTO | null) => {
  const initialValues = useMemo<FundraisingFormValues>(
    () => ({
      [fields.fundraiseId]: defaultTo(undefined, fundraise?.fundraiseId),
      [fields.eventName]: defaultTo("", fundraise?.eventName),
      [fields.targetAmount]: defaultTo(undefined, fundraise?.targetAmount),
      [fields.preMoneyValuation]: defaultTo(undefined, fundraise?.preMoneyValuation),
      [fields.startsAt]: defaultTo("", fundraise?.startsAt),
      [fields.endsAt]: defaultTo("", fundraise?.endsAt),
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.REVIEW_LATER, fundraise?.documentStatusId),
      [fields.filesData]: {
        oldFiles: defaultTo([], fundraise?.documentFiles),
        newFiles: [],
      },
    }),
    [fundraise]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.targetAmount]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 })),
        [fields.preMoneyValuation]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 })),
        [fields.eventName]: string().required(tv("required")),
        [fields.startsAt]: string().required(tv("required")),
        [fields.endsAt]: string()
          .required(tv("required"))
          .test("endsAt", "End date must be after start date", function (value) {
            const { startsAt } = this.parent;
            const startDate = parseDateString(startsAt);
            const endDate = parseDateString(value || "");
            return startDate && endDate ? isBefore(startDate, endDate) : false;
          }),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useFundraisingSlidePanel;
