import { FC } from "react";
import { Modal as BModal } from "react-bootstrap";
import { generatePath, useNavigate } from "react-router-dom";
import axios from "axios";

import { getEMPath } from "app/Router/RouterHelper";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { createTranslation, TranslationNS } from "translation";

import { useStoreState } from "../../../../store/store";
import { Button, H, P } from "../index";

type PropsTypes = {
  isOpen: boolean;
  handleClose(): void;
  onUpgrade?(): void;
};

const t = createTranslation(TranslationNS.common, "components.stakeholdersLimitModal");

const Modal: FC<PropsTypes> = ({ isOpen, handleClose, onUpgrade }) => {
  const { user } = useStoreState((state) => state.account);
  const { subscription } = useStripeSubscription();
  const navigate = useNavigate();

  const handleUpgradeClick = async () => {
    if (onUpgrade) {
      onUpgrade();
      handleClose();
    }

    if (user?.email.toLowerCase() === subscription?.buyerEmail?.toLowerCase()) {
      try {
        const currentUrl = window.location.href;

        // triggering API side session initialization to provide stripe customer portal for the user
        const billingRequest = await axios.post<{ sessionUrl: string }>("/api/stripe/billing-url", {
          customerId: subscription?.customerId || "",
          returnUrl: currentUrl,
        });

        if (billingRequest.status === 200 && billingRequest.data.sessionUrl) {
          const link = document.createElement("a");

          link.href = billingRequest.data.sessionUrl;
          link.rel = "noopener noreferrer";
          link.click();
        }
      } catch (e) {
        console.error({ e });
      }
    } else {
      navigate(generatePath(getEMPath(["settings", "companySettings"])));
    }
  };

  return (
    <BModal
      show={isOpen}
      onHide={handleClose}
      centered
      contentClassName="d-flex flex-column align-items-center text-center p-5"
    >
      <H.xs className="mb-3">{t("title")}</H.xs>
      <P.m className="mb-5">{t("content")}</P.m>
      <div>
        <Button className="me-2" onClick={handleUpgradeClick}>
          {t("submit")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </div>
    </BModal>
  );
};

const StakeholdersLimitModal: FC<PropsTypes> = (props) => {
  if (props.isOpen) {
    return <Modal {...props} />;
  }
};

export default StakeholdersLimitModal;
