import { Config, Driver, driver } from "driver.js";

class GuideController {
  private static guideInstance: Driver | null = null;

  static startGuide(config: Config) {
    this.stopGuide();
    this.guideInstance = driver(config);
    this.guideInstance.drive();
  }

  static moveToStep(stepIndex: number) {
    this.guideInstance?.moveTo(stepIndex);
  }

  static moveNext() {
    this.guideInstance?.moveNext();
  }

  static stopGuide() {
    if (this.guideInstance) {
      this.guideInstance.destroy();
    }
  }

  static getCurrentGuideStep() {
    return this.guideInstance?.getActiveIndex() ?? 0;
  }
}

export default GuideController;
