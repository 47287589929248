import { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";

import { Button, P, TextField, Ui } from "common/components/atoms";
import { OnboardingCompanySearch } from "common/components/molecules";
import { DownloadIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { ImportMode } from "../company-information";
import { CompanyInformationValues } from "../types";
import { KRONER_ID } from "../use-company-info-form";

const t = createTranslation(TranslationNS.pages, "onboard.company.companyInformation");

type PT = {
  importMode: ImportMode;
  setImportMode(mode: ImportMode): void;
};

const NorwayImport: FC<PT> = ({ importMode, setImportMode }) => {
  const isGetCompanyLoading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);
  const { values, touched, errors, handleChange, setFieldValue, handleBlur } =
    useFormikContext<CompanyInformationValues>();

  const handleSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue("selectedCompanyId", e.target.value);
    },
    [setFieldValue]
  );

  const handleCantFind = () => {
    setImportMode(ImportMode.NorwayManual);
  };

  const isManualImport = importMode === ImportMode.NorwayManual;

  useEffect(() => {
    if (isManualImport && values.currencyId !== KRONER_ID) {
      setFieldValue("currencyId", KRONER_ID);
    }
  }, [isManualImport, setFieldValue, values.currencyId]);

  if (isManualImport) {
    return (
      <>
        <TextField
          className="mb-3"
          label={t("companyName")}
          value={values.companyName}
          isTouched={touched.companyName}
          error={errors.companyName}
          name="companyName"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          isOptional
          className="mb-3"
          label={t("orgNumber")}
          value={values.orgNumber}
          isTouched={touched.orgNumber}
          error={errors.orgNumber}
          name="orgNumber"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          className="mb-3"
          label={t("city")}
          value={values.city}
          isTouched={touched.city}
          error={errors.city}
          name="city"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </>
    );
  }

  return (
    <div className="rounded-2 p-3" style={{ backgroundColor: scssVariables.element2 }}>
      <div className="d-flex mb-2 align-items-center">
        <DownloadIcon fontSize={24} />
        <Ui.m bold className="ms-1">
          {t("importTitle")}
        </Ui.m>
      </div>
      <P.m color="foregroundMedium" className="mb-3">
        {t("importDescription")}
      </P.m>
      <OnboardingCompanySearch
        onChange={handleSelect}
        disabled={isGetCompanyLoading}
        selectedValue={values.selectedCompanyId}
      />
      <Button variant="tertiary" size="s" className="mt-3" onClick={handleCantFind}>
        {t("cantFindCompany")}
      </Button>
    </div>
  );
};

export default NorwayImport;
