import { FC } from "react";
import { useFormikContext } from "formik";

import { FilesDataType } from "../../../types/DocumentFiles.types";
import FileUploader, { FilesDataMultiple } from "../../atoms/FileUploader/FileUploader";

type UploadDocumentsFieldProps = {
  className?: string;
};
const UploadDocumentsField: FC<UploadDocumentsFieldProps> = ({ className }) => {
  const { values, errors, setFieldValue } = useFormikContext<FilesDataType>();

  const handleChangeDocumentFiles = (data: FilesDataMultiple) => {
    setFieldValue("filesData", data).then();
  };

  return (
    <div className={className}>
      <FileUploader
        label="Upload documents"
        error={errors.filesData as string}
        multiple
        prevFileData={values.filesData?.oldFiles}
        onChange={handleChangeDocumentFiles}
      />
    </div>
  );
};

export default UploadDocumentsField;
