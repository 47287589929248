import { FC } from "react";

import { Tooltip, Ui } from "common/components/atoms";
import { WarningIcon } from "common/icons/svg";

type Props = {
  name?: string;
  eventType: number;
};
const ShareClassCell: FC<Props> = ({ name, eventType }) => {
  if (!name) {
    return (
      <Tooltip
        popupContent={
          <div style={{ width: 128, textAlign: "left" }}>
            <Ui.xs bold color="foregroundMedium">
              Share class missing
            </Ui.xs>
            <Ui.xs color="foregroundLow">
              {eventType === 3
                ? "Update warrant in Financial Instruments to select share class."
                : "Update conversion to select share class."}
            </Ui.xs>
          </div>
        }
      >
        <div>
          <Ui.m color="foregroundLow" style={{ cursor: "pointer" }}>
            <WarningIcon fontSize={24} />
          </Ui.m>
        </div>
      </Tooltip>
    );
  }

  return <Ui.s>{name}</Ui.s>;
};

export default ShareClassCell;
