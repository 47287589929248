import { FC, useMemo } from "react";

import { Tag, TagVariant } from "common/components/atoms";

const statuses = [
  { id: 1, name: "Draft" },
  { id: 2, name: "New" },
  { id: 3, name: "Suggestion" },
  { id: 4, name: "Document sent" },
  { id: 5, name: "Soft commit" },
  { id: 6, name: "Committed" },
  { id: 7, name: "Signed" },
  { id: 8, name: "Paid" },
];

type StatusCellProps = {
  status: number;
};
const StatusCell: FC<StatusCellProps> = ({ status }) => {
  const item = useMemo(() => {
    const item = statuses.find((item) => item.id === status);
    let variant: TagVariant = "closed";

    if (item && [4, 5, 6].includes(item.id)) {
      variant = "information";
    } else if (item && [7, 8, 9].includes(item.id)) {
      variant = "complete";
    }

    return {
      label: item?.name || "-",
      variant: variant,
    };
  }, [status]);

  return <Tag variant={item.variant}>{item.label}</Tag>;
};

export default StatusCell;
