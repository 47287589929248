import { FC } from "react";
import { isEmpty } from "ramda";

import { P, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { MilestonesIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { PlanConditionDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import classes from "../Summary.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.summary");

type Props = {
  milestones?: PlanConditionDTO[];
};

const StandaloneMilestones: FC<Props> = ({ milestones = [] }) => {
  const fNumber = useFormatNumbers();
  if (isEmpty(milestones)) {
    return null;
  }

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center">
        <MilestonesIcon strokeWidth={1} />

        <Ui.m className="fw-500 ms-1">{t("milestoneBasedVesting")}</Ui.m>
      </div>

      <div className="d-flex flex-wrap mt-1" style={{ columnGap: 16, rowGap: 16 }}>
        {milestones.map((el) => (
          <div className={classes["milestone"]} key={el.title + el.targetDate}>
            <Ui.m className="mb-1 fw-500">{el.title}</Ui.m>

            {el.description ? (
              <P.s className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
                {el.description}
              </P.s>
            ) : null}

            <Ui.s className="mt-auto" style={{ color: scssVariables.foregroundMedium }}>
              {t.el("targetDate", {
                values: {
                  date: transformDateToCommonDateFormat(el.targetDate),
                },
                components: [<strong key={1} style={{ color: scssVariables.foregroundHigh }} />],
              })}
            </Ui.s>

            <Ui.s className="mt-2" style={{ color: scssVariables.foregroundMedium }}>
              {t.el("optionsNumber", {
                values: {
                  options: fNumber(el?.numberOfOptions, "amount"),
                },
                components: [<strong key={1} style={{ color: scssVariables.foregroundHigh }} />],
              })}
            </Ui.s>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StandaloneMilestones;
