import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FundraiseEventsReviewInstrument } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import NameCell from "../../../components/DashboardTable/NameCell/NameCell";

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.events.table");

const useEventItemTable = () => {
  const fNumber = useFormatNumbers();

  const columns = useMemo<ColumnDef<FundraiseEventsReviewInstrument>[]>(
    () => [
      {
        accessorKey: "stakeholderInitials",
        cell: (info) => {
          return (
            <NameCell
              initials={info.getValue() as string}
              name={info.row.original.stakeholderName}
              representByName={info.row.original.stakeholderRepresentedBy}
              isCompany={info.row.original.isCompanyOwned}
              isAttached={false}
            />
          );
        },
        header: () => <Ui.s tag="span">{t("stakeholder")}</Ui.s>,
        enableResizing: false,
      },
      {
        accessorKey: "numberOfShares",
        cell: (info) => <Ui.s>{fNumber(info.getValue() as number, "amount")}</Ui.s>,
        header: () => <Ui.s tag="span">{t("shares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footerColSpan: 2 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {fNumber(total, "amount")}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "sharePrice",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number, "unitPrice")}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("sharePrice")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "nominalSharePrice",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number, "unitPrice")}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("nominalSharePrice")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "premiumSharePrice",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number, "unitPrice")}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("premium")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "shareCapitalSum",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("sumShareCapital")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footerColSpan: 4 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.shareCapitalSum || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {fNumber(total)}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "premiumSum",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("sumPremium")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footerColSpan: 1 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.premiumSum || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {fNumber(total)}
            </Ui.s>
          );
        },
      },
      {
        accessorKey: "subscriptionAmount",
        cell: (info) => {
          return <Ui.s>{fNumber(info.getValue() as number)}</Ui.s>;
        },
        header: () => <Ui.s tag="span">{t("sumSubscription")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", footerColSpan: 1 },
        enableResizing: false,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.subscriptionAmount || 0), 0);
          return (
            <Ui.s tag="span" bold>
              {fNumber(total)}
            </Ui.s>
          );
        },
      },
    ],
    [fNumber]
  );

  return { columns };
};
export default useEventItemTable;
