import { useMemo } from "react";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import {
  TransactionCategory,
  TransactionCategoryIds,
  TransactionTypes,
  TransactionTypesIds,
} from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import { GetFormTransaction, PostFormTransaction, ShareClass } from "../types";

const t = createTranslation(TranslationNS.validation);

export const fields = {
  transactedAt: "transactedAt",
  transactionCategoryId: "transactionCategoryId",
  transactionTypeId: "transactionTypeId",
  numberOfShares: "numberOfShares",
  nominalValue: "Pålydende",
  sharePrice: "sharePrice",
  shareClassId: "shareClassId",
  toStakeholderId: "toStakeholderId",
  fromStakeholderId: "fromStakeholderId",
  isin: "isin",

  transactionId: "transactionId",
  altinTypeId: "altinTypeId",
  total: "total",
} as const;

export const getDefaultShareClass = (shareClasses: ShareClass[]) => {
  if (shareClasses.length === 1) {
    return shareClasses[0].id;
  }

  if (shareClasses.length === 0) {
    return 0;
  }

  if (shareClasses.length > 1) {
    return 0;
  }

  return 0;
};

export const createInitValues = (shareClasses: ShareClass[]) => {
  return {
    [fields.transactionCategoryId]: TransactionCategory.Issue,
    [fields.transactedAt]: "",
    [fields.transactionTypeId]: TransactionTypes.InvestorSharesIssued,
    [fields.numberOfShares]: 0,
    [fields.nominalValue]: 0,
    [fields.sharePrice]: 0,
    [fields.shareClassId]: getDefaultShareClass(shareClasses),
    [fields.toStakeholderId]: 0,
    [fields.fromStakeholderId]: -1,
    [fields.isin]: "",
  };
};

const useEditTransactionsForm = (transactions: GetFormTransaction[], shareClasses: ShareClass[]) => {
  const initialValues = useMemo<PostFormTransaction[]>(() => {
    if (transactions.length > 0) {
      return transactions.map((transaction) => {
        const source =
          transaction.transactionCategoryId === TransactionCategory.Issue
            ? -1
            : transaction.transactionCategoryId === TransactionCategory.Sell
            ? transaction.fromStakeholderId
            : 0;

        return {
          [fields.transactionId]: defaultTo(undefined, transaction.transactionId),
          [fields.transactionCategoryId]: defaultTo(TransactionCategory.Issue, transaction.transactionCategoryId),
          [fields.transactedAt]: defaultTo("", transaction.transactedAt),
          [fields.transactionTypeId]: defaultTo(TransactionTypes.InvestorSharesIssued, transaction.transactionTypeId),
          [fields.numberOfShares]: defaultTo(0, transaction.numberOfShares),
          [fields.sharePrice]: defaultTo(0, transaction?.sharePrice),
          [fields.shareClassId]: defaultTo(getDefaultShareClass(shareClasses || []), transaction.shareClassId),
          [fields.toStakeholderId]: defaultTo(0, transaction.toStakeholderId),
          [fields.fromStakeholderId]: source,
        };
      });
    }

    return [createInitValues(shareClasses || [])];
  }, [shareClasses, transactions]);

  const validationSchema = useMemo(() => {
    const objectSchema = Yup.object().shape({
      [fields.transactedAt]: Yup.string().nullable(true).required(t("required")),
      [fields.numberOfShares]: Yup.number()
        .nullable(true)
        .integer(t("integer"))
        .min(1, t("minNumber", { number: 1 }))
        .required(t("required")),
      [fields.sharePrice]: Yup.number().when([fields.transactionCategoryId, fields.transactionTypeId], {
        is: (categoryId: TransactionCategoryIds, typeId: TransactionTypesIds) => {
          if (categoryId === TransactionCategory.Sell) {
            if (
              typeId === TransactionTypes.Gift ||
              typeId === TransactionTypes.GiftWithoutTaxContinuity ||
              typeId === TransactionTypes.Inheritance ||
              typeId === TransactionTypes.InheritanceWithoutTaxContinuity
            ) {
              return true;
            }
          }
        },
        then: Yup.number().nullable(true).required(t("required")),
        otherwise: Yup.number()
          .nullable(true)
          .min(0.000001, t("minNumber", { number: 0.000001 }))
          .required(t("required")),
      }),
      [fields.shareClassId]: Yup.number().min(1, t("required")).required(t("required")),
      [fields.toStakeholderId]: Yup.number().min(0, t("required")).required(t("required")),
      [fields.fromStakeholderId]: Yup.number().when(fields.transactionCategoryId, {
        is: (id: number) => id === TransactionCategory.Issue,
        then: Yup.number().notRequired(),
        otherwise: Yup.number().min(0, t("required")).required(t("required")),
      }),
    });

    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useEditTransactionsForm;
