import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";

import { createTranslation, TranslationNS } from "../../../../../../translation";
import { fields, FundraisingFormValues } from "../useFundraisingSlidePanel";

type AmountToRiseFieldProps = {
  className?: string;
};
const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising.fields");

const FundraiseNameField: FC<AmountToRiseFieldProps> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FundraisingFormValues>();

  return (
    <Row className={className}>
      <Col>
        <Helper>
          <Helper.Question type="input" questionId={fields.eventName}>
            <TextField
              name={fields.eventName}
              label={t("fundraiseName.label")}
              value={values.eventName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.eventName}
              isTouched={touched.eventName}
            />
          </Helper.Question>
          <Helper.Answer
            className="mt-3"
            withRightMargin
            answerId={fields.eventName}
            text={t("fundraiseName.answer")}
          />
        </Helper>
      </Col>
    </Row>
  );
};
export default FundraiseNameField;
