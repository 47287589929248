import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { LinkHelpText, Ui } from "common/components/atoms";
import { CloseIcon, QuestionCircleIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { ShareClassSingleType } from "../../../../../../../store/types";
import ShareClassInfoCard from "../../../../../../shareClass/components/InfoCard/ShareClassInfoCard";
import { scssVariables } from "../../../../../../utils/constants";
import { P } from "../../../../../atoms/Typography";
import classes from "../styles.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const ShareClassSection = ({
  title,
  shareClass,
  description,
}: {
  shareClass: ShareClassSingleType;
  title: string;
  description: string;
}) => {
  return (
    <div className="position-relative">
      <Ui.m bold>{title}</Ui.m>

      <P.s className="my-2" color="foregroundLow">
        {description}
      </P.s>

      <ShareClassInfoCard
        className={classes["share-class"]}
        shareClass={{ ...shareClass, shareClassAntiDilutionId: 1 }}
        id={String(shareClass?.id)}
      />
      <CloseIcon
        fontSize={20}
        color={scssVariables.foregroundLow}
        className={classNames("position-absolute cursor-pointer", classes["close-btn"])}
        onClick={() => document.body.click()}
      />
    </div>
  );
};

const SingleAgreementDetailSection: FC<{
  title?: string | ReactNode | JSX.Element;
  subTitle: string;
  infoContent: string;
  isShareClass?: boolean;
  shareClassInfo?: ShareClassSingleType | null;
  className?: string;
}> = ({ className, title, subTitle, infoContent, isShareClass, shareClassInfo }) => {
  return (
    <div className={classNames(className, classes["single"], "text-truncate px-2 m-0")}>
      <div className=" d-block">
        <Ui.s className="text-truncate fw-500">{title}</Ui.s>

        <Ui.xs className={classes.subTitle} color="foregroundLow">
          {subTitle}
        </Ui.xs>
      </div>

      <LinkHelpText
        title={t("infoWidget.title", { infoTitle: subTitle })}
        content={infoContent}
        type="modal"
        trigger="click"
        withCloseButton
        CustomOverlay={
          isShareClass && shareClassInfo ? (
            <ShareClassSection
              title={t("infoWidget.title", { infoTitle: subTitle })}
              description={infoContent}
              shareClass={shareClassInfo}
            />
          ) : undefined
        }
      >
        <QuestionCircleIcon />
      </LinkHelpText>
    </div>
  );
};

export default SingleAgreementDetailSection;
