import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";

import { UploadFileTypes } from "../../../../../common/enums/enum";
import SetFundingEventForm from "./SetFundingEventForm";
import useFundingEventSlidePanel, { FundingRoundFormValues } from "./useFundingEventSlidePanel";

type SetFundingRoundFormikProps = {
  fundraiseId?: number;
  fundraiseRoundId?: number;
  onSaved?: () => void;
  onHide: () => void;
};

const SetFundingEventFormik: FC<SetFundingRoundFormikProps> = ({ fundraiseId, onSaved, onHide, fundraiseRoundId }) => {
  const { companyId } = useParams();

  const { setRoundThunk, getFundraiseRoundThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { isGetFundraiseRoundLoading } = useStoreState((state) => state.fundraisingModel);
  const fundraiseRound = useStoreState((state) => state.fundraisingModel?.fundraiseRound);
  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const roundData = useMemo(() => {
    return fundraiseRoundId && fundraiseRound ? fundraiseRound : { fundraiseId };
  }, [fundraiseRoundId, fundraiseRound, fundraiseId]);

  const { initialValues, validationSchema } = useFundingEventSlidePanel(roundData);

  const handleSubmit = useCallback(
    async (values: FundingRoundFormValues) => {
      const resp = await setRoundThunk(values);
      if (resp.data.fundingRoundId) {
        await updateCompanyDocuments(
          companyId!,
          resp.data.fundingRoundId,
          UploadFileTypes.FundraiseEventDocument,
          values.filesData.newFiles,
          values.filesData.oldFiles,
          values.documentStatusId
        );
      }

      notify(`Round successfully ${values.fundingRoundId ? "updated" : "created"}`, true, "success");
      onSaved?.();
      onHide();
    },
    [companyId, onHide, onSaved, setRoundThunk, updateCompanyDocuments]
  );

  useEffect(() => {
    if (fundraiseRoundId) {
      getFundraiseRoundThunk(fundraiseRoundId).then();
    }
  }, [getFundraiseRoundThunk, fundraiseRoundId]);

  return (
    <SlidePanel.Body isLoading={isGetFundraiseRoundLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <SetFundingEventForm onCancel={onHide} />
      </Formik>
    </SlidePanel.Body>
  );
};
export default SetFundingEventFormik;
