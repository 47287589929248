import { ChangeEventHandler, FC } from "react";

import { createTranslation, TranslationNS } from "translation";

import { DocumentStatusEnum } from "../../../enums/enum";
import ChecksGroup from "../Checks/ChecksGroup";
const t = createTranslation(TranslationNS.common, "molecules.documentUploadWithStatusesSignatureEmailInvitation");

type DocumentStatusProps = {
  value: string | number;
  label?: string;
  error?: string;
  touched?: boolean;
  name?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: ChangeEventHandler<HTMLInputElement> | undefined;
};
const DocumentStatus: FC<DocumentStatusProps> = ({
  value,
  label,
  touched,
  error,
  name,
  disabled,
  onChange,
  onBlur,
}) => {
  const radioName = name || "documentStatusId";
  return (
    <ChecksGroup
      className="mt-4"
      label={label || t("documentsStatus")}
      error={error}
      isTouched={touched}
      isDisabled={disabled}
    >
      <ChecksGroup.Check
        isNoFocus
        type="radio"
        disabled={disabled}
        name={radioName}
        label={t("documentsUploaded")}
        checked={+value === DocumentStatusEnum.DOCUMENT_UPLOADED}
        value={DocumentStatusEnum.DOCUMENT_UPLOADED}
        onChange={onChange}
        onBlur={onBlur}
      />
      <ChecksGroup.Check
        isNoFocus
        type="radio"
        disabled={disabled}
        name={radioName}
        label={t("willUploadLater")}
        checked={+value === DocumentStatusEnum.REVIEW_LATER}
        value={DocumentStatusEnum.REVIEW_LATER}
        onChange={onChange}
        onBlur={onBlur}
      />
      <ChecksGroup.Check
        isNoFocus
        type="radio"
        disabled={disabled}
        name={radioName}
        label={t("noDocsRequired")}
        value={DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
        checked={+value === DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
        onChange={onChange}
        onBlur={onBlur}
      />
    </ChecksGroup>
  );
};

export default DocumentStatus;
