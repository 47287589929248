import { FC, ReactNode } from "react";
import classNames from "classnames";

import { Tag, Ui } from "common/components/atoms";

import classes from "./CapTableCard.module.scss";

type CapTableCardProps = {
  label: ReactNode;
  value: ReactNode;
  additionalValue?: ReactNode;
  className?: string;
};

const CapTableCard: FC<CapTableCardProps> = ({ label, value, additionalValue, className }) => {
  return (
    <div className={classNames(classes.wrap, className, "p-3")}>
      <Ui.s color="foregroundLow">{label}</Ui.s>
      <div className="mt-2 d-flex">
        <Ui.xl bold color="foregroundHigh">
          {value}
          {additionalValue && (
            <Tag className="ms-1" variant="information">
              {additionalValue}
            </Tag>
          )}
        </Ui.xl>
      </div>
    </div>
  );
};

export default CapTableCard;
