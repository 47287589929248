import { FC, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";

import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";

import { ConvertibleInfoProps } from "../../../../ConvertibleInfo/ConvertibleInfo";
import TotalInfoBlock from "../../components/TotalInfoBlock/TotalInfoBlock";
import { ConversionFormValues, fields } from "../../fields/fieldsConversion";

type Props = {
  convertible?: ConvertibleInfoProps["convertible"] | null;
  valuationAtConversion?: number;
};

type LoanConversionCalculationDto = {
  numberOfNewShares: number;
  sharePrice: number;
  interestToRepay: number;
};
const LoanTotalBlock: FC<Props> = ({ convertible, valuationAtConversion }) => {
  const fNumber = useFormatNumbers();
  const { values, setFieldValue } = useFormikContext<ConversionFormValues>();

  const [loan, setLoan] = useState<LoanConversionCalculationDto | null>(null);

  const debounceChange = useMemo(
    () =>
      debounce((payload: ConvertibleInfoProps["convertible"] | (null & ConversionFormValues)) => {
        axios
          .post<LoanConversionCalculationDto>("/api/equity-management/issue-equity/convertible-loan/calculate", payload)
          .then((res) => {
            setLoan(res.data);

            setFieldValue(fields.autoCalculatedInterest, res.data.interestToRepay);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setFieldValue("isLoading", false);
          });
      }, 1000),
    [setFieldValue]
  );

  useEffect(() => {
    const params = {
      valuationAtConversion: valuationAtConversion,
      valuationCap: convertible?.valuationCap,
      loanAmount: convertible?.investmentAmount,
      agreementDate: convertible?.agreementDate,
      loanSettleDate: values.conversionDate,
      discount: convertible?.discount,
      interestRate: convertible?.interestRate,
      sharesInCompanyBeforeConversion: convertible?.sharesInCompanyBeforeConversion,
      loanAmountRepaid: values.loanRepaymentAmount || 0,
      interestNotConvertedToEquity: values.interestPaidInCash,
      overrideInterestAmount: values?.interestAmount || null,
      overrideNumberOfShares: null,
      overrideSharePrice: null,
      useManualConversion: false,
    };
    if (values.conversionDate) {
      setFieldValue("isLoading", true);
      debounceChange(params);
    }

    return () => {
      debounceChange.cancel();
    };
  }, [
    convertible?.agreementDate,
    convertible?.discount,
    convertible?.interestRate,
    convertible?.investmentAmount,
    convertible?.sharesInCompanyBeforeConversion,
    convertible?.valuationCap,
    debounceChange,
    setFieldValue,
    valuationAtConversion,
    values.conversionDate,
    values.interestAmount,
    values.interestPaidInCash,
    values.loanRepaymentAmount,
  ]);

  const aboveDividerList = useMemo(
    () => [
      {
        name: "Loan",
        value: fNumber(convertible?.investmentAmount),
      },
      {
        name: "Interest",
        value: fNumber(loan?.interestToRepay),
      },
      {
        name: "Loan repayment",
        value: "- " + fNumber(values.loanRepaymentAmount || 0),
      },
    ],
    [convertible?.investmentAmount, fNumber, loan?.interestToRepay, values.loanRepaymentAmount]
  );

  const belowDividerList = useMemo(() => {
    const subscriptionAmount =
      (loan?.interestToRepay ? +loan?.interestToRepay : 0) +
      (convertible?.investmentAmount ? +convertible?.investmentAmount : 0) -
      (values.loanRepaymentAmount || 0);

    const notRoundedNumberOffShares = subscriptionAmount / (loan?.sharePrice || 1);

    const reminder = subscriptionAmount - (loan?.numberOfNewShares || 0) * (loan?.sharePrice || 0);
    return [
      {
        name: "Subscription amount (Conversion)",
        value: fNumber(subscriptionAmount),
      },
      {
        name: `Share price (${convertible?.discount}% discount)`,
        value: fNumber(loan?.sharePrice),
      },
      {
        name: "Number of shares",
        value: `${formatNumber(notRoundedNumberOffShares, { decimals: "percent" })} ≈ ${fNumber(
          loan?.numberOfNewShares,
          "amount"
        )}`,
        bold: true,
      },
      {
        name: "Remainder",
        value: fNumber(reminder),
      },
    ];
  }, [
    convertible?.discount,
    convertible?.investmentAmount,
    fNumber,
    loan?.interestToRepay,
    loan?.numberOfNewShares,
    loan?.sharePrice,
    values.loanRepaymentAmount,
  ]);

  if (!values.conversionDate) {
    return null;
  }

  return <TotalInfoBlock className="mt-4" aboveDividerList={aboveDividerList} belowDividerList={belowDividerList} />;
};

export default LoanTotalBlock;
