import React, { useMemo, useState } from "react";

import Table from "common/components/atoms/Table/Table";
import { sortHistoryValuation } from "common/helpers";
import { sortingParams } from "common/types/Collapsible.types";
import { useStoreState } from "store/store";
import { ValuationSingle } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "../ValuationHistory/ValuationHistory.module.scss";
import ValuationTableBody from "./ValuationTableBody";
import ValuationTableHead from "./ValuationTableHead";

const t = createTranslation(TranslationNS.pages, "company.valuation");

export type ValuationHistoryTableSortValue = {
  field: keyof Pick<ValuationSingle, "title" | "editor" | "valuation" | "validFrom">;
  type: keyof typeof sortingParams;
};

const ValuationHistoryTable = () => {
  const valuation = useStoreState((state) => state.company.valuation);

  const [activeSortedValue, setActiveSortedValue] = useState<ValuationHistoryTableSortValue>({
    field: "validFrom",
    type: sortingParams.desc,
  });

  const valuationSorted = useMemo(() => sortHistoryValuation(valuation?.valuations || []), [valuation?.valuations]);

  const rows = useMemo(
    () => [
      {
        key: "title",
        value: t("valuationHistoryTable.title"),
      },

      {
        key: "validFrom",
        value: t("valuationHistoryTable.date"),
      },
      {
        key: "editor",
        value: t("valuationHistoryTable.editor"),
      },
      {
        key: "description",
        value: t("valuationHistoryTable.description"),
        sortDisabled: true,
      },
      {
        key: "valuation",
        value: t("valuationHistoryTable.valuation"),
      },
    ],
    []
  );

  return (
    <div className={classes["table-container"]}>
      <Table
        className={classes.table}
        headComponent={
          <ValuationTableHead rows={rows} sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />
        }
        bodyComponent={<ValuationTableBody data={valuationSorted} sortedValue={activeSortedValue} />}
      />
    </div>
  );
};

export default ValuationHistoryTable;
