import { FC } from "react";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { fields, NewSubscriptionFormValues } from "../useNewSubscriptionSlidePanel";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.newSubscription.fields");

const PurchasePriceField: FC<Props> = ({ className }) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<NewSubscriptionFormValues>();

  return (
    <div className={className}>
      <Helper>
        <Helper.Question type="input" questionId={fields.sharePrice}>
          <TextField
            type="number"
            name={fields.sharePrice}
            label={t("sharePrice.label")}
            value={values.sharePrice}
            error={errors.sharePrice}
            isTouched={touched.sharePrice}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Helper.Question>
        <Helper.Answer className="mt-3" withRightMargin answerId={fields.sharePrice} text={t("sharePrice.answer")} />
      </Helper>
    </div>
  );
};

export default PurchasePriceField;
