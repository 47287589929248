import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { Button, ModalInfo } from "common/components/atoms";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

export type DeleteInstrumentModalProps = {
  entityId?: number;
  instrumentEntityTypeId?: number;
  stakeholderName?: string;
  instrumentName?: string;
  roundName?: string;
  fundraiseId?: number;
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
};
const t = createTranslation(TranslationNS.pages, "fundraising.modals.deleteInstrument");

const DeleteInstrumentModal: FC<DeleteInstrumentModalProps> = (props) => {
  const { stakeholderName, roundName, show, instrumentName, onSaved } = props;

  const [updateFundraise, setUpdateFundraise] = useState(false);

  const handleOnExited = useCallback(() => {
    if (updateFundraise) {
      onSaved?.();
      setUpdateFundraise(false);
    }
  }, [updateFundraise, onSaved]);

  return (
    <ModalInfo
      onExited={handleOnExited}
      size="sm"
      show={show}
      header={t("title", { stakeholderName, instrumentName: instrumentName?.toLowerCase(), roundName })}
    >
      <RoundModalForm setUpdateFundraise={setUpdateFundraise} {...props} />
    </ModalInfo>
  );
};

type RoundModalContent = DeleteInstrumentModalProps & {
  setUpdateFundraise: Dispatch<SetStateAction<boolean>>;
};
const RoundModalForm: FC<RoundModalContent> = ({
  onHide,
  setUpdateFundraise,
  instrumentName,
  entityId,
  instrumentEntityTypeId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { removeInstrumentThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleRemoveInstrument = useCallback(async () => {
    setIsSubmitting(true);
    try {
      if (entityId && instrumentEntityTypeId) {
        await removeInstrumentThunk({ entityId, instrumentEntityTypeId });
        setUpdateFundraise(true);
      }
      onHide();
    } finally {
      setIsSubmitting(false);
    }
  }, [entityId, instrumentEntityTypeId, setUpdateFundraise, onHide, removeInstrumentThunk]);
  return (
    <div style={{ width: 275, margin: "0 auto" }}>
      <div>{t("description", { instrumentName: instrumentName?.toLowerCase() })}</div>
      <div className="mt-5">
        <Button
          onClick={handleRemoveInstrument}
          type="submit"
          variant="danger"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          {t("delete")}
        </Button>
        <Button isDisabled={isSubmitting} onClick={onHide} className="ms-2" variant="secondary">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};
export default DeleteInstrumentModal;
