import { FC } from "react";
import classNames from "classnames";

import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { FundraiseTransactionBundlesDTO } from "store/modelTypes";

import CapitalIncreaseBody from "./capital-increase-body/capital-increase-body";
import CapitalIncreaseHeader from "./capital-inscrease-header/capital-increase-header";
import classes from "./FundraiseCapitalIncrease.module.scss";

type FundraiseTransactionItemProps = {
  transactionBundle: FundraiseTransactionBundlesDTO;
};
const FundraiseCapitalIncrease: FC<FundraiseTransactionItemProps> = ({
  transactionBundle: { transactions, ...props },
}) => {
  return (
    <ExpansionPanel className={classNames("w-100", classes.wrap)}>
      <ExpansionPanel.Toggle className="ps-3" eventKey={"1"}>
        <CapitalIncreaseHeader {...props} categoryName="Capital increase" />
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey={"1"} className="px-3 pb-3 pt-1x">
        <CapitalIncreaseBody transactions={transactions} numberOfShares={props.numberOfShares} />
      </ExpansionPanel.Collapse>
    </ExpansionPanel>
  );
};

export default FundraiseCapitalIncrease;
