import { FC, useMemo } from "react";

import { VestingTypesEnum } from "common/enums/enum";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import StandaloneMilestones from "../../../../create-one-off-plans/steps/Summary/Milestones/Milestones";
import Item from "./Item/Item";
import TimeBasedChart from "./TimeBasedChart/TimeBasedChart";

export type VestingConditionsProps = JSX.IntrinsicElements["div"];

const t = createTranslation(TranslationNS.pages, "createProgram.summary.vestingConditions");

const VestingConditions: FC<VestingConditionsProps> = ({ className, ...props }) => {
  const formData = useStoreState((state) => state.programModel.program);

  const showTimeBasedSection = useMemo<boolean>(
    () =>
      !!formData?.vestingTypeId &&
      (+formData.vestingTypeId === VestingTypesEnum.TIME_VESTING ||
        +formData.vestingTypeId === VestingTypesEnum.COMBINE_VESTING),
    [formData?.vestingTypeId]
  );

  const showConditionBasedSection = useMemo<boolean>(
    () =>
      !!formData?.vestingTypeId &&
      (+formData.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING ||
        +formData.vestingTypeId === VestingTypesEnum.COMBINE_VESTING),
    [formData?.vestingTypeId]
  );

  return (
    <div className={className} {...props}>
      {showTimeBasedSection && (
        <Item vestingType={VestingTypesEnum.TIME_VESTING}>
          <Item.Header>
            <Item.Header.Item label={t("duration")} value={t("months", { count: formData?.vestingPeriod || 0 })} />

            <Item.Header.Item label={t("vestingCliff")} value={t("months", { count: formData?.vestingCliff || 0 })} />

            <Item.Header.Item
              label={t("vestingInterval")}
              value={t("months", { count: formData?.vestingInterval || 0 })}
            />

            <Item.Header.Item label={t("totalShares")} value={formData?.numberOfShares} />
          </Item.Header>

          <Item.Body>
            <TimeBasedChart formData={formData as any} />
          </Item.Body>
        </Item>
      )}

      {showConditionBasedSection && (
        <Item vestingType={VestingTypesEnum.MILESTONE_VESTING}>
          <StandaloneMilestones milestones={formData?.conditions} />
        </Item>
      )}
    </div>
  );
};

export default VestingConditions;
