import { FC, memo, useCallback, useMemo } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, NewAvatar, Tag, Tooltip, Ui } from "common/components/atoms";
import {
  ActiveUserBudgeIcon,
  DeleteIcon,
  EditIcon,
  EmailIcon,
  MenuTabBarVerticalIcon,
  SendEmailInvitationIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { GetStakeholdersDTO } from "../../types";
import {
  StakeholderContactsCounter,
  StakeholderContactsRowText,
} from "../stakeholder-contacts-counter/stakeholder-contacts-counter";

type PropsTypes = GetStakeholdersDTO[0] & {
  onEdit: (id: number) => void;
  onClick?: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.card");

const Row: FC<PropsTypes> = ({
  stakeholderId,
  avatarFilePath,
  initials,
  companyName,
  firstName,
  lastName,
  email,
  relationship,
  onEdit,
  hasSentInvite,
  isActiveUser,
  handleRemoveStakeholder,
  canDelete,
  className,
  onClick,
  contacts,
}) => {
  const { openInviteModal, manageResendEmailInviteModal } = useStoreActions((actions) => actions.stakeholderInvitation);
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  const handleEditClick = useCallback(() => {
    onEdit(stakeholderId);
  }, [onEdit, stakeholderId]);

  const handleInviteClick = useCallback(() => {
    openInviteModal({
      stakeholderId,
      firstName,
      lastName,
      email,
      relationshipId: Number(relationship),
    });
  }, [email, firstName, lastName, openInviteModal, relationship, stakeholderId]);

  const handleDeleteClick = useCallback(() => {
    handleRemoveStakeholder?.(stakeholderId);
  }, [handleRemoveStakeholder, stakeholderId]);

  const handleRowClick = useCallback(() => {
    onClick?.(stakeholderId);
  }, [onClick, stakeholderId]);

  const handleResendInviteClick = useCallback(() => {
    manageResendEmailInviteModal({ show: true, stakeholderId, email, firstName, lastName });
  }, [email, firstName, lastName, manageResendEmailInviteModal, stakeholderId]);

  const dropdownItems = useMemo(() => {
    const commonItems: ContextMenuProps["items"][0][] = [
      {
        key: "edit",
        label: t("editBtn"),
        icon: <EditIcon />,
        onClick: handleEditClick,
        isDisabled: !hasFullAccess,
        forceHideDropdown: true,
      },
    ];

    if (!isActiveUser && !hasSentInvite) {
      commonItems.push({
        key: "invite",
        label: t("inviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleInviteClick,
        isDisabled: !hasFullAccess,
        forceHideDropdown: true,
      });
    }

    if (!isActiveUser && hasSentInvite) {
      commonItems.push({
        key: "reinvite",
        label: t("reinviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleResendInviteClick,
        isDisabled: !hasFullAccess,
        forceHideDropdown: true,
      });
    }

    if (canDelete) {
      commonItems.push({
        key: "delete",
        label: "Delete",
        type: "delete",
        onClick: handleDeleteClick,
        icon: <DeleteIcon />,
        isDisabled: !hasFullAccess,
        forceHideDropdown: true,
      });
    }

    return commonItems;
  }, [
    canDelete,
    handleDeleteClick,
    handleEditClick,
    handleInviteClick,
    handleResendInviteClick,
    hasFullAccess,
    hasSentInvite,
    isActiveUser,
  ]);

  return (
    <tr key={stakeholderId} className={className} onClick={handleRowClick}>
      <td>
        <div className="d-flex align-items-center">
          <NewAvatar size="m" imageUrl={avatarFilePath} initials={initials} company={!!companyName} />
          <div className="ms-2 d-flex">
            {companyName ? (
              <div>
                <div className="d-flex align-items-center">
                  <Ui.s bold>{companyName}</Ui.s>

                  {isActiveUser ? (
                    <Tooltip popupContent={<div>Registered user</div>}>
                      <ActiveUserBudgeIcon fontSize={16} className="ms-1" />
                    </Tooltip>
                  ) : null}

                  {!isActiveUser && hasSentInvite && (
                    <Tooltip popupContent={<div>{t("invited")}</div>}>
                      <EmailIcon fontSize={16} className="ms-1" color={scssVariables.foregroundLow} />
                    </Tooltip>
                  )}
                </div>

                <div className="d-flex align-items-center">
                  <StakeholderContactsRowText
                    contacts={
                      contacts.length === 0
                        ? [
                            {
                              name: `${firstName} ${lastName}`,
                              isPrimary: true,
                            },
                          ]
                        : (contacts as unknown as {
                            name: string;
                            isPrimary: boolean;
                          }[])
                    }
                  />

                  <StakeholderContactsCounter
                    contacts={
                      contacts as unknown as {
                        name: string;
                        isPrimary: boolean;
                      }[]
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <Ui.s bold>{`${firstName} ${lastName}`}</Ui.s>

                {isActiveUser ? (
                  <Tooltip popupContent={<div>Registered user</div>}>
                    <ActiveUserBudgeIcon fontSize={16} className="ms-1" />
                  </Tooltip>
                ) : null}

                {!isActiveUser && hasSentInvite && (
                  <Tooltip popupContent={<div>{t("invited")}</div>}>
                    <EmailIcon fontSize={16} className="ms-1" color={scssVariables.foregroundLow} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <Ui.s>{email}</Ui.s>
      </td>
      <td>
        <Tag variant="closed">{relationship}</Tag>
      </td>
      <td>
        <div className="d-flex justify-content-end">
          <ContextMenu items={dropdownItems} withPropagation>
            <Button
              size="s"
              isOnlyIcon
              variant="tertiary"
              data-testid="plan-dropdown-btn"
              style={{ height: 30, width: 30 }}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </div>
      </td>
    </tr>
  );
};

export default memo(Row);
