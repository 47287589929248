import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { fields, FundraisingFormValues } from "../useFundraisingSlidePanel";

type AmountToRiseFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising.fields");

const AmountToRiseField: FC<AmountToRiseFieldProps> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FundraisingFormValues>();
  const { currencySymbol } = useCurrency();
  return (
    <Row className={className}>
      <Col>
        <Helper>
          <Helper.Question type="input" questionId={fields.targetAmount}>
            <TextField
              name={fields.targetAmount}
              label={t("amountToRaise.label")}
              value={values.targetAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.targetAmount}
              isTouched={touched.targetAmount}
              type="number"
              iconRight={currencySymbol}
            />
          </Helper.Question>
          <Helper.Answer
            className="mt-3"
            withRightMargin
            answerId={fields.targetAmount}
            text={t("amountToRaise.answer")}
          />
        </Helper>
      </Col>
    </Row>
  );
};
export default AmountToRiseField;
