import React, { FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import SetFundraiseFormik from "./SetFundraiseFormik";

type FundraisingSlidePanelProps = {
  show: boolean;
  onHide: () => void;
  fundraiseId?: number;
  onSaved?: () => void;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising");

const SetFundraiseSlidePanel: FC<FundraisingSlidePanelProps> = ({ show, onHide, fundraiseId, onSaved }) => {
  const [isSaved, setIsSaved] = useState(false);

  const handleOnSaved = useCallback(() => {
    setIsSaved(true);
  }, []);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      setIsSaved(false);
      onSaved?.();
    }
  }, [isSaved, onSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t(`title_${fundraiseId ? "edit" : "start"}`)} onHide={onHide} />
      <SetFundraiseFormik onHide={onHide} fundraiseId={fundraiseId} onSaved={handleOnSaved} />
    </SlidePanel>
  );
};

export default SetFundraiseSlidePanel;
