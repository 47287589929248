import { FC, JSX, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import classNames from "classnames";

import { Ui } from "common/components/atoms/Typography";

import WizardLayoutStore from "../store";
import Content from "./Content/Content";
import ControlButtons from "./Content/ControlButtons/ControlButtons";
import Sidebar from "./Sidebar/Sidebar";
import classes from "./WizardContent.module.scss";

export type WizardContentProps = JSX.IntrinsicElements["div"] & {
  loading?: boolean;
  maxStep?: number;
  hideSteps?: boolean;
  stepClassName?: string;
};

type WizardContentComponent = FC<WizardContentProps> & {
  Content: typeof Content;
  Sidebar: typeof Sidebar;
  Controls: typeof ControlButtons;
};

const WizardContent: WizardContentComponent = ({
  className,
  loading,
  children,
  maxStep,
  hideSteps,
  stepClassName,
  ...props
}) => {
  const currentStepStore = WizardLayoutStore.useStoreState((state) => state.currentStep);
  const totalSteps = WizardLayoutStore.useStoreState((state) => state.totalSteps);

  const setTotalStepsAction = WizardLayoutStore.useStoreActions((actions) => actions.setTotalSteps);

  useEffect(() => {
    if (maxStep) {
      setTotalStepsAction(maxStep);
    }
  }, [maxStep, setTotalStepsAction]);

  return (
    <div className={classNames(classes["wrap"], { "wizard-loading": loading }, className)} {...props}>
      {!hideSteps && (
        <Ui.l className={classNames("wizard-steps-section", classes["step-info"], stepClassName || "")}>
          Step {currentStepStore} / {totalSteps}
        </Ui.l>
      )}

      {children}

      {loading && (
        <div className={classes["spinner-wrap"]}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

WizardContent.Content = Content;
WizardContent.Sidebar = Sidebar;
WizardContent.Controls = ControlButtons;

export default WizardContent;
