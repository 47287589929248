import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { equals } from "ramda";

import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import BankDetails from "./tabs/BankDetails/BankDetails";
import CompanyDetails from "./tabs/CompanyDetails/CompanyDetails";
import CompanyInfoTabs, { CompanyTabsTypes } from "./tabs/CompanyInfoTabs";
import IncentiveAgreementsSettings from "./tabs/IncentiveAgreementsSettings/IncentiveAgreementsSettings";
import CompanyBillingDetails from "./tabs/PlanAndBilling/CompanyBillingDetails";

type PropsTypes = {};

const [translation, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.general"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const companyFeatures = [CompanyFeatures.PlanAndBills];

const CompanyInformation: FC<PropsTypes> = () => {
  useDocumentTitleUpdate(translation("title"));

  const { companyId } = useParams<{ companyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { hasFullAccess, hasSubscriptionAccess } = useFeatures(FEATURES.companyInformation, companyFeatures);

  const { getGeneralInfoThunk } = useStoreActions((state) => state.company);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<CompanyTabsTypes>("details");

  const isBillingHidden = useMemo(
    () => !hasFullAccess || !hasSubscriptionAccess,
    [hasFullAccess, hasSubscriptionAccess]
  );

  const getData = useCallback(
    async (companyId: number) => {
      setIsLoading(true);
      await getGeneralInfoThunk(companyId);
      setIsLoading(false);
    },
    [getGeneralInfoThunk]
  );

  useEffect(() => {
    getData(companyId ? +companyId : 0).then();
  }, [companyId, getData]);

  useEffect(() => {
    switch (searchParams.get("tab")) {
      case "details":
        setActiveTab("details");
        break;
      case "billing":
        setActiveTab("billing");
        break;
      case "bank-details":
        setActiveTab("bank-details");
        break;
      case "stock-options":
        setActiveTab("stock-options");
        break;
      default:
        setActiveTab("details");
    }
  }, [searchParams]);

  useEffect(() => {
    if (isBillingHidden && searchParams.get("tab") === "billing") {
      setSearchParams("tab=details");
      setActiveTab("details");
    }
  }, [isBillingHidden, searchParams, setSearchParams]);

  return (
    <PageContent data-testid="company-profile-general-test-id">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag>{tCommon("viewOnly")}</Tag>}
        </div>

        {/*<ViewPitchButton id={Number(companyId)} />*/}
      </PageContent.Header>

      <LoaderContainer loading={isLoading}>
        <CompanyInfoTabs activeTab={activeTab} hideTabs={isBillingHidden ? ["billing"] : undefined} />

        {equals(activeTab, "details") && <CompanyDetails />}

        {equals(activeTab, "billing") && <CompanyBillingDetails />}

        {equals(activeTab, "stock-options") && <IncentiveAgreementsSettings />}

        {equals(activeTab, "bank-details") && <BankDetails />}
      </LoaderContainer>
    </PageContent>
  );
};

export default CompanyInformation;
