import { FC } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import { ActualCapTableSortValue, HeaderRow } from "./Table";
import classes from "./Table.module.scss";

type HeadProps = {
  sortedValue: ActualCapTableSortValue;
  rows: HeaderRow[];
  setSortedValue: (e: ActualCapTableSortValue) => void;
};

const Head: FC<HeadProps> = ({ sortedValue, rows, setSortedValue }) => {
  return (
    <thead className={classes["head-row"]}>
      <tr>
        {rows.map((el, i) => (
          <th
            key={el.key}
            className={classes["head-item"]}
            colSpan={i === 0 ? 3 : undefined}
            onClick={() => {
              if (el.disableSorting) {
                return;
              }
              setSortedValue({
                field: el.key,
                type:
                  sortedValue.field !== el.key
                    ? sortingParams.inc
                    : sortedValue.type === sortingParams.inc
                    ? sortingParams.desc
                    : sortingParams.inc,
              });
            }}
          >
            <div className="d-flex">
              <Ui.xs className={el.className}>{el.value}</Ui.xs>

              {!el?.disableSorting && (
                <span
                  className={cn(classes.sort, {
                    invisible: sortedValue.field !== el.key,
                    [classes["rotated"]]: sortedValue.field === el.key && sortedValue.type === sortingParams.inc,
                  })}
                >
                  <FilledArrowDownIcon />
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Head;
