import { FC, useCallback } from "react";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import ChangeNominalValueForm from "./change-nominal-value-form";

const t = createTranslation(TranslationNS.pages, "company.transactions");

type Props = {
  onClose: () => void;
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
};

const ChangeNominalValueContainer: FC<Props> = ({ onSubmit, onClose }) => {
  const isNominalValueFormOpen = TransactionsContext.useStoreState((state) => state.isNominalValueFormOpen);
  const { postChangeNominalValueThunk } = TransactionsContext.useStoreActions((actions) => actions);

  const handleSubmitNominalValueForm = useCallback(
    async (changeValueData: FormData) => {
      const createdSuccessfully = await postChangeNominalValueThunk(changeValueData);

      if (createdSuccessfully) {
        notify(t("changeNominalValueForm.successUpdated"), true, "success", 5000, false, "top-center");

        onSubmit(String(0));
        return true;
      } else {
        return false;
      }
    },
    [postChangeNominalValueThunk, onSubmit]
  );

  return (
    <SlidePanel show={isNominalValueFormOpen}>
      <ChangeNominalValueForm onSubmit={handleSubmitNominalValueForm} onClose={onClose} />
    </SlidePanel>
  );
};

export default ChangeNominalValueContainer;
