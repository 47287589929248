import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import cn from "classnames";

import { NewAvatar } from "common/components/atoms";
import { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import Tag from "common/components/atoms/Tag/Tag";
import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CommonFileIcon } from "common/icons/svg";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ConvertibleInfo.module.scss";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.info");

export type ConvertibleInfoProps = {
  convertible?: {
    stakeholderCompanyName?: string;
    firstName?: string;
    lastName?: string;
    imageUrl?: string;
    investmentAmount?: number;
    interestRate?: number;
    triggerAmount?: number;
    discount?: number;
    valuationCap?: number;
    shareClass?: string;
    agreementDate?: string;
    expirationDate?: string;
    sharesInCompanyBeforeConversion?: number;
    documentFiles?: UploadedFile[];
  } | null;
  type?: "loan" | "note";
};

const ConvertibleInfo: FC<ConvertibleInfoProps> = ({ convertible, type }) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={cn(classes.info)}>
      <div className={classes.userInfo}>
        <div className={classes.userAvatar}>
          <NewAvatar
            size="m"
            company={!!convertible?.stakeholderCompanyName}
            firstName={convertible?.firstName}
            lastName={convertible?.lastName}
            imageUrl={convertible?.imageUrl}
            companyName={convertible?.stakeholderCompanyName}
          />
          <div className="ms-1">
            <Ui.xl bold>{`${convertible?.firstName} ${convertible?.lastName}`}</Ui.xl>
            <Ui.s className={classes.company}>{convertible?.stakeholderCompanyName}</Ui.s>
          </div>
        </div>
        <div>
          <Tag variant="closed">{type === "loan" ? "Convertible Loan" : t("convertibleNote")}</Tag>
        </div>
      </div>
      <div className="mt-5">
        <Row>
          <Col md={4}>{t("investmentAmount")}</Col>
          <Col className="bold">{fNumber(convertible?.investmentAmount, "amount")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("interestRate")}</Col>
          <Col className="bold">{fNumber(convertible?.interestRate, "percent")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("triggerAmount")}</Col>
          <Col className="bold">{fNumber(convertible?.triggerAmount, "amount")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("discount")}</Col>
          <Col className="bold">{fNumber(convertible?.discount, "percent")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("valuationCap")}</Col>
          <Col className="bold">{fNumber(convertible?.valuationCap, "value")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("shareClass")}</Col>
          <Col className="bold">{convertible?.shareClass || "-"}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("agreementDate")}</Col>
          <Col className="bold">
            {convertible?.agreementDate ? transformDateToCommonDateFormat(convertible?.agreementDate) : "-"}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("expirationDate")}</Col>
          <Col className="bold">
            {convertible?.expirationDate ? transformDateToCommonDateFormat(convertible?.expirationDate) : "-"}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("documentation")}</Col>
          <Col md={8}>
            {convertible?.documentFiles && convertible?.documentFiles.length
              ? convertible.documentFiles.map((document, index) => (
                  <div
                    className={cn(
                      { "mb-3": convertible?.documentFiles && convertible.documentFiles.length - 1 !== index },
                      classes.document
                    )}
                    key={index}
                  >
                    <span>
                      <CommonFileIcon />
                    </span>
                    <a href="#">{document.fileName}</a>
                  </div>
                ))
              : "-"}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConvertibleInfo;
