import { FC, useMemo } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { NewAvatar } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import Tag from "common/components/atoms/Tag/Tag";
import { Ui } from "common/components/atoms/Typography";
import { sortCaptableData } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "../card-view/user-card/user-card.module.scss";
import { AccessControlUserDTO } from "../types";
import { AccessControlSortValue } from "./table-view";

type PropsTypes = {
  users: AccessControlUserDTO[];
  handleOpenEditAccessSidebar: (id: number) => void;
  sortedValue: AccessControlSortValue;
};

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const TableBody: FC<PropsTypes> = ({ users, sortedValue, handleOpenEditAccessSidebar }) => {
  const { hasFullAccess } = useFeatures(FEATURES.userAccessManagement);

  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: users,
      sortedField: sortedValue.field as any,
      sortType: sortedValue.type,
    });
  }, [users, sortedValue.field, sortedValue.type]);

  return (
    <tbody>
      {sortedData.map((user) => (
        <tr key={user.companyUserId}>
          <td>
            <div className="d-flex align-items-center">
              <NewAvatar initials={user.initials} imageUrl={user.avatarFilePath} />
              <Ui.s className="ms-2">{(user as any).fullName}</Ui.s>
            </div>
          </td>
          <td>
            <Ui.s>{user.email}</Ui.s>
          </td>
          <td>
            {!user.hasAcceptedInvite && (
              <Tag className={classes.invited} size="s" variant="draft">
                {t("invitedTag")}
              </Tag>
            )}
            {user.isAdmin && user.hasAcceptedInvite && (
              <Tag className={classes.invited} size="s" variant="information">
                {t("adminTag")}
              </Tag>
            )}
          </td>
          <td>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                size="s"
                onClick={() => handleOpenEditAccessSidebar(user.companyUserId)}
                isDisabled={!hasFullAccess}
                data-testid="edit-btn"
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={tCommon("tooltip")}
              >
                {t("editPermissionBtn")}
              </Button>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
