import { FC } from "react";
import { useFormikContext } from "formik";

import { TextArea } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { fields, NewSubscriptionFormValues } from "../useNewSubscriptionSlidePanel";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.newSubscription.fields");

const DescriptionField: FC<Props> = ({ className }) => {
  const { values, errors, touched, handleChange } = useFormikContext<NewSubscriptionFormValues>();

  return (
    <div className={className}>
      <TextArea
        name={fields.description}
        label={t("description.label")}
        placeholder={t("description.placeholder")}
        isOptional
        value={values.description}
        error={errors.description}
        isTouched={touched.description}
        onChange={handleChange}
      />
    </div>
  );
};

export default DescriptionField;
