import { FC, useMemo } from "react";

//import { Ui } from "common/components/atoms";
//import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { useStoreState } from "store/store";

import FundraisingOverview from "./FundraisingOverview/FundraisingOverview";
import classes from "./Summary.module.scss";

type SummaryProps = {};
const Summary: FC<SummaryProps> = () => {
  const fundraise = useStoreState((state) => state.fundraisingModel.summary);

  const raisedFunds = useMemo(() => {
    if (fundraise) {
      const subscriptions = fundraise.events.find((round) => round.fundingEventTypeId === 1)?.instruments;
      return (
        subscriptions?.reduce((acc, curr) => {
          return acc + curr.subscriptionAmount;
        }, 0) || 0
      );
    }
    return 0;
  }, [fundraise]);

  return (
    <div className={classes.wrap}>
      {/*<ExpansionPanel.Toggle eventKey={"1"}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              Summary
            </Ui.xl>
          </div>
        </div>
      </ExpansionPanel.Toggle>*/}
      {/*<ExpansionPanel.Collapse className="pt-1 pe-4 pb-4 ps-4" eventKey={"1"}>*/}
      <FundraisingOverview
        valuationPostMoney={fundraise?.postMoneyValuation || 0}
        raisedFunds={raisedFunds}
        targetAmount={fundraise?.targetAmount || 0}
        dilution={fundraise?.dillutionPercentage || 0}
        valuationPreMoney={fundraise?.preMoneyValuation || 0}
        totalInvestors={0}
        totalInvestorsCommitted={0}
        shares={fundraise?.preMoneyNumberOfShares || 0}
        dilutionPercentage={fundraise?.dilutionPercentage || 0}
        sharesDiluted={fundraise?.postMoneyNumberOfShares || 0}
        startDate={fundraise?.startsAt || "02-04-2022"}
        endDate={fundraise?.endsAt || "02-04-2022"}
      />
      {/*</ExpansionPanel.Collapse>*/}
    </div>
  );
};

export default Summary;
