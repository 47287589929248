import { FC } from "react";
import cn from "classnames";

import { Transaction, TransactionCategory, TransactionCategoryIds } from "../../../types";
import NominalValueItemBody from "../../forms/form-sections/nominal-value-details/nominal-value-item-body";
import CapitalIncreaseBody from "./transaction-bodies/capital-increase-body";
import CapitalIncreaseImportBody from "./transaction-bodies/capital-increase-import-body";
import DeletionBody from "./transaction-bodies/deletion-body";
import IssueSharesAndBuySellBody from "./transaction-bodies/issue-shares-and-buy-sell-body";
import SplitBody from "./transaction-bodies/split-body";
import classes from "./transaction-item.module.scss";

export type TransactionContextMenuHandlers = {
  handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
  handleOpenConfirmTransaction?: (transaction: Transaction) => void;
  handleOpenDeleteTransaction?: (transactionId: number) => void;
  handleOpenRollbackTransaction?: (transaction: Transaction) => void;
};

type PropsTypes = {
  transaction: Transaction;
  eventKey: string;
  contextMenuHandlers: TransactionContextMenuHandlers;
  importView?: boolean;
};

const Body: FC<PropsTypes> = ({ eventKey, transaction, contextMenuHandlers, importView }) => {
  if (transaction.categoryId === TransactionCategory.Split) {
    return (
      <div className={classes.bodyContainer}>
        <SplitBody transaction={transaction} eventKey={eventKey} />
      </div>
    );
  }

  if (
    (transaction.categoryId === TransactionCategory.CapitalIncrease ||
      transaction.categoryId === TransactionCategory.ShareTransfer) &&
    importView
  ) {
    return (
      <CapitalIncreaseImportBody bundle={transaction} eventKey={eventKey} contextMenuHandlers={contextMenuHandlers} />
    );
  }

  if (
    transaction.categoryId === TransactionCategory.CapitalIncrease ||
    transaction.categoryId === TransactionCategory.ShareTransfer
  ) {
    return <CapitalIncreaseBody bundle={transaction} eventKey={eventKey} contextMenuHandlers={contextMenuHandlers} />;
  }

  if (transaction.categoryId === TransactionCategory.ChangeNominalValue) {
    return (
      <div className={classes.bodyContainer}>
        <NominalValueItemBody transaction={transaction} />
      </div>
    );
  }

  if (transaction.categoryId === TransactionCategory.Deletion) {
    return (
      <div className={cn("p-4", classes.bodyContainer, classes.darkBackground)}>
        <DeletionBody transaction={transaction} />
      </div>
    );
  }

  return <IssueSharesAndBuySellBody transaction={transaction} />;
};

export default Body;
