import { FC, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { motion } from "framer-motion";
import { defaultTo, equals } from "ramda";

import { NewAvatar, Tooltip, Ui } from "common/components/atoms";
import { StakeholderInvitationStatus } from "common/components/organisms/stakeholder-details/types";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ActiveUserBudgeIcon, EmailIcon, ExclamationMarkCircle } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import StakeholderFormSidebar from "../../../stakeholders-management/components/stakeholder-form-sidebar";
import { CapTableServiceGetResponse } from "../../CapTable.types";
import ShareholderDetailsModal from "../../common/ShareholderDetailsModal/ShareholderDetailsModal";
import CapTableStore from "../../store";
import NotesCell from "./cells/NotesCell";
import classes from "./Table.module.scss";

export type TableRowProps = {
  data: CapTableServiceGetResponse["capTable"][number];
};

const Row: FC<TableRowProps> = ({ data }) => {
  const fNumber = useFormatNumbers();

  const { companyId } = useParams<{ companyId: string }>();

  const [isEditStakeholderModalOpen, setIsEditStakeholderModalOpen] = useState(false);
  const { actualData, selectedRowData } = CapTableStore.useStoreState((state) => state);
  const { setSelectedRowData } = CapTableStore.useStoreActions((actions) => actions);

  const selectedDateForActualCapTable = CapTableStore.useStoreState((state) => state.selectedDateForActualCapTable);
  const { getActualDataThunk } = CapTableStore.useStoreActions((actions) => actions);

  const fetchCaptableData = useCallback(async () => {
    try {
      if (companyId) {
        getActualDataThunk({
          companyId: +companyId,
          date: selectedDateForActualCapTable,
        });
      }
    } catch (e) {
      console.error({ e });
    }
  }, [companyId, getActualDataThunk, selectedDateForActualCapTable]);

  const isRowSelected = useMemo(() => equals(data, selectedRowData), [data, selectedRowData]);

  const selectRow: MouseEventHandler<HTMLTableRowElement> = useCallback(
    (e) => {
      if (!actualData.hasAccessToDetails) {
        return;
      }

      const innerTarget = e.target as any;

      if (
        innerTarget.dataset.testid === "plan-dropdown-btn" ||
        innerTarget.nodeName === "circle" ||
        innerTarget.nodeName === "svg"
      ) {
        return;
      }
      setSelectedRowData(isRowSelected ? undefined : data);
    },
    [actualData.hasAccessToDetails, data, isRowSelected, setSelectedRowData]
  );

  const handleClose = useCallback(() => {
    setSelectedRowData(undefined);
  }, [setSelectedRowData]);

  const handleOpenEditStakeholderModal = () => {
    setIsEditStakeholderModalOpen(true);
  };

  const handleCloseEditStakeholderModal = () => {
    setIsEditStakeholderModalOpen(false);
  };

  return (
    <>
      <motion.tr
        className={cn(classes.tableBorder, {
          [classes.company]: isRowSelected && data.isCompanyOwned,
        })}
        initial={{
          opacity: 0,
          x: -20,
        }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
        onClick={selectRow}
      >
        <td colSpan={3} style={{ width: "25%" }}>
          <div className="d-flex align-items-center">
            <NewAvatar
              imageUrl={data.avatarFilePath}
              company={data.isCompanyOwned}
              initials={data.initials}
              className={classes.avatar}
              size="l"
            />

            <div className="ms-2">
              <div className="d-flex align-items-center">
                <Ui.s bold className="me-1">
                  {data.shareholderName || "-"}
                </Ui.s>
                {data.invitationStatus === StakeholderInvitationStatus.active && (
                  <Tooltip popupContent={<div>Registered user</div>}>
                    <ActiveUserBudgeIcon fontSize={16} />
                  </Tooltip>
                )}
                {data.invitationStatus === StakeholderInvitationStatus.invited && (
                  <Tooltip popupContent={<div>Invited user</div>}>
                    <EmailIcon fontSize={16} color={scssVariables.foregroundLow} />
                  </Tooltip>
                )}
              </div>

              {data.isCompanyOwned ? (
                <Ui.s style={{ color: scssVariables.foregroundLow }}>{data.representedBy}</Ui.s>
              ) : null}
            </div>
          </div>
        </td>

        <td className="ui-s">{data.shareClasses.join(", ")}</td>

        <td className="ui-s">{data.relationship}</td>

        <td className="ui-s text-end">{fNumber(data.shares, "amount")}</td>

        <td className="ui-s text-end">
          {fNumber((data.shares / defaultTo(1, actualData?.capTableSummary?.numberOfShares)) * 100, "percent")}
        </td>

        <td className="ui-s text-end position-relative" style={{ width: "17%" }}>
          {fNumber(data.totalValue, "value")}
        </td>
        <td>
          <NotesCell
            documents={data.documentCount}
            pledgedShares={data.pledgedShares}
            hasPledges={data.hasPledges}
            transactionDescriptions={data.descriptionCount}
          />
        </td>
      </motion.tr>

      <ShareholderDetailsModal
        type="actual"
        stakeholderId={data.stakeholderId}
        show={Boolean(isRowSelected && selectedRowData)}
        handleClose={handleClose}
        handleOpenEditStakeholderModal={handleOpenEditStakeholderModal}
      />

      <StakeholderFormSidebar
        isOpen={isEditStakeholderModalOpen}
        onClose={handleCloseEditStakeholderModal}
        onCreateSuccess={fetchCaptableData}
        stakeholderId={data.stakeholderId}
      />
    </>
  );
};

export default Row;
