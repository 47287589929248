import { FC, useCallback, useState } from "react";
import { AxisProps } from "@nivo/axes";
import { DatumValue, DotsItem, ValueFormat } from "@nivo/core";
import { Datum, Layer, LineProps, LineSvgProps, Point, ResponsiveLine } from "@nivo/line";
import { ScaleValue, TicksSpec } from "@nivo/scales";
import { area } from "d3-shape";
import * as R from "ramda";

import { formatNumber } from "common/hooks/useFormatNumbers";
import { AreaLayerProps, CustomComputedSerie, singleStepChartProps } from "common/types/Charts.types";
import { setLinearChartType } from "common/utils/functions";

type LinearChartProps = {
  height?: number;
  dashedLines?: boolean;
  withCustomPoint?: boolean;
  numberOfAxisValues?: TicksSpec<ScaleValue>;
  data: singleStepChartProps[];
  chartType?: LineProps["curve"];
  axisYTickPadding?: number;
  selectedPointLabel?: string[];
  showPointOnHover?: boolean;
  yFormat?: ValueFormat<DatumValue>;
  axisXFormatter?: (value: AxisProps["format"]) => void;
};

type ModifiedPoint = Point & { isLast?: boolean; data: any };

const LinearChart: FC<LinearChartProps & LineSvgProps> = ({
  data,
  yFormat,
  dashedLines,
  height = 350,
  axisXFormatter,
  withCustomPoint,
  axisYTickPadding,
  selectedPointLabel,
  showPointOnHover,
  numberOfAxisValues,
  chartType = "natural",
  ...props
}) => {
  const [mouseEnter, setMouseEnter] = useState<boolean>(false);
  const [current, setCurrent] = useState<ModifiedPoint>({} as ModifiedPoint);

  const handleHover = useCallback(
    (point: ModifiedPoint) => {
      if (point?.index === data?.[0].data.length - 1) {
        setCurrent({ ...point, isLast: true });
      } else {
        setCurrent({ ...point });
      }
    },

    [setCurrent, data]
  );

  function CustomPoint({ points }: AreaLayerProps) {
    const shownPoints = points.filter((el) => selectedPointLabel?.includes(el.data.x as string));

    return (
      <g pointerEvents="none">
        {shownPoints.map((point) => (
          <DotsItem
            size={props.pointSize || 10}
            x={point.x}
            y={point.y}
            key={point.id}
            borderWidth={1}
            labelYOffset={0}
            datum={point.data}
            color={point.color}
            borderColor={point.borderColor}
          />
        ))}
      </g>
    );
  }

  const AreaLayer = ({ series, xScale, yScale, innerHeight }: AreaLayerProps) => {
    const areaGenerator = area()
      .x((d: Datum) => xScale(d.data.x))
      .y0((d: Datum) => Math.min(innerHeight, yScale(-d.data.y)))
      .y1((d: Datum) => yScale(d.data.y))
      .curve(setLinearChartType(chartType));

    return (
      <path
        pointerEvents="none"
        fill={series[0]?.chartBgColor ? series[0]?.chartBgColor : "transparent"}
        d={areaGenerator(series[0].data as any) as string}
      />
    );
  };

  const DashedLine = ({ series, lineGenerator, xScale, yScale }: AreaLayerProps) => {
    return series.map(({ id, data, color }: CustomComputedSerie) => (
      <path
        pointerEvents="none"
        key={id}
        d={
          lineGenerator(
            data.map((d: Datum) => ({
              x: xScale(d.data.x),
              y: yScale(d.data.y),
            }))
          ) as string
        }
        fill="none"
        stroke={color}
        style={{
          strokeWidth: 2,
          strokeDasharray: "4, 4",
        }}
      />
    ));
  };

  return (
    <div
      style={{
        height,
        width: "100%",
      }}
    >
      <ResponsiveLine
        useMesh
        enableArea
        data={data}
        isInteractive
        axisLeft={null}
        curve={chartType}
        yFormat={yFormat}
        colors={{ datum: "color" }}
        margin={{ bottom: 40, left: 20, right: 40, top: 20 }}
        axisRight={{
          tickSize: 0,
          tickValues: numberOfAxisValues,
          tickPadding: axisYTickPadding ? axisYTickPadding : -10,
          format: (value) => formatNumber(value),
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 15,
          format: axisXFormatter,
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: "#89898D",
              },
            },
          },
          grid: {
            line: {
              pointerEvents: "none",
            },
          },
        }}
        layers={
          [
            "axes",
            "mesh",
            "slices",
            "legends",
            "grid",
            AreaLayer,
            withCustomPoint && CustomPoint,
            dashedLines ? DashedLine : "lines",
            mouseEnter && showPointOnHover ? () => <ActivePointLayer point={current} /> : undefined,
          ] as Layer[]
        }
        onMouseEnter={setMouseEnter.bind(null, true)}
        onMouseLeave={setMouseEnter.bind(null, false)}
        onMouseMove={showPointOnHover ? handleHover : undefined}
        {...props}
      />
    </div>
  );
};

function ActivePointLayer({ point }: { point: ModifiedPoint }) {
  if (R.isEmpty(point)) {
    return null;
  }

  return (
    <g
      transform={`translate(${point.x}, ${point.y})`}
      style={{
        transition: "all 0.5s",
      }}
    >
      <circle
        r="16"
        fill={point.color}
        style={{
          opacity: 0.3,
        }}
      />
      <circle
        r="12"
        fill={point.color}
        style={{
          opacity: 0.5,
        }}
      />
      <circle r="6" fill={point.color} />
    </g>
  );
}

export default LinearChart;
