import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import { DocumentStatusEnum } from "common/enums/enum";
import { FilesDataType } from "common/types/DocumentFiles.types";
import { FundraiseNoteConversionGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { fields as fieldsConversion } from "../fields/fieldsConversion";

export type NoteConversionFormValues = {
  noteId?: number;
  useManualConversion?: boolean;
  conversionDate?: string;
  shareClassId?: number;
  comment?: string;
  interestAmount?: number | null;
  sharePrice?: number;
  interestPaidInCash?: boolean;
  sharesAllocation?: number;
  documentStatusId?: number;
  numberOfShares?: number;
} & FilesDataType;

export const fields = {
  noteId: "noteId",
  ...fieldsConversion,
} as const;

const tv = createTranslation(TranslationNS.validation);
const useNoteConversionSlidePanel = (data?: FundraiseNoteConversionGetDTO | null) => {
  const initialValues = useMemo<NoteConversionFormValues>(
    () => ({
      [fields.noteId]: defaultTo(undefined, data?.convertibleNoteId),
      [fields.useManualConversion]: defaultTo(false, data?.useManualConversion),
      [fields.conversionDate]: defaultTo(undefined, data?.conversionDate),
      [fields.shareClassId]: defaultTo(0, data?.shareClassId),
      [fields.comment]: defaultTo("", data?.comment),
      [fields.sharePrice]: defaultTo(undefined, data?.sharePrice),
      [fields.interestAmount]: defaultTo(null, data?.interestAmount),
      [fields.interestPaidInCash]: defaultTo(false, data?.interestNotConvertedToEquity),
      [fields.sharesAllocation]: defaultTo(undefined, data?.sharesAllocation),
      [fields.autoCalculatedInterest]: 0,
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.NO_DOCUMENT_REQUIRED, data?.documentStatusId),
      [fields.filesData]: {
        oldFiles: defaultTo([], data?.documentFiles),
        newFiles: [],
      },
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.conversionDate]: string().when(fields.useManualConversion, {
          is: false,
          then: string().required(tv("required")),
        }),
        [fields.interestAmount]: number().when(fields.useManualConversion, {
          is: false,
          then: number()
            .nullable(true)
            .transform((value, originalValue) => {
              // console.log(originalValue === "" ? -1 : value, "tupac");
              return originalValue === null ? 1 : value;
            })
            .required(tv("required"))
            .min(0, tv("minNumber", { number: 1 })),
        }),
        [fields.shareClassId]: number().required(tv("required")).min(1, tv("required")),
        [fields.sharesAllocation]: number().when(fields.useManualConversion, {
          is: true,
          then: number()
            .required(tv("required"))
            .min(1, tv("minNumber", { number: 1 })),
        }),
        [fields.sharePrice]: number().when(fields.useManualConversion, {
          is: true,
          then: number()
            .required(tv("required"))
            .min(1, tv("minNumber", { number: "0,000001" })),
        }),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useNoteConversionSlidePanel;
