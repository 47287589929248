import { FC } from "react";
import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import P from "common/components/atoms/Typography/Paragraph";
import Ui from "common/components/atoms/Typography/Ui";
import keyPng from "common/icons/key.png";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import scssVariables from "scss/variables.module.scss";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "noAccess");

function getLastSegment(url: string) {
  const segments = url.split("/");
  return segments[segments.length - 1];
}

type NoAccessProps = {
  pageTitle?: string;
};

const NoAccess: FC<NoAccessProps> = ({ pageTitle }) => {
  const { pathname } = useLocation();
  const pageName = getLastSegment(pathname);

  return (
    <>
      <PageContent>
        <PageContent.Header>
          <PageContent.Header.Title>{pageTitle ? pageTitle : t(`titles.${pageName}`)}</PageContent.Header.Title>
        </PageContent.Header>
        <div>
          <div className="d-flex justify-content-between paper-container bg-white">
            <div>
              <Ui.xl className="mb-2">{t("content.header")}</Ui.xl>
              <P.m style={{ color: scssVariables.neutral700 }}>{t("content.body")}</P.m>
            </div>
            <Image alt="key image" src={keyPng} height={90} />
          </div>
        </div>
      </PageContent>
      <div style={{ width: "320px" }} />
    </>
  );
};

export default NoAccess;
