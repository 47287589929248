import { ChangeEvent, FC, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { Form as FormikForm, useFormikContext } from "formik";

import { Button, Check, P, Ui } from "common/components/atoms";
import { SharePriceTypeEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import {
  fields,
  IncentiveAgreementSettingsGetDto,
  StockOptionsSettingsFormValues,
} from "./IncentiveAgreementsSettings";
import classes from "./IncentiveAgreementsSettings.module.scss";

type Props = {
  sharePrices: IncentiveAgreementSettingsGetDto | null;
};
const IncentiveAgreementsSettingsForm: FC<Props> = ({ sharePrices }) => {
  const fNumber = useFormatNumbers();

  const { values, setFieldValue, handleChange, handleBlur, isSubmitting } =
    useFormikContext<StockOptionsSettingsFormValues>();

  const handleChangeRadio = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, +e.target.value).then();
      if (+e.target.value === SharePriceTypeEnum.FULLY_DILUTED) {
        setFieldValue(fields.includeUnusedAuthorizedShares, true).then();
        setFieldValue(fields.includeValueOfFinancialInstruments, true).then();
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (values.calculationMethod === SharePriceTypeEnum.ACTUAL) {
      setFieldValue(fields.includeUnusedAuthorizedShares, false).then();
      setFieldValue(fields.includeValueOfFinancialInstruments, false).then();
    }
  }, [setFieldValue, values.calculationMethod]);

  const getSharePrice = useCallback(() => {
    if (values.calculationMethod === SharePriceTypeEnum.ACTUAL) {
      return sharePrices?.sharePrice;
    }

    if (values.calculationMethod === SharePriceTypeEnum.FULLY_DILUTED) {
      if (!values.includeValueOfFinancialInstruments && !values.includeUnusedAuthorizedShares) {
        return sharePrices?.fdExclValueSharePrice;
      }

      if (values.includeValueOfFinancialInstruments && !values.includeUnusedAuthorizedShares) {
        return sharePrices?.fdInclValueSharePrice;
      }

      if (!values.includeValueOfFinancialInstruments && values.includeUnusedAuthorizedShares) {
        return sharePrices?.fdExclValueInclUnusedPoolsSharePrice;
      }

      if (values.includeValueOfFinancialInstruments && values.includeUnusedAuthorizedShares) {
        return sharePrices?.fdInclValueInclUnusedPoolsSharePrice;
      }
    }

    return sharePrices?.sharePrice;
  }, [
    values.calculationMethod,
    values.includeValueOfFinancialInstruments,
    values.includeUnusedAuthorizedShares,
    sharePrices?.sharePrice,
    sharePrices?.fdExclValueSharePrice,
    sharePrices?.fdInclValueSharePrice,
    sharePrices?.fdExclValueInclUnusedPoolsSharePrice,
    sharePrices?.fdInclValueInclUnusedPoolsSharePrice,
  ]);

  return (
    <FormikForm>
      <div className="mb-5">
        <Ui.xl bold>Incentive agreements settings</Ui.xl>
        <P.m className="mt-2">
          These preferences apply to all RSA and stock option agreements granted by your company.
        </P.m>
      </div>
      <div>
        <Ui.m style={{ fontWeight: 500 }}>Calculation method for incentive agreements valuation</Ui.m>
        <div className={classNames(classes.calculationMethod, { [classes.selected]: false })}>
          <Form.Check className={classes.typeRadio} type="radio" id={"vestType_IncludeUnusedAuthorizedShares"}>
            <Form.Check.Input
              type="radio"
              name={fields.calculationMethod}
              value={SharePriceTypeEnum.ACTUAL}
              onChange={handleChangeRadio}
              checked={values.calculationMethod === SharePriceTypeEnum.ACTUAL}
              onBlur={handleBlur}
            />
            <div>
              <Form.Check.Label>
                <Ui.m tag="span" color="foregroundHigh" style={{ fontWeight: 500 }}>
                  Actual ownership
                </Ui.m>
              </Form.Check.Label>
              <P.s color="foregroundLow" style={{ marginTop: 2, cursor: "default" }}>
                The value is calculated using only issued shares.
              </P.s>
            </div>
          </Form.Check>
        </div>
        <div className={classNames(classes.calculationMethod, { [classes.selected]: false })}>
          <Form.Check className={classes.typeRadio} type="radio" id={"vestType_IncludeValueOfFinancialInstruments"}>
            <Form.Check.Input
              type="radio"
              name={fields.calculationMethod}
              value={SharePriceTypeEnum.FULLY_DILUTED}
              onChange={handleChangeRadio}
              checked={values.calculationMethod === SharePriceTypeEnum.FULLY_DILUTED}
              onBlur={handleBlur}
            />
            <div>
              <Form.Check.Label>
                <Ui.m tag="span" color="foregroundHigh" style={{ fontWeight: 500 }}>
                  Fully diluted ownership
                </Ui.m>
              </Form.Check.Label>
              <P.s color="foregroundLow" style={{ marginTop: 2, cursor: "default" }}>
                The value is calculated using unissued shares, including stock options, warrants, loans, notes, and
                unused equity pools; in addition to issued shares.
              </P.s>
              <div>
                <Check
                  label="Include unused authorized shares from pools"
                  className="mb-1 mt-1"
                  name={fields.includeUnusedAuthorizedShares}
                  checked={values.includeUnusedAuthorizedShares}
                  onChange={handleChange}
                  disabled={values.calculationMethod !== SharePriceTypeEnum.FULLY_DILUTED}
                  onBlur={handleBlur}
                />
                <Check
                  label="Add monetary value of financial instruments to company valuation"
                  name={fields.includeValueOfFinancialInstruments}
                  checked={values.includeValueOfFinancialInstruments}
                  onChange={handleChange}
                  disabled={values.calculationMethod !== SharePriceTypeEnum.FULLY_DILUTED}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </Form.Check>
        </div>
        <Ui.m color="foregroundHigh" className="mt-3">
          The selected calculation method will result in a share price of{" "}
          <Ui.m bold tag="span">
            {fNumber(getSharePrice(), "sharePrice")}.
          </Ui.m>{" "}
        </Ui.m>
      </div>
      <div>
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" className="mt-8" type="submit">
          Save changes
        </Button>
      </div>
    </FormikForm>
  );
};
export default IncentiveAgreementsSettingsForm;
