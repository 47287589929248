import { ReactNode } from "react";
import { Config } from "driver.js";

export enum GuideIds {
  UploadAsIsCapTable = 1,
  ClearAndAddTransactions = 2,
  AddRecentTransactions = 3,
  AddNominalValue = 4,
  AddCompanyValuation = 5,
}

export const GuideNamesById: Record<number, GuideNames> = {
  [GuideIds.UploadAsIsCapTable]: "UploadAsIsCapTable",
  [GuideIds.ClearAndAddTransactions]: "ClearAndAddTransactions",
  [GuideIds.AddRecentTransactions]: "AddRecentTransactions",
  [GuideIds.AddNominalValue]: "AddNominalValue",
  [GuideIds.AddCompanyValuation]: "AddCompanyValuation",
} as const;

export type GuideNames = keyof typeof GuideIds;

export type CardStates = {
  starred: boolean;
  done: boolean;
  hidden: boolean;
};

export type CardConfig = {
  title: string;
  description: string;
  icon: ReactNode;
} & CardStates;

export type GuideConfig = {
  id: GuideIds;
  cardConfig: CardConfig;
  flowConfig: Config;
};

export type GuidesStructure = Record<GuideNames, GuideConfig>;
