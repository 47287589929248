import { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { times } from "ramda";
import * as Yup from "yup";

import {
  Button,
  ChecksGroup,
  Divider,
  Dropdown,
  Helper,
  P,
  SlidePanel,
  TextArea,
  TextField,
} from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ValuationContext from "../../ValuationContext";

const [t, tv] = [createTranslation("pages", "company.valuation"), createTranslation(TranslationNS.validation)];

type ValuesForm = {
  taxableValue?: number;
  taxYear: string | undefined;
  sendEmail: boolean;
  emailComment: string;
  taxableDescription?: string;
};

const EditTaxableValue: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { currencySymbol } = useCurrency();

  const { getValuationThunk } = useStoreActions((actions) => actions.company);
  const { isTaxableValueEdit } = ValuationContext.useStoreState((state) => state);
  const { setIsTaxableValueEdit } = ValuationContext.useStoreActions((actions) => actions);

  const taxYearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();

    return times((index) => (currentYear + 1 - index).toString(), 10);
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        taxableValue: Yup.number()
          .required(tv("required"))
          .min(0.000001, tv("minNumber", { number: "0.000001" })),
        taxYear: Yup.string().typeError(tv("required")).required(tv("required")),
        sendEmail: Yup.boolean(),
        emailComment: Yup.string(),
      }),
    []
  );

  const handleClose = () => {
    setIsTaxableValueEdit(false);
    resetForm();
  };

  const handleSubmitValuation = async (values: ValuesForm) => {
    try {
      await axios.post("/api/company/valuation/taxable-value", {
        companyId,
        taxableValue: values.taxableValue,
        valuationYear: values.taxYear,
        description: values.taxableDescription,
      });
      await getValuationThunk(Number(companyId));
      handleClose();
    } catch (error) {
      console.error({ error });
    }
  };

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue, isSubmitting, resetForm } =
    useFormik<ValuesForm>({
      initialValues: {
        taxableValue: undefined,
        taxYear: undefined,
        sendEmail: false,
        emailComment: "",
        taxableDescription: "",
      },
      onSubmit: handleSubmitValuation,
      validationSchema,
    });

  const handleTaxYearChange = useCallback(
    (year: string) => {
      setFieldValue("taxYear", year);
    },
    [setFieldValue]
  );

  return (
    <SlidePanel data-testid="offcanvas-container-test-id" show={isTaxableValueEdit} onHide={handleClose}>
      <Helper>
        <Form onSubmit={handleSubmit}>
          <SlidePanel.Header title={t("updateTaxableValue")} onHide={handleClose} />
          <SlidePanel.Section title={t("valuationDetails")}>
            <Helper.Question questionId="valuePerShare" questionMarkClassName="mt-2">
              <TextField
                type="number"
                className="w-100"
                name="taxableValue"
                label={t("taxableValuePerShare")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.taxableValue}
                error={errors.taxableValue}
                isTouched={touched.taxableValue}
              />
            </Helper.Question>
            <Helper.Answer
              className="mt-3"
              answerId="valuePerShare"
              text={t("taxableValuePerShareHelperText", {
                currency: currencySymbol,
              })}
            />

            <Helper.Question questionId="taxYear" className="mt-4">
              <Dropdown
                className="w-100"
                name="taxYear"
                placeholder={t("taxYearPlaceholder")}
                options={taxYearOptions}
                error={errors.taxYear}
                isTouched={touched.taxYear}
                selectedValue={values.taxYear}
                onChange={handleTaxYearChange}
              />
            </Helper.Question>

            <Helper.Answer className="mt-3" answerId="taxYear" text={t("taxYearHelperText")} />

            <Helper.Question questionId="description" className="mt-4" questionMarkClassName="mt-2">
              <TextArea
                isOptional
                name="taxableDescription"
                label={t("taxableDescription")}
                value={values.taxableDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.taxableDescription}
                isTouched={touched.taxableDescription}
              />
            </Helper.Question>

            <Helper.Answer className="mt-3" answerId="description" text={t("taxableDescriptionHelperText")} />
            <Divider />

            <ChecksGroup className="mb-4">
              <ChecksGroup.Check
                name="sendEmail"
                label={t("checkEmailTitle")}
                description={t("checkEmailDescription")}
                checked={values.sendEmail}
                onChange={handleChange}
              />
            </ChecksGroup>

            {values.sendEmail && (
              <TextArea
                className="mb-7"
                name="emailComment"
                isOptional
                value={values.emailComment}
                isTouched={touched.emailComment}
                error={errors.emailComment}
                onChange={handleChange}
                label={t("additionalCommentTitle")}
              />
            )}
            <P.m className="mt-5">{t("updatingValueDescription")}</P.m>
          </SlidePanel.Section>
          <SlidePanel.Actions>
            <Button isLoading={isSubmitting} variant="primary" type="submit">
              Update Value
            </Button>
            <Button onClick={handleClose} isDisabled={isSubmitting} className="ms-2" variant="secondary">
              Cancel
            </Button>
          </SlidePanel.Actions>
        </Form>
      </Helper>
    </SlidePanel>
  );
};

export default EditTaxableValue;
