import { FC } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import * as R from "ramda";

import { H, P } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ValuationContext from "../../ValuationContext";
import classes from "../RemoveValiationModal/RemoveValuationModal.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation.removeTaxableValueModal");

const RemoveTaxableValueModal: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { isLoading, removeTaxableValue } = ValuationContext.useStoreState((state) => state);
  const { setIsLoading, setRemoveTaxableValue } = ValuationContext.useStoreActions((actions) => actions);

  const { getValuationThunk } = useStoreActions((state) => state.company);

  const handleClose = () => {
    setRemoveTaxableValue(null);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const request = await axios.delete(`/api/company/valuation/taxable-value/${removeTaxableValue?.id}`);

      if (request.status === 200) {
        getValuationThunk(Number(companyId));
        handleClose();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      centered
      show={!R.isNil(removeTaxableValue)}
      className={classes["modal"]}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleClose}
    >
      <div className={cn(classes["container"], "p-5")}>
        <H.xs className="text-center">
          {t("title", {
            year: removeTaxableValue?.year,
          })}
        </H.xs>

        <P.m className="mt-3 mb-5 text-center">
          {t("description", {
            year: removeTaxableValue?.year,
          })}
        </P.m>

        <div className="text-center">
          <Button className={cn(classes["delete-btn"], "me-2")} isDisabled={isLoading} onClick={handleDelete}>
            {t("delete")}
          </Button>

          <Button variant="secondary" isDisabled={isLoading} onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </div>
      {isLoading && <Spinner />}
    </Modal>
  );
};

export default RemoveTaxableValueModal;
