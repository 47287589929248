import scssVars from "scss/variables.module.scss";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const localStoragePublicCompanyKey = "unlisted-selected-public-company-id";

export const DEFAULT_CURRENCY_CODE = "NOK";

export const HEADER_ID = "app-header";
export const FOOTER_ID = "unlisted-footer";
export const OFF_CANVAS_HEADER_ID = "off-canvas-header";
export const MAX_FILE_SIZE = 20000000;
export const MAX_IMAGE_FILE_SIZE = 5000000;

export const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const VIEW_PRICES_LINK = "https://en.unlisted.ai/priser";
export const CONTACT_US_LINK = "https://www.unlisted.ai/kontakt-oss";

export enum SCSSVariablesType {
  // primary
  primary1 = "primary1",

  // secondary
  secondary1 = "secondary1",

  //foreground
  foregroundHigh = "foregroundHigh",
  foregroundMedium = "foregroundMedium",
  foregroundLow = "foregroundLow",
  foregroundDisabled = "foregroundDisabled",
  foregroundOnPrimary = "foregroundOnPrimary",
  foregroundOnSecondary = "foregroundOnSecondary",

  // element

  element1 = "element1",
  element2 = "element2",
  element3 = "element3",

  // stroke
  strokeHigh = "strokeHigh",
  strokeMedium = "strokeMedium",

  // surface
  surface1 = "surface1",

  // background
  background1 = "background1",
  background2 = "background2",

  //critical
  critical050 = "critical050",
  critical300 = "critical300",
  critical500 = "critical500",
  critical700 = "critical700",
  critical900 = "critical900",

  // positive
  positive050 = "positive050",
  positive300 = "positive300",
  positive500 = "positive500",
  positive700 = "positive700",
  positive900 = "positive900",

  // information
  information050 = "information050",
  information300 = "information300",
  information500 = "information500",
  information700 = "information700",
  information900 = "information900",

  // warning
  warning050 = "warning050",
  warning300 = "warning300",
  warning500 = "warning500",
  warning700 = "warning700",
  warning900 = "warning900",

  //additional pallete

  "additional1100" = "additional1100",
  "additional1200" = "additional1200",
  "additional1300" = "additional1500",
  "additional1400" = "additional1550",
  additional1600 = "additional1600",

  "additional2100" = "additional2100",
  "additional2200" = "additional2200",
  "additional2500" = "additional2500",
  "additional2550" = "additional2550",
  "additional2600" = "additional2600",

  "additional3100" = "additional3100",
  "additional3200" = "additional3200",
  "additional3500" = "additional3500",
  "additional3550" = "additional3550",
  "additional3600" = "additional3600",

  "additional4100" = "additional4100",
  "additional4200" = "additional4200",
  "additional4500" = "additional4500",
  "additional4550" = "additional4550",
  "additional4600" = "additional4600",

  "additional5100" = "additional5100",
  "additional5200" = "additional5200",
  "additional5500" = "additional5500",
  "additional5550" = "additional5550",
  "additional5600" = "additional5600",

  "additional6100" = "additional6100",
  "additional6200" = "additional6200",
  "additional6500" = "additional6500",
  "additional6550" = "additional6550",
  "additional6600" = "additional6600",

  "additional7100" = "additional7100",
  "additional7200" = "additional7200",
  "additional7500" = "additional7500",
  "additional7550" = "additional7550",
  "additional7600" = "additional7600",

  "additional8100" = "additional8100",
  "additional8200" = "additional8200",
  "additional8500" = "additional8500",
  "additional8550" = "additional8550",
  "additional8600" = "additional8600",

  "additional9100" = "additional9100",
  "additional9200" = "additional9200",
  "additional9500" = "additional9500",
  "additional9550" = "additional9550",
  "additional9600" = "additional9600",

  "additional10100" = "additional10100",
  "additional10250" = "additional10250",
  "additional10500" = "additional10500",
  "additional10550" = "additional10550",
  "additional10600" = "additional10600",

  "additional11100" = "additional11100",
  "additional11200" = "additional11200",
  "additional11500" = "additional11500",
  "additional11550" = "additional11550",
  "additional11600" = "additional11600",

  "additional12050" = "additional12050",
  "additional12100" = "additional12100",
  "additional12500" = "additional12500",
  "additional12550" = "additional12550",
  "additional12600" = "additional12600",

  "additional13100" = "additional13100",
  "additional13200" = "additional13200",
  "additional13500" = "additional13500",
  "additional13550" = "additional13550",
  "additional13600" = "additional13600",
  // fonts
  fontWeightMedium = "fontWeightMedium",
  fontWeightBold = "fontWeightBold",
  paragraphL = "paragraphL",
  fontFamilyBase = "fontFamilyBase",
  uiXXS = "uiXXS",

  //shadows
  boxShadow03 = "boxShadow03",
}

export const scssVariables = scssVars as { [key in SCSSVariablesType]: string };

export const INITIAL_DOCUMENT_TITLE = process.env.REACT_APP_TITLE || "Unlisted";
