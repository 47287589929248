import { FC } from "react";
import cn from "classnames";

import { NewAvatar, Tooltip, Ui } from "common/components/atoms";
import { UserIcon } from "common/icons/svg";

import classes from "./NameCell.module.scss";

type NameCellProps = {
  representByName?: string;
  name: string;
  isAttached: boolean;
  initials?: string;
  isCompany?: boolean;
  showBlueDot?: boolean;
  showRedDot?: boolean;
  imageUrl?: string;
};

const NameCell: FC<NameCellProps> = ({
  name,
  isAttached,
  initials,
  isCompany,
  representByName,
  showBlueDot,
  showRedDot,
  imageUrl,
}) => {
  return (
    <div className="d-flex align-items-center" style={{ gap: 12 }}>
      {showBlueDot && (
        <div
          className={cn(classes.blueCircle, {
            [classes.isTransparent]: !showBlueDot,
          })}
        />
      )}
      {showRedDot && (
        <div
          className={cn(classes.redCircle, {
            [classes.isTransparent]: !showRedDot,
          })}
        />
      )}
      <NewAvatar imageUrl={imageUrl} initials={initials || "-"} size="m" company={isCompany} />
      <div>
        <Ui.s bold>{name}</Ui.s>
        {isCompany && <Ui.xs color="foregroundLow">{representByName}</Ui.xs>}
      </div>
      {isAttached && (
        <Tooltip popupContent={<div>Already a stakeholder in the company.</div>}>
          <div className={classes.icon}>
            <UserIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default NameCell;
