import { FC } from "react";
import classNames from "classnames";
import { FormikErrors, useFormikContext } from "formik";

import { DatePicker, P, Ui } from "common/components/atoms";
import { CalendarCheckIcon } from "common/icons/svg";

import { createTranslation, TranslationNS } from "../../../../../translation";
import { ReviewEventsFormikValues } from "../ReviewEventsFormik";
import classes from "./ConfirmTransactionDate.module.scss";

type Props = {
  className?: string;
  index: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.finisCampaign.events.transactionDate");

const ConfirmTransactionDate: FC<Props> = ({ className, index }) => {
  const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext<ReviewEventsFormikValues>();

  return (
    <div className={classNames(classes.wrap, className)}>
      <div className="d-flex align-items-center">
        <div className={classes.icon}>
          <CalendarCheckIcon />
        </div>
        <Ui.m bold color="foregroundHigh">
          {t("title")}
        </Ui.m>
      </div>
      <P.m color="foregroundHigh" className="mt-1 pe-4">
        {t("description")}
      </P.m>

      <div className="col-md-3">
        <DatePicker
          className="mt-3"
          isDateOnlyString
          isWithTimeSelect
          date={values.events[index].transactedAt}
          name={`events[${index}].transactedAt`}
          label={t("label")}
          isTouched={touched?.events?.[index]?.transactedAt}
          error={(errors?.events as FormikErrors<ReviewEventsFormikValues["events"]>)?.[index]?.transactedAt}
          onBlur={handleBlur}
          onChange={(date, name) => {
            setFieldValue(name!, date);
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmTransactionDate;
